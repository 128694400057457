import { useState } from 'react'
import MessageHelperComp from '../../../../helpers/MessageHelper'
import { AutorenewRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../helpers/TooltipIcon'

const Selector: React.FC<{
  tooltipText: string
  caption: string
  selected: string
  onChange: (value: string) => void
  value: string
  icon: string
  isdisabled: boolean
  activeIcons: string
}> = ({ tooltipText, caption, selected, onChange, value, icon, isdisabled, activeIcons }) => (
  <>
    <div
      onClick={() => !isdisabled && onChange(value)}
      className={`${isdisabled ? 'bg-[#122B460D]' : 'cursor-pointer'} ${
        selected === value && 'border-SeabiscuitMainThemeColor/5 bg-SeabiscuitMainThemeColor/5'
      } min-h-[130px] border border-[#122B4633] rounded-xl pl-3 pt-1 pr-1 pb-2 flex flex-col w-full`}
    >
      <div className="inline-flex items-center justify-end h-6">
        <Tooltip title={<h1 className="tooltip_title">{tooltipText}</h1>} placement="top" arrow>
          <button className="" tabIndex={-1}>
            <TooltipIcon
              color={selected === value ? '#F70763' : '#8C95AC'}
              height={20}
              width={20}
            />
            {/* <img src={hintIcon} alt="icon" /> */}
          </button>
        </Tooltip>
      </div>
      <img src={selected === value ? activeIcons : icon} className="w-8 h-8" alt="icon" />
      <span className="text-[10px] h-5 pt-1 mt-1 text-[#122B4680] italic">
        {isdisabled ? 'Coming soon' : null}
      </span>

      <span
        className={`text-[14px] font-[400] ${
          selected === value ? 'text-SeabiscuitMainThemeColor' : 'text-[#122B4680]'
        }`}
      >
        {caption}
      </span>
    </div>
  </>
)

const Prototype03Button: React.FC<{
  className?: string
  caption: string
  type: number
  disabled?: boolean
  onClick?: () => void
  icon?: boolean
  loading: boolean
}> = ({ className, caption, type, disabled, onClick, icon, loading }) => {
  const buttonTypeClassGenerator = () => {
    switch (type) {
      case 1:
        return 'items-center py-3 px-4 rounded-xl w-full relative bg-SeabiscuitMainThemeColor text-white shadow-xl disabled:opacity-[.5] border-1 border-transparent border-solid'
      case 2:
        return 'items-center py-3 px-4 rounded-xl w-full relative bg-white border-[1px] border-solid border-SeabiscuitMainThemeColor text-SeabiscuitMainThemeColor shadow-xl disabled:opacity-[.5]'
      case 3:
        return 'items-center py-3 px-4 rounded-xl w-full relative bg-[#EEEEEF] text-[#484646] shadow-xl disabled:opacity-[.5] border-1 border-transparent border-solid'
    }
  }
  return (
    <button
      onClick={onClick}
      className={`${buttonTypeClassGenerator()} ${className ?? ''} w-`}
      disabled={disabled}
    >
      {loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : caption}
    </button>
  )
}

interface EventCreateShowInitialDialogStartProps {
  onSelection: any
}

const EventCreateShowInitialDialogPrimary: React.FC<EventCreateShowInitialDialogStartProps> = (
  props
) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [eventName, setEventName] = useState<string>('')
  const [errors] = useState({ eventName: '' })
  const [loading, setLoading] = useState<boolean>(false)

  const selectorHandle = (value: string) => {
    setSelectedOption(value)
  }

  const onMainSelectionConfirm = () => {
    setLoading(true)
    props.onSelection(selectedOption, eventName)
  }

  return (
    <div className="w-xl m-auto px-5">
      <div className="text-md text-SeabiscuitDark200ThemeColor mb-2">Select event type</div>
      <div className="w-full shrink-0 md:min-w-[300px] gap-2 flex flex-col md:flex-row justify-between items-center mx-auto">
        <Selector
          icon="/assets/cp_icons/LaurelWreath.svg"
          activeIcons="/assets/cp_icons/LaurelWreath-2.svg"
          caption="Licensed Comp."
          value="licensed"
          tooltipText="New licensed horse competition"
          isdisabled={false}
          selected={selectedOption}
          onChange={selectorHandle}
        />

        <Selector
          icon="/assets/cp_icons/Prize.svg"
          activeIcons="/assets/cp_icons/Prize-2.svg"
          caption="Unlicensed"
          value="unlicensed"
          tooltipText="New unlicensed equestrian competition"
          isdisabled={false}
          selected={selectedOption}
          onChange={selectorHandle}
        />

        <Selector
          icon="/assets/cp_icons/GraduationCap.svg"
          activeIcons="/assets/cp_icons/GraduationCap-2.svg"
          caption="Clinic"
          value="clinic"
          tooltipText="New horse clinic"
          isdisabled={false}
          selected={selectedOption}
          onChange={selectorHandle}
        />

        <Selector
          icon="/assets/cp_icons/TwoTickets.svg"
          activeIcons="/assets/cp_icons/Two Tickets-2.svg"
          caption="Other"
          value="other"
          tooltipText="New equestrian event other than a competition or clinic"
          isdisabled={false}
          selected={selectedOption}
          onChange={selectorHandle}
        />
      </div>

      <div>
        {selectedOption !== '' ? (
          <>
            <div className="bg-[#122B461A] h-[1px] w-full mt-8 mb-5 block"></div>
            <label className="block mt-4 mb-0.5 mx-2">
              <div className="text-md capitalize text-SeabiscuitDark200ThemeColor mb-2">
                Name event
              </div>
              <input
                onChange={(e: any) => setEventName(e.target.value)}
                className={`placeholder:text-SeabiscuitGray600ThemeColor block w-full border rounded-xl p-4 focus:outline-none focus:text-SeabiscuitMainThemeColor focus:bg-SeabiscuitMainThemeColor/5 focus:border-SeabiscuitMainThemeColor/5 text-sm ${
                  eventName.length
                    ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                    : '  text-SeabiscuitDark200ThemeColor border-SeabiscuitGray500ThemeColor'
                } `}
                placeholder="Enter a name for this event...."
              />
            </label>

            {errors.eventName !== '' ? (
              <MessageHelperComp className="mx-2" isError={true} message={errors.eventName} />
            ) : null}
          </>
        ) : null}

        <div className="md:w-52 w-full mt-[80px] mx-auto">
          <Prototype03Button
            caption="NEXT"
            type={1}
            loading={loading}
            disabled={selectedOption === '' || eventName === ''}
            onClick={onMainSelectionConfirm}
          />
        </div>
      </div>
    </div>
  )
}

export default EventCreateShowInitialDialogPrimary
