import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { sumBy } from 'lodash'

import ExhibitorRegistrationAccordion from './accordions/ExhibitorRegistrationAccordion'

import Accordion from '../../../components/accordion/common/Accordion'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IScratch } from '../../../store/events/eventsSlice'
import { setExhibitorTotals } from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch } from '../../../store/hooks'
import ExhibitorRegistrationRefundAccordion from './accordions/ExhibitorRegistrationRefundAccordion'
import { IOnRefundAmountChangeFn } from './ExhibitorProfileDisplayTab'
import { toFixed } from '../../../helpers/helpers'
import useHorse from '../manage/common/table/HorsesManage/hooks/useHorse'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorProfileTeamTabProps = {
  isRefund: boolean
  registrationsByDay?: IRegistrationByDayInterface[]
  isRecipient?: boolean
  showScratchedView: boolean
  onRefundAmountChange?: IOnRefundAmountChangeFn
  getScratchView: (scratchType: IScratch['scratchType']) => ReactJSXElement | null
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  eventId: string
}

const ExhibitorProfileRegistrationTab = (props: IExhibitorProfileTeamTabProps) => {
  const { allHorses } = useHorse(props.eventId)

  const dispatch = useAppDispatch()
  const { getScratchView, registrationsByDay, setListToScratch, showScratchedView, listToScratch } =
    props

  const [itemsWithoutScratched, setItemsWithoutScratched] = useState<IRegistrationByDayInterface[]>(
    []
  )
  const [isOpen, setIsOpen] = useState(false)
  const [priceTotal, setPriceTotal] = useState(0)
  const [listToScratchPriceTotal, setListToScratchPriceTotal] = useState<number>(0)

  useEffect(() => {
    if (registrationsByDay && registrationsByDay.length) {
      const price = sumBy(registrationsByDay, 'registrationPrice')

      let qualifyFee = 0
      registrationsByDay.forEach((register) => {
        if (register.isQualify) qualifyFee += Number(register.qualifyFee)
      })

      setItemsWithoutScratched(registrationsByDay)
      setPriceTotal(toFixed(price + qualifyFee, 2))

      dispatch(
        setExhibitorTotals({
          keyToUpdate: 'eventsPriceTotal',
          value: price,
        })
      )
    }
  }, [registrationsByDay])

  useEffect(() => {
    if (registrationsByDay && registrationsByDay.length) {
      const listToScratchPrice = parseFloat(
        sumBy(
          registrationsByDay.filter((registration) =>
            listToScratch.includes(registration.id ?? '')
          ),
          'registrationPrice'
        ).toFixed(2)
      )
      setListToScratchPriceTotal(listToScratchPrice)
    }
  }, [listToScratch, registrationsByDay])

  return (
    <>
      {/* Registration Accordion */}
      <Accordion
        icon={<AddCircleOutline className="add_icon text-[red]" />}
        initialState={true}
        className="mb-2"
        header={
          <div
            className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
          >
            Classes
            <div className="flex items-center details">
              <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
                {showScratchedView
                  ? `${listToScratch.length} ${listToScratch.length > 1 ? 'items' : 'item'} scratched, $${listToScratchPriceTotal} refund requested`
                  : `${listToScratch.length ? listToScratch.length : itemsWithoutScratched?.length} Registration${itemsWithoutScratched.length > 0 ? '' : 's'}, $${toFixed(priceTotal, 2)}`}
              </div>
            </div>
          </div>
        }
        headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
      >
        {props?.isRefund ? (
          <ExhibitorRegistrationRefundAccordion
            registrationsByDay={registrationsByDay ?? []}
            onRefundAmountChange={props.onRefundAmountChange}
          />
        ) : (
          <ExhibitorRegistrationAccordion
            registrationsByDay={registrationsByDay ?? []}
            registrationPrice={priceTotal}
            showScratchedView={props.showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            horses={allHorses}
          />
        )}
        {getScratchView('event')}
      </Accordion>
    </>
  )
}

export default ExhibitorProfileRegistrationTab
