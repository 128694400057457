// Third party
import moment from 'moment'

import { formatDate } from '../../../../helpers/helpers'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types

type Paperwork = {
  title: string
  document: string
  expiration: string
  problems: boolean
}

type CompetitionNumber = {
  pic: string
  title: string
  number: string
  expiration: string | { seconds: number }
  problems: boolean
}

type TrackingNumber = {
  title: string
  number: string
  expiration: any
  problems: boolean
}

type Bio = {
  title: string
  content: string
}

type Data = {
  horseName: string
  horseClass: string
  horsePic: string
  paperwork: {
    title: string
    content: Paperwork[]
  }
  competitionNumbers: {
    title: string
    content: CompetitionNumber[]
  }
  trackingNumbers: {
    title: string
    content: TrackingNumber[]
  }
  bio: {
    title: string
    content: Bio[]
  }
}

const ExhibitorHorseInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="h-[38px] w-[38px] p-0 rounded-full" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRowPaperwork = (props: Paperwork) => {
  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0  flex flex-col lg:flex-row lg:items-center w-full">
      <div className="lg:hidden font-medium text-[12px]">Paperwork</div>
      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3  lg:m-1 lg:w-1/2 ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={props.title}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Document</div>
      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={
          props.document === 'empty' ? (
            ''
          ) : props.document ? (
            <div
              className="flex items-center justify-center relative cursor-pointer"
              onClick={() => {
                window.open(props.document, '_blank')
              }}
            >
              <img
                className="absolute left-1 top-0"
                src={
                  props.problems
                    ? '/assets/cp_icons/FullScreen-2.svg'
                    : '/assets/cp_icons/FullScreen-1.svg'
                }
                alt="FullScreen-1.svg"
              />
              View Document
            </div>
          ) : (
            'No document on file'
          )
        }
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Date</div>
      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={
          props.expiration && moment(props.expiration).isValid()
            ? formatDate(props.expiration)
            : !props.expiration && 'N/A'
        }
      />
    </div>
  )
}

const DetailRowCompetitionNumber = (props: CompetitionNumber) => {
  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row lg:items-center w-full">
      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] flex items-center gap-4 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 mb-2 lg:m-1 lg:w-1/2 ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={props.title}
        icon={props.pic}
      />

      <ExhibitorHorseInput
        className={`rounded-lg ellipsis xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 mb-2 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={props.number !== '' ? props.number : 'No number on file'}
      />

      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={
          props.expiration && moment(props.expiration).isValid()
            ? formatDate(props.expiration)
            : !props.expiration && 'N/A'
        }
      />
    </div>
  )
}

const DetailRowTrackingNumber = (props: TrackingNumber) => {
  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0  flex flex-col lg:flex-row lg:items-center w-full">
      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] flex items-center gap-4 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/2 ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={props.title}
      />

      <ExhibitorHorseInput
        className={`rounded-lg ellipsis xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 my-2 lg:m-1 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={props.number !== '' ? props.number : 'No number on file'}
      />

      <ExhibitorHorseInput
        className={`rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/4 text-center ${props.problems ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : ''}`}
        data={
          props.expiration && moment(props.expiration).isValid()
            ? formatDate(props.expiration)
            : !props.expiration && 'N/A'
        }
      />
    </div>
  )
}

const DetailRowBio = (props: Bio) => {
  return (
    <div className="flex mt-2 items-center w-full gap-2 lg:gap-0">
      <ExhibitorHorseInput
        className="rounded-lg xl:text-[14px] flex items-center gap-4 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-3/4"
        data={props.title}
      />

      <ExhibitorHorseInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 lg:w-1/4 text-center"
        data={props.content !== '' ? props.content : 'N/A'}
      />
    </div>
  )
}

const paperworkProblems = (props: Paperwork) => {
  const today = new Date()
  const expirationParts = props.expiration?.split('/')
  const expirationDate =
    expirationParts?.length > 0 &&
    new Date(`${expirationParts[2]}-${expirationParts[0]}-${expirationParts[1]}`)

  const expired = expirationDate < today || props.expiration === '' || props.expiration === 'No'
  const noDocument = !props.document

  return expired || noDocument
}

const competitionNumberProblems = (props: CompetitionNumber) => {
  const today = new Date()
  let expiration = props.expiration

  if ((expiration as { seconds: number })?.seconds) {
    expiration = `${new Date((expiration as { seconds: number }).seconds * 1000)}`
  }
  const expirationParts = moment(props.expiration).isValid()
    ? (expiration as string)?.split('/')
    : []
  const expirationDate =
    expirationParts?.length > 0 &&
    new Date(`${expirationParts[2]}-${expirationParts[0]}-${expirationParts[1]}`)

  return expirationDate < today
}

const trackingNumberProblems = (props: TrackingNumber) => {
  if (props?.expiration) {
    const today = new Date()
    let expiration
    if (props?.expiration?.seconds) {
      expiration = new Date(props?.expiration?.seconds * 1000)
    } else {
      expiration = new Date(props?.expiration)
    }
    return expiration < today
  } else {
    return true
  }
}

const ExhibitorHorseAccordion: React.FC<Data> = ({
  paperwork,
  competitionNumbers,
  trackingNumbers,
  bio,
}) => {
  const paperworkProblemCount = paperwork.content.reduce((count, currentItem) => {
    return count + (paperworkProblems(currentItem) ? 1 : 0)
  }, 0)

  const competitionNumberProblemCount = competitionNumbers.content.reduce((count, currentItem) => {
    return count + (competitionNumberProblems(currentItem) ? 1 : 0)
  }, 0)

  const trackingNumberProblemCount = trackingNumbers.content.reduce((count, currentItem) => {
    return count + (trackingNumberProblems(currentItem) ? 1 : 0)
  }, 0)

  return (
    <>
      <div className="flex justify-between">
        <div className="hidden lg:block ml-2 font-semibold text-md mt-2">{paperwork.title}</div>
        <div className="mr-2">{paperworkProblemCount} problems found</div>
      </div>

      {paperwork.content.map((currentItem, index) => {
        return (
          <DetailRowPaperwork
            key={`${JSON.stringify(currentItem)}${index}`}
            title={currentItem.title}
            document={currentItem.document}
            expiration={currentItem.expiration}
            problems={paperworkProblems(currentItem)}
          />
        )
      })}

      <hr className="my-4"></hr>

      <div className="flex justify-between">
        <div className="ml-2 font-semibold mt-2">{competitionNumbers.title}</div>
        <div className="mr-2">{competitionNumberProblemCount} problems found</div>
      </div>

      {competitionNumbers.content.map((currentItem, index) => {
        return (
          <DetailRowCompetitionNumber
            key={`${JSON.stringify(currentItem)}${index}`}
            pic={currentItem.pic}
            title={currentItem.title}
            number={currentItem.number}
            expiration={currentItem.expiration}
            problems={competitionNumberProblems(currentItem)}
          />
        )
      })}

      <hr className="my-4"></hr>

      <div className="flex justify-between">
        <div className="ml-2 font-semibold mt-2">{trackingNumbers.title}</div>
        <div className="mr-2">{trackingNumberProblemCount} problems found</div>
      </div>
      {trackingNumbers.content.map((currentItem, index) => {
        return (
          <DetailRowTrackingNumber
            key={`${JSON.stringify(currentItem)}${index}`}
            title={currentItem.title}
            number={currentItem.number}
            expiration={currentItem.expiration}
            problems={trackingNumberProblems(currentItem)}
          />
        )
      })}

      <hr className="my-4"></hr>
      <div className="ml-2 font-semibold mt-2">{bio.title}</div>
      {bio.content.map((currentItem, index) => {
        return (
          <DetailRowBio
            key={`${JSON.stringify(currentItem)}${index}`}
            title={currentItem.title}
            content={currentItem.content}
          />
        )
      })}
    </>
  )
}

export default ExhibitorHorseAccordion
