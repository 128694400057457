import React from 'react'
import { StepTitle } from './StepTitle'
import { IRider } from '../../../../types/entryRider'
import Select from 'react-select'
import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'
import { IconPlusCircle } from '../../../icons/IconPlusCircle'
import { IOption } from '../../../inputs/InputNewMemberRow'
import { IconDelete2 } from '../../../icons/IconDelete2'

export interface INewMembers {
  member: IOption | null
  role: IOption | null
}

export type THandlerMemberType = 'role' | 'member'

interface IProps {
  users: IOption[]
  currentRider: IRider
  newMembers: INewMembers[]
  updateHandlerNewMember: (index: number, itm: IOption, type: THandlerMemberType) => void
  addHandlerNewMember: () => void
  removeMemberHandler: (index: number) => void
  roles: IOption[]
}

export const SetTeamMembers: React.FC<IProps> = ({
  currentRider,
  users,
  newMembers,
  addHandlerNewMember,
  updateHandlerNewMember,
  removeMemberHandler,
  roles,
}) => {
  return (
    <>
      <StepTitle
        title={'Add team members'}
        desc={'Pegasus will automatically send team members liability paperwork to sign'}
      />
      <div className={'flex flex-col gap-3'}>
        <div className={'flex gap-3 w-full'}>
          <div className={'bg-[#F6F7FB] rounded flex items-center box-border h-[56px] flex-1'}>
            <span className={'text-[#122B4680] text-[14px] px-[17px] '}>
              {currentRider.riderName}
            </span>
          </div>
          <div className={'bg-[#F6F7FB] rounded flex box-border  items-center h-[56px] flex-[0.5]'}>
            <span className={'text-[#122B4680] text-[14px]  px-[17px]'}>Rider</span>
          </div>
          <div className={'w-[20px]'} />
        </div>
        {newMembers.map((itm, index) => (
          <div
            key={index + `${itm.role?.value}_${itm.member?.value}`}
            className={'flex gap-3 w-full'}
          >
            <Select
              className={`border rounded-md w-40 !border-[#D3DAEE] flex-1 box-border`}
              isClearable={false}
              value={itm.member}
              onChange={(newMember) => {
                if (newMember) updateHandlerNewMember(index, newMember as IOption, 'member')
              }}
              styles={customStyles}
              isSearchable={true}
              isMulti={false}
              options={users}
              placeholder={'Select team member'}
            />
            <Select
              isMulti={false}
              placeholder={<div className="flex text-[13px]">Role</div>}
              options={roles}
              onChange={(role) => {
                if (role) updateHandlerNewMember(index, role as IOption, 'role')
              }}
              value={itm.role}
              className={`border rounded-md w-40 !border-[#D3DAEE] flex-[0.5] box-border`}
              isClearable={false}
              styles={customStyles}
              isSearchable={false}
            />
            <button onClick={() => removeMemberHandler(index)}>
              <IconDelete2 />
            </button>
          </div>
        ))}

        <div className={'flex justify-center mt-3'}>
          <button
            onClick={addHandlerNewMember}
            className={
              'group flex gap-2 text-[#122B4680] hover:text-SeabiscuitMainThemeColor text-[12px] items-center'
            }
          >
            <IconPlusCircle />
            <span className={'underline'}>Add person</span>
          </button>
        </div>
      </div>
    </>
  )
}
