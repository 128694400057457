import React, { useState } from 'react'
import { IUserInterface } from '../../models/users/user.interface'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'
import { CONST } from '../../const/const'
import { IRider } from '../../types/entryRider'
import {
  SwitchHorseHeader,
  SelectRiderClass,
  SelectNewHorse,
  SetTeamMembers,
  INewMembers,
  THandlerMemberType,
} from './components/switchHorse'
import FirestoreService from '../../services/firestoreService'
import { getConvertedData } from '../../models/interface.helper'
import { EventReviewPublishModel } from '../../models/event-review-publish/event-review-publish.model'
import useToasterHelper from '../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import ViewsLoader from '../loader/ViewsLoader'
import { RegistrationFeesType } from '../../models/event-fees/event-fees.interface'
import { HorseModel } from '../../models/horse/horse.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { IOption } from '../inputs/InputNewMemberRow'
import { Confirm } from './components/switchHorse/Confirm'
import { where } from 'firebase/firestore'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { IDocumentList } from '../../fakeData/fakeDocumentList'
import helpers from '../../commonHelpers/helpers'
import { CustomError, getUserFullName } from '../../helpers/helpers'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { getFilteredPaperworks } from '../../helpers/documents'
import { signedStatuses } from '../../const/data/v01/rider-team-members/firestore-v01-rider-team-member-const'
import { useAppSelector } from '../../store/hooks'
import { selectAllUsers } from '../../store/users/usersSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

const FILE_NAME = 'addTeamMemberToPaperwork'

const ROLES = Object.values(TEAM_MEMBER_ROLES)
  .filter((role) => role !== TEAM_MEMBER_ROLES.RIDER)
  .map((value) => ({
    value,
    label: value,
  }))

type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    rider: IRider
    cb: (horse: IHorseData, horses: IHorseData[], horseIds: string[]) => void
  }
}

const HORSES_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

enum EScreens {
  loader,
  entry,
  horse,
  teamMember,
  confirm,
}

export const SwitchHorse: React.FC<IProps> = (props) => {
  const [step, setStep] = useState<EScreens>(EScreens.loader)
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState<IEventReviewPublish | null>(null)
  const [entries, setEntries] = useState<RegistrationFeesType[]>([])
  const [selectedEntries, setSelectedEntries] = useState<RegistrationFeesType[]>([])
  const toastFunctions = useToasterHelper()
  const [isBtnNextDisabled, setIsBtnNextDisabled] = useState(true)
  const [horses, setHorses] = useState<IHorseData[]>([])
  const [selectedHorse, setSelectedHorse] = useState<IHorseData | null>(null)
  const [selectedRiderMultiHorse, setSelectedRiderMultiHorse] = useState<IHorseData | null>(null)
  const [isMultiHorseShown, setIsMultiHorseShown] = useState(false)
  const users = useAppSelector(selectAllUsers)
  const { rider, cb } = props.dataToPassOn
  const [exibitor, setExibitor] = useState<IUserInterface | null>(null)
  const [newMembers, setNewMembers] = useState<INewMembers[]>([
    {
      member: null,
      role: null,
    },
  ])

  React.useEffect(() => {
    setIsMultiHorseShown(rider.horses.length > 1)
  }, [rider])

  React.useEffect(() => {
    ;(() => {
      switch (step) {
        case EScreens.horse:
          if (isMultiHorseShown && selectedRiderMultiHorse) {
            setIsBtnNextDisabled(false)
          } else {
            setIsBtnNextDisabled(!selectedHorse)
          }

          break
        case EScreens.entry:
          setIsBtnNextDisabled(!selectedEntries[0])
          break

        case EScreens.teamMember:
        case EScreens.confirm:
          setIsBtnNextDisabled(false)
          break

        case EScreens.loader:
        default:
          setIsBtnNextDisabled(true)
          break
      }
    })()
  }, [step, selectedEntries, selectedHorse, isMultiHorseShown, selectedRiderMultiHorse])

  const getEntriesFromEvent = (event: IEventReviewPublish) => {
    const _entries: RegistrationFeesType[] = []

    if (event.EventFees.registrationFees && Array.isArray(event.EventFees.registrationFees)) {
      event.EventFees.registrationFees.forEach((fee) => {
        _entries.push(fee)
      })
    }

    return _entries
  }

  const getUsers = async () => {
    const currUser = users.find((user) => user.id === props.dataToPassOn.rider.userId)
    setExibitor(currUser || null)
  }

  const getEventFromDb = React.useCallback(async () => {
    if (!props.dataToPassOn.rider) return toastFunctions.error({ message: 'Rider does not exist' })
    try {
      const publishedEventInDbSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        props.dataToPassOn.rider.eventId || ''
      )
      const convertedData = getConvertedData(
        EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
      )

      const _horses: IHorseData[] = []

      const allHorse = await FirestoreService.filterItems(HORSES_TABLE.NAME, [])

      allHorse.docs.forEach((doc) => {
        let data = HorseModel.fromFirestoreDoc(doc).toObject()
        _horses.push(data)
      })

      setHorses(_horses)

      const _entries = getEntriesFromEvent(convertedData)
        .filter((entr) => props.dataToPassOn.rider.classes.includes(entr.name || 'Registration'))
        .map((itm) => ({
          ...itm,
          name: itm.name || 'Registration',
        }))

      await getUsers()

      setEntries(_entries)
      setEventData(convertedData)
      if (_entries.length === 1) {
        setSelectedEntries(_entries)
        setStep(EScreens.horse)
      } else {
        setStep(EScreens.entry)
      }
    } catch (e) {
      console.log('=>(SwitchHorse.tsx:56) e', e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }, [])

  React.useEffect(() => {
    getEventFromDb().then(() => null)
  }, [getEventFromDb])

  const entrySelectHandler = (entry: RegistrationFeesType) => {
    const _entries: RegistrationFeesType[] = [...selectedEntries]

    const index = _entries.findIndex((itm) => itm.uuid === entry.uuid)

    if (index >= 0) {
      _entries.splice(index, 1)
    } else {
      _entries.push(entry)
    }

    setSelectedEntries(_entries)
  }
  const horseSelectHandler = (item: IHorseData) => {
    let newSelectedHorse = selectedHorse ? { ...selectedHorse } : null

    if (newSelectedHorse?.id === item.id) {
      newSelectedHorse = null
    } else {
      newSelectedHorse = item
    }

    setSelectedHorse(newSelectedHorse)
  }

  const updateHandlerNewMember = (index: number, itm: IOption, type: THandlerMemberType) => {
    const copyNewMembers = [...newMembers]

    if (!copyNewMembers[index]) return null

    copyNewMembers[index][type] = itm

    setNewMembers(copyNewMembers)
  }

  const addHandlerNewMember = () => {
    const copyNewMembers = [...newMembers]

    copyNewMembers.push({
      member: null,
      role: null,
    })

    setNewMembers(copyNewMembers)
  }
  const removeMemberHandler = (index: number) => {
    const copyNewMembers = [...newMembers]
    copyNewMembers.splice(index, 1)
    setNewMembers(copyNewMembers)
  }
  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const updateRidersTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      ridersTeamMember.id,
      new RiderTeamMemberModel(ridersTeamMember).toFirestore()
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  const updateUserDocuments = async (userDocument: IUserDocument) => {
    await FirestoreService.updateItem(
      COLLECTIONS.USERS_DOCUMENTS.NAME,
      userDocument.id,
      new UserDocumentModel(userDocument).toFirestore()
    )
  }

  const savePaperworkTabData = async ({
    filteredPaperworkDocuments,
    ridersTeamMembersToAdd,
    ridersTeamMembersToUpdate,
    registeredUser,
    userDocumentsToUpdate,
  }: {
    ridersTeamMembersToAdd: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate: IRiderTeamMemberInterface[]
    filteredPaperworkDocuments: IDocumentList
    registeredUser: IUserInterface
    userDocumentsToUpdate: IUserDocument[]
  }) => {
    const userDocumentsToAdd: IUserDocument[] = []
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'savePaperworkTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRidersTeamMember({
            ...new RiderTeamMemberModel(ridersTeamMembersToUpdate![currIndex]).toFirestore(),
            id: ridersTeamMembersToUpdate![currIndex].id,
          })
        },
      })

      if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
        for (const document of filteredPaperworkDocuments) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            userDocumentsToAdd.push(
              getConvertedData({
                eventId: props.dataToPassOn.rider!.eventId!,
                u: false,
                status: 'Not Signed',
                riderId: ridersTeamMember.riderId,
                riderName: ridersTeamMember.riderName,
                activityUser: registeredUser ? getUserFullName(registeredUser) : '',
                competitorId: registeredUser?.id,
                eventLogo: eventData!.EventDetails.eventLogo!,
                eventName: eventData!.EventDetails!.competitionName!,
                documentOwner: ridersTeamMember.teamMemberId,
                documentName: document.key,
                documentUrl: document.document_image[0],
                documentOriginalName: document.document_name,
                documentNameAsPerPdf: '',
                signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
                signatoryDefaultRole: ridersTeamMember.teamMemberRole,
                signatoryEmail: ridersTeamMember.teamMemberEmail,
                signatoryName: ridersTeamMember.teamMemberName,
                signatoryId: ridersTeamMember.teamMemberId,
                reminder: false,
                registrationDocId: rider.registrationDocId,
                registrationByDayDocId: ridersTeamMember.registrationByDayDocId,
                registrationByDayUuid: ridersTeamMember.registrationByDayUuid || '',
                registrationByDayUniqId: ridersTeamMember.registrationByDayUniqId || '',
                riderTeamMemberDocId: ridersTeamMember.id ?? null,
                pageNumberToSignOn: null,
                coordinatesToSignOn: { x: null, y: null },
              })
            )
          }
        }
      }

      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
          },
        })
      }

      if (userDocumentsToUpdate.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToUpdate?.length ?? 0,
          functionToFire: async (currIndex) => {
            await updateUserDocuments({
              ...new UserDocumentModel(userDocumentsToUpdate![currIndex]).toFirestore(),
              id: userDocumentsToUpdate![currIndex].id,
            })
          },
        })
      }
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const switchHandler = async () => {
    if (!eventData || !exibitor) return null

    setLoading(true)

    const universalOrganizerWaver = !!eventData.EventPaperwork.universalOrganizerWaver
    const USEFWaiverAndReleaseOfLiability =
      !!eventData.EventPaperwork.USEFWaiverAndReleaseOfLiability
    const USEFEntryAgreement = !!eventData.EventPaperwork.USEFEntryAgreement
    const USDFWaiverAndReleaseOfLiability =
      !!eventData.EventPaperwork.USDFWaiverAndReleaseOfLiability
    const USEAWaiverAndReleaseOfLiability =
      !!eventData.EventPaperwork.USEAWaiverAndReleaseOfLiability
    const USHJAWaiverAndReleaseOfLiability =
      !!eventData.EventPaperwork.USHJAWaiverAndReleaseOfLiability

    const filteredPaperworkDocuments = getFilteredPaperworks({
      universalOrganizerWaver,
      USEFWaiverAndReleaseOfLiability,
      USEFEntryAgreement,
      USDFWaiverAndReleaseOfLiability,
      USEAWaiverAndReleaseOfLiability,
      USHJAWaiverAndReleaseOfLiability,
    })

    const mailLog = filteredPaperworkDocuments.map((paperwork) => ({
      status: signedStatuses.NOT_SIGNED,
      paperworkKey: paperwork.key,
    }))

    try {
      let registrationByDay: IRegistrationByDayInterface = new RegistrationByDayModel()
      const teamMembers: IRiderTeamMemberInterface[] = []
      const newTeamMembers: IRiderTeamMemberInterface[] = []
      const userDocuments: IUserDocument[] = []

      const registeredUsersSnap = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', rider.eventId)]
      )

      registeredUsersSnap.forEach((user) => {
        const registrationByDay_ = getConvertedData(
          RegistrationByDayModel.fromFirestoreDoc(user).toObject()
        )

        const hasClass = selectedEntries.find(
          (entr) => entr.name === registrationByDay_.registrationByDayName
        )

        if (
          hasClass &&
          registrationByDay_.riderId === rider.riderId &&
          registrationByDay_.horseId === rider.horseId &&
          registrationByDay_.id === rider.id
        ) {
          registrationByDay = registrationByDay_
        }
      })

      const teamMembersSnap = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', rider.eventId),
          where(
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY,
            '==',
            registrationByDay.id
          ),
        ]
      )

      if (teamMembersSnap.size) {
        teamMembersSnap.forEach((currDoc) => {
          const teamMember = getConvertedData(
            RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject()
          )
          teamMembers.push({
            ...teamMember,
            mailLog: mailLog,
          })
        })
      }

      const userDocumentsSnap = await FirestoreService.filterItems(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        [
          where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', rider.eventId),
          where(
            COLLECTIONS.USERS_DOCUMENTS.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY,
            '==',
            registrationByDay.id
          ),
        ]
      )

      if (userDocumentsSnap.size) {
        userDocumentsSnap.forEach((currDoc) => {
          const document = getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
          userDocuments.push({
            ...document,
            status: 'Not Signed',
          })
        })
      }

      if (exibitor && selectedHorse) {
        for (let newTeamMember of newMembers) {
          const user = users.find((u) => u.id === newTeamMember.member?.value)

          if (user && newTeamMember.member && newTeamMember.role) {
            newTeamMembers.push(
              new RiderTeamMemberModel({
                userId: exibitor?.id,
                registrationDocId: rider.registrationDocId ?? null,
                eventId: registrationByDay.eventId!,
                riderDob: registrationByDay.riderDob,
                riderId: registrationByDay.riderId,
                riderName: registrationByDay.riderName!,
                eventName: registrationByDay.eventName ?? null,
                registrationByDayDocId: registrationByDay.id ?? null,
                registrationByDayUuid: registrationByDay.uuid,
                registrationByDayUniqId: registrationByDay.uniqId || '',
                teamMemberId: user.id ?? null,
                teamMemberDob: user.userDOB ?? null,
                teamMemberName: user.userName,
                teamMemberRole: newTeamMember?.role?.value || '',
                teamMemberEmail: user.userEmail ?? null,
                teamMemberProfilePicture: user.userProfilePicture ?? null,
                mailLog,
              }).toObject()
            )
          }
        }
      }

      await savePaperworkTabData({
        filteredPaperworkDocuments: filteredPaperworkDocuments,
        registeredUser: exibitor,
        ridersTeamMembersToAdd: newTeamMembers,
        ridersTeamMembersToUpdate: teamMembers,
        userDocumentsToUpdate: userDocuments,
      })

      const isMulti = rider.horses.length > 1
      const isMainHorse =
        selectedRiderMultiHorse && selectedRiderMultiHorse.id === registrationByDay.horseId
      const mainHorse = rider.horses.find((h) => h.id === registrationByDay.horseId)
      const newHorses =
        isMulti && selectedRiderMultiHorse
          ? [...rider.horses.filter((h) => h.id !== selectedRiderMultiHorse.id), selectedHorse!]
          : [selectedHorse!]
      const newHorsesIds = newHorses.map((h) => h!.id || '')

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        registrationByDay.id,
        {
          horseId: isMulti
            ? selectedRiderMultiHorse && isMainHorse
              ? selectedHorse?.id
              : registrationByDay.horseId
            : selectedHorse?.id,
          horseName: isMulti
            ? selectedRiderMultiHorse && isMainHorse
              ? selectedHorse?.horseName
              : registrationByDay.horseName
            : selectedHorse?.horseName,
          horseProfilePicture: isMulti
            ? selectedRiderMultiHorse && isMainHorse
              ? selectedHorse?.horseProfilePicture
              : registrationByDay.horseProfilePicture
            : selectedHorse?.horseProfilePicture,
          horseIds: newHorsesIds,
        }
      )

      if (selectedHorse) {
        cb(
          isMulti && isMainHorse ? selectedHorse : mainHorse || selectedHorse,
          newHorses,
          newHorsesIds
        )
      }
    } catch (e) {
      console.log('=>(SwitchHorse.tsx:321) e', e)
    } finally {
      setLoading(false)
    }
  }

  const renderModalBody = () => {
    console.log('=>(SwitchHorse.tsx:607) step', step)

    switch (step) {
      case EScreens.loader:
        return (
          <>
            <ViewsLoader
              className="flex items-center w-full justify-center !h-[300px]"
              size="xl"
              color="red"
            />
          </>
        )
      case EScreens.entry:
        return (
          <SelectRiderClass
            selectedEntries={selectedEntries}
            entries={entries}
            selectHandler={entrySelectHandler}
          />
        )
      case EScreens.teamMember:
        return (
          <SetTeamMembers
            newMembers={newMembers}
            users={users.map((u) => {
              const label = getUserFullName(u)

              return {
                value: u.id,
                label: label || '',
              }
            })}
            currentRider={props.dataToPassOn.rider}
            addHandlerNewMember={addHandlerNewMember}
            updateHandlerNewMember={updateHandlerNewMember}
            removeMemberHandler={removeMemberHandler}
            roles={ROLES}
          />
        )

      case EScreens.horse:
        return (
          <SelectNewHorse
            rider={props.dataToPassOn.rider}
            selectedRiderMultiHorse={selectedRiderMultiHorse}
            setSelectedRiderMultiHorse={setSelectedRiderMultiHorse}
            isMultiHorseShown={isMultiHorseShown}
            setIsMultiHorseShown={setIsMultiHorseShown}
            horses={horses}
            selectedHorse={selectedHorse}
            selectHandler={horseSelectHandler}
          />
        )
      case EScreens.confirm:
        return (
          <Confirm
            selectedHorse={selectedHorse!}
            selectedEntries={selectedEntries!}
            rider={props.dataToPassOn.rider}
            selectedRiderMultiHorse={selectedRiderMultiHorse}
            newTeamMembers={newMembers}
          />
        )
      default:
        return (
          <>
            <p className={'text-black py-4'}>{MESSAGES_CONST.SOMETHING_WENT_WRONG}</p>
          </>
        )
    }
  }

  return (
    <>
      <MainModal
        title="Switch horse"
        show={true}
        type="SWITCH_HORSE"
        size="xl"
        titleClassName="!font-normal"
        buttons={[
          {
            label: step === EScreens.confirm ? 'SWITCH horse' : 'Next >',
            bgClass: 'bg-SeabiscuitMainThemeColor !w-full',
            loading,
            onClick: () => {
              if (step === EScreens.confirm) {
                switchHandler()
                return null
              }
              if (step === EScreens.horse && isMultiHorseShown) {
                return setIsMultiHorseShown(false)
              }
              setStep((v) => v + 1)
              return null
            },
            className: 'outline-none w-full',
            textClass: 'text-white',
            disabled: isBtnNextDisabled,
          },
          {
            label: 'Back',
            bgClass: 'bordered !w-full',
            loading,
            onClick: () => {
              console.log('=>(SwitchHorse.tsx:682) step', step)
              if (step === EScreens.horse) {
                setIsMultiHorseShown(true)
              } else {
                setStep((v) => v - 1)
              }
            },
            className: 'w-full',
            textClass: 'text-black',
            disabled:
              (entries.length === 1 && step === EScreens.horse && isMultiHorseShown) ||
              step === EScreens.entry ||
              loading,
          },
          {
            label: 'CANCEL',
            loading,
            disabled: loading,
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor !w-full',
            onClick: () => props.handleModal(false, MODAL_CONSTS.SWITCH_HORSE),
          },
        ]}
      >
        <SwitchHorseHeader rider={props.dataToPassOn.rider} />

        {renderModalBody()}
      </MainModal>
    </>
  )
}
