import React from 'react'

interface IProps {}

export const IconRulebook: React.FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" fill="none" viewBox="0 0 22 18">
      <path
        fill="#8c95ac"
        className="group-hover:fill-SeabiscuitMainThemeColor group-hover:opacity-1"
        // fillOpacity="0.5"
        d="M11 .005c-.516 0-1.031.113-1.514.338L.433 4.57a.75.75 0 000 1.36l3.58 1.672A.75.75 0 004 7.75v6.515c0 1.06.606 2.046 1.584 2.493 1.796.82 3.572 1.248 5.415 1.242h.004a.733.733 0 00.081-.004 12.961 12.961 0 004.032-.707 2.236 2.236 0 01-.6-1.382c-1.165.385-2.36.589-3.519.593-1.612.006-3.15-.358-4.789-1.106a1.24 1.24 0 01-.708-1.13V8.297l3.986 1.861a3.56 3.56 0 003.028 0L16 8.53v6.223a1.25 1.25 0 101.5-.001V7.918a.75.75 0 00-.157-.586.75.75 0 00-.386-.263h-.002l-5.39-2.5a.75.75 0 10-.63 1.362l3.555 1.648L11.88 8.8a1.972 1.972 0 01-1.758 0L2.522 5.25l7.6-3.548a1.972 1.972 0 011.757 0l7.598 3.548-2.026.946.035.017h.003l.002.002a1.743 1.743 0 01.958 1.17l3.118-1.456a.75.75 0 00.24-.176V5.75a.75.75 0 00.144-.237l.006-.015a.75.75 0 00.042-.277l-.001-.004a.75.75 0 00-.018-.142c0-.002-.002-.003-.003-.005a.745.745 0 00-.044-.13c-.002-.002-.002-.006-.003-.008l-.005-.01a.746.746 0 00-.073-.121l-.004-.004a.748.748 0 00-.086-.096l-.023-.02a.745.745 0 00-.09-.067L21.62 4.6a.75.75 0 00-.054-.03L12.514.343A3.574 3.574 0 0011 .005z"
      ></path>
    </svg>
  )
}
