import React, { ChangeEvent, useRef, useState } from 'react'

// Third party
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { v4 as uuidv4 } from 'uuid'
import clsx from 'clsx'

// Services
import FirebaseStorageService from '../../services/storageService'

// Redux
import { useSelector } from 'react-redux'
import {
  selectHorseReducer,
  setSelectedHorseCompetetionPaperwork,
} from '../../store/horses/horseSlice'
import { useAppDispatch } from '../../store/hooks'

// Constants
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'

// Helpers
import { fileObjToBase64 } from '../../commonHelpers/helpers'
import useToasterHelper from '../../helpers/ToasterHelper'

// Models
import { getConvertedData } from '../../models/interface.helper'
import { AutorenewRounded, Clear } from '@mui/icons-material'

// Types
type Props = {
  handleCloseModal: () => void
  dataToPassOn: {
    fileUploadType: string
    data: any
    setter?: (key: string, imageUrls: string[]) => void
  }
}
type IFile = {
  progress?: number
  downloadUrl: string
  file: File
  base64Code: string
  type: string
}

const STORAGE_PATH = CONST.DATA.STORAGE.HORSE.HORSE_COGGNIS_CERTIFICATE_URL.PREFIX

const AddCertificateModal = ({ handleCloseModal, dataToPassOn }: Props) => {
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()

  // Hooks and vars
  const [allFiles, setAllFiles] = useState<IFile[]>([])
  const {
    selected: {
      horse: { data: selectedHorse },
    },
  } = useSelector(selectHorseReducer)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const getFileType = (file: File): string => {
    return file.type.split('/')[1]
  }

  // Upload a single file
  const uploadFile = async () => {
    const newFilesUpdatedUrl: IFile[] = []
    try {
      // Upload the file to storage
      for (let i = 0; i < allFiles.length; i++) {
        const downloadUrl = await FirebaseStorageService.uploadFile(
          allFiles[i].file,
          allFiles[i].downloadUrl,
          (progress: number) => {
            // set progress
            allFiles[i] = { ...allFiles[i], progress: progress }
            setAllFiles([...allFiles])
          }
        )
        newFilesUpdatedUrl.push({
          ...allFiles[i],
          downloadUrl: downloadUrl as string,
        })
      }
      return newFilesUpdatedUrl
    } catch (error) {
      // On error set file Uploaded to false
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  // Uploads file to firestore storage
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const currentFiles = event.target.files ?? []
    const newImages = []

    // Converts file's file obj to base64Code
    if (currentFiles?.length > 0) {
      for (let i = 0; i < currentFiles.length; i++) {
        let currFileObj = currentFiles[i]
        const base64Code = await fileObjToBase64(currFileObj)

        const fileWithBase64Code: IFile = {
          file: currFileObj,
          base64Code: base64Code as string,
          downloadUrl: `${STORAGE_PATH}${dataToPassOn.fileUploadType ? `/${dataToPassOn.fileUploadType}` : ''}/${selectedHorse?.horseId}-${uuidv4()}`,
          type: currFileObj.type,
        }

        newImages.push(fileWithBase64Code)
      }

      setAllFiles([...allFiles, ...newImages])
    }

    if (inputRef?.current?.value) inputRef.current.value = ''
    setLoading(false)
  }

  // Upload images/files certificates to horse profile
  const updateHorseProfile = async (data: IFile[]) => {
    const imagesList = data.map((file) => file.downloadUrl)

    dispatch(
      setSelectedHorseCompetetionPaperwork({
        competitionPaperwork: {
          ...getConvertedData(dataToPassOn.data),
          [dataToPassOn.fileUploadType]: imagesList,
        },
      })
    )
    dataToPassOn?.setter?.(dataToPassOn.fileUploadType, imagesList)
  }

  // Removes a uploaded image/file
  const onRemoveImage = (index: number) => {
    // remove the image/file from array by index
    allFiles.splice(index, 1)
    setAllFiles([...allFiles])
  }

  // Closes the modal
  const onSave = async () => {
    setLoading(true)
    const response = await uploadFile()
    if (response) {
      await updateHorseProfile(response)
      if (inputRef?.current?.value) inputRef.current.value = ''
      handleCloseModal()
    } else {
      toastFunction.error({
        message: 'Error in uploading file',
      })
    }
    setLoading(false)
  }

  return (
    <div className="fixed w-full h-full left-0 top-0 z-40">
      <div
        className="bg-black/10 absolute z-10 left-0 top-0 w-full h-full"
        onClick={handleCloseModal}
      />
      <div className="bg-white relative z-20 w-full max-w-[700px] rounded-md p-4 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
        <button
          type="button"
          className="absolute top-2 right-2 w-[20px] transition-all hover:opacity-70"
          onClick={handleCloseModal}
        >
          <Clear className="text-[#122B46]" fontSize="small" />
        </button>
        <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md mt-2">
          <h5
            className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
            id="exampleModalScrollableLabel"
          >
            Add images
          </h5>
        </div>
        <div
          id="drag_drop_area"
          className={clsx(
            'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor p-4 relative rounded-md border border-dashed border-SeabiscuitMainThemeColor text-center transition-all hover:opacity-70',
            { 'pointer-events-none': allFiles?.length > 0 && loading }
          )}
        >
          <img src="/assets/img/redCloud.png" className="m-auto w-24" alt="cloudIcon" />
          {allFiles?.length ? (
            loading ? (
              'Files are being uploaded'
            ) : (
              'Files are uploaded'
            )
          ) : (
            <>
              <p className="text-SeabiscuitMainThemeColor">Drag & Drop Images Here</p>
              <p className="text-SeabiscuitMainThemeColor">OR</p>
              <div className="text-SeabiscuitWhiteThemeColor bg-SeabiscuitMainThemeColor w-fit p-2 rounded-md relative m-auto cursor-pointer mt-2">
                Browse Files
              </div>
            </>
          )}
          <input
            ref={inputRef}
            type="file"
            multiple
            accept=".jpg,.jpeg,.png,.pdf"
            className="absolute w-full h-full opacity-0 cursor-pointer left-0 top-0"
            onChange={handleFileUpload}
          />
        </div>
        <div className="mt-4">
          {allFiles.length ? (
            <p className="text-SeabiscuitDark200ThemeColor text-[16px]">Uploaded Files</p>
          ) : null}

          {allFiles.map((file, index) => (
            <div
              key={index}
              className={`flex justify-between rounded-md border border-[#D3DAEE] mt-2 items-center ${file.progress && file.progress < 100 ? 'animate-pulse' : ''}`}
            >
              <div className="w-[80px] h-[80px] overflow-hidden p-2">
                <img
                  className={clsx('w-full h-full object-cover', {
                    'border border-[#D3DAEE]': getFileType(file.file) !== 'pdf',
                  })}
                  src={
                    getFileType(file.file) === 'pdf'
                      ? '/assets/img/pdf.png'
                      : (file?.base64Code ?? '/assets/img/redCloud.png')
                  }
                  alt="fileImage"
                />
              </div>
              <div className="flex gap-y-2 flex-col w-3/4">
                <div className="text-left flex justify-between mr-3">
                  <p className="text-SeabiscuitDark200ThemeColor text-[16px] w-[80%] truncate">
                    {file?.file?.name}
                  </p>
                  {file.progress && (
                    <p className="text-gray-400 text-[16px] transition-all">{file.progress}%</p>
                  )}
                </div>
                {file.progress && (
                  <div className="text-left">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className={`${file.progress === 100 ? 'bg-[#00B6AA]' : 'bg-SeabiscuitMainThemeColor'} h-2.5 rounded-full transition-all`}
                        style={{
                          width: `${file.progress}%`,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-1/1 ml-4">
                <CancelOutlinedIcon
                  className={clsx(
                    'mr-2 cursor-pointer transition-all hover:opacity-70',
                    file.downloadUrl && !loading
                      ? 'text-[#00B6AA]'
                      : 'pointer-events-none text-SeabiscuitMainThemeColor'
                  )}
                  onClick={() => onRemoveImage(index)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center px-3 py-3 md:px-[0] gap-3 md:gap-0">
          <button
            disabled={loading || allFiles.length <= 0}
            onClick={onSave}
            className="hover:opacity-70 disabled:bg-SeabiscuitLightThemeColor disabled:text-SeabiscuitLightTextColor disabled:opacity-100 text-white bg-SeabiscuitMainThemeColor hover:text-white w-full max-w-[250px] items-center py-3 text-nr rounded-xl border-solid cursor-pointer transition-all"
          >
            {loading ? (
              <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddCertificateModal
