import React, { FC, useEffect, useRef, useState } from 'react'

import { where } from 'firebase/firestore'
import { useForm } from 'react-hook-form'

import MainModal from './common/MainModal'
import { HorsePaperwork } from '../../pages/horse/HorsePaperwork'
import { IProfileReqField } from '../../pages/competitor/clinicAndOther/Tabs/components/ProfileFields'

import useToasterHelper from '../../helpers/ToasterHelper'

import { getConvertedData } from '../../models/interface.helper'

import { IHorseCompetitorDocument } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../models/user-horse-mapping/userHorseMapping.model'

import { httpService } from '../../services/httpService'
import FirestoreService from '../../services/firestoreService'

import { MODAL_CONSTS } from '../../const/modal-const'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'

interface UpdateTeamDetailsProps {
  dataToPassOn: {
    profileFiled: IProfileReqField
    selectedHorse: IHorseData
    horsePaperwork: IHorseCompetitorDocument
    onSave: () => Promise<void>
  }
  handleModal: (showHide: boolean, typeOfModal: string) => void
}

const defaultValue = new HorseCompetitonPaperworkModel({
  ...new HorseCompetitonPaperworkModel().toObject(),
  horseMeasurementCards: [
    {
      horseShodStatus: null,
      horseMeasurementDate: null,
      horseHeightMeasurement: null,
      horseMeasurementCardType: null,
      horseLeftHeelMeasurement: null,
      horseRightHeelMeasurement: null,
      horseMeasurementCardsImage: [],
    },
  ],
}).toObject()

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const UpdateTeamDetails: FC<UpdateTeamDetailsProps> = ({ dataToPassOn, handleModal }) => {
  const toastFunctions = useToasterHelper()

  const { horsePaperwork, profileFiled } = dataToPassOn

  const imagesUrlsToDeleteFromStorage = useRef<string[]>([])

  const [loading, setLoading] = useState(false)
  const [horseMapping, setHorseMapping] = useState<IUserHorseMappingInterface | null>(null)

  const { control, watch, handleSubmit, setValue } = useForm<IHorseCompetitorDocument>({
    mode: 'onChange',
    defaultValues: horsePaperwork ?? defaultValue,
  })

  const getHorseMapping = async () => {
    setLoading(true)
    let mapping: IUserHorseMappingInterface | null = null

    const horseMappingSnapshot = await FirestoreService.filterItems(
      COLLECTIONS.USER_HORSE_MAPPING.NAME,
      [
        where(
          COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_ID.KEY,
          '==',
          dataToPassOn.selectedHorse.id
        ),
        where(
          COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_OWNER_ID.KEY,
          '==',
          dataToPassOn.selectedHorse.horseOwnerId
        ),
      ]
    )

    if (horseMappingSnapshot)
      mapping = getConvertedData(
        UserHorseMappingModel.fromFirestoreDoc(horseMappingSnapshot.docs[0]).toObject()
      )
    setHorseMapping(mapping)

    setLoading(false)
  }
  useEffect(() => {
    getHorseMapping().then()
  }, [])

  const onSubmit = async (data: any) => {
    if (!dataToPassOn.selectedHorse?.id) return

    data = {
      ...data,
      id: horsePaperwork?.id,
      horseId: dataToPassOn.selectedHorse!.horseId,
      horseOwnerId: dataToPassOn.selectedHorse!.horseOwnerId,
    }

    setLoading(true)

    let horseCompetitionPaperworkModel = new HorseCompetitonPaperworkModel(data)

    if (imagesUrlsToDeleteFromStorage.current.length) {
      await httpService({
        method: 'POST',
        url: 'remove_images',
        data: {
          urls: imagesUrlsToDeleteFromStorage.current,
        },
      })
    }

    imagesUrlsToDeleteFromStorage.current = []

    try {
      if (horsePaperwork?.id) {
        await FirestoreService.updateItem(
          COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          horsePaperwork?.id,
          horseCompetitionPaperworkModel.toFirestore()
        )
      } else {
        horseCompetitionPaperworkModel.id = horseMapping?.id
        await FirestoreService.createItemWithCustomId(
          COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          horseMapping?.id!,
          horseCompetitionPaperworkModel.toFirestore()
        )
      }
    } catch (err: any) {
      console.log(err, 'err')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
      await dataToPassOn.onSave()
    }
  }

  return (
    <MainModal
      title={`Add ${dataToPassOn.profileFiled?.title}`}
      titleClassName="!font-normal"
      show={true}
      setHeightAsPerContent
      type="UPDATE_TEAM_DETAILS"
      size="4xl"
      buttons={[
        {
          label: 'SAVE',
          fullWidth: true,
          loading,
          onClick: handleSubmit(async (data) => {
            await onSubmit(data)
            handleModal(false, MODAL_CONSTS.UPDATE_TEAM_DETAILS)
          }),
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          loading,
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          fullWidth: true,
          onClick: () => handleModal(false, MODAL_CONSTS.UPDATE_TEAM_DETAILS),
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        <div className="flex flex-wrap justify-between gap-2 items-center">
          <HorsePaperwork
            type="update-paperwork-modal"
            control={control}
            onSubmit={onSubmit}
            selectedHorse={dataToPassOn.selectedHorse}
            setValue={setValue}
            watch={watch}
            imagesUrlsToDeleteFromStorage={imagesUrlsToDeleteFromStorage}
            profileFiled={profileFiled}
          />
        </div>
      </div>
    </MainModal>
  )
}
