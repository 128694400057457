import { Skeleton } from '@mui/material'

import { IRegistrationFeesStatsAll, IStats } from '../ManageClinicNOtherLogisticsTab'

import { capitalize, generateName } from '../../../../../helpers/helpers'

import { IHandleModal } from '../../../../../layout/mainlayout/MainLayout'

import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'

const CATEGORIES: Record<string, string> = {
  stalls: 'item',
  feed: 'item',
  bedding: 'item',
  other: 'item',
}

export const columns = (activeTab: IStats, handleModalContext: IHandleModal | null) => [
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Buyer</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex items-center gap-4">
        <span className="w-14 h-14 p-1 rounded-full block shrink-0">
          {row.loading ? (
            <Skeleton
              variant="circular"
              width={48}
              height={48}
              className="mb-2"
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <img
              src={row.userProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
              alt="icons"
              className="object-cover w-[48px] h-[48px] rounded-full"
              onError={(e) =>
                ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                  row.userName ?? ''
                )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
            />
          )}
        </span>

        {row.loading ? (
          <Skeleton
            variant="rounded"
            width={100}
            height={20}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          <div className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize">
            {row?.userName}
          </div>
        )}
      </div>
    ),
    width: '50%',
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Amount</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        {row.loading ? (
          <Skeleton
            variant="rounded"
            width="90%"
            height={20}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          <span className="text-nr text-SeabiscuitDark200ThemeColor font-normal">
            {`${row[activeTab]?.boughtItemsCount} ${CATEGORIES[activeTab]}${
              (row[activeTab]?.boughtItemsCount as number) > 1 ? 's' : ''
            }`}
          </span>
        )}
      </div>
    ),
    width: '14%',
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Details</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col gap-1 w-full">
        {row.loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="100%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
            <Skeleton
              variant="rounded"
              width="100%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </>
        ) : (
          row[activeTab]?.registrationFeesDocIdsDetails.map((currItemDetails) => {
            return (
              <div
                key={currItemDetails.registrationFeesDocId}
                className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize"
              >
                {currItemDetails.selectedItemCount}x {capitalize(currItemDetails.feesTitle)}
              </div>
            )
          })
        )}
      </div>
    ),
    width: '14%',
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
        <span className="whitespace-nowrap">Status</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex capitalize">
        {row.loading ? (
          <Skeleton
            variant="rounded"
            width={80}
            height={20}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          row.paymentStatus
        )}
      </div>
    ),
    width: '13%',
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
        <span className="whitespace-nowrap">Contact</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="w-full justify-center flex">
        {row.loading ? (
          <Skeleton
            variant="rounded"
            width={107}
            height={40}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          <p
            role="button"
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: row.userEmail,
                user_name: row.userName,
                profilePicture: row.userProfilePicture,
              })
            }}
            className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor"
          >
            Message
          </p>
        )}
      </div>
    ),
    width: '10%',
  },
]

export const columnsAll = () => [
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Buyer</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex items-center gap-4 w-full">
        <span className="w-14 h-14 p-1 rounded-full block shrink-0">
          {row.loading ? (
            <Skeleton
              variant="circular"
              width={48}
              height={48}
              className="mb-2"
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <img
              src={row.userProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
              alt="icons"
              className="object-cover w-[48px] h-[48px] rounded-full"
              onError={(e) =>
                ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                  row.userName ?? ''
                )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
            />
          )}
        </span>

        {row.loading ? (
          <Skeleton
            variant="rounded"
            width="50%"
            height={20}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          <div className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize">
            {row?.userName}
          </div>
        )}
      </div>
    ),
    width: '23%',
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Stalls</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col gap-1 w-full">
        {row.loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </>
        ) : row.stalls ? (
          row.stalls.registrationFeesDocIdsDetails?.map((currItemDetails) => {
            return (
              <div
                key={currItemDetails.registrationFeesDocId}
                className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize"
              >
                {currItemDetails.selectedItemCount}x {capitalize(currItemDetails.feesTitle)}
              </div>
            )
          })
        ) : (
          '-'
        )}
      </div>
    ),
    width: '15%',
    style: { padding: '4px 8px 4px 0' },
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Feed</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col gap-1 w-full">
        {row.loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </>
        ) : row.feed ? (
          row.feed.registrationFeesDocIdsDetails?.map((currItemDetails) => {
            return (
              <div
                key={currItemDetails.registrationFeesDocId}
                className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize"
              >
                {currItemDetails.selectedItemCount}x {capitalize(currItemDetails.feesTitle)}
              </div>
            )
          })
        ) : (
          '-'
        )}
      </div>
    ),
    width: '15%',
    style: { padding: '4px 8px 4px 0' },
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Bedding</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col gap-1 w-full">
        {row.loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </>
        ) : row.bedding ? (
          row.bedding.registrationFeesDocIdsDetails?.map((currItemDetails) => {
            return (
              <div
                key={currItemDetails.registrationFeesDocId}
                className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize"
              >
                {currItemDetails.selectedItemCount}x {capitalize(currItemDetails.feesTitle)}
              </div>
            )
          })
        ) : (
          '-'
        )}
      </div>
    ),
    width: '15%',
    style: { padding: '4px 8px 4px 0' },
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Other</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="flex flex-col gap-1 w-full">
        {row.loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={20}
              style={{ backgroundColor: '#F1F3F8' }}
            />
          </>
        ) : row.other ? (
          row.other.registrationFeesDocIdsDetails?.map((currItemDetails) => {
            return (
              <div
                key={currItemDetails.registrationFeesDocId}
                className="text-nr text-SeabiscuitDark200ThemeColor font-normal capitalize"
              >
                {currItemDetails.selectedItemCount}x {capitalize(currItemDetails.feesTitle)}
              </div>
            )
          })
        ) : (
          '-'
        )}
      </div>
    ),
    width: '15%',
    style: { padding: '4px 8px 4px 0' },
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
        <span className="whitespace-nowrap">Total Amount</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => {
      return (
        <div className="flex flex-col gap-1 w-full">
          {row.loading ? (
            <>
              <Skeleton
                variant="rounded"
                width="90%"
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
              <Skeleton
                variant="rounded"
                width="90%"
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            </>
          ) : (
            <span className="text-nr text-SeabiscuitDark200ThemeColor font-normal">
              {(row.stalls?.boughtItemsCount ?? 0) +
                (row.feed?.boughtItemsCount ?? 0) +
                (row.bedding?.boughtItemsCount ?? 0) +
                (row.other?.boughtItemsCount ?? 0)}{' '}
              items
            </span>
          )}
        </div>
      )
    },
    width: '10%',
    style: { padding: '4px 8px 4px 0' },
  },
  {
    name: (
      <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
        <span className="whitespace-nowrap">Status</span>
      </span>
    ),
    cell: (row: IRegistrationFeesStatsAll) => (
      <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex capitalize">
        {row.loading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={20}
            style={{ backgroundColor: '#F1F3F8' }}
          />
        ) : (
          row.paymentStatus
        )}
      </div>
    ),
    width: '7%',
  },
]
