import { DATA_FIRESTORE_V01_EVENTS } from '../const/data/v01/events/firestore-v01-events-const'

export const validateOldTags = (tags: string[]): string[] => {
  if (!tags) {
    return []
  }

  return tags.map((tag) => {
    let newTag = tag
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.EVENTING) {
      newTag = 'Eventing'
    }
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.HUNTER_JUMPER) {
      newTag = 'Hunter Jumper'
    }
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.HUNTER) {
      newTag = 'Hunter'
    }
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.JUMPER) {
      newTag = 'Jumper'
    }
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.EQUITATION) {
      newTag = 'Equitation'
    }
    if (tag === DATA_FIRESTORE_V01_EVENTS.FIELDS.TAGS.VALUES.DRESSAGE) {
      newTag = 'Dressage'
    }
    return newTag
  })
}
