import { useEffect, useState, useContext } from 'react'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

// Third party
import { AddCircleOutlineOutlined, AutorenewRounded, ChevronRight } from '@mui/icons-material'

// Types
import { ITeamMember } from '../../../../models/users/user.interface'
import { ITeamMemberRoles } from '../../../../types/competitor_types'

// Redux
import { useAppSelector } from '../../../../store/hooks'
import { selectTeamMembers, selectUserId } from '../../../../store/user/userSlice'
import { setUserProfileComplete } from '../../../../store/user/userSlice'
import { useAppDispatch } from '../../../../store/hooks'

// Constants
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../home/modals/UserProfileTeamTabAddTeamMember'

// Custom hooks
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../helpers/helpers'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'
import { httpService } from '../../../../services/httpService'
import { RootState } from '../../../../store/store'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import UserProfileSection from './UserProfileSection'

// Types
type Props = {
  title?: string
  description?: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IProfile = { membersArr: ITeamMember[]; type: ITeamMemberRoles }

// Constants
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ProfileRootPageTeamTab = (props: Props) => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const teamMembersList = useAppSelector(selectTeamMembers) as ITeamMember[]
  const [ownerRole, setOwnerRole] = useState<string | null>(null)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const { getModelledUserDetails, updateUserDetails } = useProfileHook({ dontFetch: true })
  const handleModalContext = useContext(HandleModalContext)
  const dispatch = useAppDispatch()

  const toastFunctions = useToasterHelper()

  const [teamMembers, setTeamMembers] = useState<IProfile[]>([])
  const [owner, setOwner] = useState<IProfile[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const formStyles = formFunctions.getFormStyles()

  // Funtions
  const ownerFilter = () => {
    const memberName = profileDetails ? getUserFullName(profileDetails) : 'Unknown'
    let ownerData = {
      memberName: memberName,
      memberId: userId ?? '',
      defaultRole: profileDetails?.userDefaultRole ?? 'Owner',
      memberProfilePicture: profileDetails?.userProfilePicture ?? '',
      memberStatus: '1',
      memberprofileSynced: false,
      memberAddress: profileDetails?.userAddress,
      memberEmail: profileDetails?.userEmail,
      memberShipActive: false,
      memberSafeSupportTraining: false,
      memberCountry: profileDetails?.userNationality ?? '',
      refundAmount: 0,
    }
    return [ownerData]
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    setTeamMembers([{ membersArr: teamMembersList, type: TEAM_MEMBER_ROLES.TEAM_MEMBER }])

    setOwner([{ membersArr: ownerFilter(), type: TEAM_MEMBER_ROLES.OWNER }])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersList, profileDetails])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const sendEmailtoTeam = async (data: any[]) => {
    if (data.length) {
      const reciever_data: { recieverName: string; recieverEmail: string }[] = []
      data.forEach((curr) => {
        if (curr.newSelected) {
          reciever_data.push({
            recieverName: curr.memberName,
            recieverEmail: curr.memberEmail,
          })
        }
      })
      if (reciever_data.length) {
        const res: any = httpService({
          url: 'sendEmails',
          method: 'POST',
          data: {
            mail_type: 'member_added',
            mail_data: {
              reciever_data,
              sender_data: {
                senderName: getUserFullName(profileDetails),
              },
            },
          },
        })

        if (res?.status) {
          return true
        } else {
          return true
        }
      }
    }
    return 0
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const saveTeamMemberToDB = async () => {
    setLoading(true)

    const structuredData = getModelledUserDetails(profileDetails)
    if (structuredData) {
      structuredData.userTeamMembers = teamMembersList

      if (ownerRole) structuredData.userDefaultRole = ownerRole

      const response = await updateUserDetails(structuredData)
      if (response.status) {
        await sendEmailtoTeam(teamMembersList)
        toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBERS_LIST_UPDATED })
      } else {
        toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      }
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openAddTeamMemberModal = () => {
    props.handleModal(true, MODAL_CONSTS.TEAM, { modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM })
  }

  const handleteaminvite = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
      type: 5,
      maxWidth: 430,
    })

    return dispatch(setUserProfileComplete(false))
  }

  return (
    <>
      <FormHeader
        title="Team settings"
        description="Add team members and authorize their access to manage your account"
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => handleteaminvite()}
              disabled={loading}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              Invite Staff
            </button>
            <button
              type="button"
              onClick={saveTeamMemberToDB}
              disabled={loading}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        }
      />

      <div style={formStyles.styles} className={formStyles.className}>
        {owner && owner.length ? (
          <>
            {owner.map((curr: IProfile, index: number) => {
              if (curr.membersArr.length)
                return (
                  <div key={`${curr.type}${index}`}>
                    {index > 0 ? <hr className="my-4" /> : null}
                    {curr.membersArr.map((item, index) => (
                      <UserProfileSection
                        isOwner={item.memberId === userId}
                        key={`${curr.type}${index}`}
                        user={item}
                        myId={userId ?? ''}
                        setOwnerRole={setOwnerRole}
                      />
                    ))}
                  </div>
                )
              else return null
            })}
            <div className="h-[1px] w-full bg-[#D3DAEE] my-8"></div>
          </>
        ) : null}

        {teamMembers.map((curr: IProfile, index: number) => {
          if (curr.membersArr.length)
            return (
              <div key={`${curr.type}${index}`}>
                {index > 0 ? <hr className="my-4" /> : null}

                {curr.membersArr.map((item, index) => (
                  <UserProfileSection
                    handleModal={props.handleModal}
                    isOwner={item.memberId === userId}
                    key={`${curr.type}${index}`}
                    user={item}
                    myId={userId ?? ''}
                    teamMembersList={teamMembersList ?? []}
                    setOwnerRole={setOwnerRole}
                  />
                ))}
              </div>
            )
          else return null
        })}

        {teamMembersList.length ? (
          <div
            className="flex w-fit mb-6 mt-14 items-center text-SeabiscuitMainThemeColor cursor-pointer"
            onClick={openAddTeamMemberModal}
          >
            <AddCircleOutlineOutlined
              className="mr-2 text-SeabiscuitMainThemeColor xl:text-sm 2xl:text-base"
              fontSize="medium"
            />{' '}
            Add team member
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col w-full h-[500px]">
            <p className="py-8">You have not add any team members</p>
            <img src="/assets/placeholders/teamMemberPlaceholder.svg" alt="teamMemberPlaceholder" />
            <p
              onClick={openAddTeamMemberModal}
              className="flex items-center text-SeabiscuitMainThemeColor py-8 cursor-pointer"
            >
              Add your first team member
              <ChevronRight />
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileRootPageTeamTab
