import { IField, REQUIERED_FILEDS } from '../const/requeredFields'
import { IHorseCompetitorDocument } from '../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IProfileReqField } from '../pages/competitor/clinicAndOther/Tabs/components/ProfileFields'
import { IUserInterface } from '../models/users/user.interface'

import { disciplineData } from '../fakeData/disciplineList'
import { horseBreedList } from '../fakeData/horseBreedList'
import { horseHeightList } from '../fakeData/HorseHeightList'
import { SexRestOptions } from '../components/customStyles/ReactSelectCustomStyle'
import { zoneList } from '../fakeData/ZoneList'
import { horseColorList } from '../fakeData/horseColorList'
import { CountryList } from '../fakeData/countryList'
import { COUNTRIES_LIST } from '../fakeData/countriesList'
import { IHorseData } from '../models/horse/horse.interface'

const getLabel = (arr: { label: string; value: string }[], value: string) => {
  const listItm = arr.find((itm) => itm.value === value)
  if (listItm?.label) return listItm.label
  return ''
}

const convertToDate = (date: Date, text: string, options: any) => {
  if (!text) {
    return 'No Number'
  }
  return date
    ? `Current • Expires ${date.toLocaleDateString('en-GB', options).replace(',', '')}`
    : ''
}

const options = { day: '2-digit', month: 'short', year: '2-digit' }

export const getHorseProfileReqField = ({
  horse,
  field,
  horsesPaperwork,
}: {
  horse: IHorseData
  field: IField
  horsesPaperwork: IHorseCompetitorDocument[] | null
}) => {
  const profileReqField: IProfileReqField = {
    ...field,
    profileValue: '',
  }
  const horsePaperwork = horsesPaperwork?.find((paperwork) => paperwork.horseId === horse?.horseId)
  if (field && horse[field.value as keyof IHorseData] !== undefined) {
    const value: any = horse[field.value as keyof IHorseData]
    const cutValue = field.value.replace('Number', '')
    const expirationField = horse[(cutValue + 'Expiration') as keyof IHorseData]

    profileReqField.isTextField =
      REQUIERED_FILEDS.horseMain.some((itm) => itm.value === field.value) ||
      REQUIERED_FILEDS.horseSecondary.some((itm) => itm.value === field.value)

    let profileValue = value
    if (field.value === 'horseDiscipline') {
      profileValue = getLabel(disciplineData, `${value}`)
    }
    if (field.value === 'horseBreed') {
      profileValue = getLabel(horseBreedList, `${value}`)
    }
    if (field.value === 'horseHeight') {
      profileValue = getLabel(horseHeightList, `${value}`)
    }
    if (field.value === 'horseGender') {
      profileValue = getLabel(SexRestOptions as any, `${value}`)
    }
    if (field.value === 'horseZone') {
      profileValue = getLabel(zoneList, `${value}`)
    }
    if (field.value === 'horseColor') {
      profileValue = getLabel(horseColorList, `${value}`)
    }
    if (field.value === 'horseCountry') {
      profileValue = getLabel(CountryList, `${value}`)
    }

    profileReqField.profileValue = profileValue

    if (expirationField) {
      const endDate = new Date(`${expirationField}`)
      const currentDate = new Date()
      profileReqField.isTextField = false
      profileReqField.profileValue = value ? convertToDate(endDate, value, options) : ''
      profileReqField.isExpired = currentDate > endDate || !expirationField
      profileReqField.expiredDate = `${expirationField}`
    } else if (!profileReqField.isTextField && field.value !== 'horseUsefNumber') {
      profileReqField.profileValue = ''
      profileReqField.isExpired = true
      profileReqField.expiredDate = ``
    }
  }

  let endDate
  const currentDate = new Date().getTime()

  if (
    field.value === 'horseMeasurementCard' ||
    field.value === 'horseCoggins' ||
    field.value === 'horseHealthCertificate' ||
    field.value === 'horseRhinopneumonitis' ||
    field.value === 'horseInfluenza'
  ) {
    let imagesCount = 0
    if (field.value === 'horseMeasurementCard') {
      const horseMeasurementCard = horsePaperwork?.horseMeasurementCards?.[0]

      endDate = new Date(horseMeasurementCard?.horseMeasurementDate as Date).getTime()
      imagesCount = horseMeasurementCard?.horseMeasurementCardsImage
        ? horseMeasurementCard?.horseMeasurementCardsImage.length
        : 0
    }
    if (field.value === 'horseCoggins') {
      endDate = new Date(horsePaperwork?.cogginsInspectionDate as Date).getTime()
      imagesCount = horsePaperwork?.cogginsCertificate
        ? horsePaperwork?.cogginsCertificate.length
        : 0
    }

    if (field.value === 'horseHealthCertificate') {
      endDate = new Date(horsePaperwork?.healthInspectionDate as Date).getTime()
      imagesCount = horsePaperwork?.healthCertificate ? horsePaperwork?.healthCertificate.length : 0
    }

    if (field.value === 'horseRhinopneumonitis') {
      endDate = new Date(horsePaperwork?.rhinopneumonitisInspectionDate as Date).getTime()
      imagesCount = horsePaperwork?.rhinopneumonitisCertificate
        ? horsePaperwork?.rhinopneumonitisCertificate.length
        : 0
    }
    if (field.value === 'horseInfluenza') {
      endDate = new Date(horsePaperwork?.influenzaInspectionDate as Date).getTime()
      imagesCount = horsePaperwork?.influenzaCertificate
        ? horsePaperwork?.influenzaCertificate.length
        : 0
    }

    if (endDate) {
      endDate += 1000 * 60 * 60 * 24 * 364 // 1 year
      profileReqField.isExpired = currentDate > endDate || imagesCount <= 0
    } else {
      profileReqField.isExpired = true
    }

    profileReqField.profileValue = endDate
      ? `Current • Expires ${new Date(endDate).toLocaleDateString('en-GB', options as any).replace(',', '')}`
      : ''
  }

  return profileReqField
}

export const getUserProfileReqField = ({
  field,
  user,
}: {
  field: IField
  user: IUserInterface
}) => {
  const profileReqField: IProfileReqField = {
    ...field,
    profileValue: '',
  }

  const value: any = user[field.value as keyof IUserInterface]
  if (value && value.documentNumber) {
    const date = new Date(value.endDate)

    profileReqField.profileValue = value.endDate
      ? `Current • Expires ${date.toLocaleDateString('en-GB', options as any).replace(',', '')}`
      : ''

    if (value.endDate && value.documentNumber) {
      const endDate = new Date(value.endDate.seconds ? value.endDate.seconds : value.endDate)
      const currentDate = new Date()
      profileReqField.isExpired = currentDate > endDate

      if (currentDate <= endDate) {
        profileReqField.isExpired = !value.endDate.seconds ? !value.endDate : true
      }

      profileReqField.expiredDate = value.endDate
    }
  } else if (typeof value === 'string' || typeof value === 'number') {
    let profileValue = `${value}`
    profileReqField.isExpired = false
    if (field.value === 'userDiscipline') {
      profileValue = getLabel(disciplineData, `${value}`)
    }
    if (field.value === 'userNationality') {
      profileValue = getLabel(COUNTRIES_LIST, `${value}`)
    }

    profileReqField.profileValue = profileValue
    profileReqField.isTextField = true
  }

  return profileReqField
}
