import { Modal } from './Modal'

interface Props {
  open: boolean
  onClose: () => void | Promise<void>
  onDelete: () => void
}

export const DeleteModal = ({ open, onClose, onDelete }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="lg:w-[640px] lg:h-[640px] bg-white transition duration-500 rounded-xl p-5 text-black flex flex-col justify-between">
        <div className="flex flex-col">
          <h2 className="font-bold text-3xl py-8">Delete message</h2>
          <h6 className="text-xs text-[#122B4680]">Delete message permanently</h6>
          <h4 className="text-[#122B46] text-xl">Are you sure want to delete this message?</h4>
        </div>
        <div className="flex flex-col items-center space-y-2 pb-6">
          <button
            onClick={onDelete}
            className="bg-SeabiscuitMainThemeColor h-[56px] w-[243px] rounded-md text-white"
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className="bg-[#E7EAED] h-[56px] w-[243px] rounded-md text-[#122B46B2]"
          >
            Cancel
          </button>
        </div>
        <button className="absolute right-0 top-0 p-[10px] text-[#122B46]" onClick={onClose}>
          X
        </button>
      </div>
    </Modal>
  )
}
