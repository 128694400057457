// Third party
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

// Data
import { SalesChartOptions } from './salesChart/salesChart.options'
import { ISalesChartProps } from './salesChart/salesChart.types'

const SalesChart = (props: ISalesChartProps) => {
  // Hooks and vars
  const themePrimaryColor = '#F70763'
  const series: ApexOptions['series'] = [
    {
      type: 'line',
      name: 'sales',
      color: themePrimaryColor,
      data: props.data,
    },
  ]

  return (
    <div className={`flex flex-col p-2 w-1/2 2xl:w-2/6`}>
      <div className="bg-SeabiscuitWhiteThemeColor p-3 rounded-xl">
        <div className="font-bold text-md flex text-SeabiscuitDark200ThemeColor items-center px-4 pt-2">
          <div className="icons bg-[#00B6AA26] p-1 rounded-md 2xl:rounded-xl w-10 h-10 2xl:w-12 2xl:h-12 flex items-center justify-center mr-3 2xl:mr-4 shrink-0">
            <img src={props.iconSrc ?? '/assets/cp_icons/UserGroups-3.svg'} alt="usersGroup" />
          </div>
          {props.title} sales
        </div>
        <Chart options={SalesChartOptions} height={270} series={series} type="line" />
      </div>
    </div>
  )
}

export default SalesChart
