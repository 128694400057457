import { StylesConfig } from 'react-select'

type MyOptionType = {
  label: JSX.Element | string
  value: string
}

type IsMulti = boolean

export const customStyles: StylesConfig<MyOptionType, IsMulti> = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '10px',
    ':hover': {
      background: '#F6F7FB',
      color: '#122B46',
    },
    width: '100%',
    backgroundColor: state.isSelected ? '#122B46' : 'white',
    color: state.isSelected ? '#fff' : '#122B46',
    textTransform: 'capitalize',
    fontSize: 14,
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: 5,
    border: 0,
    width: '100%',
    boxShadow: 'none',
    color: state.isSelected ? '#122B46' : '#122B4680',
    borderRadius: '0.5rem',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0.5rem',
    width: '100%',
    boxShadow: '10, 10, 10, black',
    justifyContent: 'start',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 13,
    fontWeight: 400,
    color: '#122b4680',
  }),
  // selector view
  singleValue: (provided: any, state: any) => {
    const transition = 'opacity 300ms'
    return {
      ...provided,
      transition,
      fontSize: 14,
      fontWeight: 400,
      display: 'inline',
      color: state.isDisabled ? '#122b46' : provided.color,
      opacity: state.isDisabled ? '1' : provided.opacity,
    }
  },
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#122B4680',
    display: state.isDisabled ? 'none' : '',
  }),
  menuList: (provided: any) => ({
    ...provided,
    overflow: 'hidden',
    overflowY: 'scroll!important',
    fontSize: 14,
    paddingTop: '0px',
    paddingBottom: '0px',
    boxShadow: 'none',
    borderRadius: '0px 0px 8px 8px',
    zIndex: 999,
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    marginTop: '0px',
    boxShadow: 'none',
    border: '1px solid #1f41731a',
    width: 'calc(100% + 2px)',
    left: '-1px',
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: 14,
    ':focus': {
      backgroundColor: '#8b0b316e',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#FEF5F6',
    borderRadius: '5px',
    padding: '3px',
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#F70763',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: '#F70763',
    ':hover': {
      backgroundColor: '#FEF5F6',
      color: '#F70763',
    },
    display: state.isDisabled ? 'none' : 'block',
  }),
}

//team dropdown

export const customStyles_: StylesConfig<MyOptionType, IsMulti> = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '10px',
    ':hover': {
      background: '#F6F7FB',
      color: '#122B46',
    },
    width: '100%',
    backgroundColor: state.isSelected ? '#122B46' : 'white',
    color: state.isSelected ? '#fff' : '#122B46',
    textTransform: 'capitalize',
    fontSize: 14,
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    padding: 5,
    border: 0,
    width: '100%',
    boxShadow: 'none',
    color: state.isSelected ? '#122B46' : '#122B4680',
    borderRadius: '0.5rem',
    height: '51px',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0.5rem',
    width: '100%',
    boxShadow: '10, 10, 10, black',
    justifyContent: 'start',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 400,
    color: '#122b4680',
  }),
  // selector view
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return {
      ...provided,
      opacity,
      transition,
      color: '#122B46',
      fontSize: 14,
      fontWeight: 400,
      display: 'inline',
    }
  },
  menuList: (provided: any, state: any) => ({
    ...provided,
    overflow: 'hidden',
    overflowY: 'scroll!important',
    fontSize: 14,
    paddingTop: '0px',
    paddingBottom: '0px',
    boxShadow: 'none',
    borderRadius: '0px 0px 8px 8px',
    zIndex: 999,
    maxHeight: '220px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    marginTop: '0px',
    boxShadow: 'none',
    border: '1px solid #1f41731a',
    width: 'calc(100% + 2px)',
    left: '-1px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    ':focus': {
      backgroundColor: '#8b0b316e',
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#122B4680',
  }),
}

export const breedLabel: MyOptionType[] = [
  {
    value: '0',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Biotech.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="biotech"
        />
        Select breed...
      </span>
    ),
  },
]

export const breedRestOptions: MyOptionType[] = [
  {
    value: 'appaloosa',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Biotech.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="biotech"
        />
        Appaloosa
      </span>
    ),
  },
  {
    value: 'arabian',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Biotech.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="biotech"
        />
        Arabian
      </span>
    ),
  },
]

export const heightLabel: MyOptionType[] = [
  {
    value: '0',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Ruler Vertical-1.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="ruler"
        />
        Select height...
      </span>
    ),
  },
]
export const HeightRestOptions: MyOptionType[] = [
  {
    label: <span className="leading-4">5.0</span>,
    value: '5.0',
  },
  {
    label: <span className="leading-4">5.1</span>,
    value: '5.1',
  },
  {
    label: <span className="leading-4">5.2</span>,
    value: '5.2',
  },
  {
    label: <span className="leading-4">5.3</span>,
    value: '5.3',
  },
  {
    label: <span className="leading-4">6.0</span>,
    value: '6.0',
  },
  {
    label: <span className="leading-4">6.1</span>,
    value: '6.1',
  },
  {
    label: <span className="leading-4">6.2</span>,
    value: '6.2',
  },
  {
    label: <span className="leading-4">6.3</span>,
    value: '6.3',
  },
  {
    label: <span className="leading-4">7.0</span>,
    value: '7.0',
  },
  {
    label: <span className="leading-4">7.1</span>,
    value: '7.1',
  },
  {
    label: <span className="leading-4">7.2</span>,
    value: '7.2',
  },
  {
    label: <span className="leading-4">7.3</span>,
    value: '7.3',
  },
  {
    label: <span className="leading-4">8.0</span>,
    value: '8.0',
  },
  {
    label: <span className="leading-4">8.1</span>,
    value: '8.1',
  },
  {
    label: <span className="leading-4">8.2</span>,
    value: '8.2',
  },
  {
    label: <span className="leading-4">8.3</span>,
    value: '8.3',
  },
  {
    label: <span className="leading-4">9.0</span>,
    value: '9.0',
  },
  {
    label: <span className="leading-4">9.1</span>,
    value: '9.1',
  },
  {
    label: <span className="leading-4">9.2</span>,
    value: '9.2',
  },
  {
    label: <span className="leading-4">9.3</span>,
    value: '9.3',
  },
  {
    label: <span className="leading-4">10.0</span>,
    value: '10.0',
  },
  {
    label: <span className="leading-4">10.1</span>,
    value: '10.1',
  },
  {
    label: <span className="leading-4">10.2</span>,
    value: '10.2',
  },
  {
    label: <span className="leading-4">10.3</span>,
    value: '10.3',
  },
  {
    label: <span className="leading-4">11.0</span>,
    value: '11.0',
  },
  {
    label: <span className="leading-4">11.1</span>,
    value: '11.1',
  },
  {
    label: <span className="leading-4">11.2</span>,
    value: '11.2',
  },
  {
    label: <span className="leading-4">11.3</span>,
    value: '11.3',
  },
  {
    label: <span className="leading-4">12.0</span>,
    value: '12.0',
  },
  {
    label: <span className="leading-4">12.1</span>,
    value: '12.1',
  },
  {
    label: <span className="leading-4">12.2</span>,
    value: '12.2',
  },
  {
    label: <span className="leading-4">12.3</span>,
    value: '12.3',
  },
  {
    label: <span className="leading-4">13.0</span>,
    value: '13.0',
  },
  {
    label: <span className="leading-4">13.1</span>,
    value: '13.1',
  },
  {
    label: <span className="leading-4">13.2</span>,
    value: '13.2',
  },
  {
    label: <span className="leading-4">13.3</span>,
    value: '13.3',
  },
  {
    label: <span className="leading-4">14.0</span>,
    value: '14.0',
  },
  {
    label: <span className="leading-4">14.1</span>,
    value: '14.1',
  },
  {
    label: <span className="leading-4">14.2</span>,
    value: '14.2',
  },
  {
    label: <span className="leading-4">14.3</span>,
    value: '14.3',
  },
  {
    label: <span className="leading-4">15.0</span>,
    value: '15.0',
  },
  {
    label: <span className="leading-4">15.1</span>,
    value: '15.1',
  },
  {
    label: <span className="leading-4">15.2</span>,
    value: '15.2',
  },
  {
    label: <span className="leading-4">15.3</span>,
    value: '15.3',
  },
  {
    label: <span className="leading-4">16.0</span>,
    value: '16.0',
  },
  {
    label: <span className="leading-4">16.1</span>,
    value: '16.1',
  },
  {
    label: <span className="leading-4">16.2</span>,
    value: '16.2',
  },
  {
    label: <span className="leading-4">16.3</span>,
    value: '16.3',
  },
  {
    label: <span className="leading-4">17.0</span>,
    value: '17.0',
  },
  {
    label: <span className="leading-4">17.1</span>,
    value: '17.1',
  },
  {
    label: <span className="leading-4">17.2</span>,
    value: '17.2',
  },
  {
    label: <span className="leading-4">17.3</span>,
    value: '17.3',
  },
  {
    label: <span className="leading-4">18.0</span>,
    value: '18.0',
  },
  {
    label: <span className="leading-4">18.1</span>,
    value: '18.1',
  },
  {
    label: <span className="leading-4">18.2</span>,
    value: '18.2',
  },
  {
    label: <span className="leading-4">18.3</span>,
    value: '18.3',
  },
  {
    label: <span className="leading-4">19.0</span>,
    value: '19.0',
  },
  {
    label: <span className="leading-4">19.1</span>,
    value: '19.1',
  },
  {
    label: <span className="leading-4">19.2</span>,
    value: '19.2',
  },
  {
    label: <span className="leading-4">19.3</span>,
    value: '19.3',
  },
  {
    label: <span className="leading-4">20+</span>,
    value: '20+',
  },
]

export const SexLabel: MyOptionType[] = [
  {
    value: '0',
    label: <span className="leading-4">Select sex......</span>,
  },
]
export const SexRestOptions: MyOptionType[] = [
  {
    value: 'Mare',
    label: 'Mare',
  },
  {
    value: 'Gelding',
    label: 'Gelding',
  },
  {
    value: 'Stallion',
    label: 'Stallion',
  },
]

export const ColorLabel: MyOptionType[] = [
  {
    value: '0',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Paint Palette.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="palette"
        />
        Select Coloring...
      </span>
    ),
  },
]
export const ColorRestOptions: MyOptionType[] = [
  {
    value: 'black',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Paint Palette.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="palette"
        />
        Black
      </span>
    ),
  },
  {
    value: 'white',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Paint Palette.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="palette"
        />
        White
      </span>
    ),
  },
  {
    value: 'brown',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Paint Palette.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="palette"
        />
        Brown
      </span>
    ),
  },
]

export const MarkingLabel: MyOptionType[] = [
  {
    value: '0',
    label: (
      <span className="leading-4">
        <img
          src="/assets/cp_icons/Paint Brush.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="brush"
        />
        Select markings...
      </span>
    ),
  },
]
export const MarkingRestOptions: MyOptionType[] = [
  {
    value: 'blackdot',
    label: (
      <span className="leading-4">
        <img
          src="/assets/cp_icons/Paint Brush.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="brush"
        />
        Black dot
      </span>
    ),
  },
  {
    value: 'whitedot',
    label: (
      <span className="leading-4">
        <img
          src="/assets/cp_icons/Paint Brush.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="brush"
        />
        White dot
      </span>
    ),
  },
]

export const MeasurementCardTypeLabel: MyOptionType[] = [
  {
    value: '0',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Ruler Vertical.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="ruler"
        />
        Select measurement card type...
      </span>
    ),
  },
]
export const MeasurementCardTypeRestOptions: MyOptionType[] = [
  {
    value: 'primary',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Ruler Vertical-1.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="ruler"
        />
        Primary
      </span>
    ),
  },
  {
    value: 'secondry',
    label: (
      <span className="leading-4">
        <img
          src="/assets/og_icons/Ruler Vertical-1.svg"
          className="inline mr-4"
          height="16px"
          width="16px"
          alt="ruler"
        />
        Secondry
      </span>
    ),
  },
]
