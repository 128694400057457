import React from 'react'

interface IProps {}

export const IconPlus: React.FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#122B4680"
        className={'group-hover:fill-SeabiscuitMainThemeColor'}
        d="M7.992 1.66a.5.5 0 00-.492.507v5.334H2.166a.5.5 0 100 1H7.5v5.333a.5.5 0 101 0V8.501h5.333a.5.5 0 100-1H8.5V2.167a.5.5 0 00-.508-.507z"
      ></path>
    </svg>
  )
}
