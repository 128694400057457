import React from 'react'

interface IProps {}

export const IconUserName: React.FC<IProps> = () => {
  return (
    <svg
      fill="#122b46"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 2C6.48615 2 2 6.48615 2 12C2 17.5139 6.48615 22 12 22C13.2342 22 14.42 21.7758 15.5137 21.3652C15.607 21.3314 15.6927 21.2795 15.7657 21.2123C15.8388 21.1452 15.8978 21.0642 15.9394 20.9741C15.9809 20.8839 16.0042 20.7865 16.0078 20.6873C16.0114 20.5881 15.9953 20.4892 15.9604 20.3963C15.9255 20.3034 15.8725 20.2183 15.8046 20.1461C15.7366 20.0738 15.6549 20.0157 15.5643 19.9752C15.4737 19.9347 15.3759 19.9126 15.2767 19.9102C15.1775 19.9077 15.0788 19.925 14.9863 19.9609C14.058 20.3094 13.0528 20.5 12 20.5C7.29685 20.5 3.5 16.7031 3.5 12C3.5 7.29685 7.29685 3.5 12 3.5C16.7031 3.5 20.5 7.29685 20.5 12V13.25C20.5 14.5017 19.5017 15.5 18.25 15.5C16.9983 15.5 16 14.5017 16 13.25V12V7.75C16.0014 7.65062 15.983 7.55194 15.946 7.45972C15.9089 7.36749 15.8539 7.28355 15.7841 7.21277C15.7143 7.142 15.6312 7.08579 15.5395 7.04743C15.4478 7.00907 15.3494 6.98932 15.25 6.98932C15.1506 6.98932 15.0522 7.00907 14.9605 7.04743C14.8688 7.08579 14.7857 7.142 14.7159 7.21277C14.6461 7.28355 14.5911 7.36749 14.554 7.45972C14.517 7.55194 14.4986 7.65062 14.5 7.75V8.2832C13.7098 7.49218 12.6657 7 11.5 7C8.97105 7 7 9.29764 7 12C7 14.7024 8.97105 17 11.5 17C12.9362 17 14.1913 16.2584 15.0088 15.123C15.6597 16.2416 16.8696 17 18.25 17C20.3123 17 22 15.3123 22 13.25V12C22 6.48615 17.5139 2 12 2ZM11.5 8.5C13.107 8.5 14.5 10.0154 14.5 12C14.5 13.9846 13.107 15.5 11.5 15.5C9.89295 15.5 8.5 13.9846 8.5 12C8.5 10.0154 9.89295 8.5 11.5 8.5Z" />
    </svg>
  )
}
