import React from 'react'
import { IconCheck } from '../icons/IconCheck'

interface IProps {
  text: string
  isChecked: boolean
  onClick: () => void
}

export const InputCheckMemberInfo: React.FC<IProps> = ({ text, isChecked, onClick }) => {
  return (
    <div onClick={onClick} className="relative z-10 flex my-2 pb-6 border-b border-[#D3DAEE] pl-4">
      <p className={'text-[#122B46] text-[14px] flex m-0 gap-[18px] cursor-pointer'}>
        <span
          className={
            'w-[16px] h-[16px] rounded-[2px] flex items-center justify-center mt-1 border border-[#122B46]'
          }
        >
          {isChecked && <IconCheck />}
        </span>
        <span className={'flex-1'}>{text}</span>
      </p>
    </div>
  )
}
