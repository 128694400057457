import { useState } from 'react'

import IAccordionTypes from './Accordion.types'
import clsx from 'clsx'

const Accordion = (props: IAccordionTypes['IAccordionProps']) => {
  // Hooks and vars
  const [isOpen, setIsOpen] = useState(props?.initialState ?? true)

  const { header, children } = props

  // Functions

  const toggleAccordion = () => {
    setIsOpen(!isOpen)

    if (isOpen) props?.onOpen?.()
    else props?.onClose?.()
  }

  return (
    <div
      id={props?.id}
      className={clsx('border rounded-md shadow-sm border-[#D3DAEE]', props.className)}
    >
      <div
        className="flex justify-between items-center px-4 py-3 cursor-pointer"
        onClick={() => !props.disabled && toggleAccordion()}
      >
        <div
          className={clsx(
            'text-base flex-1',
            props.headerTextClassName,
            isOpen && 'text-SeabiscuitMainThemeColor accOpen font-bold'
          )}
        >
          {header}
        </div>
        {props?.status && (
          <div
            className={clsx(
              'text-[14px] text-center rounded-full py-0.5 px-2 min-w-[90px] mr-2',
              props?.status === 'Available'
                ? 'bg-SeabiscuitGreenThemeColor/5 text-SeabiscuitGreenThemeColor'
                : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor',
              isOpen && '!bg-[#fff4f6] text-SeabiscuitMainThemeColor'
            )}
          >
            {props.status}
          </div>
        )}
        <div className="flex items-center gap-2 transform transition-transform">
          <span
            className={clsx(
              'text-[14px]',
              isOpen ? 'text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'
            )}
          >
            {props.info}
          </span>
          {isOpen ? (
            <div className={'add_icon cursor-pointer'}>
              <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
            </div>
          ) : (
            <div className={'remove_icon cursor-pointer'}>
              <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx(
          `px-3 transition-all duration-300 ease-in-out`,
          isOpen ? 'h-fit opacity-100 pb-3' : 'h-0 opacity-0 overflow-hidden',
          props?.childrenContainerClassName
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
