import { useEffect } from 'react'
import { useParams } from 'react-router'

// Third party

// Constants

// Components

// Models

// Redux

// Helpers

// Hooks
import clsx from 'clsx'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import UI_STAFF_PROFILE_CONST from '../../../const/ui/ui-staff-profile-const'
import ExhibitorProfilePageWrapper from '../exhibitorProfile/ExhibitorProfilePageWrapper'
import StaffProfileHeader from './header/StaffProfileHeader'
import useStaffProfile from './hooks/useStaffProfile'
import StaffProfileTabsIndex from './tabs/StaffProfileTabsIndex'
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import { getUserFullName } from '../../../helpers/helpers'
import { CONST } from '../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IParams = {
  staffId: string
  eventId: string
  staffRole: string
  isOtherStaff: '0' | '1'
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffProfileRootPage = (props: Props) => {
  // Hooks and vars
  const { staffId, eventId, staffRole, isOtherStaff } = useParams<IParams>()
  const {
    loading,
    profile,
    documents,
    activeTab,
    onTabClick,
    isElligible,
    openLightBox,
    showLightBox,
    closeLightBox,
    fetchPageData,
    lightBoxImages,
    isStaffScratched,
    onScratchButtonClick,
    areAllPaperworksSigned,
  } = useStaffProfile()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let isOtherStaff_: boolean | null = null

    if (isOtherStaff === '0') isOtherStaff_ = false
    else if (isOtherStaff === '1') isOtherStaff_ = true

    if (staffId && eventId && isOtherStaff_ !== null && staffRole)
      fetchPageData({
        staffId,
        eventId,
        staffRole,
        isOtherStaff: isOtherStaff_,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId, eventId, staffRole, isOtherStaff, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const staffProfileHeaderProps = {
    isElligible,
    staffRole,
    staff: profile,
    isStaffScratched,
    onScratchButtonClick,
    areAllPaperworksSigned,
  }

  const userName = profile ? getUserFullName(profile) : 'Unknown'

  return (
    <ExhibitorProfilePageWrapper
      title="Back"
      link={`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`}
    >
      {loading ? (
        <div className="h-[50vh] min-h-[400px] flex justify-center items-center">
          <ViewsLoader size="lg" color="#F70763" />
        </div>
      ) : (
        <>
          <StaffProfileHeader {...staffProfileHeaderProps} />

          <div className="my-4 border-t border-solid border-[#D3DAEE]"></div>
          <div className="flex flex-col md:flex-row md:items-center mb-8">
            {UI_STAFF_PROFILE_CONST.TABS.map((currentItem, index) => {
              return (
                <div
                  key={`${index}${JSON.stringify(currentItem)}`}
                  onClick={() => onTabClick(currentItem)}
                  className={clsx(
                    'py-2 px-4 cursor-pointer mr-2 rounded-xl',
                    activeTab === currentItem
                      ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                      : 'text-SeabiscuitDark200ThemeColor'
                  )}
                >
                  {currentItem}
                </div>
              )
            })}
            <div className="flex w-full justify-end">
              <button
                type="button"
                onClick={onScratchButtonClick}
                className="border border-solid ml-2 bg-SeabiscuitGrayThemeColor hover:bg-[#FEF5F6] border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor hover:border-SeabiscuitMainThemeColor cursor-pointer hover:text-SeabiscuitMainThemeColor text-sm py-2 px-4 rounded-lg w-24"
              >
                Scratch
              </button>
            </div>
          </div>

          <StaffProfileTabsIndex
            openLightBox={openLightBox}
            staffName={userName}
            activeTab={activeTab}
            documents={documents}
          />
        </>
      )}

      <LightboxCommonComponent
        onClose={closeLightBox}
        visible={showLightBox}
        slides={lightBoxImages}
      />
    </ExhibitorProfilePageWrapper>
  )
}

export default StaffProfileRootPage
