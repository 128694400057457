import React from 'react'
import { IconDelete } from '../../../../components/icons/IconDelete'
import SwitchElementUpdated from '../../../../components/events/forms/paperwork/SwitchElement'

interface IProps {
  value: string
  isRequired: boolean
  onChange: (value: string) => void
  onChangeRequired: (value: boolean) => void
  removeHandler: () => void
}

export const EventQuestionInput: React.FC<IProps> = ({
  onChange,
  value,
  removeHandler,
  onChangeRequired,
  isRequired,
}) => {
  const [innerValue, setInnerValue] = React.useState(value)
  const [editMode, setEditMode] = React.useState(false)
  const ref = React.useRef<HTMLInputElement | null>(null)

  const saveHandler = React.useCallback(() => {
    setEditMode(false)
    onChange(innerValue)
  }, [innerValue])

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        saveHandler()
      }
    }

    // Добавляем обработчик кликов по всему документу
    document.addEventListener('mousedown', handleClickOutside)

    // Убираем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [saveHandler])

  if (editMode) {
    return (
      <div ref={ref} className="w-full items-center gap-x-3 gap-y-7 flex flex-wrap md:flex-nowrap">
        <input
          className={
            'w-full px-[22px] border-solid border-[#D3DAEE] rounded-lg h-[55px] !text-[16px]'
          }
          onChange={(e) => {
            // onChange(e.target.value)
            setInnerValue(e.target.value)
          }}
          type="text"
          placeholder={'Enter question'}
          value={innerValue}
        />
        <div className={'relative flex items-center'}>
          <div className={'text-[10px] absolute bottom-[100%] left-0 mb-0.5'}>Required</div>
          <SwitchElementUpdated
            onText={'Yes'}
            offText={'No'}
            onSwitch={() => onChangeRequired(!isRequired)}
            on={isRequired}
          />
        </div>
        <button onClick={removeHandler}>
          <IconDelete />
        </button>
        {/*<button*/}
        {/*  className={'text-[#122B46] opacity-70 text-[12px]'}*/}
        {/*  onClick={() => setEditMode(false)}*/}
        {/*>*/}
        {/*  Cancel*/}
        {/*</button>*/}
        {/*<button className={'text-SeabiscuitMainThemeColor text-[12px]'} onClick={saveHandler}>*/}
        {/*  Ok*/}
        {/*</button>*/}
      </div>
    )
  }
  return (
    <div className="w-full items-center gap-x-3 gap-y-7 flex flex-wrap md:flex-nowrap">
      <div
        onClick={() => {
          setEditMode(true)
          setTimeout(() => {
            if (ref.current) (ref.current as HTMLDivElement).querySelector('input')?.focus()
          }, 100)
        }}
        className={
          'w-full bg-[#F6F7FB] px-[22px] py-[10px] rounded-lg min-h-[55px] text-[16px] flex items-center cursor-text'
        }
      >
        <p className={`w-full text-[#122B46] ${!value ? 'opacity-20' : ''}`}>
          {value || 'Enter question'}
        </p>
      </div>

      <div className={'relative flex items-center'}>
        <div className={'text-[10px] absolute bottom-[100%] left-0 mb-0.5'}>Required</div>
        <SwitchElementUpdated
          onText={'Yes'}
          offText={'No'}
          onSwitch={() => onChangeRequired(!isRequired)}
          on={isRequired}
        />
      </div>

      <button onClick={removeHandler}>
        <IconDelete />
      </button>
    </div>
  )
}
