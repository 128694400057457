// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { Controller } from 'react-hook-form'
import { getReactPickerDate } from '../../../../../models/interface.helper'
import showPassword from '../assets/show-password.svg'
import './GlobalInputComponent.css'

// Component
import clsx from 'clsx'
import Select from 'react-select'
import CustomDatePicker from '../../../../common/inputs/CustomDatePicker'

import { CountryList, ICountryList } from '../../../../../fakeData/countryList'
import { customStyles } from '../../../../customStyles/ReactSelectCustomStyle'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const GlobalInputComponent: React.FC<{
  isTimeSelector?: boolean
  className?: string
  accept?: string
  control?: any
  placeholder: string
  type: string
  maxLength?: string
  disabled?: boolean
  onChange?: (e: any) => void
  value?: string | Array<any>
  defaultValue?: string
  icon?: string
  outlined?: boolean
  register?: any
  inputName?: string
  minDate?: any
  maxDate?: any
  isdisableInput?: boolean
}> = (props) => {
  const {
    placeholder,
    disabled,
    accept,
    control,
    onChange,
    value,
    icon,
    type,
    className,
    outlined,
    register,
    inputName,
    minDate,
    maxDate,
    isTimeSelector,
    maxLength,
    isdisableInput,
    defaultValue,
  } = props

  const [innerType, setInnerType] = useState(type === 'date' ? 'text' : type)
  const colorTheme = outlined
    ? `border-[#BCC6D5] border-[1px] border-solid bg-white`
    : `bg-SeabiscuitGrayThemeColor`

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param place
   * @returns
   */
  const getLocationDetails = (place: any) => {
    let location: any = {}

    if (typeof place === 'object') {
      let data = place as any

      location = data?.address_components?.reduce((accumulator: any, currAddComponent: any) => {
        switch (true) {
          case currAddComponent?.types.includes('country'):
            accumulator.userNationality = currAddComponent?.long_name
            break
          case currAddComponent?.types.includes('administrative_area_level_1'):
            accumulator.userState = currAddComponent?.long_name
            break
        }
        return accumulator
      }, {})

      if (place.hasOwnProperty('formatted_address'))
        location.formatted_address = place.formatted_address

      if (location) {
        location.userLong = data?.geometry?.location?.lat()
        location.userLat = data?.geometry?.location?.lng()
      }

      const countryCode = CountryList.filter((currentCountry: ICountryList) => {
        return currentCountry?.label?.toLowerCase() === location?.userNationality?.toLowerCase()
      })

      return {
        userLat: location?.userLat ?? '',
        userLong: location?.userLong ?? '',
        userState: location?.userState ?? '',
        userNationality: countryCode?.[0]?.value ?? '',
        userAddress:
          location?.formatted_address ?? location?.userNationality ?? location?.userState,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div
      className={`text-gray-500 flex items-center flex-1 ${
        type === 'textarea' ? '!items-start' : ''
      } gap-2 w-full px-4 py-2 rounded-xl EventTimeSelect ${colorTheme} ${className}`}
    >
      {type !== 'file' && icon && (
        <div>
          <img src={icon} alt="icon" className={`min-w-6 ${type === 'textarea' ? 'mt-2' : ''}`} />
        </div>
      )}
      {type === 'file' && (
        <label className="flex items-center gap-2 justify-center w-full py-10">
          {icon && (
            <div>
              <img src={icon} alt="icon" />
            </div>
          )}
          <div>{placeholder}</div>
          <input
            type={innerType}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            className="hidden"
            accept={accept}
          />
        </label>
      )}

      {type === 'select' && typeof value === 'object' && (
        <>
          {isTimeSelector ? (
            <Controller
              name={inputName ?? ''}
              control={control}
              render={({ field: { onChange, value: controllerValue } }) => {
                return (
                  <Select
                    classNamePrefix="select"
                    options={value}
                    onInputChange={(e) => {
                      if (!!isdisableInput) {
                        return ''
                      } else {
                        return e
                      }
                    }}
                    className={className}
                    value={{
                      label: controllerValue || defaultValue,
                      value: controllerValue || defaultValue,
                    }}
                    placeholder={placeholder}
                    onChange={(newValue) => {
                      props.onChange && props.onChange(newValue?.value ?? newValue)
                      onChange(newValue?.value ?? newValue)
                    }}
                    styles={
                      {
                        ...customStyles,
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: '#4b5563',
                        }),
                        input: (styles: any, state: any) => ({
                          ...customStyles['input']?.(styles, state),
                          width: '30px',
                        }),
                      } as any
                    }
                    isClearable={false}
                    components={{ DropdownIndicator: null }}
                    isSearchable={true}
                  />
                )
              }}
            />
          ) : (
            <select
              className="flex-1 border-0 outline-0 text-gray-600 bg-transparent w-full focus:ring-0 focus:border-0 focus:outline-0"
              disabled={disabled}
              value={value.filter((item) => item.selected)[0]}
              onChange={onChange}
            >
              <option disabled value="-1">
                {placeholder}
              </option>
              {value.map((option, index) => (
                <option key={`${JSON.stringify(option)}${index}`} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          )}
        </>
      )}
      {type === 'textarea' && (
        <textarea
          placeholder={placeholder}
          disabled={disabled}
          {...register(inputName)}
          className="flex-1 border-0 outline-0 text-gray-600 resize-y min-h-[120px] bg-transparent w-full focus:ring-0"
        />
      )}
      {type !== 'select' &&
        type !== 'file' &&
        type !== 'textarea' &&
        (type === 'date' ? (
          inputName ? (
            minDate ? (
              <Controller
                name={inputName}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      dropdownMode="select"
                      minDate={minDate ?? new Date()}
                      showMonthDropdown
                      disabled={disabled}
                      showYearDropdown
                      placeholderText={placeholder}
                      className={`flex-1 text-md !border-0 !outline-0 text-gray-600 bg-transparent w-full${className ? ` ${className}` : ''}`}
                    />
                  )
                }}
              />
            ) : maxDate ? (
              <Controller
                name={inputName}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      dropdownMode="select"
                      maxDate={maxDate ?? new Date()}
                      showMonthDropdown
                      disabled={disabled}
                      showYearDropdown
                      wrapperClassName=""
                      placeholderText={placeholder}
                      className={`flex-1 text-md !border-0 !outline-0 text-gray-600 bg-transparent w-full${className ? ` ${className}` : ''}`}
                    />
                  )
                }}
              />
            ) : (
              <Controller
                name={inputName}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={getReactPickerDate(value)}
                      peekNextMonth
                      dropdownMode="select"
                      minDate={minDate ?? new Date()}
                      maxDate={maxDate ?? new Date()}
                      showMonthDropdown
                      disabled={disabled}
                      showYearDropdown
                      wrapperClassName=""
                      placeholderText={placeholder}
                      className={`flex-1 text-md !border-0 !outline-0 text-gray-600 bg-transparent w-full${className ? ` ${className}` : ''}`}
                    />
                  )
                }}
              />
            )
          ) : null
        ) : type === 'location' ? (
          <Controller
            name={inputName ?? ''}
            control={control}
            disabled={disabled}
            render={({ field: { onChange: controllerOnChange, value } }) => {
              return (
                <Autocomplete
                  placeholder={props?.placeholder ? placeholder : 'Enter the address'}
                  className={clsx(
                    `flex-1 focus:ring-0 focus:border-0 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-transparent w-full pl-[15px] py-2 pr-3`,
                    props.className
                  )}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  id="autocomplete"
                  defaultValue={value}
                  disabled={disabled}
                  onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                    let details = getLocationDetails(place)
                    controllerOnChange(details?.userAddress)
                    onChange?.(place)
                  }}
                  onChange={(e: any) => {
                    controllerOnChange?.(e?.target?.value ?? '')
                    onChange?.(e?.target?.value)
                  }}
                  options={{
                    types: [],
                    fields: [
                      'address_components',
                      'geometry.location',
                      'place_id',
                      'formatted_address',
                    ],
                  }}
                />
              )
            }}
          />
        ) : (
          <>
            <input
              type={innerType}
              placeholder={placeholder}
              {...register(inputName, {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (props?.onChange) props?.onChange(e)
                },
              })}
              maxLength={maxLength}
              disabled={disabled}
              className="flex-1 focus:ring-0 focus:border-0 border-0 outline-0 text-gray-600 bg-transparent w-full"
            />
          </>
        ))}
      {type === 'password' && (
        <div onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}>
          <img
            src={showPassword}
            alt="icon"
            className={`${innerType === 'password' ? '' : 'brightness-50'}`}
          />
        </div>
      )}
    </div>
  )
}
export default GlobalInputComponent
