import React, { FC, useContext, useEffect } from 'react'

import { useParams } from 'react-router'
import { Tooltip } from '@mui/material'

import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { IconHorseImg } from '../../../../../components/icons/IconHorseImg'
import { NoProfileData } from './NoProfileData'
import { ProfileFields } from './ProfileFields'

import { selectHorses } from '../../../../../store/horses/horseSlice'
import { selectIsLoggedIn } from '../../../../../store/user/userSlice'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'

import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../../../services/firestoreService'

import { IHorseCompetitorDocument } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import ITypes from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'

import { CONST } from '../../../../../const/const'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import clsx from 'clsx'

interface HorsesProps {
  horsesPaperwork: IHorseCompetitorDocument[] | null
  setHorsesPaperwork: (value: IHorseCompetitorDocument[] | null) => void
}
export const Horses: FC<HorsesProps> = ({ horsesPaperwork, setHorsesPaperwork }) => {
  const { userId } = useParams<ITypes['IParams']>()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const myHorses = useAppSelector(selectHorses)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const { Event } = useAppSelector(selectedEvent)

  const getHorsesPaperwork = async () => {
    const horsesPaperworkSnap = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME
    )

    let horsesPaperwork_ = horsesPaperworkSnap?.docs.map((doc) => {
      return HorseCompetitonPaperworkModel.fromFirestoreDoc(doc).toObject()
    })
    setHorsesPaperwork([...horsesPaperwork_])
  }

  useEffect(() => {
    if (Event?.id && userId) {
      getHorsesPaperwork().then()
    }
  }, [Event?.id, userId])

  if (!myHorses || !myHorses[0]) {
    return (
      <NoProfileData
        title={'You have not added any horses'}
        createHandler={() => {
          handleModal?.(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
            userPicture: '',
            userId,
            createCb: () => {
              handleModal?.(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
            },
          })
        }}
        btnCreateTitle={'Add your first horse now >'}
      />
    )
  }

  return (
    <div
      className={clsx(
        'w-full lg:w-[48%] flex border flex-col rounded-md border-[#D3DAEE] p-4 gap-5 min-h-[300px]',
        !isLoggedIn && 'opacity-30'
      )}
    >
      <div className="flex w-full justify-between">
        <div>
          <div className="flex items-center">
            <h3 className="text-[#122B46] text-[22px] font-bold mr-1">Horses</h3>
            <Tooltip title={''} placement="top" arrow>
              <button
                type="button"
                className=""
                onClick={() => handleModal?.(true, MODAL_CONSTS.REGISTRATION_HORSES)}
              >
                <TooltipIcon color="#122B46" />
              </button>
            </Tooltip>
          </div>
          <p className={'text-[#122B4680] text-[14px]'}>Add all horses included in registration</p>
        </div>
        <div className={'flex gap-5'}>
          {isLoggedIn && (
            <div className={'flex flex-col text-right'}>
              <span className={'text-[#122B4680] text-[12px]'}>Team</span>
              <button
                onClick={() => handleModal?.(true, MODAL_CONSTS.HORSE)}
                className={'text-[14px] text-[#122B46] underline hover:no-underline'}
              >
                Add horse
              </button>
            </div>
          )}

          <div
            className={'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'}
          >
            <IconHorseImg />
          </div>
        </div>
      </div>

      {isLoggedIn ? (
        myHorses.map((item, index) => (
          <ProfileFields
            eventRequiredFields={Event?.requiredHorseFields || []}
            key={`${item.horseName} + ${index}`}
            horse={item}
            headerImgSrc={item?.horseProfilePicture || ''}
            headerTitle={item?.horseName || 'Unknown'}
            editHandler={async (profileFiled, horse) => {
              if (
                profileFiled.value === 'horseMeasurementCard' ||
                profileFiled.value === 'horseCoggins' ||
                profileFiled.value === 'horseHealthCertificate' ||
                profileFiled.value === 'horseRhinopneumonitis' ||
                profileFiled.value === 'horseInfluenza'
              ) {
                const horsePaperwork = horsesPaperwork?.find(
                  (horsePaperwork_) => horsePaperwork_.horseId === horse?.horseId
                )
                handleModal?.(true, MODAL_CONSTS.UPDATE_TEAM_DETAILS, {
                  profileFiled,
                  horsePaperwork: horsePaperwork,
                  selectedHorse: horse,
                  onSave: async () => await getHorsesPaperwork(),
                })
              } else {
                handleModal?.(true, MODAL_CONSTS.UPDATE_USER_NUMBER, {
                  data: profileFiled,
                  isHorse: true,
                  horseData: item,
                })
              }
            }}
            horsesPaperwork={horsesPaperwork}
            myHorses={myHorses}
          />
        ))
      ) : (
        <div className="flex p-10 items-center justify-center">Please log in to see the horses</div>
      )}
    </div>
  )
}
