import { useContext, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import { useParams } from 'react-router'
import helpers from '../../../../../../commonHelpers/helpers'
import { PillButtonElement } from '../../../../../../components/elements/buttons/pill/PillButtonElement'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../../helpers/helpers'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { IRegistrationExtended } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  organizerReducers,
  organizerSliceAcs,
} from '../../../../../../store/organizer/organizerSlice'
import { ITabs } from '../../../../../../store/organizer/organizerSlice.types'
import ManageClinicTableWrapper from '../../ManageClinicTableWrapper'
import ExhitiborAllList from './ExhitiborAllList'
import { MANAGE_CLINIC_EXHIBITOR_CONSTS } from './data/exhibitor.data.const'
import { IExhibitorDataTypes } from './data/exhibitor.data.types'
import { Tooltip } from '@mui/material'

// Constants
const MCEC = MANAGE_CLINIC_EXHIBITOR_CONSTS

const ExhibitorsListingRoot = (
  props: IExhibitorDataTypes['IExhitiborClinicExhibitorsListProps']
) => {
  // Hooks and vars
  const { eventId } = useParams<{ eventId?: any }>()
  const { className, isFullScreen = false } = props
  const handleModalContext = useContext(HandleModalContext)
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const exhibitors = useAppSelector(organizerReducers.exhibitors)

  const [searchValue, setSearchValue] = useState('')
  const [activeTab, setActiveTab] = useState<ITabs>(MCEC.TABS[0])
  const [Emails, setEmails] = useState<string[]>([])
  const [imgSrc, setImgSrc] = useState('/assets/og_icons/Mail.svg')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleMailSendAll = async (tempData: IRegistrationExtended[]) => {
    let emails: string[] = []
    if (tempData.length) {
      tempData.forEach((data) => {
        if (data.riderEmail) {
          emails.push(data.riderEmail)
        }
      })
    }
    setEmails(emails)
  }

  useEffect(() => {
    let tempData: IRegistrationExtended[] = []
    tempData = exhibitors.tabs[activeTab.name].data

    if (tempData.length) {
      handleMailSendAll(tempData)
    }
  }, [exhibitors, activeTab])

  useEffect(() => {
    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'useEffect',
          fileName: 'ExhibitorsListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        }),
      })

      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      dispatch(organizerSliceAcs.resetExhibitorAc())

      return
    }

    dispatch(
      organizerSliceAcs.setExhibitorsAc({
        eventId,
        cursorId: null,
        tabName: activeTab.name,
      })
    )

    return () => {
      dispatch(organizerSliceAcs.resetExhibitorAc())
    }
  }, [eventId, activeTab])

  // Functions
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Handles data table tabs,
   */
  const handleActiveTab = (item: ITabs) => {
    setActiveTab(item)

    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'handleActiveTab',
          fileName: 'ExhibitorsListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        }),
      })

      return toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }

    dispatch(
      organizerSliceAcs.setActionListingTab({
        listType: 'exhibitor',
        tabName: item.name,
      })
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Filters the list of users
   */
  const searchval = (e: any) => {
    const eventKey = e.target.value

    dispatch(
      organizerSliceAcs.setExhibitorsAc({
        eventId,
        cursorId: null,
        tabName: null as any,
        query: {
          search: eventKey ?? '',
        },
      })
    )
    setSearchValue(eventKey ?? '')
  }

  return (
    <ManageClinicTableWrapper
      title="Riders"
      eventId={eventId}
      className={className}
      isFullScreen={isFullScreen}
      redirectUrl={`${CONST.ROUTES.MANAGE.EXHIBITORS.URL}/${eventId}`}
    >
      <>
        <div className={clsx('flex items-center justify-between', isFullScreen ? 'mb-5' : 'mb-4')}>
          <div className="flex items-center my-2">
            {MCEC.TABS?.map((currentItem, index) => {
              return (
                <PillButtonElement
                  selected={activeTab.name === currentItem.name}
                  key={`${JSON.stringify(currentItem)}a${index}`}
                  onButtonClicked={() => handleActiveTab(currentItem)}
                  Class={clsx(
                    'py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl !border-none',
                    activeTab.name === currentItem.name
                      ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                      : 'text-SeabiscuitDark200ThemeColor'
                  )}
                  text={currentItem.tab}
                />
              )
            })}
          </div>

          {isFullScreen ? (
            <div className="flex">
              <div className="search-div-wrapper border relative rounded-lg w-72 border-[#d3daee6a] mb-2">
                <div className="relative rounded-lg">
                  <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-SeabiscuitDark200ThemeColor"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>

                  <input
                    type="search"
                    ref={inputRef}
                    id="default-search"
                    className="block w-full p-2 pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
                    placeholder="Search riders..."
                    onChange={searchval}
                  />
                </div>
              </div>
              <Tooltip
                title={<h1 className="tooltip_title">{`Email All`}</h1>}
                placement="top"
                arrow
              >
                <button
                  className="bg-[#F2F5F6] text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor rounded-lg shadow-sm p-2 ml-3 !h-[40px]"
                  onClick={() => {
                    handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                      emailId: Emails.toString(),
                    })
                  }}
                  onMouseEnter={() => setImgSrc('/assets/og_icons/Mail-2.svg')}
                  onMouseLeave={() => setImgSrc('/assets/og_icons/Mail-1.svg')}
                >
                  <img src={imgSrc} className="" alt="edit" />
                </button>
              </Tooltip>
            </div>
          ) : null}
        </div>
        <div className="w-full">
          <ExhitiborAllList
            {...props}
            eventId={eventId}
            searchValue={searchValue}
            activeTab={activeTab}
          />
        </div>
      </>
    </ManageClinicTableWrapper>
  )
}

export default ExhibitorsListingRoot
