import { useEffect, useState } from 'react'

// Third party
import { where } from 'firebase/firestore'

import helpers from '../../../../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../../../../helpers/ToasterHelper'

import FirestoreService from '../../../../../../../services/firestoreService'

import { HorseModel } from '../../../../../../../models/horse/horse.model'
import { IHorseData } from '../../../../../../../models/horse/horse.interface'
import { HorseTeamModel } from '../../../../../../../models/horse-team/horseTeam.model'
import { ITeamMember } from '../../../../../../../models/users/user.interface'
import { HorseCompetitonPaperworkModel } from '../../../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { IHorseCompetitorDocument } from '../../../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorsesDataTypes } from '../data/horses.data.types'

// Constants
import { CONST } from '../../../../../../../const/const'
import { RegistrationByDayModel } from '../../../../../../../models/registrations-by-day/registrationByDay.model'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const useHorse = (eventId?: string) => {
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [horses, setHorses] = useState<IHorseData[]>([])
  const [allHorses, setAllHorses] = useState<IHorseData[]>([])

  useEffect(() => {
    if (eventId) getEventRegisteredHorses(eventId)
  }, [eventId])

  const getEventRegisteredHorses = async (eventId?: string) => {
    try {
      const registeredUsersSnap = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId)]
      )

      const horsesIds: string[] = []
      const registeredRiders: {
        registrationDocId: string
        userId: string
        riderId: string
        riderName: string
        horseId: string
      }[] = []
      registeredUsersSnap.forEach((user) => {
        const registrationByDay = RegistrationByDayModel.fromFirestoreDoc(user).toObject()

        if (registrationByDay.horseId) {
          horsesIds.push(registrationByDay.horseId)
          registeredRiders.push({
            registrationDocId: registrationByDay.registrationDocId ?? '',
            userId: registrationByDay.userId ?? '',
            riderId: registrationByDay.riderId ?? '',
            riderName: registrationByDay.riderName ?? '',
            horseId: registrationByDay.horseId,
          })
        }
      })

      const horseSnap = await FirestoreService.filterItems(COLLECTIONS.HORSES.NAME)
      const horses: IHorseData[] = []
      const _allHorses: IHorseData[] = []
      horseSnap.forEach((horse) => {
        const currentHorse = HorseModel.fromFirestoreDoc(horse).toObject()
        _allHorses.push(currentHorse)
        if (currentHorse.id) {
          if (horsesIds.includes(currentHorse.id)) horses.push(currentHorse)
        }
      })

      setAllHorses(_allHorses)

      const teamMembersSnap = await FirestoreService.listItems(COLLECTIONS.HORSE_TEAM.NAME)
      const teamMembers: ITeamMember[] = []
      teamMembersSnap.forEach((horse) => {
        teamMembers.push(HorseTeamModel.fromFirestoreDoc(horse).toObject())
      })

      const horsesPaperworkSnap = await FirestoreService.listItems(
        COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME
      )
      const horsesPaperwork: IHorseCompetitorDocument[] = []
      horsesPaperworkSnap.forEach((paperwork) => {
        const currentPaperwork =
          HorseCompetitonPaperworkModel.fromFirestoreDoc(paperwork).toObject()
        horsesPaperwork.push(currentPaperwork)
      })

      const horsesWithTrainerAndPaperwork = horses.map((horse) => {
        let eligibility = false
        let trainer = ''
        let memberId = ''

        const filteredPaperwork = horsesPaperwork.filter(
          (paperwork) => paperwork.horseId === horse.horseId
        )

        if (filteredPaperwork.length > 0) {
          const isCogginsCertificate =
            filteredPaperwork[0].cogginsCertificate!.length > 0 &&
            filteredPaperwork[0].cogginsInspectionDate

          const isHealthCertificate =
            filteredPaperwork[0].healthCertificate!.length > 0 &&
            filteredPaperwork[0].healthInspectionDate

          const isVaccination =
            filteredPaperwork[0].vaccinationCertificate!.length > 0 &&
            filteredPaperwork[0].vaccinationInspectionDate

          const isRhinopneumonitis =
            filteredPaperwork[0].rhinopneumonitisCertificate!.length > 0 &&
            filteredPaperwork[0].rhinopneumonitisInspectionDate
          const isInfluenza =
            filteredPaperwork[0].influenzaCertificate!.length > 0 &&
            filteredPaperwork[0].influenzaInspectionDate

          // set paperwork
          eligibility =
            !!isCogginsCertificate &&
            !!isHealthCertificate &&
            !!isVaccination &&
            !!isRhinopneumonitis &&
            !!isInfluenza
        }

        const filteredTeamMembers = teamMembers.filter(
          (member) => member.horseId === horse.id && member.memberRole === 'Trainer'
        )

        // set trainer
        if (filteredTeamMembers.length > 0) {
          trainer = filteredTeamMembers[0].memberName ?? ''
          memberId = filteredTeamMembers[0].memberId ?? ''
        }

        return {
          ...horse,
          eligibility,
          trainer,
          memberId,
        }
      })

      const horsesWithTrainerAndPaperworkAndRider: IHorsesDataTypes[] = []
      horsesWithTrainerAndPaperwork.forEach((horse, horseIndex) => {
        const currentUser = registeredRiders.filter((rider) => rider.horseId === horse.id)
        if (currentUser.length > 0) {
          currentUser.forEach((current, currentIndex) => {
            horsesWithTrainerAndPaperworkAndRider.push({
              ...horse,
              userId: current?.userId,
              riderId: current?.riderId,
              riderName: current?.riderName,
              registrationId: current?.registrationDocId,
              index: `${horseIndex}${currentIndex}`,
            })
          })
        } else {
          horsesWithTrainerAndPaperworkAndRider.push({ ...horse, index: horseIndex })
        }
      })

      // console.log(
      //   '=>(useHorse.tsx:163) horsesWithTrainerAndPaperworkAndRider',
      //   horsesWithTrainerAndPaperworkAndRider
      // )
      //
      // const uniqueHorses = horsesWithTrainerAndPaperworkAndRider.reduce(
      //   (accumulate: IHorsesDataTypes[], current) => {
      //     const isDuplicated = accumulate.filter(
      //       (rider) =>
      //         rider.horseId === current.horseId &&
      //         rider.riderName === current.riderName &&
      //         rider.trainer === current.trainer
      //     )
      //     if (isDuplicated.length <= 0) accumulate.push(current)
      //     return accumulate
      //   },
      //   []
      // )

      // console.log('=>(useHorse.tsx:176) uniqueHorses', horsesWithTrainerAndPaperworkAndRider)
      setHorses(horsesWithTrainerAndPaperworkAndRider)
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message,
      })

      helpers.logger({
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  return {
    horses,
    loading,
    allHorses,
  }
}

export default useHorse
