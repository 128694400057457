import React, { useEffect, useState } from 'react'
import customImageComponent from '../../common/CustomImageComponent'
import { IconClasses } from '../../icons/IconClasses'
import { useAppSelector } from '../../../store/hooks'
import { IPaperworkTeam } from '../../../store/paperworks/paperworkSlice'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IconBackNumber } from '../../icons/IconBackNumber'

interface IProps {
  horseProfilePicture: string
  horseName: string
  rider: IPaperworkTeam
}

export const AddTeamMemberToPaperworkHeader: React.FC<IProps> = ({
  horseProfilePicture,
  horseName,
  rider,
}) => {
  const currentEntries = useAppSelector((state) => state.organizer.currentEntries)
  const [currentEntriesRider, setCurrentEntriesRider] =
    useState<IRegistrationByDayInterface | null>(null)

  useEffect(() => {
    if (currentEntries[0]) {
      const flattenedData = currentEntries.flat()
      const foundItem = flattenedData.find((item) => item.riderId === rider.riderId)

      setCurrentEntriesRider(foundItem || null)
    }
  }, [currentEntries])
  return (
    <div className={'py-5 w-full border-b border-[#D3DAEE]'}>
      <div className={'flex flex-row w-full justify-between gap-5'}>
        <div className={'flex w-full flex-col gap-3'}>
          <div className={'flex flex-1 w-full gap-2 items-center'}>
            {customImageComponent(
              rider.riderProfilePicture || rider.teamMemberProfilePicture,
              rider.riderName || rider.teamMemberName,
              'w-[45px] h-[45px] avatarImg rounded object-cover'
            )}
            <div>
              <p className={'text-[#122B4680] text-[14px]'}>Rider</p>
              <h4 className={'text-[#122B46] font-normal text-[16px]'}>{rider.riderName}</h4>
            </div>
          </div>
          <div className={'flex flex-1 gap-2 items-center'}>
            {customImageComponent(
              horseProfilePicture,
              horseName,
              'w-[45px] h-[45px] avatarImg rounded object-cover'
            )}
            <div>
              <p className={'text-[#122B4680] text-[14px]'}>Horse</p>
              <h4 className={'text-[#122B46] font-normal text-[16px]'}>{horseName}</h4>
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-3'}>
          <div className={'flex gap-2 justify-end items-center'}>
            <div>
              <p className={'text-[#122B4680] whitespace-nowrap text-right text-[14px]'}>
                Back Number
              </p>
              <h4 className={'text-[#122B46] whitespace-nowrap text-right font-normal text-[16px]'}>
                {currentEntriesRider?.backNumber || 0}
              </h4>
            </div>
            <div
              className={
                'w-[45px] h-[45px] bg-SeabiscuitMainThemeColor/5 rounded flex items-center justify-center'
              }
            >
              <IconBackNumber />
            </div>
          </div>
          <div className={'flex gap-2 justify-end items-center'}>
            <div>
              <p className={'text-[#122B4680] text-right whitespace-nowrap text-[14px]'}>Entries</p>
              <h4 className={'text-[#122B46] text-right whitespace-nowrap font-normal text-[16px]'}>
                {currentEntries.length} classes
              </h4>
            </div>
            <div
              className={
                'w-[45px] h-[45px] bg-SeabiscuitMainThemeColor/5 rounded flex items-center justify-center'
              }
            >
              <IconClasses />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
