import { useState } from 'react'
import ModalComponent from '../global/modalComponent'
import { AddCircleOutlineRounded } from '@mui/icons-material'

const dummyZones = [
  { id: 1, name: 'General zone specs' },
  { id: 2, name: 'Adult Amateur Hunter specs' },
  { id: 3, name: 'Low Adult Amateur Hunter specs' },
  { id: 4, name: 'Adult Amatuer Jumper specs' },
  { id: 5, name: 'Age Group Equitation: Adult Section' },
  { id: 6, name: 'Childrens Hunter Horse' },
  { id: 7, name: 'Low Children’s Hunter Horse' },
  { id: 8, name: 'Childrens Hunter Pony' },
]

const zoneSpecDetail: IZoneSpecDetail = [
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
  { title: 'abc', desc: 'lorem ipsum....' },
]

type IZoneSpecDetail = IZoneSpecSingleDetailBlock[]
type IZoneSpecSingleDetailBlock = {
  title: string
  desc: string
}

const EventDetailViewSingleZoneSpec = () => {
  return (
    <div className="w-full">
      {zoneSpecDetail.map((curr: IZoneSpecSingleDetailBlock, index: number) => {
        return (
          <div className="mb-4">
            <div className="text-[#3C4B7199] font-bold text-[12px] pt-1">
              {curr?.title + `${index + 1}`}
            </div>
            <div className="text-[11px] text-[#3C4B7199]">{curr?.desc + `${index + 1}`}</div>
          </div>
        )
      })}
    </div>
  )
}

const EventDetailViewComponentZoneSpecsModal: React.FC<{
  show: boolean
  onHide?: () => void
  handleModal?: any
}> = ({ show, onHide, handleModal }) => {
  // Hooks and vars
  const [activeTab, setActiveTab] = useState<number[]>([])

  // Functions
  const handleActiveTabs = (index: number) => {
    const openedSponserNewVal = [...activeTab]

    if (openedSponserNewVal.includes(index)) {
      const indexToRemove = openedSponserNewVal.indexOf(index)
      openedSponserNewVal.splice(indexToRemove, 1)
    } else openedSponserNewVal.push(index)

    setActiveTab([...openedSponserNewVal])
  }

  return (
    <>
      <ModalComponent
        show={show}
        onHide={onHide}
        handleModal={handleModal}
        modalFor="eventZoneSpecs"
        type="full-modal"
        title="Zone 6 Specs"
        size="xl"
      >
        <div className="zones flex flex-col items-center px-4">
          {dummyZones.map((currZone) => {
            return (
              <>
                <div
                  className="mb-2 border-[SeabiscuitDark400ThemeColor] border-solid border-[1px] rounded-md p-2 w-full"
                  key={`zoneSpec${currZone.id}`}
                >
                  <div
                    className="cursor-pointer grid grid-cols-[80%_50px] justify-between w-full"
                    onClick={() => handleActiveTabs(currZone.id)}
                  >
                    <span
                      className={`${activeTab.includes(currZone.id) ? 'text-SeabiscuitMainThemeColor' : 'opacity-70 text-SeabiscuitDark200ThemeColor'} text-[13px]`}
                    >
                      {currZone.name}
                    </span>
                    <span className="text-right">
                      <AddCircleOutlineRounded
                        htmlColor={`${activeTab.includes(currZone.id) ? '#F70763' : '#ccc'}`}
                        fontSize="small"
                      />
                    </span>
                  </div>

                  {activeTab.includes(currZone.id) && <EventDetailViewSingleZoneSpec />}
                </div>
              </>
            )
          })}
        </div>
      </ModalComponent>
    </>
  )
}

export default EventDetailViewComponentZoneSpecsModal
