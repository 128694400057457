import React from 'react'

interface IProps {}

export const IconBackNumber: React.FC<IProps> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
        fill="none"
        viewBox="0 0 16 20"
      >
        <path
          fill="#F70763"
          d="M6.25 0C5.102 0 4.153.883 4.025 2H2.25A2.261 2.261 0 000 4.25v13.5A2.261 2.261 0 002.25 20h11.5A2.261 2.261 0 0016 17.75V4.25A2.261 2.261 0 0013.75 2h-1.775C11.847.883 10.898 0 9.75 0h-3.5zm0 1.5h3.5c.423 0 .75.327.75.75s-.327.75-.75.75h-3.5a.739.739 0 01-.75-.75c0-.423.327-.75.75-.75zm-4 2h2.135c.406.601 1.092 1 1.865 1h3.5c.773 0 1.46-.399 1.865-1h2.135c.423 0 .75.327.75.75v13.5c0 .423-.327.75-.75.75H2.25a.739.739 0 01-.75-.75V4.25c0-.423.327-.75.75-.75zM4.741 5a.486.486 0 00-.112.015l-1 .25a.5.5 0 00.242.97l.379-.094V8.5A.5.5 0 105.251 8.5v-3a.5.5 0 00-.51-.5zM8 7a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5H8zM4.75 9.5c-.822 0-1.293.483-1.442.768a.493.493 0 00.2.659.51.51 0 00.678-.182c.015-.025.162-.245.564-.245a.5.5 0 01.5.5c0 .242-.472.506-.85.718-.54.3-1.15.64-1.15 1.282a.5.5 0 00.5.5h2a.5.5 0 000-1h-.702c.572-.325 1.202-.742 1.202-1.5 0-.827-.673-1.5-1.5-1.5zM8 11a.751.751 0 100 1.5h3.75a.751.751 0 100-1.5H8zm-3.313 3c-.78 0-1.25.478-1.302.532a.497.497 0 00.02.698c.196.19.51.185.704-.007A.864.864 0 014.688 15c.163 0 .437.033.437.25 0 .1 0 .25-.375.25a.5.5 0 000 1c.083 0 .5.012.5.25s-.417.25-.5.25c-.442 0-.708-.157-.728-.17a.5.5 0 00-.55.836c.052.034.523.334 1.278.334.897 0 1.5-.503 1.5-1.25 0-.325-.114-.604-.316-.815.123-.191.191-.423.191-.685 0-.736-.591-1.25-1.438-1.25zM8 15a.751.751 0 100 1.5h3.75a.751.751 0 100-1.5H8z"
        ></path>
      </svg>
    </>
  )
}
