import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import { cloneDeep } from 'lodash'

// Component imports
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import CustomReactSelect from '../../../../components/common/select/react-select/CustomReactSelect'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { DaysTabs } from '../../../../components/events/common/DaysTabs/DaysTabs'
import { HeaderHorseItems } from './components/HeaderHorseItems'

import { useAppSelector } from '../../../../store/hooks'
import { selectRegisterTabData } from '../../../../store/registration/registrationSlice'
import { selectedEvent } from '../../../../store/events/eventsSlice'

import { ISaveRegisterTabDataFnArgs } from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import {
  IRegisterTab,
  IRegisterTabItem,
} from '../../../../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'

import { daysOfWeek } from '../../../../helpers/time'
import useHorseTab from './helpers/useHorseTab'

// Styles
import './clinicNOtherReviewAndPayTab.css'
import { customStyles } from '../../../../components/customStyles/ReactSelectCustomStyle'

import { selectHorses } from '../../../../store/horses/horseSlice'

type IClinicNOtherRegisterHorseTabProps = {
  title?: string
  description?: string
  saveRegisterTabData: (args: ISaveRegisterTabDataFnArgs) => Promise<ISaveRegisterTabDataFnArgs>
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}

export interface IHandleHorseChange {
  id: string
  currentRow: IRegistrationByDayInterface
  isRemove: boolean
  removedIndex?: number
  isChildren: boolean
}

const getRegistrations = (registerTabData: IRegisterTab[] | null) => {
  const parentRegistrations: IRegistrationByDayInterface[] = []
  const childrenRegistrations: IRegistrationByDayInterface[] = []

  registerTabData?.forEach((register) => {
    register.registrationsByDay.forEach((registrationByDay) => {
      parentRegistrations.push(registrationByDay)
    })
    register.children?.forEach((children) => {
      children.registrationsByDay.forEach((registrationByDay) => {
        childrenRegistrations.push(registrationByDay)
      })
    })
  })

  return {
    allRegistrations: [...parentRegistrations, ...childrenRegistrations],
  }
}

const ClinicNOtherRegisterHorseTab = ({
  title,
  description,
  saveRegisterTabData,
  isManage,
  manageInfo,
  setManageInfo,
}: IClinicNOtherRegisterHorseTabProps) => {
  const { onRegistrationByDayHorseChange } = useHorseTab({
    saveRegisterTabData: saveRegisterTabData,
  })

  const selectedEventR = useAppSelector(selectedEvent)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const myHorses = useAppSelector(selectHorses)

  const [currentRegisterTabData, setCurrentRegisterTabData] = useState<IRegisterTab[] | null>(null)
  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')
  const [loading, setLoading] = useState(false)
  const [loadingCheckbox, setLoadingCheckbox] = useState(false)

  const handleHorseChange = async ({
    id,
    currentRow,
    removedIndex,
    isRemove,
    isChildren,
  }: IHandleHorseChange) => {
    const horse = myHorses.find((horse) => horse.id === id) || null

    await onRegistrationByDayHorseChange({
      currentRow,
      horse,
      isRemove,
      removedIndex,
      isChildren,
      isManage: isManage,
      manageInfo: manageInfo,
      setManageInfo: setManageInfo,
    })
  }

  const updateRegistrationByDayKey = async ({
    currRegistrationByDay,
    isChildren,
    key,
  }: {
    currRegistrationByDay: IRegistrationByDayInterface
    isChildren: boolean
    key: 'isBreak' | 'isQualify'
  }) => {
    setLoadingCheckbox(true)
    if (isManage) {
      const currentRegisterTabData_ = cloneDeep(currentRegisterTabData)
      let rowIndex = -1
      let byDayIndex = -1

      if (currentRegisterTabData_) {
        if (isChildren) {
          let childIndex = -1

          currentRegisterTabData_.forEach((parent, rowIdx) => {
            parent?.children?.forEach((child, childIdx) => {
              child.registrationsByDay.forEach((registrationByDay, byDayIdx) => {
                if (registrationByDay.uniqId === currRegistrationByDay.uniqId) {
                  rowIndex = rowIdx
                  childIndex = childIdx
                  byDayIndex = byDayIdx
                }
              })
            })
          })
          ;(currentRegisterTabData_[rowIndex].children as IRegisterTabItem[])[
            childIndex
          ].registrationsByDay[byDayIndex][key] = !(
            currentRegisterTabData_[rowIndex].children as IRegisterTabItem[]
          )[childIndex].registrationsByDay[byDayIndex][key]
        } else {
          currentRegisterTabData_.forEach((register, rowIdx) => {
            register.registrationsByDay.forEach((registrationByDay, byDayIdx) => {
              if (registrationByDay.uniqId === currRegistrationByDay.uniqId) {
                rowIndex = rowIdx
                byDayIndex = byDayIdx
              }
            })
          })

          currentRegisterTabData_[rowIndex].registrationsByDay[byDayIndex][key] =
            !currentRegisterTabData_?.[rowIndex].registrationsByDay[byDayIndex][key]
        }

        setManageInfo({ ...manageInfo, register: currentRegisterTabData_ })
      }
    } else {
      await saveRegisterTabData({
        registrationsByDayToUpdate: [
          {
            ...currRegistrationByDay,
            [key]: !currRegistrationByDay[key],
          },
        ],
      })
    }
    setLoadingCheckbox(false)
  }

  useEffect(() => {
    const days_: string[] = []
    currentRegisterTabData?.forEach((fee) => {
      const day = daysOfWeek[new Date(fee.startDate)?.getDay()]
      if (!days_.includes(day)) days_.push(day)
    })

    setDays(
      days_.sort((a, b) => {
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
      })
    )
  }, [currentRegisterTabData])

  useEffect(() => {
    const register = isManage ? manageInfo.register : registerTabData
    setCurrentRegisterTabData(register)
  }, [isManage, manageInfo.register, registerTabData])

  return (
    <CompetitorEventRegisterWrapper title={title} description={description}>
      <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
      {getRegistrations(currentRegisterTabData).allRegistrations.length > 0 && (
        <div className="hidden md:block">
          <HeaderHorseItems EventFees={selectedEventR?.EventFees} />
        </div>
      )}
      <div className="flex flex-col gap-2">
        {getRegistrations(currentRegisterTabData).allRegistrations.length > 0 ? (
          currentRegisterTabData?.map((currentRow) => {
            const registrationsByDayAll: IRegistrationByDayInterface[] = []
            let isChildren = false

            currentRow.registrationsByDay.forEach((registrationByDay) => {
              registrationsByDayAll.push(registrationByDay)
            })
            currentRow.children?.forEach((children) => {
              children.registrationsByDay.forEach((registrationByDay) => {
                isChildren = true
                registrationsByDayAll.push(registrationByDay)
              })
            })

            return registrationsByDayAll.map((currRegistrationByDay) => {
              if (
                daysOfWeek[new Date(currentRow.startDate)?.getDay()] === activeDay ||
                activeDay === 'All'
              ) {
                const values: { index: number; label: string; value: string }[] = []
                let count = 0

                getRegistrations(currentRegisterTabData).allRegistrations.forEach(
                  (registrationByDay) => {
                    if (
                      registrationByDay.horseIds &&
                      registrationByDay.horseIds?.length > 0 &&
                      registrationByDay.uniqId === currRegistrationByDay.uniqId
                    ) {
                      registrationByDay.horseIds?.forEach((id) => {
                        values.push({
                          index: count,
                          value: myHorses?.find((horse) => horse.id === id)?.id ?? '',
                          label: myHorses?.find((horse) => horse.id === id)?.horseName ?? '',
                        })
                        count++
                      })
                    }
                  }
                )

                return (
                  <div
                    className={clsx(
                      `w-full flex justify-between flex-wrap lg:mb-0 gap-2 text-SeabiscuitDark200ThemeColor`
                    )}
                  >
                    <div
                      className={clsx(
                        'w-full md:w-auto md:flex-1 gap-2 text-base flex flex-wrap items-center pr-4 py-3 pl-4 border rounded-lg border-SeabiscuitLightThemeColorD3'
                      )}
                    >
                      {currRegistrationByDay.registrationByDayName}{' '}
                      {currRegistrationByDay.order ? (
                        <span className="text-[14px] text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5 rounded-full py-0.5 px-3">
                          {currRegistrationByDay.order}
                        </span>
                      ) : null}
                      <div className="ml-auto flex flex-wrap items-center justify-end text-SeabiscuitDark200ThemeColor/50">
                        <span className="text-SeabiscuitDark200ThemeColor mr-1">
                          {currRegistrationByDay.qualifyFee &&
                          selectedEventR?.EventFees?.qualifyingClasses?.isEnable
                            ? `$${currRegistrationByDay.qualifyFee}`
                            : '$0'}
                        </span>{' '}
                        {currRegistrationByDay.qualifyFeeName || 'qual fee'} •{' '}
                        <span className="mx-1 text-SeabiscuitDark200ThemeColor mr-1">
                          {currRegistrationByDay.breakTime ?? '0'} min
                        </span>{' '}
                        break •{' '}
                        <span className="ml-1 text-SeabiscuitMainThemeColor">
                          {currRegistrationByDay.riderName}
                        </span>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        'w-full md:w-[20%] min-w-[180px] flex items-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative'
                      )}
                    >
                      <CustomReactSelect
                        isMulti
                        onChange={async (_currenValue, actionMeta: any) => {
                          setLoading(true)
                          await handleHorseChange({
                            id: actionMeta?.option?.value
                              ? actionMeta.option?.value
                              : actionMeta?.removedValue?.value
                                ? actionMeta.removedValue?.value
                                : '',
                            currentRow: currRegistrationByDay,
                            removedIndex: actionMeta?.removedValue?.index,
                            isRemove: actionMeta?.action === 'remove-value',
                            isChildren,
                          })
                          setLoading(false)
                        }}
                        options={myHorses.map((horse, index) => ({
                          index,
                          label: horse.horseName ?? '',
                          value: horse.id ?? '',
                        }))}
                        value={values}
                        isClearable={false}
                        isSearchable={false}
                        isLoading={loading}
                        isDisabled={loading}
                        styles={customStyles}
                        placeholder="Select Horse"
                        className={`w-full rounded-lg searchableComponent focus:ring-0 p-0 focus:ring-transparent h-full flex items-center capitalize ${!myHorses.length ? 'bg-[#f8f8ff]' : ''}`}
                      />
                    </div>
                    <div className="ml-auto flex flex-col">
                      <div className="block md:hidden">
                        <HeaderHorseItems EventFees={selectedEventR?.EventFees} />
                      </div>
                      <div className="flex-1 flex gap-2">
                        {selectedEventR?.EventFees?.rideTimeRequirement?.isEnable && (
                          <div
                            className={clsx(
                              'ml-auto group w-[55px] min-h-[55px] flex items-center justify-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative',
                              Number(currRegistrationByDay?.breakTime) > 0 && 'cursor-pointer',
                              loadingCheckbox && 'pointer-events-none opacity-50'
                            )}
                            onClick={async () => {
                              if (Number(currRegistrationByDay?.breakTime) > 0)
                                await updateRegistrationByDayKey({
                                  currRegistrationByDay,
                                  isChildren,
                                  key: 'isBreak',
                                })
                            }}
                          >
                            <div
                              className={clsx(
                                'w-5 h-5 rounded-full select-none',
                                Number(currRegistrationByDay?.breakTime) > 0
                                  ? 'border-SeabiscuitDark200ThemeColor cursor-pointer group-hover:group-hover:opacity-70'
                                  : 'border-SeabiscuitDark200ThemeColor/20',
                                !currRegistrationByDay.isBreak && 'border-2'
                              )}
                            >
                              {currRegistrationByDay.isBreak && (
                                <img className="w-full" src="/assets/cp_icons/Ok-3.svg" alt="" />
                              )}
                            </div>
                          </div>
                        )}
                        {selectedEventR?.EventFees?.qualifyingClasses?.isEnable && (
                          <div
                            className={clsx(
                              'group w-[55px] min-h-[55px] flex items-center justify-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative',
                              Number(currRegistrationByDay?.qualifyFee) > 0 && 'cursor-pointer',
                              loadingCheckbox && 'pointer-events-none opacity-50'
                            )}
                            onClick={async () => {
                              if (Number(currRegistrationByDay?.qualifyFee) > 0)
                                await updateRegistrationByDayKey({
                                  currRegistrationByDay,
                                  isChildren,
                                  key: 'isQualify',
                                })
                            }}
                          >
                            <div
                              className={clsx(
                                'w-5 h-5 rounded-full select-none',
                                Number(currRegistrationByDay?.qualifyFee) > 0
                                  ? 'border-SeabiscuitDark200ThemeColor cursor-pointer group-hover:opacity-70'
                                  : 'border-SeabiscuitDark200ThemeColor/20',
                                !currRegistrationByDay.isQualify && 'border-2'
                              )}
                            >
                              {currRegistrationByDay.isQualify && (
                                <img className="w-full" src="/assets/cp_icons/Ok-3.svg" alt="" />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })
          })
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="Please add a rider before adding a horse"
          />
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherRegisterHorseTab
