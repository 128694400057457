import React from 'react'
import { IconProfilePicture } from '../icons/IconProfilePicture'
import { IconPhoto } from '../icons/IconPhoto'
import { Autorenew } from '@mui/icons-material'

interface IProps {
  userIcon: string
  title: string
  picture: string
  isLoading?: boolean
  onchangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputUploadMemberPictureRow: React.FC<IProps> = ({
  userIcon,
  picture,
  isLoading,
  onchangeHandler,
}) => {
  return (
    <>
      <div className={'flex items-center mb-3'}>
        <div className={'flex items-center gap-[16px] min-w-[180px]'}>
          <IconProfilePicture />
          <span className={'text-[#122B46] text-[14px]'}>Profile picture</span>
        </div>
        <button
          className={
            'group relative rounded-full w-[90px] h-[90px] bg-[#D3DAEE54] bg-no-repeat bg-center hover:bg-[#d0dcff6e]'
          }
          style={{ backgroundImage: `url(${userIcon})`, backgroundSize: '60px' }}
        >
          {picture && (
            <img
              src={picture}
              className="object-cover rounded-full h-full w-full"
              alt="profile_picture"
            />
          )}
          {!picture && (
            <span
              className={
                'absolute z-10 top-full left-full -translate-y-full -translate-x-full bg-[#8C95AC] w-[30px] h-[30px] flex items-center justify-center rounded-full group-hover:bg-[#69748f]'
              }
            >
              <IconPhoto />
            </span>
          )}

          <input
            onChange={onchangeHandler}
            disabled={isLoading}
            type="file"
            accept=".jpeg, .png, .jpg"
            className="UserProfilePicture absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
          />

          {isLoading && <Autorenew className="animate-spin text-SeabiscuitMainThemeColor" />}
        </button>
      </div>
    </>
  )
}
