import { IonList } from '@ionic/react'
import NotFoundAlert from '../../components/common/alerts/not-found/NotFoundAlert'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { useHorsePublicProfile } from './hooks'
import IHorsePublicProfile from './types'
import HorseProfileCard from './components/profile-card'
import HorseCompetetionDetailsCard from './components/competetion-details-card'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../models/interface.helper'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import FirestoreService from '../../services/firestoreService'
import { CONST } from '../../const/const'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IEventInterface } from '../../models/events/event.interface'
import { EventModel } from '../../models/events/event.model'
import moment from 'moment'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const CURRENT_DATE = (() => {
  let date = new Date(Date.now())
  date.setHours(0, 0, 0, 0)
  return date
})()
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorsePublicProfile = (_: IHorsePublicProfile['IProps']) => {
  const [events, setEvents] = useState<IEventInterface[]>([])
  const [pastEvents, setPastEvents] = useState<IEventInterface[]>([])
  const [NewEvents, setNewEvents] = useState<IEventInterface[]>([])
  const { horseDocId } = useParams<{ horseDocId: string }>()
  const horsePublicProfile = useHorsePublicProfile()

  const handlegethorse = async (horseDocId: string) => {
    let registrationsByDay: IRegistrationByDayInterface[] = []
    let events: IEventInterface[] = []
    let Pastevents: IEventInterface[] = []
    let Newevents: IEventInterface[] = []
    const registrationByDaySnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.HORSE_ID.KEY, '==', horseDocId)]
    )

    registrationByDaySnaps.docs.forEach((currDoc) => {
      registrationsByDay.push(
        getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    if (registrationsByDay.length) {
      await asyncWhileLoop({
        loopCount: registrationsByDay?.length,
        functionToFire: async (index) => {
          const currByday = registrationsByDay[index]
          const { eventId } = currByday

          if (eventId) {
            const eventSnapshot = await FirestoreService.getItem(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
              eventId
            )

            if (eventSnapshot.exists()) {
              const event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()

              if (moment(event.eventEndDate).isBefore(CURRENT_DATE)) {
                Pastevents.push(event)
              }
              if (moment(event.eventEndDate).isAfter(CURRENT_DATE)) {
                Newevents.push(event)
              }
              events.push(event)
            }
          }
        },
      })
      setEvents(events)
      setPastEvents(Pastevents)
      setNewEvents(Newevents)
    }
  }
  useEffect(() => {
    if (horseDocId) {
      handlegethorse(horseDocId)
    }
  }, [horseDocId])

  return (
    <WrapperContainer
      className="flex"
      removeShadow={true}
      removePadding={true}
      loading={horsePublicProfile.loading}
      isError={horsePublicProfile.horseNotFound}
      noBg={!horsePublicProfile.loading && !horsePublicProfile.horseNotFound}
    >
      {horsePublicProfile.horseNotFound ? (
        <NotFoundAlert description="Horse not found" />
      ) : (
        <>
          <div className="flex flex-col gap-1 w-full mt-2">
            <div className="cursor-pointer text-[24px] text-SeabiscuitDark200ThemeColor py-2 font-bold wrapper_container">
              Profile
            </div>
            <div className="flex lg:flex-row flex-col gap-6">
              <IonList className="home_root_ion_list lg:max-w-[435px]">
                <HorseProfileCard {...horsePublicProfile} />
              </IonList>
              <IonList className="home_root_ion_list">
                <HorseCompetetionDetailsCard
                  events={events}
                  pastEvents={pastEvents}
                  NewEvents={NewEvents}
                />
              </IonList>
            </div>
          </div>
        </>
      )}
    </WrapperContainer>
  )
}

export default HorsePublicProfile

type IAsyncWhileLoopArgs = {
  loopCount: number
  functionToFire: (index: number) => Promise<any>
}

type IAsyncWhileLoopFn = (args: IAsyncWhileLoopArgs) => Promise<void>

const asyncWhileLoop: IAsyncWhileLoopFn = async (args) => {
  let currLoopIndex = 0

  let { loopCount, functionToFire } = args

  while (currLoopIndex < loopCount) {
    await functionToFire(currLoopIndex)
    currLoopIndex++
  }
}
