import { useEffect } from 'react'

// Third party
import clsx from 'clsx'
import { v4 } from 'uuid'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { IRegistrationTicketInterface } from '../../../../models/registration-tickets/registrationTicket.interface'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CONST } from '../../../../const/const'
import { IPaymentStatus } from '../../../../models/event-registered-users/event-registered-users.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorTicketsAccordion = {
  data: IRegistrationTicketInterface[]
  showScratchedView: boolean
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  registrationticketData: IRegistrationTicketInterface[]
}

type IDetailRowProps = {
  title: string | null
  cost?: number
  units: number
  itemId?: string | null
  showScratchedView: boolean
  listToScratch: string[]
  handleScratchList: (itemId: string) => void
  isScratched?: boolean
  Item: IRegistrationTicketInterface
  paymentStatus: IPaymentStatus
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const ExhibitorTicketsInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({
  title,
  cost,
  units,
  showScratchedView,
  isScratched,
  Item,
  listToScratch,
  itemId,
  handleScratchList,
  paymentStatus,
}: IDetailRowProps) => {
  let id = `${v4()}${Date.now()}`
  let checked = Item.id ? listToScratch.find((data) => data === Item.id) : false

  // { title, cost, totalFees, units }: { title: any, cost?: any, totalFees?: any, units: any }
  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row lg:items-center w-full">
      <div className="lg:hidden font-medium text-[12px]">Ticket</div>
      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4"
        data={title}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Amount</div>
      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 lg:text-center"
        data={units}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Price per ticket</div>
      <ExhibitorTicketsInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 lg:text-center"
        data={`$${cost}`}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Total</div>
      <ExhibitorTicketsInput
        className="relative rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center"
        data={isScratched ? 'Scratched' : `$${Number((cost ?? 0) * units).toFixed(2)}`}
        icon={
          paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />

      {showScratchedView && !!itemId && typeof itemId === 'string' ? (
        <label
          htmlFor={id}
          className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/4 text-center relative flex items-center justify-center"
        >
          {isScratched ? (
            'Scratched'
          ) : (
            <>
              {' '}
              Scratch
              <div
                className={clsx(
                  'ml-2 !ring-0 !shadow-none absolute shrink-0 flex items-center justify-center',
                  !checked
                    ? 'border-2 border-SeabiscuitDark200ThemeColor rounded-full w-5 h-5 right-[14px]'
                    : 'w-6 h-6 right-3'
                )}
              >
                {checked || isScratched ? (
                  <img
                    src="/assets/og_icons/Ok-3.svg"
                    alt="Ok"
                    className="w-full h-full object-cover"
                  />
                ) : null}
              </div>{' '}
            </>
          )}

          {isScratched ? null : (
            <input
              type="checkbox"
              className="hidden"
              id={id}
              checked={!!checked}
              onChange={() => handleScratchList(itemId as string)}
            />
          )}
        </label>
      ) : null}
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorTicketsAccordion = (props: IExhibitorTicketsAccordion) => {
  const toastMethods = useToasterHelper()

  // Hooks and vars
  const { showScratchedView, setListToScratch, listToScratch, registrationticketData } = props
  const data = registrationticketData

  const handleShowToast = () => {
    return toastMethods.info({ message: 'Selected member Bill has not yet paid' })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    // setAmountToRefund(0);
    setListToScratch([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScratchedView])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleScratchList = (itemId: string) => {
    let newstr = [...listToScratch]

    if (listToScratch.find((data) => data === itemId)) {
      let filter = listToScratch.filter((data) => data !== itemId)
      setListToScratch(filter)
    } else {
      let memberData = data.filter((registration) => registration.id === itemId)
      if (memberData.length && memberData[0].paymentStatus !== 'paid') {
        handleShowToast()
      } else {
        setListToScratch([...newstr, itemId])
      }
    }
  }

  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorTicketsInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Ticket</span>}
        />
        <ExhibitorTicketsInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Amount</span>}
        />
        <ExhibitorTicketsInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Price per ticket</span>}
        />
        <ExhibitorTicketsInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Total</span>}
        />

        {showScratchedView ? (
          <ExhibitorTicketsInput
            className={clsx(
              'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/5'
            )}
            data={
              <span className="flex items-center">
                Scratch
                <Tooltip title={<h1 className="tooltip_title">Scratch</h1>} placement="top" arrow>
                  <button className="">
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </span>
            }
          />
        ) : null}
      </div>

      {data && data.length
        ? data.map((currentItem, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.ticketTitle}
                units={currentItem.selectedUnitsCount ?? 0}
                cost={currentItem.ticketPrice ?? 0}
                itemId={currentItem?.id}
                listToScratch={listToScratch}
                Item={currentItem}
                showScratchedView={showScratchedView}
                handleScratchList={handleScratchList}
                isScratched={currentItem?.isScratched ?? false}
                paymentStatus={currentItem?.paymentStatus ?? null}
              />
            )
          })
        : null}

      {/* <div className='flex items-center w-full'>

        <ExhibitorTicketsInput
          className={
            clsx(
              'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1',
              showScratchedView ? "w-1/5" : "w-1/4"
            )
          }
          data={`Total`}
        />

        {!showScratchedView ?
          <>
            <ExhibitorTicketsInput
              className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 text-center w-1/4"
              data={`${totalItemCount} ${totalItemCount > 1 ? "items" : "item"}`}
            />

            <ExhibitorTicketsInput
              className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 text-center w-1/4"
              data={""}
            />
          </> :
          null}

        <ExhibitorTicketsInput
          className={
            clsx(
              'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1',
              showScratchedView ? "w-[calc(80%+24px)] text-right" : "w-1/4 text-center"
            )}
          data={(() => {
            if (!showScratchedView)
              return `$${props.ticketsTotal}`
            else
              return `${listToScratch.length} ${listToScratch.length > 1 ? "items" : "item"} scratched, $${ticketsTotal} refund requested`
          })()}
        />

      </div> */}
    </>
  )
}

export default ExhibitorTicketsAccordion
