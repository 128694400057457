// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// Styles
import '@ionic/react/css/ionic-swiper.css'
import 'swiper/css/lazy'

import './EventPostHorizontalCardComponent.css'

// Third party
import _ from 'lodash'
import SwiperCore, { A11y, Lazy, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Constants
import clsx from 'clsx'
import { CONST } from '../../../../const/const'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { IEventInterface } from '../../../../models/events/event.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import { IconCalendar } from '../../../icons/IconCalendar'
import { CalendarIcon } from '../../../icons/CalendarIcon'
import { IconBookmark } from '../../../icons/IconBookmark'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const DUMMY_USER_IMAGE = IMAGE_CONSTS.PLACEHOLDERS.USER
const DETAIL_PAGE_ROUTE = CONST.ROUTES.EVENT_DETAILS.URL + '/'
const DEFAULT_CARD_IMAGE_URL = 'assets/img/Empty Event Portrait.png'
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export interface EventHorizontalCardComponentProps
  extends Omit<
    IEventInterface,
    | 'currentRegistrationRevenue'
    | 'expectedRegistrationRevenue'
    | 'currentTicketRevenue'
    | 'expectedTicketRevenue'
    | 'currentFeesRevenue'
    | 'expectedFeesRevenue'
    | 'totalRegistrationRevenue'
    | 'updateTicketCount'
    | 'ticketItemsCount'
    | 'soldTicketItemsCount'
    | 'totalStallsCount'
    | 'soldStallsCount'
    | 'totalFeedCount'
    | 'soldFeedCount'
    | 'totalBeddingCount'
    | 'soldBeddingCount'
    | 'totalOtherCount'
    | 'soldOtherCount'
  > {
  id: any
  city?: string
  sale?: string
  state?: string
  title?: string
  tags?: string[]
  onSelected?: any
  country?: string
  available?: number
  registered?: number
  isLicensed?: boolean
  isBookmarked: boolean
  waitlistCount?: number
  bookmarkId: string | null
  briefDescription?: string
  cardCoverImageUrl?: string
  registrationCloseDays?: number
  bookmarkBeingProcessed: boolean
  competitionCourseMapImages?: string[]
  bookmarkEvent: (eventId: string, bookmarkId: null | string) => Promise<void>
  resetEventDetailPageData: () => void
  isLoggedIn: boolean
}

interface ExtendedSwiper extends SwiperCore {
  swiper?: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

type IEventDate = {
  seconds: number
  nanoseconds: number
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const getEventDetails = (obj: EventHorizontalCardComponentProps) => {
  const imagesArr = [
    { src: '/assets/og_icons/YearofHorse-1.svg', alt: 'TypeOfHorse', key: 'tags' },
    { src: '/assets/og_icons/Prize-1.svg', alt: 'Prize', key: 'prize' },
    { src: '/assets/og_icons/Location-1.svg', alt: 'Location', key: 'location' },
    {
      src: '/assets/og_icons/Calendar-1.svg',
      alt: 'Registration',
      key: 'registration',
      component: CalendarIcon,
    },
  ]

  const getFormattedDate = (date: string | Date) => {
    let formattedDate
    formattedDate = date as unknown as IEventDate
    date = new Date(formattedDate?.nanoseconds ?? formattedDate)
    if (_.isDate(new Date(formattedDate?.nanoseconds))) {
      return `${monthNames[date.getMonth()]} ${date.getDate()}`
    }
    return '00 Jan'
  }

  const getVal = (curr: any) => {
    let strToReturn = ''
    switch (curr.key) {
      case 'tags':
        strToReturn =
          obj.category === COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.UNLICENSED
            ? 'N/A'
            : (obj?.tags?.join(', ') ?? 'Tags')
        break
      case 'prize':
        strToReturn = obj?.category ?? 'No Prize'
        break
      case 'location':
        strToReturn = obj?.state ?? 'Unknown'
        break
      case 'registration':
        strToReturn = `Registrations: ${getFormattedDate(obj?.registrationOpenDate as Date)} - ${getFormattedDate(obj?.registrationCloseDate as Date)}`
        break
    }

    return strToReturn
  }

  return (
    <>
      {imagesArr.map((curr) => {
        return (
          <div className="flex items-start mb-1" key={curr.src}>
            {curr.component ? (
              <span className={'w-[18px] mr-[8px] pt-[1px]'}>
                <curr.component
                  color={obj.status === 'cancel' ? '#F2583E' : '#122B46'}
                  width={18}
                  height={18}
                />
              </span>
            ) : (
              <img
                src={curr?.src}
                alt={curr?.alt}
                className="object-cover mr-2 xl:w-[15px] xl:h-[15px] 2xl:w-[18px] 2xl:h-[18px]"
              />
            )}

            <span
              className="capitalize text-SeabiscuitDark200ThemeColor xl:text-[12px] 2xl:text-[14px] line-clamp-1"
              title={getVal(curr)}
            >
              {curr.key === 'registration' ? (
                obj.status === 'cancel' ? (
                  <span className={'text-[#F2583E] uppercase font-bold'}>Cancelled</span>
                ) : obj.status === 'past' ? (
                  'PAST'
                ) : (
                  getVal(curr)
                )
              ) : (
                getVal(curr)
              )}
            </span>
          </div>
        )
      })}
    </>
  )
}

const EventPostHorizontalCardComponent: React.FC<EventHorizontalCardComponentProps> = (props) => {
  /* Hooks and vars */
  const { eventStartDate } = props
  const swiper = useRef<ExtendedSwiper>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const bookmarked = props.isBookmarked
  const disablePrev = currentIndex === 0
  const disableNext = swiper.current?.swiper?.isEnd || !props?.eventImages?.length

  let registeredUsersImages = [
    ...(props?.registeredUsersImages ?? []),
    ...[...Array(3)].map(() => DUMMY_USER_IMAGE),
  ].slice(0, 3)

  const goNext = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slidePrev()
    }
  }

  const handleClickOnBookmark = () => {
    props.bookmarkEvent(props?.id, props.bookmarkId)
  }

  const customNavigation = (length: number) => {
    if (length > 1) {
      return (
        <div className={clsx(`flex flex-row  absolute z-10 bottom-[10px]`)}>
          {[...Array(length <= 6 ? length : 6)].map((data, currDotIndex) => {
            return (
              <span
                key={`${JSON.stringify(data)}${currDotIndex}`}
                className={` rounded ${currDotIndex <= currentIndex ? `bg-[white]` : `bg-[white]`} p-[4px] mx-[2px] ${currDotIndex > currentIndex ? `opacity-[0.4]` : ''}`}
              >
                {data}
              </span>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="event-post-horizontal-card-component transition-shadow duration-300 ease-in-out relative mb-4 h-full w-auto">
      <Swiper
        lazy={{ enabled: true, loadPrevNext: true, loadPrevNextAmount: 1 }}
        ref={swiper as any}
        modules={[Navigation, Pagination, Scrollbar, A11y, Lazy]}
        className="mb-4 group rounded-xl w-full h-auto aspect-square max-h-[26vh] xl:h-[26vh] 2xl:h-[26vh] xl:max-h-[26vh] 2xl:max-h-[26vh] overflow-hidden"
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onActiveIndexChange={(activeIndex_) => {
          setCurrentIndex(activeIndex_.activeIndex)
        }}
      >
        {props?.eventImages?.length && typeof props?.eventImages === 'object' ? (
          props?.eventImages.map((currImage, index) => {
            return (
              <SwiperSlide
                key={`${currImage}${index}`}
                className="rounded-xl overflow-hidden"
                style={{ height: '267px' }}
              >
                {currentIndex === index && customNavigation(Number(props?.eventImages?.length))}
                <Link className="w-full" to={`${DETAIL_PAGE_ROUTE}${props?.id}`}>
                  {customImageComponent(
                    currImage ?? DEFAULT_CARD_IMAGE_URL,
                    '',
                    'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
                    true
                  )}
                  <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                    <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })
        ) : (
          <SwiperSlide className="rounded-xl overflow-hidden">
            <Link className="w-full" to={`${DETAIL_PAGE_ROUTE}${props?.id}`}>
              {customImageComponent(
                props?.eventCardCoverImageUrl
                  ? props?.eventCardCoverImageUrl
                  : DEFAULT_CARD_IMAGE_URL,
                '',
                'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
                true
              )}
              <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
              </div>
            </Link>
          </SwiperSlide>
        )}

        {!disablePrev && props?.eventImages?.length && props?.eventImages?.length > 1 ? (
          <button
            onClick={goPrev}
            className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in left-3  rounded-full"
          >
            <img src="/assets/next_swiper.svg" alt="next" className="h-[40px]" />
          </button>
        ) : null}

        {!disableNext && props?.eventImages?.length && props?.eventImages?.length > 1 ? (
          <button
            onClick={goNext}
            className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in right-3 rounded-full"
          >
            <img src="/assets/prev_swiper.svg" alt="next" height={50} className="h-[40px]" />
          </button>
        ) : null}
      </Swiper>

      {props.isLoggedIn ? (
        <div
          onClick={handleClickOnBookmark}
          className={clsx(
            `group event-post-horizontal-card-component__bookmark-label flex items-center justify-center z-50 cursor-pointer transition-[0.2s] hover:bg-white ${bookmarked ? 'bg-white' : ''}`,
            props.bookmarkBeingProcessed && 'animate-pulse'
          )}
        >
          {bookmarked ? (
            <img
              src={'assets/og_icons/Bookmark-6.svg'}
              className="w-[11px] h-7 mx-auto"
              alt="bookmark"
            />
          ) : (
            <IconBookmark
              className={
                'fill-white group-hover:fill-SeabiscuitMainThemeColor transition-[0.2s] w-[20px] h-auto'
              }
            />
          )}
        </div>
      ) : null}

      <Link to={`${DETAIL_PAGE_ROUTE}${props?.id}`} onClick={props.resetEventDetailPageData}>
        <div className="event-post-horizontal-card-component__date-label z-50">
          <div className="event-post-horizontal-card-component__upper-date-day__text">
            {new Date(eventStartDate ?? '').getDate()}
          </div>
          <div className="event-post-horizontal-card-component__upper-date-month__text">
            {new Date(eventStartDate ?? '').toLocaleString('default', { month: 'short' })}
          </div>
        </div>

        <div className="event-post-details">
          <div className="event-post-horizontal-card-component__card__title w-full !mb-4 text-SeabiscuitDark200ThemeColor cursor-pointer 2xl:text-[20px] xl:text-[14px] capitalize">
            {props.eventName}
          </div>

          {/* EVENT DETAILS */}

          {getEventDetails(props)}

          {/* EVENT DETAILS */}

          {/* <div className="event-post-horizontal-card-component__card__registration__counter items-center flex pt-2">
            <div className="flex -space-x-1 relative z-0 overflow-hidden min-w-[72px]">
              {registeredUsersImages.length
                ? registeredUsersImages.map((currUserImage, index) => {
                    return (
                      <img
                        alt={'UI'}
                        onError={(e) => ((e.target as any).src = DUMMY_USER_IMAGE)}
                        src={currUserImage}
                        key={index}
                        className={clsx(
                          'relative inline-block object-cover w-[28px] h-[26px] xl:h-[26px] xl:w-[26px] 2xl:h-[28px] 2xl:w-[28px] rounded-full  bg-white'
                        )}
                      />
                    )
                  })
                : null}
            </div>
            <div className="text-sm text-SeabiscuitDark200ThemeColor pl-2">
              <span className="text-SeabiscuitMainThemeColor font-semibold pr-1 whitespace-nowrap">
                {`${!isNaN(props.registeredCountIncludingUnpaid ?? 0) ? props.registeredCountIncludingUnpaid : 0}`}{' '}
                Registered,
              </span>
              {props.waitlistCount ?? 0} on waitlist
            </div>
          </div> */}
        </div>
      </Link>
    </div>
  )
}

EventPostHorizontalCardComponent.defaultProps = {
  id: '',
  cardCoverImageUrl: '',
  isLicensed: true,
  title: 'Default Title',
  briefDescription: 'Temporary Brief Description',
  registered: 0,
  available: 0,
  registrationCloseDays: 0,
  sale: '0.00',
}

export default EventPostHorizontalCardComponent
