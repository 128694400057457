import React, { FC, useContext, useState } from 'react'
import clsx from 'clsx'
import { Add, HighlightOff } from '@mui/icons-material'

import { IOnRemoveImageButtonClickFn } from '../HorsePaperwork'

import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'

import { getImage } from '../../../helpers/image'
import AddCertificateModal from '../../../components/modals/AddCertificateModal'
import { IHorseData } from '../../../models/horse/horse.interface'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'

interface CogginsProps {
  type: 'update-paperwork-modal' | 'horse-paperwork-tab'
  name: keyof IHorseCompetitorDocument
  label: string
  notEditable?: boolean
  onRemoveImageButtonClick: IOnRemoveImageButtonClickFn
  values?: string[]
  selectedHorse: IHorseData | null
  onSubmit: (data: any, keepUnsaved: boolean) => Promise<void>
  setValue: UseFormSetValue<IHorseCompetitorDocument>
  watch: UseFormWatch<IHorseCompetitorDocument>
}

export const CertificateImages: FC<CogginsProps> = ({
  type,
  name,
  label,
  values,
  notEditable,
  onRemoveImageButtonClick,
  selectedHorse,
  onSubmit,
  setValue,
  watch,
}) => {
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const [loading, setLoading] = useState(false)
  const [addCertificateModalShow, setAddCertificateModalShow] = useState(false)

  const imageSetter = async (key: any, imageUrls: string[]) => {
    setValue(key, [...(watch(key) ?? []), ...imageUrls])

    await onSubmit(watch(), true)
  }

  const onUploadCertificateClick = () => {
    if (!selectedHorse?.id || notEditable) return

    if (type === 'horse-paperwork-tab') {
      handleModal?.(true, MODAL_CONSTS.HORSE_ADD_CERTIFICATE, {
        fileUploadType: name,
        data: watch(),
        setter: imageSetter,
      })
    }

    if (type === 'update-paperwork-modal') {
      setAddCertificateModalShow(true)
    }
  }

  return (
    <>
      {addCertificateModalShow && (
        <AddCertificateModal
          handleCloseModal={() => setAddCertificateModalShow(false)}
          dataToPassOn={{
            fileUploadType: name,
            data: watch(),
            setter: imageSetter,
          }}
        />
      )}
      <div className="flex flex-wrap items-center mt-2 w-full gap-4">
        <div className="flex items-center min-w-[150px] w-full sm:w-[18%] text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="" />
          Upload {label} Certificate
        </div>
        <div className="wrapper flex flex-col gap-4 flex-grow py-2 w-full sm:w-[78%]">
          {values && values.length > 0 && (
            <div className="flex flex-wrap rounded-md relative gap-2">
              {values.map((item, index) => (
                <div
                  key={`${name}${item}${index}`}
                  className={clsx(
                    'image-preview rounded-md w-24 h-24 z-[0] relative',
                    {
                      border: getImage(item).type === 'image',
                    },
                    loading && 'pointer-events-none opacity-70'
                  )}
                >
                  <img
                    src={getImage(item).url}
                    className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                    alt=""
                    onClick={() => window.open(item, '_blank')}
                  />
                  {!notEditable ? (
                    <button
                      onClick={async () => {
                        setLoading(true)
                        await onRemoveImageButtonClick({ index, type: name })
                        setLoading(false)
                      }}
                      type="button"
                      className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                    >
                      <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999] transition-all hover:opacity-70" />
                    </button>
                  ) : null}
                </div>
              ))}
            </div>
          )}

          {!notEditable ? (
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={onUploadCertificateClick}
            >
              <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
            </div>
          ) : (
            values &&
            values?.length <= 0 && (
              <div className="text-SeabiscuitDark200ThemeColor text-sm opacity-50">No image</div>
            )
          )}
        </div>
      </div>
    </>
  )
}
