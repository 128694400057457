const tags = [
  'All',
  'American Saddlebred',
  'American Quarter Horse',
  'Andalusian',
  'Arabian',
  'Baroque',
  'Bareback Riding',
  'Barrel Racing',
  'Breakaway Roping',
  'Breed',
  'Bull Riding',
  'Carriage',
  'Connemara',
  'Cutting',
  'Dressage',
  'Driving',
  'Endurance',
  'Equitation',
  'Eventing',
  'Field Hunter',
  'Fox Hunting',
  'Friesian',
  'Gaited',
  'Gymkhana',
  'Hackney',
  'Halter',
  'Horse Management',
  'Horsemanship',
  'Hunter',
  'Hunter Jumper',
  'Jumper',
  'IHSA',
  'Leisure',
  'Lusitano',
  'Morgan',
  'Mounted Games',
  'Mounted Shooting',
  'National Show Horse',
  'NCEA',
  'OTTB',
  'Painthorse',
  'Paraequestrian',
  'Paso Fino',
  'Pleasure',
  'Polo',
  'Polocrosse',
  'Pony Club',
  'Quiz',
  'Racing',
  'Ranch Riding',
  'Reining',
  'Reining Cow Horse',
  'Rodeo',
  'Roping',
  'Saddle Bronc',
  'Saddle Seat',
  'Shetland',
  'Show Jumping',
  'Side Saddle',
  'Stallion at Stud',
  'Steer Roping',
  'Steer Wrestling',
  'Team Penning',
  'Team Roping',
  'Tennessee Walking Horse',
  'Tetrathlon',
  'Tie Down Roping',
  'Trail',
  'Trail Riding',
  'Trotting',
  'Vaulting',
  'Welsh Pony/Cob',
  'Western Dressage',
  'Western Hunter',
  'Western Other',
  'Western Pleasure',
  'Western Riding',
  'Working Cow Horse',
  'Working Equitation',
  'Online',
  'Other',
]

export { tags }
