import React from 'react'

export const IconClasses: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none" viewBox="0 0 16 20">
    <path
      fill="#F70763"
      d="M2.25 0A2.261 2.261 0 000 2.25v15.5A2.261 2.261 0 002.25 20h11.5A2.261 2.261 0 0016 17.75V6.25a.75.75 0 00-.22-.53l-.007-.008L10.28.22A.75.75 0 009.75 0h-7.5zm0 1.5H9v3.25A2.261 2.261 0 0011.25 7h3.25v10.75c0 .423-.327.75-.75.75H2.25a.739.739 0 01-.75-.75V2.25c0-.423.327-.75.75-.75zm8.25 1.06l2.94 2.94h-2.19a.739.739 0 01-.75-.75V2.56zM4.75 9a.75.75 0 100 1.5h6.5a.751.751 0 100-1.5h-6.5zm0 3a.751.751 0 100 1.5h6.5a.751.751 0 100-1.5h-6.5zm0 3a.751.751 0 100 1.5h4.5a.751.751 0 100-1.5h-4.5z"
    ></path>
  </svg>
)
