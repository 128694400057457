import React, { FC } from 'react'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { CertificateImages } from './components/CertificateImages'
import { MeasurementCard } from './components/MeasurementCard'
import { CertificateDate } from './components/CertificateDate'

import { setSelectedHorseCompetetionPaperwork } from '../../store/horses/horseSlice'
import { useAppDispatch } from '../../store/hooks'

import FirebaseStorageService from '../../services/storageService'

import { getConvertedData } from '../../models/interface.helper'
import { IHorseCompetitorDocument } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../models/horse/horse.interface'

import useToasterHelper from '../../helpers/ToasterHelper'

import { IProfileReqField } from '../competitor/clinicAndOther/Tabs/components/ProfileFields'

import { MESSAGES_CONST } from '../../const/messages-const'

export type IOnRemoveImageButtonClickFn = (args: IOnRemoveImageButtonClickProps) => Promise<void>

type IOnRemoveImageButtonClickProps = {
  index: number
  type: string
  rowIndex?: number
}

interface HorsePaperworkProps {
  type: 'update-paperwork-modal' | 'horse-paperwork-tab'
  control: Control<IHorseCompetitorDocument>
  notEditable?: boolean
  watch: UseFormWatch<IHorseCompetitorDocument>
  onSubmit: (data: any, keepUnsaved: boolean) => Promise<void>
  setValue: UseFormSetValue<IHorseCompetitorDocument>
  selectedHorse: IHorseData | null
  imagesUrlsToDeleteFromStorage: any
  profileFiled?: IProfileReqField
}
export const HorsePaperwork: FC<HorsePaperworkProps> = ({
  type,
  control,
  notEditable,
  watch,
  onSubmit,
  setValue,
  selectedHorse,
  imagesUrlsToDeleteFromStorage,
  profileFiled,
}) => {
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const onRemoveImageButtonClick: IOnRemoveImageButtonClickFn = async ({
    index,
    type,
    rowIndex,
  }) => {
    let imageUrl: string | null = null
    let horsesPaperwork = { ...watch() }

    try {
      switch (type) {
        case 'horseMeasurementCardsImage':
          if (rowIndex) {
            imageUrl = watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`)[index]
            horsesPaperwork.horseMeasurementCards?.[rowIndex].horseMeasurementCardsImage?.splice?.(
              index,
              1
            )
            await FirebaseStorageService.deleteFile(imageUrl)
          }
          break

        case 'cogginsCertificate':
          imageUrl = watch(`cogginsCertificate`)?.[index]!
          horsesPaperwork.cogginsCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'healthCertificate':
          imageUrl = watch('healthCertificate')?.[index]!
          horsesPaperwork.healthCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'vaccinationCertificate':
          imageUrl = watch(`vaccinationCertificate`)?.[index]!
          horsesPaperwork.vaccinationCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'rhinopneumonitisCertificate':
          imageUrl = watch(`rhinopneumonitisCertificate`)?.[index]!
          horsesPaperwork.rhinopneumonitisCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'influenzaCertificate':
          imageUrl = watch(`influenzaCertificate`)?.[index]!
          horsesPaperwork.influenzaCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break
      }

      await onSubmit(horsesPaperwork, true)

      dispatch(
        setSelectedHorseCompetetionPaperwork({
          competitionPaperwork: getConvertedData(horsesPaperwork),
        })
      )
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  return (
    <>
      {(profileFiled?.value === 'horseMeasurementCard' || type === 'horse-paperwork-tab') && (
        <MeasurementCard
          type={type}
          notEditable={notEditable}
          onRemoveImageButtonClick={onRemoveImageButtonClick}
          selectedHorse={selectedHorse}
          control={control}
          watch={watch}
          imagesUrlsToDeleteFromStorage={imagesUrlsToDeleteFromStorage}
          onSubmit={onSubmit}
          setValue={setValue}
        />
      )}

      {(profileFiled?.value === 'horseCoggins' || type === 'horse-paperwork-tab') && (
        <>
          <hr className="w-full my-6" />
          <CertificateImages
            type={type}
            name="cogginsCertificate"
            label="Coggins"
            notEditable={notEditable}
            values={watch('cogginsCertificate')}
            onRemoveImageButtonClick={onRemoveImageButtonClick}
            selectedHorse={selectedHorse}
            onSubmit={onSubmit}
            setValue={setValue}
            watch={watch}
          />
          <CertificateDate
            name="cogginsInspectionDate"
            label="Coggins"
            control={control}
            notEditable={notEditable}
          />
        </>
      )}

      {(profileFiled?.value === 'horseHealthCertificate' || type === 'horse-paperwork-tab') && (
        <>
          <hr className="w-full my-6" />
          <CertificateImages
            type={type}
            name="healthCertificate"
            label="Health"
            notEditable={notEditable}
            values={watch('healthCertificate')}
            onRemoveImageButtonClick={onRemoveImageButtonClick}
            selectedHorse={selectedHorse}
            onSubmit={onSubmit}
            setValue={setValue}
            watch={watch}
          />
          <CertificateDate
            name="healthInspectionDate"
            label="Health"
            control={control}
            notEditable={notEditable}
          />
        </>
      )}

      {(profileFiled?.value === 'vaccinationCertificate' || type === 'horse-paperwork-tab') && (
        <>
          <hr className="w-full my-6" />
          <CertificateImages
            type={type}
            name="vaccinationCertificate"
            label="Vaccination"
            notEditable={notEditable}
            values={watch('vaccinationCertificate')}
            onRemoveImageButtonClick={onRemoveImageButtonClick}
            selectedHorse={selectedHorse}
            onSubmit={onSubmit}
            setValue={setValue}
            watch={watch}
          />
          <CertificateDate
            name="vaccinationInspectionDate"
            label="Vaccination"
            control={control}
            notEditable={notEditable}
          />
        </>
      )}

      {(profileFiled?.value === 'horseRhinopneumonitis' || type === 'horse-paperwork-tab') && (
        <>
          <hr className="w-full my-6" />
          <CertificateImages
            type={type}
            name="rhinopneumonitisCertificate"
            label="Rhinopneumonitis"
            notEditable={notEditable}
            values={watch('rhinopneumonitisCertificate')}
            onRemoveImageButtonClick={onRemoveImageButtonClick}
            selectedHorse={selectedHorse}
            onSubmit={onSubmit}
            setValue={setValue}
            watch={watch}
          />
          <CertificateDate
            name="rhinopneumonitisInspectionDate"
            label="Rhinopneumonitis"
            control={control}
            notEditable={notEditable}
          />
        </>
      )}

      {(profileFiled?.value === 'horseInfluenza' || type === 'horse-paperwork-tab') && (
        <>
          <hr className="w-full my-6" />
          <CertificateImages
            type={type}
            name="influenzaCertificate"
            label="Influenza"
            notEditable={notEditable}
            values={watch('influenzaCertificate')}
            onRemoveImageButtonClick={onRemoveImageButtonClick}
            selectedHorse={selectedHorse}
            onSubmit={onSubmit}
            setValue={setValue}
            watch={watch}
          />
          <CertificateDate
            name="influenzaInspectionDate"
            label="Influenza"
            control={control}
            notEditable={notEditable}
          />
        </>
      )}
      <hr className="w-full my-6" />
    </>
  )
}
