import React, { useEffect, useState } from 'react'

// Components
import ModalComponent from '../../../views/details/global/modalComponent'
import { ScheduleByDayItem } from '../../../common/ScheduleByDayItem/ScheduleByDayItem'

import { daysOfWeek, sortScheduling } from '../../../../../helpers/time'

import { TCustomSchedule } from '../../../../../models/event-scheduling/event-scheduling.interface'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

// Types
type IProps = {
  show: boolean
  dataToPassOn: { data: TCustomSchedule[] }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const EventSchedulingViewScheduleModal = ({ dataToPassOn, handleModal, show }: IProps) => {
  const [schedules, setSchedules] = useState<{ [key: string]: TCustomSchedule[] }>({})

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.VIEW_SCHEDULE_ORGANIZER)
  }

  useEffect(() => {
    if (dataToPassOn.data) {
      const sortedSchedules = sortScheduling(dataToPassOn.data)

      const schedules_ = sortedSchedules.reduce((acc: any, currentEvent) => {
        const date = `${daysOfWeek[new Date(currentEvent.startDate)?.getDay()]}_${new Date(currentEvent.startDate)?.getDate()}`
        if (date) acc[date] = [...(acc[date] ?? []), currentEvent]
        return acc
      }, {})

      setSchedules(schedules_)
    }
  }, [dataToPassOn.data])

  return (
    <ModalComponent
      title="Schedule"
      show={show}
      fullScreen={true}
      fullWidth={true}
      onHide={closeModal}
      headingClassName="text-[25px] !text-SeabiscuitMainThemeColor !font-bold"
      type={MODAL_CONSTS.VIEW_SCHEDULE_ORGANIZER}
    >
      {Object.keys(schedules)?.length > 0 ? (
        Object.keys(schedules).map((schedule, index) => (
          <ScheduleByDayItem schedules={schedules} schedule={schedule} key={index} />
        ))
      ) : (
        <div className="flex justify-center fs-5 text-SeabiscuitDark200ThemeColor">
          Please add required notes for each schedules...
        </div>
      )}
    </ModalComponent>
  )
}

export default EventSchedulingViewScheduleModal
