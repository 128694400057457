import React from 'react'
import { CONST } from '../../../../const/const'

type IProps = {
  eventTab: any
  setEventTab: any
  setSearchValue: Function
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ManageEventRevenueTabs = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  // Hooks and vars
  const { setEventTab, eventTab, setSearchValue } = props
  const eventFormIndex = (function () {
    return 0
  })()

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-8 text-center py-2.5 relative min-w-fit text-nr transition ease-in duration-200 focus:outline-none rounded-lg text-center`

    if (eventTab === value || sequence < event_index) {
      style = `${style} `

      if (eventTab === value) {
        return `${style} bg-SeabiscuitDullMainThemeColor text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitWhiteThemeColor`
  }

  return (
    <div className="flex items-center justify-between mb-4 w-full">
      <div className="ulfat remove-child-pl">
        <div className="Innrclass w-full">
          <div className="flex overflow-x-auto gap-x-2 tabScroll w-full">
            {/**************************************************************/}
            {/* ALL */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.REVENUE.TABS.ALL.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.REVENUE.TABS.ALL.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.REVENUE.TABS.ALL.VALUE,
                          CONST.UI.EVENTS.REVENUE.TABS.ALL.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.REVENUE.TABS.ALL.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  All
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* PAID */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.REVENUE.TABS.PAID.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.REVENUE.TABS.PAID.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.REVENUE.TABS.PAID.VALUE,
                          CONST.UI.EVENTS.REVENUE.TABS.PAID.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.REVENUE.TABS.PAID.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Paid
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* UNPAID */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.REVENUE.TABS.UNPAID.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.REVENUE.TABS.UNPAID.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.REVENUE.TABS.UNPAID.VALUE,
                          CONST.UI.EVENTS.REVENUE.TABS.UNPAID.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.REVENUE.TABS.UNPAID.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Unpaid
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* REFUNDED */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.REVENUE.TABS.REFUNDED.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.REVENUE.TABS.REFUNDED.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.REVENUE.TABS.REFUNDED.VALUE,
                          CONST.UI.EVENTS.REVENUE.TABS.REFUNDED.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.REVENUE.TABS.REFUNDED.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Refunded
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="search-div-wrapper relative rounded-lg w-72 border border-[#d3daee6a] mb-2">
        <div className="relative rounded-full">
          <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-SeabiscuitDark200ThemeColor"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>

          <input
            type="search"
            ref={ref}
            id="default-search"
            placeholder="Search customers..."
            onChange={(e) => setSearchValue(e.target.value)}
            className="block w-full p-2 pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
          />

          {/* <button type="submit"
                        className="text-white flex items-center justify-center absolute top-1.5 right-1 z-50 bottom-2 bg-SeabiscuitDullMainThemeColor hover:bg-SeabiscuitDullMainThemeColor font-medium rounded-lg text-sm px-2 py-1 mr-3"
                    >
                        <img alt="icons" className="w-5" src="assets/cp_icons/volume.svg" />
                    </button> */}
        </div>
      </div>
    </div>
  )
})

export default ManageEventRevenueTabs
