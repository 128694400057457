import React from 'react'

interface IProps {
  className?: string
}

export const IconBookmark: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.25 2.5C6.46403 2.5 5 3.96403 5 5.75V20.75C5.00004 20.8881 5.03818 21.0234 5.11022 21.1412C5.18225 21.2589 5.2854 21.3545 5.40828 21.4175C5.53117 21.4804 5.66903 21.5081 5.80669 21.4977C5.94435 21.4873 6.07646 21.4391 6.18848 21.3584L12 17.1738L17.8115 21.3584C17.9235 21.4391 18.0557 21.4873 18.1933 21.4977C18.331 21.5081 18.4688 21.4804 18.5917 21.4175C18.7146 21.3545 18.8177 21.2589 18.8898 21.1412C18.9618 21.0234 19 20.8881 19 20.75V5.75C19 3.96403 17.536 2.5 15.75 2.5H8.25ZM8.25 4H15.75C16.725 4 17.5 4.77497 17.5 5.75V19.2861L12.4385 15.6416C12.3108 15.5496 12.1574 15.5001 12 15.5001C11.8426 15.5001 11.6892 15.5496 11.5615 15.6416L6.5 19.2861V5.75C6.5 4.77497 7.27497 4 8.25 4Z" />
    </svg>
  )
}
