import React, { FC } from 'react'

import { Control, Controller } from 'react-hook-form'
import clsx from 'clsx'

import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'

import { getReactPickerDate } from '../../../models/interface.helper'
import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'

interface CertificateDateProps {
  name: keyof IHorseCompetitorDocument
  label: string
  control: Control<IHorseCompetitorDocument>
  notEditable?: boolean
}
export const CertificateDate: FC<CertificateDateProps> = ({
  name,
  label,
  control,
  notEditable,
}) => {
  return (
    <div className="flex flex-wrap items-center mt-2 w-full gap-4">
      <div className="flex items-center min-w-[150px] w-full sm:w-[18%] text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
        <img src="/assets/img/dark/Certificate.png" className="mr-4 w-6" alt="" />
        {label} inspection date
      </div>
      <div
        className={clsx(
          'wrapper flex flex-wrap rounded max-w-[300px] w-full sm:w-[78%]',
          !notEditable && 'border'
        )}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomDatePicker
              onChange={onChange}
              onBlur={onBlur}
              selected={getReactPickerDate(value)}
              peekNextMonth
              dropdownMode="select"
              wrapperClassName="w-auto"
              disabled={notEditable}
              showMonthDropdown
              popperPlacement="bottom"
              placeholderText={notEditable ? 'No date' : 'Enter date'}
              showYearDropdown
              className={clsx(
                'py-2.5 rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 !text-sm mr-2 w-60',
                notEditable
                  ? 'pl-0 !border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
                  : 'pl-4 !border-[#D3DAEE]'
              )}
            />
          )}
        />
      </div>
    </div>
  )
}
