import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import clsx from 'clsx'

// Component imports
import { ManageExhibitorProfile } from './ManageExhibitorProfile'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import WrapperContainer from '../../../../components/common/wrappers/WrapperContainer'

// Types
import { IEventInterface } from '../../../../models/events/event.interface'

// Custom Hooks
import useGetBasicEventDetails from '../../../../hooks/users/common/useGetBasicEventDetails'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'

import { selectEventLoading, selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'

// Constants
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

// Types

type Props = {
  handleClick?: any
  menuToggle?: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IUserParams = {
  userId: string
  eventId: string
  registrationId: string
}

const ManageRiderPage = (props: Props) => {
  // Hooks and vars
  const { getAllData } = useGetEventData()
  const { userId, eventId, registrationId } = useParams<IUserParams>()
  const { Event: event } = useAppSelector(selectedEvent)
  const eventsLoading = useAppSelector(selectEventLoading)
  const eventTitleNodeRef = useRef<HTMLSpanElement>(null)
  const eventInRedux = useAppSelector(selectedEvent).Event as IEventInterface

  const maxTagsToShow = 4
  const eventTags =
    event?.tags && event?.tags?.length > maxTagsToShow
      ? [...event?.tags]?.splice(0, 3)
      : event?.tags
  let elemWidth = `md:max-w-[calc(100vw-${
    (eventTitleNodeRef?.current?.clientWidth ?? 0) + 10 + 200
  }px)] max-w-[calc(100vw-40px)] md:max-w-[calc(100vw-120px)]`

  useGetBasicEventDetails(eventId)

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, [
        'v01_event_details',
        'v01_event_registered_users',
        'v01_spectator_tickets',
        'v01_events',
        'v01_event_ticketing',
        'v01_event_fees',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  // Returns header
  const getCustomHeader = (): ReactJSXElement => {
    if (eventsLoading) return <></>

    return (
      <div className="event_comp">
        {event ? (
          <div className="flex md:flex-nowrap flex-wrap items-center mb-4">
            <span
              ref={eventTitleNodeRef}
              className="mr-4 xl:text-[22px] 2xl:text-2xl min-w-fit pt-0 md:pt-2 lg:pt-2 xl:pt-1"
            >
              <h1
                title={event?.eventName}
                className="text-SeabiscuitDark200ThemeColor text-[22px] 2xl:text-[28px] font-bold ellipsis capitalize"
              >
                {event?.eventName}
              </h1>
            </span>

            {event?.category ? (
              <div className={clsx('overflow-x-auto flex items-start pt-1 pb-2', elemWidth)}>
                <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block mt-1 text-nr md:text-base">
                  {event?.category}
                </div>
                {eventTags && eventTags.length
                  ? eventTags.map((currentItem: string, index: number) => {
                      return (
                        <div
                          title={currentItem}
                          key={`${currentItem}${index}`}
                          onClick={() => {
                            if (
                              eventInRedux.category !==
                              COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.UNLICENSED
                            ) {
                              props.handleModal(true, MODAL_CONSTS.UPDATE_DISCIPLINE, {
                                eventInRedux,
                                eventId,
                              })
                            }
                          }}
                          className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis mt-1 min-w-fit md:min-w-[unset] text-nr md:text-base cursor-pointer"
                        >
                          {currentItem}
                        </div>
                      )
                    })
                  : null}
              </div>
            ) : null}

            {eventTags && event && eventTags?.length < (event?.tags?.length ?? 0) ? (
              <div className="hidden md:block h-fit text-[30px] leading-[1]">...</div>
            ) : null}
          </div>
        ) : (
          <ViewsLoader size="xl" color="red" />
        )}
      </div>
    )
  }

  return (
    <WrapperContainer
      noBg={true}
      removeShadow={true}
      customHeader={getCustomHeader()}
      removePadding={true}
    >
      <>
        {eventsLoading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <ViewsLoader
              className="flex items-center w-full justify-center h-screen"
              size="xl"
              color="red"
            />
          </div>
        ) : (
          <ManageExhibitorProfile
            registrationId={registrationId}
            eventId={eventId}
            handleModal={props.handleModal}
            userId={userId}
          />
        )}
      </>
    </WrapperContainer>
  )
}

export default ManageRiderPage
