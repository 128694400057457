import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRegistrationFeesInterface } from './registrationFees.interface'

// Constants
const REGISTRATION_FEES_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_FEES

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
export class RegistrationFeesModel extends ModelBaseModel<IRegistrationFeesInterface> {
  // Properties
  public v: IRegistrationFeesInterface['v']
  public id: IRegistrationFeesInterface['id']
  public userId: IRegistrationFeesInterface['userId']
  public eventId: IRegistrationFeesInterface['eventId']
  public eventName: IRegistrationFeesInterface['eventName']
  public userName: IRegistrationFeesInterface['userName']
  public refundId: IRegistrationFeesInterface['refundId']
  public refundDocId: IRegistrationFeesInterface['refundDocId']
  public feesTitle: IRegistrationFeesInterface['feesTitle']
  public feesPrice: IRegistrationFeesInterface['feesPrice']
  public feesItemId: IRegistrationFeesInterface['feesItemId']
  public isScratched: IRegistrationFeesInterface['isScratched']
  public recipientId: IRegistrationFeesInterface['recipientId']
  public refundStatus: IRegistrationFeesInterface['refundStatus']
  public paymentStatus: IRegistrationFeesInterface['paymentStatus']
  public isPaidByOwner: IRegistrationFeesInterface['isPaidByOwner']
  public amountRefunded: IRegistrationFeesInterface['amountRefunded']
  public amountScratched: IRegistrationFeesInterface['amountScratched']
  public feesItemCategory: IRegistrationFeesInterface['feesItemCategory']
  public invoiceId: IRegistrationFeesInterface['invoiceId']
  public invoiceUrl: IRegistrationFeesInterface['invoiceUrl']
  public refundAmountAdded: IRegistrationFeesInterface['refundAmountAdded']
  public scratchAmountAdded: IRegistrationFeesInterface['scratchAmountAdded']
  public registrationDocId: IRegistrationFeesInterface['registrationDocId']
  public recipientNameNGram: IRegistrationFeesInterface['recipientNameNGram']
  public selectedUnitsCount: IRegistrationFeesInterface['selectedUnitsCount']
  public recipientProfilePicture: IRegistrationFeesInterface['recipientProfilePicture']

  public created: IRegistrationFeesInterface['created']
  public modified: IRegistrationFeesInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRegistrationFeesInterface`
   */
  public constructor(obj?: IRegistrationFeesInterface) {
    super()
    this.v = obj?.v ?? REGISTRATION_FEES_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? REGISTRATION_FEES_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? REGISTRATION_FEES_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? REGISTRATION_FEES_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.refundId = obj?.refundId ?? REGISTRATION_FEES_COLLECTION.FIELDS.REFUND_ID.VALUES.DEFAULT
    this.userName = obj?.userName ?? REGISTRATION_FEES_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.feesPrice = obj?.feesPrice ?? REGISTRATION_FEES_COLLECTION.FIELDS.FEES_PRICE.VALUES.DEFAULT
    this.eventName = obj?.eventName ?? REGISTRATION_FEES_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.feesTitle = obj?.feesTitle ?? REGISTRATION_FEES_COLLECTION.FIELDS.FEES_TITLE.VALUES.DEFAULT
    this.refundDocId =
      obj?.refundDocId ?? REGISTRATION_FEES_COLLECTION.FIELDS.REFUND_DOC_ID.VALUES.DEFAULT
    this.feesItemId =
      obj?.feesItemId ?? REGISTRATION_FEES_COLLECTION.FIELDS.FEES_ITEM_ID.VALUES.DEFAULT
    this.isScratched =
      obj?.isScratched ?? REGISTRATION_FEES_COLLECTION.FIELDS.IS_SCRATCHED.VALUES.DEFAULT
    this.recipientId =
      obj?.recipientId ?? REGISTRATION_FEES_COLLECTION.FIELDS.RECIPIENT_ID.VALUES.DEFAULT
    this.refundStatus =
      obj?.refundStatus ?? REGISTRATION_FEES_COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? REGISTRATION_FEES_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? REGISTRATION_FEES_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.isPaidByOwner =
      obj?.isPaidByOwner ?? REGISTRATION_FEES_COLLECTION.FIELDS.IS_PAID_BY_OWNER.VALUES.DEFAULT
    this.amountRefunded =
      obj?.amountRefunded ?? REGISTRATION_FEES_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.amountScratched =
      obj?.amountScratched ?? REGISTRATION_FEES_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.feesItemCategory =
      obj?.feesItemCategory ?? REGISTRATION_FEES_COLLECTION.FIELDS.FEES_ITEM_CATEGORY.VALUES.DEFAULT
    this.invoiceId = obj?.invoiceId ?? REGISTRATION_FEES_COLLECTION.FIELDS.INVOICE_ID.VALUES.DEFAULT
    this.invoiceUrl =
      obj?.invoiceUrl ?? REGISTRATION_FEES_COLLECTION.FIELDS.INVOICE_URL.VALUES.DEFAULT
    this.refundAmountAdded =
      obj?.refundAmountAdded ??
      REGISTRATION_FEES_COLLECTION.FIELDS.REFUND_AMOUNT_ADDED.VALUES.DEFAULT
    this.scratchAmountAdded =
      obj?.scratchAmountAdded ??
      REGISTRATION_FEES_COLLECTION.FIELDS.SCRATCH_AMOUNT_ADDED.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      REGISTRATION_FEES_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.selectedUnitsCount =
      obj?.selectedUnitsCount ??
      REGISTRATION_FEES_COLLECTION.FIELDS.SELECTED_UNITS_COUNT.VALUES.DEFAULT
    this.recipientNameNGram =
      obj?.recipientNameNGram ??
      REGISTRATION_FEES_COLLECTION.FIELDS.RECIPIENT_NAME_N_GRAM.VALUES.DEFAULT
    this.recipientProfilePicture =
      obj?.recipientProfilePicture ??
      REGISTRATION_FEES_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RegistrationFeesModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
