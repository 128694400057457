import React, { CSSProperties, FC, useCallback } from 'react'
import { DndContext, type DragEndEvent, type UniqueIdentifier } from '@dnd-kit/core'
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table'
import { CSS } from '@dnd-kit/utilities'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import useToasterHelper from '../../../../../../../helpers/ToasterHelper'

import helpers from '../../../../../../../commonHelpers/helpers'
import { generateName } from '../../../../../../../helpers/helpers'

import { RegistrationByDayModel } from '../../../../../../../models/registrations-by-day/registrationByDay.model'

import FirestoreService from '../../../../../../../services/firestoreService'

import { CONST } from '../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import { MANAGE_OPERATIONS_CONSTS } from '../../../data/operations.data.const'
import { IMAGE_CONSTS } from '../../../../../../../const/image-const'
import { sortByOrder } from '../../ManageClinicNOtherOperationsTab'
import { convertTime } from '../../../../../../../helpers/time'
import { IconPlusCircle } from '../../../../../../../components/icons/IconPlusCircle'
import { IconClock } from '../../../../../../../components/icons/IconClock'
import { TimeSelector } from './TimeSelector'
import { RegistrationFeesType } from '../../../../../../../models/event-fees/event-fees.interface'
import { useEventTrainers } from '../../../../../../../hooks/useEventTrainers'

type OrderTypes = {
  riderName: string
  horseName: string
  loading?: boolean
  isBreakDrag?: boolean
  order?: string
  id: string
  time?: string
  riderProfilePicture: string
  horseProfilePicture: string
  registrationDocId: string
  orderOfGoScratched: string
}

interface OrderOfGoTableProps {
  increment: string
  // classStart: string
  selectedClass: string
  data: any[]
  setData: (value: any[]) => void
  fee: RegistrationFeesType | null
  eventId: string
}

const BREAK = 'isBreakDrag'

export const OrderOfGoTable: FC<OrderOfGoTableProps> = ({
  data,
  setData,
  increment,
  fee,
  eventId,
  selectedClass,
}) => {
  const { eventTrainers } = useEventTrainers(eventId)
  const toastFunctions = useToasterHelper()
  const onScratch = useCallback(
    async (row: any, state: boolean) => {
      if (row.isBreakDrag) {
        const newData = data.filter((itm) => itm.id !== row.id)
        const breaks: any = []

        newData.forEach((itm, index) => {
          newData[index] = {
            ...itm,
            order: index + 1,
          }

          if (itm.isBreakDrag) {
            breaks.push(newData[index])
          } else {
            const newItem = new RegistrationByDayModel(newData[index])

            FirestoreService.updateItem(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
              newItem.id,
              newItem.toFirestore()
            )
          }
        })

        await updateBreaks(breaks)
        setData(newData)

        toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCRATCHED_UPDATED })

        return null
      }
      try {
        let newData = data.map((registration) => {
          if (registration.id === row.id) {
            return { ...registration, orderOfGoScratched: state }
          } else {
            return registration
          }
        })

        let count = 0
        newData = newData.map((registration) => {
          if (registration.orderOfGoScratched) {
            return { ...registration, order: 0 }
          } else {
            count++
            return { ...registration, order: count }
          }
        })

        newData = sortByOrder(newData)

        setData([...newData])

        newData.map(async (item) => {
          const newItem = new RegistrationByDayModel(item)

          await FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            newItem.id,
            newItem.toFirestore()
          )
        })

        toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCRATCHED_UPDATED })
      } catch (error) {
        toastFunctions.error({ message: MESSAGES_CONST.EVENT_SCRATCHED_NOT_UPDATED })
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    },
    [data, setData]
  )

  const addBreak = async () => {
    try {
      const breaks = data.filter((itm) => itm.isBreakDrag)

      const dataToCreate = {
        id: `${new Date().getTime()}`,
        order: data.length + 1,
        class: selectedClass,
        time: '00:00:00',
        isBreakDrag: true,
      }

      await updateBreaks([...breaks, dataToCreate])

      const newData = [...data, dataToCreate]

      setData(newData)
    } catch (e) {
      console.log('=>(OrderOfGoTable.tsx:131) e', e)
    } finally {
    }
  }

  const updateBreaks = (breaks: any[]) => {
    return FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
      eventId,
      {
        breaks,
      }
    )
  }

  const DraggableRow = ({
    row,
    isLastChild,
    rowIndex,
  }: {
    row: Row<OrderTypes>
    isLastChild: boolean
    rowIndex: number
  }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: row.id,
    })

    const style: CSSProperties = {
      transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
      transition: transition,
      opacity: isDragging ? 0.8 : 1,
      zIndex: isDragging ? data.length : data.length - rowIndex,
      position: 'relative',
      borderBottom: !isLastChild ? '1px solid rgb(211, 218, 238)' : 'none',
      minHeight: '72px',
      height: '70px',
    }
    return (
      // connect row ref to dnd-kit, apply important styles
      <tr ref={setNodeRef} style={style}>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} style={{ width: cell.column.getSize() }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    )
  }

  const RowDragHandleCell = ({ row }: { row: any }) => {
    const { attributes, listeners } = useSortable({
      id: row.id,
    })
    return (
      // Alternatively, you could set these attributes on the rows themselves
      <div className="justify-center flex" {...attributes} {...listeners}>
        <button className="border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg gap-2 p-2">
          <img src="/assets/cp_icons/Move.svg" alt="moveIcon" className="w-5" />
        </button>
      </div>
    )
  }

  const columns = React.useMemo<ColumnDef<OrderTypes>[]>(
    () => [
      // Create a dedicated drag handle column. Alternatively, you could just set up dnd events on the rows themselves.
      {
        accessorFn: (row) => {
          if (row.isBreakDrag) return BREAK
          return `${row.riderName != null ? row.riderName : ''}+${row?.riderProfilePicture != null ? row?.riderProfilePicture : ''}`
        },
        cell: (info: any) => {
          if (info.getValue() === BREAK) {
            return (
              <div className={'flex items-center gap-6'}>
                <span
                  className={
                    'w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#F6F7FB]'
                  }
                >
                  <IconClock />
                </span>

                <span className={'text-SeabiscuitDark200ThemeColor font-bold text-base'}>
                  Break / Drag
                </span>
              </div>
            )
          }
          return (
            <div className="flex items-center gap-4">
              <span className="w-14 h-14 p-1 rounded-full block shrink-0 my-2">
                <img
                  src={
                    info.getValue().split('+')[1].toLowerCase() !== ''
                      ? info.getValue().split('+')[1]
                      : IMAGE_CONSTS.PLACEHOLDERS.USER
                  }
                  alt="icons"
                  className="object-cover w-[48px] h-[48px] rounded-full"
                  onError={(e) =>
                    ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                      info.getValue() ?? ''
                    )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                />
              </span>
              <div className="flex flex-col">
                <span className="text-SeabiscuitDark200ThemeColor font-bold text-base">
                  {info.getValue().split('+')[0]}
                </span>
                <span className="text-SeabiscuitDark200ThemeColor/50 text-base">
                  Back number •{' '}
                  {Number(info.row.original.backNumber) > 0 ? info.row.original.backNumber : 'N/A'}
                </span>
              </div>
            </div>
          )
        },
        id: 'rider',
        header: () => (
          <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
            <span className="whitespace-nowrap">Rider</span>
          </span>
        ),
      },
      {
        accessorFn: (row) => {
          if (row.isBreakDrag) return BREAK
          return `${row.horseName != null ? row.horseName : ''}+${row?.horseProfilePicture != null ? row?.horseProfilePicture : ''}`
        },
        cell: (info: any) => {
          if (info.getValue() === BREAK) {
            return <></>
          }

          const trainer = eventTrainers?.find(
            (eventTrainer) => eventTrainer.registrationByDayUniqId === info.row.original.uniqId
          )

          return (
            <div className="flex items-center gap-4">
              <span className="w-14 h-14 p-1 rounded-full block shrink-0 my-2">
                <img
                  src={
                    info.getValue().split('+')[1].toLowerCase() !== ''
                      ? info.getValue().split('+')[1]
                      : IMAGE_CONSTS.PLACEHOLDERS.USER
                  }
                  alt="icons"
                  className="object-cover w-[48px] h-[48px] rounded-full"
                  onError={(e) =>
                    ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                      info.getValue().split('+')[0] ?? ''
                    )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                />
              </span>
              <div className="flex flex-col">
                <span className="text-SeabiscuitDark200ThemeColor font-bold text-base">
                  {info.getValue().split('+')[0] === ''
                    ? 'No Horse'
                    : info.getValue().split('+')[0]}
                </span>
                <span className="text-SeabiscuitDark200ThemeColor/50 text-base">
                  Trainer •{' '}
                  {trainer ? <span className="underline">{trainer.teamMemberName}</span> : 'N/A'}
                </span>
              </div>
            </div>
          )
        },
        id: 'horse',
        header: () => (
          <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
            <span className="whitespace-nowrap">Horse</span>
          </span>
        ),
      },
      {
        id: 'order',
        accessorFn: (row) => {
          return row.isBreakDrag ? `${row.order}-${BREAK}` : row.order || ''
        },
        header: () => (
          <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
            <span className="whitespace-nowrap">Order of Go</span>
          </span>
        ),
        cell: (info: any) => {
          const arr = `${info.getValue()}`.split('-')
          const num = arr[0]
          const isBreakDrag = arr[1]

          return (
            <div>
              {!info.row.original.orderOfGoScratched && (
                <span
                  className={`rounded-full ${isBreakDrag ? 'bg-[#122B460D] text-[#122B46]' : 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'} text-base flex items-center justify-center w-10 h-10`}
                >
                  {num}
                </span>
              )}
            </div>
          )
        },
      },
      {
        accessorFn: (row, index) => {
          if (row.isBreakDrag) return `${BREAK}-${row.time}-${row.id}`
          return index
        },
        header: () => (
          <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
            <span className="whitespace-nowrap">Ride Time</span>
          </span>
        ),
        id: 'rideTime',
        cell: (info: any) => {
          const arr = `${info.getValue()}`.split('-')
          const id = arr[2]
          const isBreak = arr[0]
          if (isBreak === BREAK && arr[1] && id) {
            const timeArr = `${arr[1]}`.split(':')
            const h = timeArr[0]
            const m = timeArr[1]
            const s = timeArr[2]
            return (
              <div
                className={
                  'text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex cursor-pointer'
                }
              >
                <TimeSelector
                  h={h}
                  m={m}
                  s={s}
                  saveHandler={async (h, m, a) => {
                    const newData = [...data]
                    const currentItmIndex = newData.findIndex((e) => e.id === id)

                    if (currentItmIndex < 0) {
                      console.log('=>(OrderOfGoTable.tsx:381) currentItmIndex', currentItmIndex)
                      return null
                    }

                    newData[currentItmIndex] = {
                      ...newData[currentItmIndex],
                      time: `${h}:${m}:${a}`,
                    }
                    const breaks = newData.filter((itm) => itm.isBreakDrag)

                    try {
                      await updateBreaks(breaks)

                      setData(newData)

                      toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
                    } catch (e) {
                      console.log('=>(OrderOfGoTable.tsx:391) e', e)
                    }
                  }}
                />
              </div>
            )
          }
          return (
            <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex">
              {info.row.original.orderOfGoScratched ? (
                <span className="text-SeabiscuitDark200ThemeColor/50">Scratched</span>
              ) : (
                convertTime(
                  `${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`,
                  increment,
                  info.getValue()
                )
              )}
            </div>
          )
        },
      },
      {
        accessorKey: 'drag',
        header: () => (
          <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
            <span className="whitespace-nowrap">Drag & Drop</span>
          </span>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <div className="flex justify-between gap-2.5 max-w-[86px]">
                {row.original.orderOfGoScratched ? (
                  <button
                    className="ml-auto flex border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg p-2"
                    onClick={async () => {
                      await onScratch(row.original, false)
                    }}
                  >
                    <img src="/assets/cp_icons/undo.svg" alt="moveIcon" className="w-5" />
                  </button>
                ) : (
                  <>
                    <button
                      className="flex border border-solid border-SeabiscuitGray500ThemeColor hover:bg-SeabiscuitGrayThemeColor transition-all rounded-lg p-2"
                      onClick={async () => {
                        await onScratch(row.original, true)
                      }}
                    >
                      <img src="/assets/cp_icons/delete.svg" alt="moveIcon" className="w-5" />
                    </button>
                    <RowDragHandleCell row={row} />
                  </>
                )}
              </div>
            </div>
          )
        },
        id: 'drag-handle',
      },
    ],
    [increment, onScratch, eventTrainers]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id, // required because row indexes will change
  })

  const dataIds = React.useMemo<UniqueIdentifier[]>(() => data?.map(({ id }) => id), [data])

  // reorder rows after drag & drop
  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id)
      const newIndex = dataIds.indexOf(over.id)
      let newArray = arrayMove(data, oldIndex, newIndex)

      let count = 0

      newArray = newArray.map((item) => {
        if (item.orderOfGoScratched) {
          return { ...item, order: 0 }
        } else {
          count++
          return { ...item, order: count }
        }
      })

      setData([...newArray])
      try {
        if (newArray.length > 0) {
          count = 0

          const breaks: any[] = []
          const reiders: any[] = []

          newArray.forEach((itm) => {
            if (itm.isBreakDrag) {
              breaks.push(itm)
            } else {
              reiders.push(itm)
            }
          })
          await updateBreaks(breaks)

          reiders.map(async (item) => {
            const newData = new RegistrationByDayModel(item)
            await FirestoreService.updateItem(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
              newData.id,
              newData.toFirestore()
            )
          })
          toastFunctions.success({ message: MESSAGES_CONST.EVENT_ORDER_UPDATED })
        }
      } catch (error) {
        toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <table className={'w-full'}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} style={{ borderBottom: '1px solid rgb(211, 218, 238)' }}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{ width: MANAGE_OPERATIONS_CONSTS.COLUMNS_WIDTH[header.id] }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <DraggableRow
                key={row.id}
                row={row}
                rowIndex={rowIndex}
                isLastChild={rowIndex === table.getRowModel().rows.length - 1}
              />
            ))}
          </SortableContext>
          <tr className={'border-t border-solid'}>
            <td className={'py-4'}>
              <button
                onClick={addBreak}
                className={'flex group gap-2 items-center text-[#122B4680]'}
              >
                <IconPlusCircle />{' '}
                <span
                  className={'underline hover:no-underline hover:text-SeabiscuitMainThemeColor'}
                >
                  Add break
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </DndContext>
  )
}
