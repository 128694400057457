import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'

const initialState: RiderTeamMemberModel[] = []

const eventMembersSlice = createSlice({
  name: 'eventMembers',
  initialState: initialState,
  reducers: {
    setEventMembers: (_state, { payload }: { payload: RiderTeamMemberModel[] }) => payload,
  },
})

export const { setEventMembers } = eventMembersSlice.actions

export const selectEventMembers = (state: RootState) => state.eventMembers

export default eventMembersSlice.reducer
