const MODAL_CONSTS = {
  AUTO_ASSIGN_MODAL: 'autoAssignModal',
  CARD: 'card',
  TEAM: 'team',
  HORSE: 'horse',
  FILTERS: 'filters',
  SUCCESS: 'success',
  ZONE_MODAL: 'zone',
  PARTNER: 'partner',
  SEE_MORE: 'seeMore',
  POLICIES: 'policies',
  LOCATION: 'location',
  BILL_BRIEFE: 'bills',
  GOTODASH: 'gotodash',
  VIDEO_PLAYER: 'videoPlayer',
  SWITCH_ACCOUNT: 'switchAccounts',
  SPECTATOR_TICKET_RECEIPT: 'spectatorTicketReceipt',
  LIST_REMOVE_CONFIRMATIONS: 'listRemoveConf',
  TICKET_BUYER_DETAILS: 'ticketBuyerDetails',
  TICKET_BUYER_DETAILS_COMPSIDE: 'TicketBuyerDetailsCompSide',
  SEND_MESSAGE: 'sendMessage',
  SEND_MESSAGE_ENTRIES: 'sendMessageEntries',
  SCRATCH_ENTRY: 'scratchEntry',
  HELP_VIDEO: 'helpVideo',
  EVENTS_FEES: 'eventsFees',
  REVIEW_ANNEX: 'ReviewAnnex',
  NEW_TRANSFER: 'newTransfer',
  SCORING: 'scoring',
  RESULTS_LOCKED: 'resultsLocked',
  ABOUT_EVENTS: 'aboutEvents',
  MESSAGE_USER: 'messageUser',
  SIGN_DOCUMENT: 'signDocument',
  MEMBERSHIP_ADD: 'membershipAdd',
  ADD_NOTE: 'vendorTabNoteModel',
  SHARE_DOCUMENT: 'shareDocument',
  VIEW_BUTTON_EVENT: 'viewButton',
  MANAGE_STRIPE_CONNECT_ACCOUNT: 'manageStripeConnectAccount',
  JUDGING_LICENCE: 'judgingLicence',
  AUTHENTICATION: 'authentication',
  ACCOUNT_PRIVACY: 'accountPrivacy',
  REGISTRATION_PEOPLE: 'registrationPeople',
  REGISTRATION_HORSES: 'registrationHorses',
  NEWS: 'news',
  DEAL: 'deal',
  PRODUCT: 'product',
  CREATE_AND_UPDATE_NEWS: 'createAndUpdateNews',
  CREATE_AND_UPDATE_DEAL: 'createAndUpdateDeal',
  CREATE_AND_UPDATE_PRODUCT: 'createAndUpdateProduct',
  EVENT_ZONE_SPECS: 'eventZoneSpecs',
  FORGOT_PASSWORD: 'forgotPassword',
  FILTER_BUTTON_EVENT: 'filterButton',
  REGISTER_FOR_PAPERWORK: 'paperwork',
  ADD_NEARBY_NOTE: 'NearbyTabNoteModel',
  SPECTATOR_TICKETS: 'spectatorTickets',
  MEMBERSHIP_MANAGE: 'membershipManage',
  UPDATE_DISCIPLINE: 'updateDiscipline',
  REMOVE_TEAM_MEMBER: 'removeteammember',
  ADD_POLICIES_MODAL: 'addPoliciesModal',
  MANAGE_EVENT_MODAL: 'manageEventModal',
  CANCEL_EVENT_MODAL: 'cancelEventModal',
  NEARBY_BUSSINESSES: 'nearbyBussinesses',
  SPLIT_PAYMENT_MODAL: 'splitPaymentModal',
  HANDLE_LEGAL_CLAUSE: 'handleLegalClause',
  REGISTER_EXHIBITORS: 'registerExhibitors',
  UPLOAD_EVENT_IMAGES: 'uploadEventsImages',
  REMOVE_CONFIRMATION: 'removeConfirmation',
  LINEAGE_ADDING_HORSES: 'lineageAddingHorse',
  RESULT_VIEW_BUTTON_EVENT: 'resultViewButton',
  REGISTER_FOR_COMPETITION_PAY: 'competitonPay',
  TOTAL_REGISTERS_EVENT: 'totalRegistersEvents',
  REGISTER_FOR_PAPERWORK_MEMBER: 'paperworkmember',
  VIEW_SCHEDULE_ORGANIZER: 'viewScheduleOrganizer',
  ADD_COGGINS_CERTIFICATE_IMAGE: 'addCogginsImage',
  EVENT_PUBLISH_DONE_MODAL: 'eventPublishDoneModal',
  REGISTER_FOR_COMPETETION: 'registerForCompetetion',
  CLOSE_EVENT_CONFIRMATION: 'closeEventConfirmation',
  ORGNAIZER_EVENT_STAFF_ADD: 'organizerEventStaffAdd',
  TICKETS_DISTRIBUTER_MODAL: 'ticketsDistributeModal',
  VENDOR_SPONSOR_INVITATIONS: 'vendorSponsorInvitations',
  MESSAGES_VIA_MAIL: 'messageviaMail',
  REGISTER_FOR_COMPETITION_PAY_BY_CARD: 'competitonPayByCard',
  ORGANIZER_MARK_PAID: 'organizerMarkPaid',
  TEAM_MEMBER_ROLE_CHANGE_WARNING: 'teamMemberRoleChangeWarning',
  EXHIBITOR_SCRATCH_CONFIRM_MODAL: 'exhibitiorScratchConfirmModal',
  VENDOR_SPONSOR_INVITATIONS_VIA_MAIL: 'vendorSponsorInvitationViaMail',
  REMOVE_HORSE: 'RemoveHorse',
  SEARCH_HORSE_BY_NAME: 'SearchHorseByName',
  SCRATCH_STAFF_MODAL: 'scratchStaffModal',
  UPDATE_PROFILE_EMAIL: 'updateProfileEmail',
  OPEN_ORGANIZER_MAIL_INFO: 'openOrganizerMail',
  REMOVE_USER_CARD_ACCOUNT_MODAL: 'removeUserCardModal',
  REGISTER_TEAM: 'RegisterTeam',
  ADD_TEAM_MEMBER_TO_PAPERWORK: 'AddTeamMemberToPaperwork',
  ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2: 'AddTeamMemberToPaperworkStep2',
  ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_3: 'AddTeamMemberToPaperworkStep3',
  ADD_NEW_USER: 'AddNewUser',
  GENERATE_TOKEN: 'generateToken',
  PUBLISH_OPERATIONS_MODAL: 'publishOperationsModal',
  CONFIRM_REMOVE_DRAFT_EVENT: 'confirmRemoveDraftEvent',
  CONFIRM_REMOVE_IMAGE: 'confirmRemoveImage',
  ADD_NEW_TEAM_MEMBER: 'AddNewTeamMember',
  CREATE_NEW_TEAM_MEMBER_PAPERWORK: 'CreateNewTeamMemberPaperwork',
  ADD_NEW_HORSE_MEMBER: 'AddNewHorseMember',
  AI_RULE_BOOK: 'AiRuleBook',
  SWITCH_HORSE: 'SwitchHorse',
  UPDATE_USER_NUMBER: 'UpdateUserNumber',
  UPDATE_TEAM_DETAILS: 'updateTeamDetails',
  BULK_ADD: 'BulkAdd',
  CLASS_MORE: 'ClassMore',
  ANSWERS: 'Answers',
  ADD_MEMBERSHIP: 'addMembership',
  JOIN: 'join',
  PURCHASE_MEMBERSHIP: 'purchaseMembership',
  CANCEL_MEMBERSHIP: 'cancelMembership',
  PAUSE_RENEW_MEMBERSHIP: 'pauseRenewMembership',
  HORSE_ADD_CERTIFICATE: 'horseAddCertificate',
}

export { MODAL_CONSTS }
