import React from 'react'
import Select from 'react-select'

import CustomDatePicker from '../common/inputs/CustomDatePicker'

import { customStyles } from '../customStyles/ReactSelectCustomStyle'

import 'react-datepicker/dist/react-datepicker.css'

export interface IOption {
  label: JSX.Element | string
  value: string
}

interface IProps {
  name: string
  isRequired?: boolean
  icon: JSX.Element
  isError?: boolean
  inputName: string
  placeholder: string
  type?: React.HTMLInputTypeAttribute | 'select'
  value?: string | Date | IOption
  selectData?: IOption[]
  onChangeHandler: (key: string, value: string | Date | IOption) => void
}

export const InputNewMemberRow: React.FC<IProps> = ({
  name,
  icon,
  inputName,
  isRequired,
  placeholder,
  type = 'text',
  selectData = [],
  value = '',
  onChangeHandler,
  isError,
}) => {
  const renderInput = () => {
    switch (type) {
      case 'date':
        return (
          <CustomDatePicker
            popperClassName="datepicker-popper-member"
            dropdownMode="select"
            targetId="fieldContainer"
            maxDate={new Date()}
            onChange={(date) => onChangeHandler(inputName, date || new Date())}
            selected={value as Date}
            className="w-full text-[#122B46] border rounded-xl placeholder:text-SeabiscuitDark200ThemeColor p-3 h-[51px] !ring-0 border-[#D3DAEE]"
          />
        )
      case 'select':
        return (
          <Select
            isMulti={false}
            onChange={(item) => onChangeHandler(inputName, item as IOption)}
            isDisabled={false}
            onBlur={() => null}
            placeholder={placeholder}
            options={selectData}
            className={`border-[#D3DAEE] border py-[1px] !text-sm w-full rounded-xl text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor`}
            isClearable={false}
            styles={customStyles}
            value={value as IOption}
            isSearchable={false}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
        )
      default:
        return (
          <input
            name={inputName}
            type={type}
            placeholder={placeholder}
            style={{ colorScheme: 'light' }}
            onChange={(e) => onChangeHandler(inputName, e.target.value)}
            className={`w-[100%] text-[#122B46] border rounded-xl placeholder:text-SeabiscuitDark200ThemeColor p-3 h-[51px] !ring-0 ${isError ? 'border-SeabiscuitMainThemeColor' : 'border-[#D3DAEE]'} focus:border-[#626c89]`}
          />
        )
    }
  }

  return (
    <div className={'flex items-center'}>
      <div className={'flex items-center gap-[16px] min-w-[180px]'}>
        {icon}
        <span className={'text-[#122B46] text-[14px]'}>
          {name}
          {isRequired && <span className={'text-[#122B4680] text-[10px] block'}>Required</span>}
        </span>
      </div>

      <div className="flex-1">{renderInput()}</div>
    </div>
  )
}
