import React, { useEffect, useRef, useState } from 'react'
import { ITeamMember } from '../../models/users/user.interface'
import { useAppSelector } from '../../store/hooks'
import { selectTeamTabData } from '../../store/registration/registrationSlice'
import { UserModel } from '../../models/users/user.model'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'
import customImageComponent from '../common/CustomImageComponent'
import ViewsLoader from '../loader/ViewsLoader'
import { CONST } from '../../const/const'
import { collection, query } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import FirebaseApp from '../../services/firebaseApp'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IPaperworkTeam } from '../../store/paperworks/paperworkSlice'
import { AddTeamMemberToPaperworkHeader } from './components/AddTeamMemberToPaperworkHeader'
import Select from 'react-select'
import { customStyles } from '../customStyles/ReactSelectCustomStyle'
import { IconPlus } from '../icons/IconPlus'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

interface IUserExtendedWithSelected extends ITeamMember {
  selected?: boolean
  status?: string
}

type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    teamMembers: IPaperworkTeam[]
    registrationByDay: IRegistrationByDayInterface
  }
}

const USER_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS

interface IUserExtendedWithStatus extends ITeamMember {
  status?: string
}
export const AddTeamMemberToPaperwork: React.FC<IProps> = (props) => {
  const toastFunction = useToasterHelper()

  const exibitorR = useAppSelector((store) => store.exhibitor.registration)
  const teamMembers = useAppSelector(selectTeamTabData)

  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<IUserExtendedWithSelected[]>()
  const [authorizedMeUsers, setAuthorizedMeUsers] = useState<IUserExtendedWithStatus[]>([])

  const [selectedRider, setSelectedRider] = useState<IPaperworkTeam | null>(null)
  const [searchValue, setSearchValue] = useState('')

  const [value] = useCollection(query(collection(FirebaseApp.firestore, USER_TABLE.NAME)), {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  useEffect(() => {
    setListToShow(authorizedMeUsers)
  }, [authorizedMeUsers])

  useEffect(() => {
    const getAllCompetitors = async () => {
      try {
        if (!exibitorR) return null
        const fetched_users: IUserExtendedWithStatus[] = []
        if (value?.size) {
          value?.docs.map((doc: any) => {
            let user = UserModel.fromFirestoreDoc(doc).toObject()
            if (user.userTeamMembers && user.userTeamMembers.length) {
              user.userTeamMembers.map((currentMember: ITeamMember) => {
                fetched_users.push({
                  ...currentMember,
                  status: currentMember.memberAuthorized === '1' ? 'authorized' : 'unauthorized',
                })
                return true
              })
            }
            return true
          })
        }

        const uniqueMembers = fetched_users.filter(
          (() => {
            const uniqueEmails = new Set()
            return (member) => {
              if (uniqueEmails.has(member.memberEmail)) {
                return false
              } else {
                uniqueEmails.add(member.memberEmail)
                return true
              }
            }
          })()
        )

        setAuthorizedMeUsers(uniqueMembers)
      } catch (err) {
        console.log('err', err)
      }
    }
    getAllCompetitors().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') {
      setListToShow(authorizedMeUsers)
    }
    const matched = authorizedMeUsers.filter((user: IUserExtendedWithSelected) => {
      return (
        user.memberName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        user.memberUserName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        user.memberEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
      )
    })

    setListToShow(matched)
  }

  useEffect(() => {
    const riders = props.dataToPassOn.teamMembers.filter(
      (member) => member.teamMemberRole === TEAM_MEMBER_ROLES.RIDER
    )

    const hasOneRider = riders.length === 1
    if (hasOneRider) {
      setSelectedRider(riders[0])
    }
  }, [])

  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value
    setSearchValue(strToSearch)
    handleUsers(strToSearch)
  }

  const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    team_member: IUserExtendedWithSelected
  ) => {
    const select = e.target.checked

    if (listToShow) {
      const sameTypeUsersArrCopy = listToShow.map((currUser) => {
        if (currUser.memberId === team_member?.memberId) {
          currUser.selected = select
          return currUser
        } else {
          return currUser
        }
      })
      setListToShow(sameTypeUsersArrCopy)
    }
  }

  const renderModalBody = () => {
    if (!selectedRider) {
      const OPTIONS = props.dataToPassOn.teamMembers
        .filter((member) => member.teamMemberRole === TEAM_MEMBER_ROLES.RIDER)
        .map((member) => ({
          value: member.id!,
          label: member.riderName || member.teamMemberName!,
        }))
      return (
        <>
          <h2 className={'text-[#122B46] mt-4'}>Select a rider</h2>
          <Select
            placeholder="Select a rider"
            onChange={(option) => {
              if (!option) return null
              if (!props.dataToPassOn.teamMembers) return null
              const rider = props.dataToPassOn.teamMembers.find(
                (r) => r.id === (option as any).value
              )

              setSelectedRider(rider || null)
            }}
            options={OPTIONS}
            className={`border-[#D3DAEE] mt-2 border rounded-md w-full`}
            styles={customStyles}
          />
        </>
      )
    }
    return (
      <>
        <AddTeamMemberToPaperworkHeader
          rider={selectedRider}
          horseName={props.dataToPassOn.registrationByDay?.horseName || ''}
          horseProfilePicture={props.dataToPassOn.registrationByDay?.horseProfilePicture || ''}
        />
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={filterUsers}
            placeholder="Search..."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />

          <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
            <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
            <button
              onClick={() => {
                props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK)
                props.handleModal(true, MODAL_CONSTS.CREATE_NEW_TEAM_MEMBER_PAPERWORK, {
                  rider: selectedRider,
                })
              }}
              className={
                'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-SeabiscuitMainThemeColor'
              }
            >
              <IconPlus /> New Person
            </button>
          </div>
          {/*<Search className="absolute text-SeabiscuitMainThemeColor right-4" />*/}
        </div>
        {searchValue && listToShow?.length ? (
          <>
            <div className="uselListWarp mt-4 flex flex-col">
              {listToShow?.length ? (
                listToShow?.map((item, index) => {
                  let condition = teamMembers.find((data) => data.memberId === item.memberId)
                  return (
                    <label
                      key={index}
                      className="form-check mb-3 cursor-pointer"
                      htmlFor={'teammember' + index}
                    >
                      <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                        <div className="min-w-fit flex items-center">
                          {customImageComponent(
                            item.memberProfilePicture,
                            item.memberName,
                            'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                          )}
                          <div className="hourseDetails w-10/12">
                            <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                              <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                                {item.memberName}
                              </p>
                              <p className="text-xs">
                                {item?.defaultRole}, {item?.memberEmail}
                              </p>
                            </div>
                          </div>
                        </div>

                        {item.selected || condition ? (
                          <img
                            src={CHECKED_ICON}
                            className="relative right-[0px] top-[0px]"
                            width={20}
                            height={20}
                            alt="checked"
                          />
                        ) : null}

                        <input
                          onChange={(e) => {
                            handleChange(e, item)
                          }}
                          // checked={!!condition}
                          disabled={!!condition}
                          id={'teammember' + index}
                          className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}
                          type="checkbox"
                        />
                      </div>
                    </label>
                  )
                })
              ) : (
                <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor"></div>
              )}
            </div>
          </>
        ) : (
          <>
            {!!searchBoxRef?.current?.value?.length && !listToShow ? (
              <>No data found</>
            ) : (
              <>
                {!!searchBoxRef?.current?.value?.length && !listToShow ? (
                  <ViewsLoader size="md" color="red" className="mt-1 pt-1" />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <MainModal
        title="Add team member to entry"
        show={true}
        type="ADD_TEAM_MEMBER_TO_PAPERWORK"
        size="xl"
        onTransitionEnd={focusInput}
        titleClassName="!font-normal"
        buttons={[
          {
            label: 'Next >',
            bgClass: 'bg-SeabiscuitMainThemeColor !w-full',

            onClick: () => {
              if (!selectedRider) return toastFunction.info({ message: 'Please select a rider' })
              if (!Array.isArray(listToShow)) return null
              const selectedUsers = listToShow.filter((itm) => itm.selected)

              if (!selectedUsers.length)
                return toastFunction.info({ message: 'At least one user should be selected' })

              props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK)
              props.handleModal(true, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2, {
                users: selectedUsers,
                teamMembers: props.dataToPassOn.teamMembers,
                registrationByDay: props.dataToPassOn.registrationByDay,
                rider: selectedRider,
              })
            },
            className: 'outline-none w-full',
            textClass: 'text-white',
            disabled: !listToShow?.length,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor !w-full',
            onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK),
          },
        ]}
      >
        {renderModalBody()}
      </MainModal>
    </>
  )
}
