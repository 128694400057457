import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import MainModal from '../../modals/common/MainModal'

import { IconUser } from '../../icons/IconUser'
import { IconEmailImg } from '../../icons/IconEmailImg'
import { IconDate } from '../../icons/IconDate'
import { NationalityIconImg } from '../../icons/NationalityIconImg'
import { IconHorseImg } from '../../icons/IconHorseImg'
import { IconError } from '../../icons/IconError'

import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'

import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'

import { isValidEmail } from '../../../helpers/isValidEmail'
import { IInputData } from '../types/inputData'
import FirebaseStorageService from '../../../services/storageService'
import FirestoreService from '../../../services/firestoreService'
import helpers from '../../../commonHelpers/helpers'
import useToasterHelper from '../../../helpers/ToasterHelper'

import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { authHelpers, getUserFullName } from '../../../helpers/helpers'
import { UserModel } from '../../../models/users/user.model'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import { RootState } from '../../../store/store'
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { storeUseMode } from '../../../store/system/systemThunk'
import { selectEventDetails } from '../../../store/events/eventsSlice'

import { MESSAGES_CONST } from '../../../const/messages-const'
import FIREBASE_CONST from '../../../const/firebase-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST, USER_DEF_PASS } from '../../../const/const'
import { CountryList } from '../../../fakeData/countryList'
import { disciplineData } from '../../../fakeData/disciplineList'
import { IconUserName } from '../../icons/IconUserName'

const auth = getAuth()

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

export const AddNewTeamMemberModal = (props: Props) => {
  const userIcon = '/assets/img/User2.png'
  const [picture, setPicture] = useState('')
  const [inputData, setInputDate] = useState<IInputData>({
    userFullName: {
      name: 'userFullName',
      value: '',
      required: true,
      placeholder: 'Enter full name...',
      type: 'text',
      label: 'Full Name',
      icon: <IconUser />,
    },
    userName: {
      name: 'userName',
      value: '',
      required: true,
      placeholder: 'Enter user name...',
      type: 'text',
      label: 'User Name',
      icon: <IconUserName />,
    },
    email: {
      name: 'email',
      value: '',
      required: true,
      placeholder: 'Enter address...',
      type: 'email',
      label: 'Email address',
      icon: <IconEmailImg />,
    },
    date: {
      name: 'date',
      value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      required: true,
      placeholder: 'Select date...',
      type: 'date',
      icon: <IconDate />,
      label: 'Date of birth',
    },
    nationality: {
      name: 'nationality',
      value: '',
      required: false,
      placeholder: 'Select nationality...',
      type: 'select',
      label: 'Nationality',
      icon: <NationalityIconImg />,
      selectData: CountryList,
    },
    discipline: {
      name: 'discipline',
      value: '',
      required: false,
      placeholder: 'Select discipline....',
      type: 'select',
      label: 'Discipline',
      icon: <IconHorseImg />,
      selectData: disciplineData,
    },
  })
  const [isValid, setIsValid] = React.useState(false)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const toastFunctions = useToasterHelper()
  const [imgLoading, setImgLoading] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const teamMembers = useAppSelector(selectTeamTabData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const event = useAppSelector(selectEventDetails)

  useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key]) {
      newInputData[key].value = value
      newInputData[key].hasError = false
      setInputDate(newInputData)
    }
  }

  const handleOnLoginValid = async (data: { email: string; password: string }) => {
    try {
      const emailOrError = await authHelpers.getEmail(data.email)

      const user = await signInWithEmailAndPassword(auth, emailOrError as any, USER_DEF_PASS)

      if (user) {
        const user_data_doc: any = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setEmail(userData.userEmail?.trim()))
        dispatch(setUsername(userData.userName?.trim()))
        dispatch(setDisplayName(getUserFullName(userData)))
        dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          if (event.id) {
            history.replace(`${CONST.ROUTES.REGISTER_EVENT.URL}/${event.id}/${userData.id}`, {
              direction: 'none',
            })
          } else {
            history.replace('/home', { direction: 'none' })
          }
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, {
            direction: 'none',
          })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))
      }
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND)
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD)
        return toastFunctions.error({ message: 'Credentials are incorrect' })
    } finally {
      setLoading(false)
    }
  }

  const submitOnlyMemberHandler = async ({
    isSetLoading,
    fromHorse,
  }: {
    isSetLoading?: boolean
    fromHorse?: boolean
  }): Promise<{
    id: string | null
    email: string | null
  } | null> => {
    const { email, name, date, nationality, discipline } = inputData
    let user: IUserInterface | null = null
    isSetLoading && setLoading(true)

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        `${email.value}`,
        USER_DEF_PASS
      )
      if (props.dataToPassOn.isLogin) await signOut(auth)

      const newUser = userCredential.user

      await updateProfile(userCredential.user, {
        displayName: `${name.value}`,
      })

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        newUser.uid,
        new UserModel({
          id: newUser.uid,
          userName: name.value as string,
          userFullName: name.value as string,
          userEmail: email.value as string,
          userType: 'competitor',
          userProfilePicture: picture ?? '',
          userDOB: date.value as Date,
          userNationality: (nationality?.value as IOption).label as string,
          userDiscipline: (discipline?.value as IOption).label as string,
          userCreated: new Date(),
          userModified: new Date(),
        }).toFirestore()
      )
      const userSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        newUser.uid
      )

      if (userSnaps.exists()) {
        user = getConvertedData(UserModel.fromFirestoreDoc(userSnaps).toObject())
      }

      if (!props.dataToPassOn.isLogin) {
        const userTeamMember: ITeamMember = {
          defaultRole: user?.userDefaultRole,
          memberAddress: user?.userAddress,
          memberAuthorized: '1',
          memberCountry: user?.userNationality,
          memberDob: user?.userDOB,
          memberEmail: user?.userEmail,
          memberId: user?.id ?? '',
          memberName: user ? getUserFullName(user) : null,
          memberPhoneNumber: user?.userPhoneNumber,
          memberProfilePicture: user?.userProfilePicture,
          memberRole: CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER,
          memberSafeSupportTraining: false,
          memberShipActive: false,
          memberStatus: '1',
          memberUserName: user?.userName,
          memberprofileSynced: false,
          selected: true,
        }
        const updatedProfileDetails = cloneDeep(profileDetails)

        if (Array.isArray(updatedProfileDetails.userTeamMembers)) {
          updatedProfileDetails.userTeamMembers.push(userTeamMember)
        } else {
          updatedProfileDetails.userTeamMembers = [userTeamMember]
        }

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          profileDetails.id,
          { userTeamMembers: updatedProfileDetails.userTeamMembers }
        )

        const teamData: IRegistrationTeamInterface[] = []

        if (user) teamData.push(getConvertedData(getSelectedUserAsTeamMember(user, profileDetails)))
        dispatch(setTeamTabData([...teamMembers, ...teamData]))
      }

      if (props.dataToPassOn.isLogin && !fromHorse) {
        await handleOnLoginValid({
          email: `${email.value}`,
          password: USER_DEF_PASS,
        })
      }

      isSetLoading && toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBER_CREATED })
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)

      return {
        email: `${email.value}`,
        id: user?.id || null,
      }
    } catch (error: any) {
      console.log(error, 'error')
      toastFunctions.error({ message: error?.message ?? MESSAGES_CONST.TEAM_MEMBER_NOT_CREATED })
      return null
    } finally {
      isSetLoading && setLoading(false)
    }
  }

  const submitHandler = () => {
    const { email } = inputData
    if (!email || !email.value || !isValidEmail(`${email.value}`)) {
      const newInputData = { ...inputData }
      newInputData['email'].hasError = true
      setInputDate(newInputData)
      toastFunctions.error({
        message: 'Invalid email address',
      })
      return null
    }

    if (props.dataToPassOn.isLogin) {
      submitOnlyMemberHandler({ isSetLoading: true }).then()
    } else {
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      props.handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        userPicture: picture,
        submitOnlyMemberHandler,
        ...props.dataToPassOn,
      })
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (downloadUrl) {
        setPicture(`${downloadUrl}`)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  return (
    <>
      <MainModal
        title="Add new team member"
        show={props.show}
        type="ADD_NEW_TEAM_MEMBER"
        size="md"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            loading,
            label: 'NEXT >',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: submitHandler,
            className: 'outline-none !w-full',
            textClass: 'text-white',
            disabled: !isValid,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER),
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4'}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              title={'Profile picture'}
              isLoading={imgLoading}
              picture={picture}
              onchangeHandler={updateProfilePic}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key, i) => (
              <div className={`relative z-${i}`}>
                <InputNewMemberRow
                  key={key}
                  value={inputData[key].value}
                  onChangeHandler={onChangeHandler}
                  placeholder={inputData[key].placeholder}
                  inputName={inputData[key].name}
                  name={inputData[key].label}
                  isError={inputData[key].hasError}
                  icon={inputData[key].icon}
                  isRequired={inputData[key].required}
                  type={inputData[key].type}
                  selectData={inputData[key].selectData || []}
                />
              </div>
            ))}
          </div>

          <InputCheckMemberInfo
            isChecked={isConfirmed}
            onClick={() => setIsConfirmed(!isConfirmed)}
            text={
              'By ticking this box I confirm that I have been given permission to create a Pegasus\n' +
              '                account on behalf of this person, and I am authorized to agree to Pegasus’ terms and\n' +
              '                conditions on their behalf, as well as register them for events.'
            }
          />

          <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
            <div className={'mt-1'}>
              <IconError />
            </div>

            <p className={'flex-1 text-[#122B46] text-[14px] m-0'}>
              The email address listed above will be notified of this account creation and provided
              with login details to join Pegasus and complete their profile.
            </p>
          </div>
        </div>
      </MainModal>
    </>
  )
}
