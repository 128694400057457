/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircleOutline, AutorenewRounded, ChevronRight } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import WrapperWithHeader from '../../components/common/wrappers/WrapperWithHeader'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { HorseLineageModel } from '../../models/horse-lineage/horse-lineage-model'
import { THorseTeamMembers } from '../../models/horse-lineage/horse-lineage.interface'
import { getConvertedData } from '../../models/interface.helper'
import FirestoreService from '../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSelectedHorseLineage,
  horseSliceThunks,
  selectHorseReducer,
  setSelectedHorseLineage,
} from '../../store/horses/horseSlice'

type ISubmitFn = (data: any) => void

type Props = {
  handleModal?: any
  setActiveTab?: any
  selectedHorse?: any
  addHorse: boolean
}

const HorseRootEditLineageTab = (props: Props) => {
  // hooks and vars
  const { handleModal } = props

  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)

  const horseReducer = useAppSelector(selectHorseReducer)
  const mappingId = horseReducer.selected.mapping.data?.id

  const selectedHorse = horseReducer.selected.horse.data
  const selectedHorseLineage = horseReducer.selected.lineage

  const { horseLineageMembers = [], id = null } = useAppSelector(getSelectedHorseLineage) ?? {}

  const { handleSubmit } = useForm()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!!!mappingId || mappingId === selectedHorseLineage.data?.id) return

    dispatch(
      horseSliceThunks.getHorseLineageThunk({
        userHorseMappingDocId: horseReducer.selected.mapping.data?.id!,
      })
    )
  }, [horseReducer.selected.mapping.data?.id])

  const handleSetLineage = ({ e, index }: { e: any; index: number }) => {
    let horseLineageMembersClone = cloneDeep(horseLineageMembers) ?? []
    horseLineageMembersClone[index].lineageName = e.target.value

    if (id) {
      dispatch(
        setSelectedHorseLineage({
          data: getConvertedData({
            id,
            horseLineageMembers: horseLineageMembersClone,
          }),
        })
      )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const validateAction = (): boolean => {
    if (!selectedHorse) {
      toastFunctions.error({
        message: MESSAGES_CONST.PLEASE_CREATE_HORSE_FIRST,
      })
      return false
    }

    if (!!!selectedHorse?.id || !!!mappingId) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }

    return true
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param data Updates horse in db
   */
  const onSubmit: ISubmitFn = async () => {
    if (!validateAction()) return

    setLoading(true)

    let horseLineageToCreate = new HorseLineageModel({
      horseLineageMembers,
    })

    try {
      if (!!!mappingId) throw new Error(`mappingId is ${mappingId}`)

      if (!!selectedHorseLineage.data?.id) {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSE_LINEAGE.NAME,
          selectedHorseLineage.data?.id,
          horseLineageToCreate.toFirestore()
        )
      } else {
        await FirestoreService.createItemWithCustomId(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSE_LINEAGE.NAME,
          mappingId,
          horseLineageToCreate.toFirestore()
        )
      }

      dispatch(
        setSelectedHorseLineage({
          data: getConvertedData({
            ...horseLineageToCreate.toObject(),
            id: selectedHorseLineage.data?.id,
          }),
        })
      )
      toastFunctions.success({ message: MESSAGES_CONST.LINEAGE_UPDATE_SUCCESSFULLY })
    } catch (err: any) {
      console.error(err)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onAddLineageClick = () => {
    if (!validateAction()) return

    handleModal(true, MODAL_CONSTS.LINEAGE_ADDING_HORSES)
  }

  const generateName = (fullName: string | undefined) => {
    return fullName
      ? fullName
          .split(' ')
          .map((name) => name[0])
          .join('')
          .toUpperCase()
      : ''
  }

  return (
    <WrapperWithHeader
      title="Lineage"
      description="Add, edit or remove horses that are related to this horse"
      onSubmit={handleSubmit(onSubmit)}
      headerButtonsContainer={
        <button
          type="submit"
          disabled={loading || selectedHorseLineage.status === 'loading'}
          className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor saveBtn"
        >
          {loading ? (
            <AutorenewRounded fontSize="small" className="animate-spin" />
          ) : (
            'Save Changes'
          )}
        </button>
      }
    >
      {selectedHorseLineage.status === 'loading' ? (
        <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
      ) : (
        <>
          {selectedHorseLineage.data?.horseLineageMembers.length ? (
            selectedHorseLineage.data?.horseLineageMembers.map(
              (item: THorseTeamMembers, index: number) => {
                return (
                  <div className="flex w-full" key={`${JSON.stringify(item)}${index}`}>
                    <div className="userList flex mb-3 w-full items-center">
                      <div className="flex items-center w-1/6">
                        <img
                          src="/assets/og_icons/YearofHorse-1.svg"
                          className="mr-1 avatarImg shrink-0 cursor-pointer !h-6"
                          alt="darkHorse"
                        />
                        <p className="text-SeabiscuitDark200ThemeColor text-sm">Horse</p>
                      </div>

                      {/* User card */}
                      <div className="user flex flex-row items-center rounded-lg py-1 px-1 pl-1.5 mr-2 border border-SeabiscuitGray500ThemeColor my-1 w-1/4">
                        <img
                          src={item?.horseProfile}
                          className="w-[42px] h-[42px] object-cover mr-2 rounded-md shrink-0 cursor-pointer"
                          alt="demoUser"
                          onError={(e) =>
                            ((e.target as any).src =
                              `https://ui-avatars.com/api/?name=${generateName(item.horseName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                          }
                        />

                        <div className="hourseDetails w-10/12">
                          <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize">
                            {item.horseName}
                            <div className="hourseCategory flex text-SeabiscuitDark200ThemeColor text-sm">
                              <p className="capitalize ellipsis">{item.decipline?.toString()}</p>
                              <p className="px-1 ellipsis">• Zone {item.horseZone}</p>
                              <p className="ellipsis">• {item.horseCountry}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="user flex flex-row items-center mr-2 w-1/4">
                        <select
                          className="form-select appearance-none block w-full bg-white border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-3.5"
                          aria-label="Default select example"
                          defaultValue={item.lineageName}
                          onChange={(e) => handleSetLineage({ e, index })}
                        >
                          <option value="0" disabled>
                            Select...
                          </option>
                          <option value="Sire">Sire</option>
                          <option value="Dam">Dam</option>
                          <option value="GrandSire">Grand Sire</option>
                          <option value="GrandDam">Grand Dam</option>
                        </select>
                      </div>
                      {/*  */}

                      <img
                        src="assets/og_icons/Cancel.svg"
                        alt="cancel"
                        className="cursor-pointer text-SeabiscuitLightParagraphTextColor"
                        onClick={() =>
                          handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
                            removingHorse: item,
                            modal_type: 'removeHorseLineage',
                          })
                        }
                      />
                    </div>
                  </div>
                )
              }
            )
          ) : (
            <div className="flex flex-col w-full min-h-[70vh] justify-center items-center">
              <p className="mb-4">You have not added any horses to lineage</p>
              <img src="/assets/placeholders/LineagePlaceholder.svg" alt="placeholderImage" />
              <p
                onClick={onAddLineageClick}
                className="text-SeabiscuitMainThemeColor cursor-pointer"
              >
                Add your first lineage <ChevronRight />{' '}
              </p>
            </div>
          )}

          {horseLineageMembers.length ? (
            <div onClick={onAddLineageClick} className="horseAdd flex items-center cursor-pointer">
              <AddCircleOutline className="text-SeabiscuitMainThemeColor" />
              <p className="ml-2 text-SeabiscuitMainThemeColor">Add horse</p>
            </div>
          ) : null}
        </>
      )}
    </WrapperWithHeader>
  )
}

export default HorseRootEditLineageTab
