import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import { where } from 'firebase/firestore'

import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { Peoples } from './components/Peoples'
import { Horses } from './components/Horses'

import useHorses from '../../../../hooks/users/competitor/profile/useHorses'

import { getConvertedData } from '../../../../models/interface.helper'

import IRegistrationTeamsTypes from '../../../../models/registeration-teams/registration-teams.interface'
import { RegistrationTeamModel } from '../../../../models/registeration-teams/registration-teams.models'

import FirestoreService from '../../../../services/firestoreService'

import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectTeamTabData,
  setHorseRegistrationErrors,
  setTeamTabData,
  setUserRegistrationErrors,
} from '../../../../store/registration/registrationSlice'
import { selectAllUsers } from '../../../../store/users/usersSlice'
import { selectHorses, setHorses as setHorsesInStore } from '../../../../store/horses/horseSlice'

import { UserHorseMappingModel } from '../../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'
import ITypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../models/horse/horse.model'
import { IHorseCompetitorDocument } from '../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IUserInterface } from '../../../../models/users/user.interface'

import { CONST } from '../../../../const/const'
import { REQUIERED_FILEDS } from '../../../../const/requeredFields'
import { getHorseProfileReqField, getUserProfileReqField } from '../../../../helpers/requiredFields'

type Props = {
  step?: number
  title?: string
  description?: string
  handleForm?: () => void
  teamMembersLoading?: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}

const REGISTRATION_TEAMS = CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_TEAMS

const ClinicNOtherStatusTab = (props: Props) => {
  const { userId } = useParams<ITypes['IParams']>()

  const dispatch = useAppDispatch()
  const { mappings } = useHorses(userId)

  const { Event } = useAppSelector(selectedEvent)
  const teamTabData = useAppSelector(selectTeamTabData)
  const myHorses = useAppSelector(selectHorses)

  const [mainLoading, setMainLoading] = useState(true)
  const [horsesPaperwork, setHorsesPaperwork] = useState<IHorseCompetitorDocument[] | null>(null)
  // const [totalErrors, setTotalErrors] = useState<IErrors>({ horses: {}, users: {} })
  const users = useAppSelector(selectAllUsers)
  const [teamTabDataWithUser, setTeamTabDataWithUser] = useState<IUserInterface[]>([])

  const getTeamDataFromDb = async () => {
    let teams: IRegistrationTeamsTypes['IRegistrationTeamInterface'][] = []
    const teamSnaps = await FirestoreService.filterItems(REGISTRATION_TEAMS.NAME, [
      where(REGISTRATION_TEAMS.FIELDS.EVENT_ID.KEY, '==', Event?.id),
      where(REGISTRATION_TEAMS.FIELDS.USER_ID.KEY, '==', userId),
    ])

    teamSnaps.docs.forEach((currDoc) => {
      teams.push(getConvertedData(RegistrationTeamModel.fromFirestoreDoc(currDoc).toObject()))
    })

    if (!teams) throw new Error('teams not exists')

    dispatch(setTeamTabData(teams))
  }

  useEffect(() => {
    if (Event?.id && userId) {
      getTeamDataFromDb().then()
    }
  }, [Event?.id, userId])

  const getHorsesInStore = async () => {
    const horsesData: IUserHorseMappingInterface[] = []
    try {
      const ids = teamTabDataWithUser.map((user) => user.id) || []
      const horsesFromDb = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES.NAME,
        [where(CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES.FIELDS.OWNER_ID.KEY, 'in', ids)]
      )

      if (horsesFromDb.size) {
        horsesFromDb.forEach((currSnap) =>
          horsesData.push(
            getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currSnap).toObject())
          )
        )
      }

      const newHorses: IHorseData[] = []

      const horsesIds: string[] = horsesData.map((h: any) => h.id)

      if (mappings.data && Array.isArray(mappings.data)) {
        for (const itm of mappings.data) {
          const isInArr = newHorses.find((h: any) => h.id === itm.horseId)
          if (!isInArr && itm.horseId) {
            horsesIds.push(itm.horseId)
          }
        }
      }

      let horsesSnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
        horsesIds
      )

      horsesSnaps.forEach((currDoc) => {
        newHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })

      dispatch(setHorsesInStore(newHorses))
    } catch (e) {
      console.error('e', e)
    } finally {
      setMainLoading(false)
    }
  }

  useEffect(() => {
    if (teamTabDataWithUser.length > 0) getHorsesInStore().then()
  }, [teamTabDataWithUser])

  useEffect(() => {
    let hasHorseError = false
    const requiredFiled = [
      ...REQUIERED_FILEDS.horseMain.filter((itm) => Event?.requiredHorseFields.includes(itm.value)),
      ...REQUIERED_FILEDS.horseSecondary.filter((itm) =>
        Event?.requiredHorseFields.includes(itm.value)
      ),
      ...REQUIERED_FILEDS.horseNumbers.filter((itm) =>
        Event?.requiredHorseFields.includes(itm.value)
      ),
    ]

    myHorses.forEach((horse) => {
      requiredFiled.forEach((field) => {
        const profileReqField = getHorseProfileReqField({ horse, field, horsesPaperwork })
        if (!profileReqField?.profileValue || profileReqField?.isExpired) hasHorseError = true
      })
    })

    if (hasHorseError) {
      dispatch(setHorseRegistrationErrors(true))
    } else {
      dispatch(setHorseRegistrationErrors(false))
    }
  }, [myHorses, Event?.requiredHorseFields])

  useEffect(() => {
    let hasUserError = false
    const requiredFiled = [
      ...REQUIERED_FILEDS.people.filter((itm) => Event?.requiredFields.includes(itm.value)),
      ...REQUIERED_FILEDS.peopleNumbers.filter((itm) => Event?.requiredFields.includes(itm.value)),
    ]

    teamTabDataWithUser.forEach((user) => {
      requiredFiled.forEach((field) => {
        const profileReqField = getUserProfileReqField({ user, field })
        if (!profileReqField?.profileValue || profileReqField?.isExpired) hasUserError = true
      })
    })

    if (hasUserError) {
      dispatch(setUserRegistrationErrors(true))
    } else {
      dispatch(setUserRegistrationErrors(false))
    }
  }, [teamTabDataWithUser, Event?.requiredFields])

  useEffect(() => {
    const competitor = users.find((user) => user.id === userId)
    const team: IUserInterface[] = []
    teamTabData.forEach((member) => {
      const current = users.find((user) => user.id === member.memberId)
      if (current?.id) team.push(current)
    })
    if (competitor) setTeamTabDataWithUser([competitor, ...team])
  }, [teamTabData, userId, users])

  return (
    <>
      <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
        <div className="flex justify-between flex-wrap gap-5">
          <Peoples
            teamTabDataWithUser={teamTabDataWithUser}
            horsesPaperwork={horsesPaperwork}
            mainLoading={mainLoading}
          />

          <Horses horsesPaperwork={horsesPaperwork} setHorsesPaperwork={setHorsesPaperwork} />
        </div>
      </CompetitorEventRegisterWrapper>
    </>
  )
}

export default ClinicNOtherStatusTab
