// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import uploadToCloudImage from '../../../components/elements/images/UploadtotheCloud.png'
import SubheadingElement from '../../../components/elements/headings/subheading/SubheadingElement'
import ProgressBar from '../../../components/events/forms/detail/ProgressBar'
import DrawerComponent from '../../../components/events/forms/detail/Components/DrawerComponent'
import GlobalButton from '../../../components/events/forms/detail/Components/GlobalButton'
import { useChatContext, useMessageInputContext } from 'stream-chat-react'

const AttachmentActions = ({ id, type }: { id: string; type: string }) => {
  const { fileUploads, imageUploads } = useMessageInputContext()
  const attachment = type === 'image' ? imageUploads[id] : fileUploads[id]
  const { removeImage, uploadImage, removeFile, uploadFile } = useMessageInputContext()
  let [remove, upload] = type === 'image' ? [removeImage, uploadImage] : [removeFile, uploadFile]

  if (attachment.state === 'uploading') {
    return (
      <ProgressBar percentage={50} imgSrc={uploadToCloudImage} imgName={attachment.file.name} />
    )
  }

  if (attachment.state === 'finished') {
    return (
      <ProgressBar percentage={100} imgSrc={uploadToCloudImage} imgName={attachment.file.name} />
    )
  }

  return (
    <button className="message-input__attachment-action" onClick={() => upload(attachment.id)}>
      Failed. Retry?
    </button>
  )
}

export const ModalAttach: React.FC<{ show: boolean; onHide: () => void }> = ({ show, onHide }) => {
  const { uploadNewFiles, isUploadEnabled, maxFilesLeft, fileOrder, imageOrder, handleSubmit } =
    useMessageInputContext()

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files

    if (files && files.length > 0) {
      uploadNewFiles(files)
    }
  }

  if (!isUploadEnabled || maxFilesLeft === 0) {
    return null
  }

  return (
    <DrawerComponent show={show} onHide={onHide} title="Add images" maxWidth={640}>
      <div className="text-SeabiscuitMainThemeColor bg-[#F7076315] border-[1px] border-dashed rounded-xl flex flex-col gap-2 items-center p-6 border-SeabiscuitMainThemeColor">
        <img src="/assets/chat/upload-icon.svg" alt="icon" />
        <div>Drag & Drop Images Here</div>
        <div>OR</div>
        <label className="py-4 px-6 text-center cursor-pointer bg-SeabiscuitMainThemeColor w-[270px] h-12 block rounded-xl shadow-md">
          <span className="text-white">Browse Files</span>
          <input type="file" id="fileSelector" className="hidden" onChange={handleChange} />
        </label>
      </div>
      <div className="container-2 mt-6">
        {!(fileOrder.length === 0 && imageOrder.length === 0) && (
          <SubheadingElement text="Uploaded files" classname="sub-heading" />
        )}
        <div className="container-3">
          <ul>
            {imageOrder.map((id) => (
              <li key={id}>
                <AttachmentActions id={id} type="image" />
              </li>
            ))}
            {fileOrder.map((id) => (
              <li key={id}>
                <AttachmentActions id={id} type="file" />
              </li>
            ))}
          </ul>
        </div>
        <GlobalButton
          type={1}
          caption={imageOrder.length > 1 || fileOrder.length > 1 ? 'Add Files' : 'Add File'}
          className="w-[270px] mx-auto block mt-20"
          onClick={() => {
            handleSubmit()
            onHide()
          }}
        />
      </div>
    </DrawerComponent>
  )
}
