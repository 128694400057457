import React from 'react'

export const IconPaid: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
    <path
      fill="#F70763"
      d="M10 0c-.822 0-1.5.426-2.001 1h-2.25a.75.75 0 00-.701 1.014l1.306 3.484C4.914 6.648.324 10.76.53 16.438.623 18.98 2.737 21 5.278 21h9.444c2.54 0 4.655-2.02 4.748-4.563.206-5.678-4.384-9.789-5.825-10.939l1.307-3.484A.75.75 0 0014.25 1H12c-.501-.574-1.178-1-2-1zm0 1.5c.464 0 .854.25 1.074.627a.75.75 0 00.649.373h1.445L12.23 5H7.77l-.938-2.5h1.445a.75.75 0 00.649-.373c.22-.378.61-.627 1.074-.627zm-2.492 5h4.984c.866.68 5.668 4.691 5.479 9.883a3.237 3.237 0 01-3.25 3.117H5.279a3.237 3.237 0 01-3.249-3.117C1.841 11.19 6.642 7.18 7.508 6.5zm2.48 1.49a.75.75 0 00-.738.76v.363c-.998.285-1.75 1.171-1.75 2.253v.001a2.395 2.395 0 002.383 2.383h.734c.496 0 .883.387.883.883a.871.871 0 01-.876.882l-.92.008a.7.7 0 01-.672-.493.75.75 0 10-1.43.45c.233.741.888 1.217 1.648 1.392v.378a.75.75 0 101.5 0v-.259c1.244-.073 2.25-1.098 2.25-2.358a2.395 2.395 0 00-2.383-2.383h-.734A.872.872 0 019 11.367c0-.492.379-.876.87-.884l.412-.006a.7.7 0 01.674.498.752.752 0 00.939.504.75.75 0 00.495-.943c-.227-.744-.881-1.222-1.64-1.402V8.75a.75.75 0 00-.762-.76z"
    ></path>
  </svg>
)
