import { useEffect, useState } from 'react'

import { where } from 'firebase/firestore'

import { RiderTeamMemberModel } from '../models/rider-team-member/riderTeamMember.model'
import FirestoreService from '../services/firestoreService'

import { MEMBER_ROLES } from '../const/ui/ui-register.const'
import { CONST } from '../const/const'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export const useEventTrainers = (eventId: string) => {
  const [eventTrainers, setEventTrainers] = useState<RiderTeamMemberModel[]>([])

  const getEventTrainers = async () => {
    if (!eventId) return null

    const trainers: RiderTeamMemberModel[] = []

    const trainersMembersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      [
        where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(
          COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.TEAM_MEMBER_ROLE.KEY,
          '==',
          MEMBER_ROLES.TRAINER
        ),
      ]
    )

    trainersMembersSnaps.forEach((member) => {
      trainers.push(
        RiderTeamMemberModel.fromFirestoreDoc(member).toObject() as RiderTeamMemberModel
      )
    })

    setEventTrainers(trainers)
  }

  useEffect(() => {
    getEventTrainers().then()
  }, [])

  return {
    eventTrainers,
  }
}
