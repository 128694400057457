import { Add, AddCircleOutline, HighlightOff } from '@mui/icons-material'
import React, { FC, useContext, useState } from 'react'
import clsx from 'clsx'
import { Control, Controller, useFieldArray, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import Select from 'react-select'

import { customStyles } from '../../../components/customStyles/ReactSelectCustomStyle'
import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'
import AddCertificateModal from '../../../components/modals/AddCertificateModal'

import { IOnRemoveImageButtonClickFn } from '../HorsePaperwork'

import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { getReactPickerDate } from '../../../models/interface.helper'
import { horseHeightList } from '../../../fakeData/HorseHeightList'
import {
  IHorseCompetitorDocument,
  IMeasurementCard,
} from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../../models/horse/horse.interface'

import { CardType, HeelSize, ShodStatus } from '../../../fakeData/horse'

import { getImage } from '../../../helpers/image'

import { MODAL_CONSTS } from '../../../const/modal-const'

const horseMeasurementCardIniVal: IMeasurementCard = {
  horseShodStatus: null,
  horseMeasurementDate: null,
  horseHeightMeasurement: null,
  horseMeasurementCardType: null,
  horseLeftHeelMeasurement: null,
  horseRightHeelMeasurement: null,
  horseMeasurementCardsImage: [],
}

interface MeasurementCardProps {
  type: 'update-paperwork-modal' | 'horse-paperwork-tab'
  onRemoveImageButtonClick: IOnRemoveImageButtonClickFn
  selectedHorse: IHorseData | null
  watch: UseFormWatch<IHorseCompetitorDocument>
  control: Control<IHorseCompetitorDocument>
  notEditable?: boolean
  imagesUrlsToDeleteFromStorage: any
  onSubmit: (data: any, keepUnsaved: boolean) => Promise<void>
  setValue: UseFormSetValue<IHorseCompetitorDocument>
}

export const MeasurementCard: FC<MeasurementCardProps> = ({
  type,
  notEditable,
  selectedHorse,
  control,
  watch,
  imagesUrlsToDeleteFromStorage,
  onRemoveImageButtonClick,
  onSubmit,
  setValue,
}) => {
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const [loading, setLoading] = useState(false)
  const [rowIndex, setRowIndex] = useState(0)
  const [addCertificateModalShow, setAddCertificateModalShow] = useState(false)

  const horseMeasurementCards = useFieldArray({
    control,
    name: 'horseMeasurementCards',
  })

  const onAddMeasurementCardClick = () => {
    if (!selectedHorse?.id || notEditable) return

    horseMeasurementCards.append(horseMeasurementCardIniVal)
  }

  const onRemoveMeasurementCardButtonClick = (index: number) => {
    imagesUrlsToDeleteFromStorage.current = [
      ...imagesUrlsToDeleteFromStorage.current,
      ...(watch('horseMeasurementCards')?.[index]?.horseMeasurementCardsImage ?? []),
    ]
    horseMeasurementCards.remove(index)
  }

  const onUploadCertificateClick = () => {
    if (!selectedHorse?.id || notEditable) return

    if (type === 'horse-paperwork-tab') {
      handleModal?.(true, MODAL_CONSTS.HORSE_ADD_CERTIFICATE, {
        fileUploadType: 'horseMeasurementCardsImage',
        data: watch(),
        setter: async (_: any, imageUrls: string[]) => {
          setValue(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`, [
            ...(watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`) ?? []),
            ...imageUrls,
          ])
          await onSubmit(watch(), true)
        },
      })
    }

    if (type === 'update-paperwork-modal') {
      setAddCertificateModalShow(true)
    }
  }

  return (
    <>
      {addCertificateModalShow && (
        <AddCertificateModal
          handleCloseModal={() => setAddCertificateModalShow(false)}
          dataToPassOn={{
            fileUploadType: 'horseMeasurementCardsImage',
            data: watch(),
            setter: async (_: any, imageUrls: string[]) => {
              setValue(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`, [
                ...(watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`) ?? []),
                ...imageUrls,
              ])
              await onSubmit(watch(), true)
            },
          }}
        />
      )}
      <div className="flex flex-wrap gap-4 w-full">
        <div className="flex items-center min-w-[150px] w-full sm:w-[18%] text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Ruler.png" className="mr-4 w-6" alt="measurementCard" />
          Measurement card
        </div>
        <div className="wrapper flex flex-col gap-4 flex-grow py-2 w-full sm:w-[78%]">
          {horseMeasurementCards.fields.map((field: any, index) => {
            return (
              <React.Fragment key={field.id}>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    {watch(`horseMeasurementCards.${index}.horseMeasurementCardsImage`).length ? (
                      <div className="flex gap-2">
                        {watch(`horseMeasurementCards.${index}.horseMeasurementCardsImage`)?.map(
                          (item: string, imageIndex: number) => {
                            return (
                              <div
                                key={`horseMeasurementCardsImage${field.id}${imageIndex}`}
                                className={clsx(
                                  'image-preview rounded-md w-24 h-24 z-[0] relative',
                                  {
                                    border: getImage(item).type === 'image',
                                  },
                                  loading && 'pointer-events-none opacity-70'
                                )}
                              >
                                <img
                                  src={getImage(item).url}
                                  onClick={() => window.open(item, '_blank')}
                                  className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                                  alt="horseMeasurementCardsImage"
                                />
                                {!notEditable ? (
                                  <button
                                    onClick={async () => {
                                      setLoading(true)
                                      await onRemoveImageButtonClick({
                                        index: imageIndex,
                                        type: 'horseMeasurementCardsImage',
                                        rowIndex: index,
                                      })
                                      setLoading(false)
                                    }}
                                    type="button"
                                    className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center z-[1]"
                                  >
                                    <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999] transition-all hover:opacity-70" />
                                  </button>
                                ) : null}
                              </div>
                            )
                          }
                        )}
                      </div>
                    ) : null}

                    {!notEditable ? (
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setRowIndex(index)
                          onUploadCertificateClick()
                        }}
                      >
                        <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                          <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                        </div>
                        <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-wrap gap-3">
                    <Controller
                      name={`horseMeasurementCards.${index}.horseMeasurementCardType`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <label className="text-sm text-gray-400">
                            Card Type
                            <Select
                              isMulti={false}
                              onChange={onChange}
                              value={CardType.find(
                                (c) => c.value === ((value as any)?.value ?? value)
                              )}
                              onBlur={onBlur}
                              placeholder={<div className="flex text-[13px]">Select</div>}
                              options={CardType}
                              isDisabled={notEditable}
                              className={`border-[#D3DAEE] border rounded-md w-40 ${notEditable ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                              isClearable={false}
                              styles={customStyles}
                              isSearchable={false}
                            />
                          </label>
                        )
                      }}
                    />

                    {/* Measurement date... */}

                    <Controller
                      name={`horseMeasurementCards.${index}.horseMeasurementDate`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <div className="flex flex-col">
                            <label className="text-sm text-gray-400">Measurement Date</label>
                            <CustomDatePicker
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={getReactPickerDate(value)}
                              peekNextMonth
                              dropdownMode="select"
                              wrapperClassName="w-auto"
                              showMonthDropdown
                              showYearDropdown
                              disabled={notEditable}
                              placeholderText="Select"
                              className={`rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 py-3 pl-10 !text-sm w-30 ${notEditable ? '!border-white text-SeabiscuitDark200ThemeColor pt-2 placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            />
                          </div>
                        )
                      }}
                    />

                    {/* Height measured... */}

                    <Controller
                      name={`horseMeasurementCards.${index}.horseHeightMeasurement`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <label className="text-sm text-gray-400">
                          Height Measured
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={horseHeightList.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            onBlur={onBlur}
                            placeholder={<div className="flex text-[13px] p-0">Select</div>}
                            options={horseHeightList}
                            isDisabled={notEditable}
                            className={`border-[#D3DAEE] border rounded-md min-w-[125px] text-[2px] ${notEditable ? '!border-white  text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </label>
                      )}
                    />

                    {/* Left heel size... */}

                    <Controller
                      name={`horseMeasurementCards.${index}.horseLeftHeelMeasurement`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <label className="text-sm text-gray-400">
                          Left Heel Size
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={HeelSize.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            onBlur={onBlur}
                            placeholder={<div className="flex text-[13px]">Select</div>}
                            options={HeelSize}
                            className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${notEditable ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            isClearable={false}
                            isDisabled={notEditable}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </label>
                      )}
                    />

                    {/* Right heel size... */}
                    <Controller
                      name={`horseMeasurementCards.${index}.horseRightHeelMeasurement`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <label className="text-sm text-gray-400">
                          Right Heel Size
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={HeelSize.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            onBlur={onBlur}
                            placeholder={<div className="flex text-[13px]">Select</div>}
                            options={HeelSize}
                            isDisabled={notEditable}
                            className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${notEditable ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </label>
                      )}
                    />

                    {/* Shod status... */}
                    <Controller
                      name={`horseMeasurementCards.${index}.horseShodStatus`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <label className="text-sm text-gray-400">
                          Shod Status
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={ShodStatus.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            onBlur={onBlur}
                            placeholder={<div className="flex text-[13px]">Select</div>}
                            isDisabled={notEditable}
                            options={ShodStatus}
                            className={`border-[#D3DAEE] border rounded-md w-40 ${notEditable ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </label>
                      )}
                    />

                    {!notEditable && index ? (
                      <div
                        onClick={() => onRemoveMeasurementCardButtonClick(index)}
                        className="cursor-pointer flex mt-[18px]"
                      >
                        <img src={'assets/og_icons/Cancel.svg'} alt="cancel" />
                      </div>
                    ) : null}
                  </div>
                </div>

                {horseMeasurementCards.fields.length - 1 > index ? <hr className="w-full" /> : null}
              </React.Fragment>
            )
          })}
        </div>
      </div>
      {!notEditable && (
        <div onClick={onAddMeasurementCardClick} className="flex items-center cursor-pointer mt-4">
          <AddCircleOutline className="text-SeabiscuitMainThemeColor" fontSize="small" />

          <p className="ml-2 text-SeabiscuitMainThemeColor text-sm">Add measurement card</p>
        </div>
      )}
    </>
  )
}
