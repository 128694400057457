import clsx from 'clsx'

const generateName = (fullName: string | undefined) => {
  return fullName
    ? fullName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase()
    : ''
}

const customImageComponent = (
  src: string | undefined | null,
  name: string | undefined | null,
  className?: string,
  lazyLoad?: boolean
) => {
  return lazyLoad ? (
    <img
      alt=""
      onError={(e) =>
        ((e.target as any).src =
          `https://ui-avatars.com/api/?name=${generateName(name ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
      }
      data-src={
        src
          ? src
          : `https://ui-avatars.com/api/?name=${generateName(name ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`
      }
      loading="lazy"
      className={clsx(className, 'object-cover h-full')}
    />
  ) : (
    <img
      alt=""
      onError={(e) =>
        ((e.target as any).src =
          `https://ui-avatars.com/api/?name=${generateName(name ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
      }
      src={
        src
          ? src
          : `https://ui-avatars.com/api/?name=${generateName(name ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`
      }
      className={clsx(className, 'object-cover h-full')}
    />
  )
}

export default customImageComponent
