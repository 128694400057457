import React from 'react'

type Props = {
  children?: any
  title?: string
  description?: string
}

const CompetitorEventRegisterWrapper = (props: Props) => {
  return (
    <>
      <div className="py-4 px-6">
        {/* header */}
        <div className="headerSection">
          <h2 className="text-SeabiscuitMainThemeColor text-xl font-semibold">{props.title}</h2>
          <p className="text-SeabiscuitDark200ThemeColor text-sm">{props.description}</p>
        </div>
        <hr className="mt-4 h-2 w-full" />
        {props.children}
      </div>
    </>
  )
}

export default CompetitorEventRegisterWrapper
