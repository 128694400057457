import { useEffect, useState } from 'react'

// Types

// Redux
import { useAppSelector } from '../../../../store/hooks'

// Third party
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { v4 } from 'uuid'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { IRegistrationFeesInterface } from '../../../../models/registration-fees/registrationFees.interface'
import { selectRegistrationR } from '../../../../store/exhibitor/exhibitorSlice'
import { CONST } from '../../../../const/const'
import { IPaymentStatus } from '../../../../models/event-registered-users/event-registered-users.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorFeesAccordionProps = {
  data: IRegistrationFeesInterface[]
  showScratchedView: boolean
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
}

type IDetailRowProps = {
  title: string | null
  cost?: number
  units: number
  itemId?: string | null
  isScratched: boolean
  showScratchedView: boolean
  paymentStatus: IPaymentStatus
  listToScratch: string[]
  handleScratchList: (itemId: string) => void
  member: IRegistrationFeesInterface
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const ExhibitorFeesInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({
  title,
  cost,
  units,
  showScratchedView,
  isScratched,
  listToScratch,
  member,
  paymentStatus,
  itemId,
  handleScratchList,
}: IDetailRowProps) => {
  let id = `${v4()}${Date.now()}`
  let checked = member.id ? listToScratch.find((data) => data === member.id) : false

  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row lg:items-center w-full">
      <div className="lg:hidden font-medium text-[12px]">Line item</div>
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4"
        data={title}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Number of units</div>

      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center"
        data={units}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Price per unit</div>
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center"
        data={`$${cost}`}
      />
      <div className="lg:hidden mt-2 font-medium text-[12px]">Total Price</div>
      <ExhibitorFeesInput
        className="relative rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center qwqwqwqwqwqw"
        data={isScratched ? 'Scratched' : `$${(Number(cost ?? 0) * units).toFixed(2)}`}
        icon={
          paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />

      {showScratchedView && !!itemId && typeof itemId === 'string' ? (
        <label
          htmlFor={id}
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/4 text-center relative flex items-center justify-center',
            !isScratched && 'cursor-pointer'
          )}
        >
          {isScratched ? (
            'Scratched'
          ) : (
            <>
              Scratch
              <div
                className={clsx(
                  'ml-2 !ring-0 !shadow-none absolute shrink-0 flex items-center justify-center',
                  !checked
                    ? 'border-2 border-SeabiscuitDark200ThemeColor rounded-full w-5 h-5 right-[14px]'
                    : 'w-6 h-6 right-3'
                )}
              >
                {checked ? (
                  <img
                    src="/assets/og_icons/Ok-3.svg"
                    alt="Ok"
                    className="w-full h-full object-cover"
                  />
                ) : null}
              </div>
              <input
                type="checkbox"
                className="hidden"
                id={id}
                checked={!!checked}
                onChange={() => handleScratchList(itemId as string)}
              />
            </>
          )}
        </label>
      ) : null}
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorFeesAccordion = (props: IExhibitorFeesAccordionProps) => {
  // Hooks and vars
  const { showScratchedView, setListToScratch, listToScratch } = props

  const toastMethods = useToasterHelper()

  const registrationR = useAppSelector(selectRegistrationR)

  const data = props.data

  const setAmountToRefund = useState(0)[1]

  const handleShowToast = () => {
    return toastMethods.info({ message: 'Selected member Bill has not yet paid' })
  }
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    setAmountToRefund(0)
    setListToScratch([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScratchedView, registrationR])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleScratchList = (itemId: string) => {
    let newstr = [...listToScratch]

    if (listToScratch.find((data) => data === itemId)) {
      let filter = listToScratch.filter((data) => data !== itemId)
      setListToScratch(filter)
    } else {
      let memberData = data.filter((registration) => registration.id === itemId)
      if (memberData.length && memberData[0].paymentStatus !== 'paid') {
        handleShowToast()
      } else {
        setListToScratch([...newstr, itemId])
      }
    }
  }

  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorFeesInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Line item</span>}
        />

        <ExhibitorFeesInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Number of units</span>}
        />

        <ExhibitorFeesInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          // data={`Price per unit`}
          data={<span className="flex items-center">Price per unit</span>}
        />

        <ExhibitorFeesInput
          className={clsx(
            'text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center">Total Price</span>}
        />

        {showScratchedView ? (
          <ExhibitorFeesInput
            className={clsx(
              'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/5'
            )}
            data={
              <span className="flex items-center">
                Scratch
                <Tooltip title={<h1 className="tooltip_title">Scratch</h1>} placement="top" arrow>
                  <button className="">
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </span>
            }
          />
        ) : null}
      </div>

      {data && data.length
        ? data.map((currentItem, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.feesTitle}
                units={currentItem.selectedUnitsCount}
                itemId={currentItem?.id}
                listToScratch={listToScratch}
                cost={currentItem.feesPrice as any}
                member={currentItem}
                showScratchedView={showScratchedView}
                handleScratchList={handleScratchList}
                isScratched={currentItem?.isScratched ?? false}
                paymentStatus={currentItem?.paymentStatus ?? null}
              />
            )
          })
        : null}
    </>
  )
}

export default ExhibitorFeesAccordion
