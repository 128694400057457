/* eslint-disable react-hooks/exhaustive-deps */
// Component imports
import ModalComponent from '../events/views/details/global/modalComponent'

import { tags } from '../events/dialogs/create-show-initial/Tags'

// Constants
import { MODAL_CONSTS } from '../../const/modal-const'
import { useEffect, useState } from 'react'
import useToasterHelper from '../../helpers/ToasterHelper'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectEditedEvent,
  selectedEvent,
  setSelectedEventKey,
} from '../../store/events/eventsSlice'
import FirestoreService from '../../services/firestoreService'
import { cloneDeep } from 'lodash'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { AutorenewRounded } from '@mui/icons-material'
import { getConvertedData } from '../../models/interface.helper'
import { EventModel } from '../../models/events/event.model'
import { IEventInterface } from '../../models/events/event.interface'
import helpers from '../../commonHelpers/helpers'
import { validateOldTags } from '../../helpers/validateOldTags'

type IOnSelectionChanged = (args: { tag?: string; added?: boolean; reset?: boolean }) => void

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Selector: React.FC<{
  caption: string
  selected: boolean
  onChange: IOnSelectionChanged
  value: string
}> = ({ caption, selected, onChange, value }) => (
  <div className="displineCheckBoxfilter">
    <input
      className="form-check-input rounded_checkboxes appearance-none h-4 w-4 border border-[#D3DAEE] bg-white checked:bg-blue-600 checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
      type="checkbox"
      name="checkbox"
      checked={selected}
      value={value}
      id={`${caption}Id`}
      onChange={(e) => onChange({ tag: value, added: e.target.checked })}
    />
    <label
      htmlFor={`${caption}Id`}
      className="labelFilter cursor-pointer text-SeabiscuitDark200ThemeColor text-sm"
    >
      {caption}
    </label>
  </div>
)

let maxCount = tags.length / 3
let redundantElemsCount = tags.length % 3
let tempArr: string[] = []
let updatedArr: string[][] = []

while (tempArr.length !== tags.length) {
  if (!tempArr.length && redundantElemsCount) maxCount = maxCount + redundantElemsCount
  else maxCount = tags.length / 3

  let extractedChunk = [...tags].splice(tempArr.length, maxCount)
  updatedArr.push(extractedChunk)
  tempArr = [...tempArr, ...extractedChunk]
}

// Types
type IViewModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const UpdateDisciplineModal = (props: IViewModalProps) => {
  // Hooks and vars
  let mounted = true

  const [loading, setLoading] = useState(false)
  const [eventInformation, setEventInformation] = useState<IEventInterface>(
    new EventModel().toObject()
  )

  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const editedEvent = useAppSelector(selectEditedEvent)
  const eventDetailsInRedux = useAppSelector(selectedEvent).Event as IEventInterface
  useEffect(() => {
    if (eventDetailsInRedux)
      if (mounted)
        setEventInformation({
          ...eventDetailsInRedux,
        } as any)

    return () => {
      mounted = false
    }
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // Functions

  /** @info Closes the modal */
  const closeModal = () => {
    props?.handleModal(false, MODAL_CONSTS.UPDATE_DISCIPLINE)
  }

  /** @info Handles the checkboxes */
  const onEventTagsChangedHandle = (args: {
    tag?: string
    added?: boolean
    reset?: boolean
    selectAll?: boolean
  }) => {
    const { tag, added, reset, selectAll } = args
    const event = EventModel.fromObject(eventInformation).cloneDeep()

    let indexOfTagToRemove = -1
    let clonedTags = cloneDeep(event.tags)

    let preseletedTags = validateOldTags(clonedTags)

    if (selectAll) event.tags = tags
    else if (reset) event.tags = []
    else {
      if (!tag) return
      if (added) {
        event.tags?.push(tag)
        let allCheckedExceptAll = event.tags?.length === tags.length - 1
        allCheckedExceptAll = allCheckedExceptAll && preseletedTags.indexOf('All') === -1

        /** If all checkboxes are checked,
         *  except the checkbox with "All" name then the check all too */
        if (allCheckedExceptAll) event.tags.push('All')
      } else {
        indexOfTagToRemove = preseletedTags.indexOf(tag)
        if (indexOfTagToRemove !== -1) {
          preseletedTags.splice(indexOfTagToRemove, 1)
        }

        if (preseletedTags.length === tags.length - 1 && preseletedTags.indexOf('All') !== -1) {
          /** If "All" tag is checked,
           *  but not all the tags are check then uncheck "All" checkbox */
          preseletedTags = preseletedTags.filter((currTag) => currTag !== 'All')
        }

        event.tags = preseletedTags
      }
    }
    if (mounted) setEventInformation({ ...event })
  }

  /** @info Handles click on tags */
  const handleChange = (data: any, currTag: any) => {
    if (currTag === 'All') {
      if (data.added) onEventTagsChangedHandle({ selectAll: true })
      else return onEventTagsChangedHandle({ reset: true })
    } else onEventTagsChangedHandle(data)
  }

  /** @info Updates the discipline in DB */
  const handleUpdateDiscipline = async () => {
    if (mounted) setLoading(true)

    if (!eventInformation?.tags?.length)
      return toastFunction.error({
        message: 'Please select at least one discipline to be able to update',
      })

    if (props?.dataToPassOn?.eventId) {
      try {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
          props?.dataToPassOn?.eventId,
          new EventModel(eventInformation).toFirestore()
        )

        dispatch(
          setSelectedEventKey({
            key: 'Event',
            value: getConvertedData(eventInformation),
          })
        )

        closeModal()

        toastFunction.success({
          message: MESSAGES_CONST.EVENT_UPDATED,
        })
      } catch (error) {
        helpers.logger({
          isError: true,
          message: error,
        })
        toastFunction.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    } else if (editedEvent)
      try {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
          editedEvent?.id,
          new EventModel(eventInformation).toFirestore()
        )

        dispatch(
          setSelectedEventKey({
            key: 'Event',
            value: getConvertedData(eventInformation),
          })
        )

        closeModal()

        toastFunction.success({
          message: MESSAGES_CONST.EVENT_UPDATED,
        })
      } catch (error) {
        helpers.logger({
          isError: true,
          message: error,
        })
        toastFunction.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    else {
      return toastFunction.error({
        message: 'Something went wrong',
      })
    }
  }

  return (
    <ModalComponent
      show={props.show}
      onHide={closeModal}
      headingClassName={'headingClassName'}
      type={MODAL_CONSTS.UPDATE_DISCIPLINE}
      size="lg"
    >
      <div className="text-xl absolute left-8 top-3 capitalize font-semibold text-SeabiscuitDark200ThemeColor mb-4  mx-2">
        Select Discipline
      </div>

      <div className="discplineSelectionCont mx-2 flex mt-5">
        {updatedArr &&
          updatedArr.map((data, index) => {
            return (
              <div
                className="discplineSelectionCont w-1/3 mx-2"
                key={`discplineSelectionCont${index}`}
              >
                {data.map((currTag, index_) => {
                  return (
                    <Selector
                      key={`currTag${index_}`}
                      caption={currTag}
                      value={currTag}
                      selected={validateOldTags(eventInformation.tags || []).includes(currTag)}
                      onChange={(data) => {
                        handleChange(data, currTag)
                      }}
                    />
                  )
                })}
              </div>
            )
          })}
      </div>

      <div className="text-center">
        <button
          className="text-SeabiscuitMainThemeColor border border-SeabiscuitMainThemeColor mt-8 py-3 px-20 m-auto border-solid rounded-xl w-[225px]"
          onClick={handleUpdateDiscipline}
        >
          {loading === true ? (
            <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
          ) : (
            'Update'
          )}
        </button>
      </div>
    </ModalComponent>
  )
}

export default UpdateDisciplineModal
