import moment from 'moment'
import { IEventFees } from '../../../../models/event-fees/event-fees.interface'
import { TEventDetails } from '../../../../models/event-review-publish/event-review-publish.interface'
import { IEventTicketing } from '../../../../models/event-ticketing/event-ticketing.interface'
import Dates from './assets/DataKey/dates.svg'
import Prize from './assets/DataKey/prize.svg'
import Registrations from './assets/DataKey/registrations.svg'
import UsDollarCircled from './assets/DataKey/usDollarCircled.svg'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CONST } from '../../../../const/const'
import FirestoreService from '../../../../services/firestoreService'
import { EventModel } from '../../../../models/events/event.model'
import { validateOldTags } from '../../../../helpers/validateOldTags'

export const EventDetailViewComponentDataKeyElement: React.FC<{
  logo: any
  title: string
  row1: Array<string | Date | undefined | Array<string>>
  row2?: Array<string | Date | undefined> | undefined
}> = ({ logo, title, row1, row2 }) => {
  const rows = {
    row1: Array.isArray(row1[1])
      ? validateOldTags(row1[1]).join(', ')
      : ((typeof row1[1] === 'object'
          ? new Date(
              (row1[1] as unknown as { nanoseconds: string })?.nanoseconds
            ).toLocaleDateString()
          : row1[1]) ?? 'unknown'),
    ...(row2 && {
      row2:
        (typeof row2[1] === 'object'
          ? new Date(
              (row2[1] as unknown as { nanoseconds: string })?.nanoseconds
            ).toLocaleDateString()
          : row2[1]) ?? 'unknown',
    }),
  }

  return (
    <div className="border border-[#D3DAEE] rounded-lg p-4 2xl:p-5">
      <div className="flex items-center gap-2 flex-wrap mb-2">
        <img src={logo} alt="logo" />
        <h3 className="text-base 2xl:text-lg font-bold text-SeabiscuitDark200ThemeColor">
          {title}
        </h3>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-[150px_auto] gap-1 justify-between">
        <div className="text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor opacity-70 font-semibold">
          {row1[0]?.toString()}:
        </div>
        <div
          title={rows.row1.includes('All') ? 'All' : rows.row1}
          className="truncate text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor opacity-70 xl:text-right"
        >
          {rows.row1.includes('All') ? 'All' : rows.row1}
        </div>
        {rows?.row2 && rows.row2 !== '' && row2 ? (
          <>
            <div className="text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor opacity-70 font-semibold">
              {row2[0]?.toString()}:
            </div>
            <div
              title={rows.row2}
              className="text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor opacity-70 truncate xl:text-right"
            >
              {rows.row2}
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailsViewComponentDataKey: React.FC<{
  EventDetails: TEventDetails
  EventFees: IEventFees
  EventTickets: IEventTicketing
}> = (props) => {
  const getEventLocation = (): string => {
    let separator = ''
    let valueToReturn = ''
    let details = props?.EventDetails ?? {}

    if (details) {
      if (details?.competitionCity && details.competitionCity !== '') {
        valueToReturn += details.competitionCity
        separator = ', '
      } else separator = ''

      if (details?.competitionState && details.competitionState !== '') {
        valueToReturn += separator + details.competitionState
        separator = ', '
      } else separator = ''

      if (details?.competitionCountry && details.competitionCountry !== '') {
        valueToReturn += separator + details.competitionCountry
      }
    }

    return valueToReturn
  }
  const params = useParams()
  const sponsorTicketsRange: null | string = (function () {
    let min: null | number = null
    let max: null | number = null
    let ticketPrice = 0
    props?.EventTickets?.tickets?.map((currTicket) => {
      ticketPrice = Number(currTicket?.cost)
      if (min === null || ticketPrice < min) min = ticketPrice
      if (max === null || ticketPrice > max) max = ticketPrice
    })

    if (!min || !max) return null
    else if (min === max) return `$${min}`
    else return `$${min}-$${max}`
  })()

  const eventLocation = getEventLocation()
  const [tags, setTags] = useState<string[]>([])
  const handlegetevent = async (eventId: any) => {
    if (eventId) {
      const eventSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        eventId
      )

      if (eventSnapshot.exists()) {
        const event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()
        setTags(event.tags)
      }
    }
  }

  useEffect(() => {
    if (params) {
      if ('eventId' in params) {
        if (params.eventId) {
          handlegetevent(params.eventId)
        }
      }
    }
  }, [params])
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  if (Object.keys(props?.EventDetails ?? {}).length) {
    const {
      competitionStartDate,
      competitionEndDate,
      competitionRegistrationOpenDate,
      competitionRegistrationCloseDate,
      competitionRegistrationOpenTime,
      competitionRegistrationCloseTime,
    } = props.EventDetails

    let compStartDate = new Date(competitionStartDate ?? '')
    let compEndDate = new Date(competitionEndDate ?? '')
    let dateStart = `${moment(competitionRegistrationOpenDate).format('MMM D,')} ${competitionRegistrationOpenTime ? Number((competitionRegistrationOpenTime as any)?.hour) : null}${competitionRegistrationOpenTime ? `:${(competitionRegistrationOpenTime as any)?.minutes}` : null} ${competitionRegistrationOpenTime ? (competitionRegistrationOpenTime as any)?.format : null}`
    let dateEnd = `${moment(competitionRegistrationCloseDate).format('MMM D,')} ${competitionRegistrationCloseTime ? Number((competitionRegistrationCloseTime as any)?.hour) : null}${competitionRegistrationCloseTime ? `:${(competitionRegistrationCloseTime as any)?.minutes}` : null} ${competitionRegistrationCloseTime ? (competitionRegistrationCloseTime as any)?.format : null}`

    let sponsorTicketsRangeRemovedDecimal = sponsorTicketsRange?.split('.')[0]

    return (
      <div className="grid grid-2 md:grid-cols-2 gap-2">
        <EventDetailViewComponentDataKeyElement
          logo={Prize}
          title="Event type"
          row1={['Discipline', tags ?? []]}
          row2={['Location', eventLocation]}
        />

        <EventDetailViewComponentDataKeyElement
          logo={UsDollarCircled}
          title="Prices"
          row1={
            sponsorTicketsRange
              ? ['Spectator tix', sponsorTicketsRangeRemovedDecimal]
              : ['Spectator tix', 'Not for sale']
          }
        />

        <EventDetailViewComponentDataKeyElement
          logo={Dates}
          title="Dates"
          row1={['Start', competitionStartDate ? moment(compStartDate).format('MMM D, YYYY') : '']}
          row2={['End', competitionEndDate ? moment(compEndDate).format('MMM D, YYYY') : '']}
        />
        <EventDetailViewComponentDataKeyElement
          logo={Registrations}
          title="Registrations"
          row1={['Open', dateStart]}
          row2={['Close', dateEnd]}
        />
      </div>
    )
  } else {
    return <></>
  }
}
export default EventDetailsViewComponentDataKey
