import React, { useEffect, useRef, useState } from 'react'
import { CONST } from '../../../../const/const'

import { IUiExhibitorConst, UI_EXHIBITOR_CONST } from '../../../../const/ui/ui-exhibitor-const'
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'
import FirestoreService from '../../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../../../models/interface.helper'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import {
  getRecipientThunk,
  resetRecipientAc,
  selectExhibitorDetails,
  selectRecipientR,
  selectRegistrationR,
  setExhibitorDetails,
  setRegistration,
} from '../../../../store/exhibitor/exhibitorSlice'
import { UserModel } from '../../../../models/users/user.model'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { Link } from 'react-router-dom'
import useGetBasicEventDetails from '../../../../hooks/users/common/useGetBasicEventDetails'
import { holdersSliceInitialState } from '../../../../store/holders/holdersSlice.data'
import {
  setScratchEventId,
  setScratchRegistrationId,
  setScratchViewVisibility,
} from '../../../../store/events/eventsSlice'
import helpers from '../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import ExhibitorProfileHeadSection from '../../exhibitorProfile/ExhibitorProfileHeadSection'
import ExhibitorProfileDisplayTabNew from '../../exhibitorProfile/ExhibitorProfileDisplayTabNew'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const SCRATCHABLE = [
  CONST.UI.EXHIBITOR.TABS.ENTRIES.VALUE,
  CONST.UI.EXHIBITOR.TABS.TICKETS.VALUE,
  CONST.UI.EXHIBITOR.TABS.FEES.VALUE,
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const AllTabs = Object.keys(CONST.UI.EXHIBITOR.TABS).map((currTabName, index) => {
  if (UI_EXHIBITOR_CONST.TABS.ENTRIES.VALUE === currTabName) {
    return <></>
  }
  return {
    tab: CONST.UI.EXHIBITOR.TABS[currTabName as keyof IUiExhibitorConst['TABS']].VALUE,
    id: index + 1,
  }
})

interface IProps {
  userId: string
  registrationId: string
  eventId: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}
export const ManageExhibitorProfile: React.FC<IProps> = ({
  userId,
  registrationId,
  eventId,
  handleModal,
}) => {
  const dispatch = useAppDispatch()

  const toastFunctions = useToasterHelper()

  useGetBasicEventDetails(eventId)

  const holderRef = useRef(holdersSliceInitialState)

  const recipientR = useAppSelector(selectRecipientR)
  const holder = useAppSelector((state) => state.holder)
  const exhibitor = useAppSelector(selectExhibitorDetails)
  const registrationR = useAppSelector(selectRegistrationR)
  const [loading, setLoading] = useState(true)

  const [profileLoading, setProfileLoading] = useState(true)
  const [showScratchedView, setShowScratchedView] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(CONST.UI.EXHIBITOR.TABS.ENTRIES.VALUE)

  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  useEffect(() => {
    return () => {
      dispatch(resetRecipientAc())
      dispatch(setExhibitorDetails(null))
    }
  }, [dispatch])

  const getRegistration = async () => {
    setLoading(true)

    let registeredUser: TEventRegisteredUsers | null = null

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      registeredUser = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
    })

    if (registeredUser) dispatch(setRegistration(registeredUser))
    setLoading(false)
  }

  useEffect(() => {
    if (registrationId) {
      dispatch(setScratchEventId(eventId))
      dispatch(setScratchRegistrationId(registrationId))
      getRegistration()
    }
  }, [eventId, registrationId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (userId) {
      setProfileLoading(true)
      getUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const registratioIdNRecipietIdPresent = registrationId && userId
    const recipientIsDifferent = recipientR.data?.recipientId !== userId
    const allowDispatch = registratioIdNRecipietIdPresent && recipientIsDifferent

    if (allowDispatch) {
      dispatch(
        getRecipientThunk({
          recipientId: userId,
          registrationId,
        })
      )
    }
  }, [registrationId, userId, recipientR.data?.recipientId, dispatch])

  // Functions
  const handleScratchView = (visible: boolean) => {
    dispatch(setScratchViewVisibility(visible))
    setShowScratchedView(visible)
  }

  async function getUserData() {
    try {
      const docSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, userId)
      const document = UserModel.fromFirestoreDoc(docSnapshot).toObject()

      if (docSnapshot.exists()) dispatch(setExhibitorDetails(getConvertedData(document)))
    } catch (err) {
      helpers.logger({
        message: err,
      })
    } finally {
      setProfileLoading(false)
    }
  }

  const handleActiveTab = (id: string) => {
    if (showScratchedView)
      return toastFunctions.info({
        message: MESSAGES_CONST.SINGLE_CAN_BE_SCRATCHED,
      })
    else setActiveTab(id)
  }

  return (
    <div className="p-8 rounded-xl bg-SeabiscuitWhiteThemeColor">
      {profileLoading || loading || recipientR.status === 'loading' ? (
        <div className="h-[50vh] min-h-[400px] flex justify-center items-center">
          <ViewsLoader size="lg" color="#F70763" />
        </div>
      ) : (
        <>
          <ExhibitorProfileHeadSection
            backUrl={`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`}
            activeTab={activeTab}
            exhibitor={exhibitor}
            recipient={recipientR.data}
            handleModal={handleModal}
            exhibitorAllData={registrationR}
            showScratchedView={showScratchedView}
            handleScratchView={handleScratchView}
          />
          <div className="my-4 border-t border-solid border-[#D3DAEE]"></div>
          <div className="flex flex-col md:flex-row md:items-center mb-8">
            {AllTabs.map((currentItem: any, index: number) => {
              if (UI_EXHIBITOR_CONST.TABS.CLASSES.VALUE === currentItem.tab) {
                return (
                  <React.Fragment key={`${index}${JSON.stringify(currentItem)}`}></React.Fragment>
                )
              }
              return (
                <div
                  key={`${index}${JSON.stringify(currentItem)}`}
                  onClick={() => handleActiveTab(currentItem.tab)}
                  className={`py-2 px-4 cursor-pointer mr-2 rounded-xl ${activeTab === currentItem.tab ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'}`}
                >
                  {currentItem.tab}
                </div>
              )
            })}
            {!showScratchedView && SCRATCHABLE.includes(activeTab ?? '') ? (
              <div className="flex w-full justify-end">
                <Link
                  to={`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/${eventId}/${userId}`}
                  className="text-center text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] cursor-pointer hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColor/5 text-sm py-2 px-4 rounded-lg w-24 mr-2"
                >
                  Add
                </Link>

                <button
                  type="button"
                  onClick={() => handleScratchView(true)}
                  className="border border-solid ml-2 bg-SeabiscuitGrayThemeColor hover:bg-[#FEF5F6] border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor hover:border-SeabiscuitMainThemeColor cursor-pointer hover:text-SeabiscuitMainThemeColor text-sm py-2 px-4 rounded-lg w-24"
                >
                  Scratch
                </button>
              </div>
            ) : null}
          </div>
          <ExhibitorProfileDisplayTabNew
            userId={userId}
            eventId={eventId}
            registrationId={registrationId}
            exhibitor={exhibitor}
            eventTab={activeTab}
            handleModal={handleModal}
            exhibitorAllData={registrationR}
            showScratchedView={showScratchedView}
            handleScratchView={handleScratchView}
          />
        </>
      )}
    </div>
  )
}
