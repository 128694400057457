import { useMemo, useState } from 'react'

import { AutorenewRounded } from '@mui/icons-material'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

import { useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import ModalComponent from '../../../../../components/events/views/details/global/modalComponent'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { EventRegisteredUsersModel } from '../../../../../models/event-registered-users/event-registered-users.model'
import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import { EventModel } from '../../../../../models/events/event.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import FirestoreService from '../../../../../services/firestoreService'
import { httpService } from '../../../../../services/httpService'
import { setSelectedEventKey } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { calculateRevenueOfSingleRegistration } from '../utils/calculateRevenueOfSingleRegistration'
import { getUserFullName } from '../../../../../helpers/helpers'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type ICloseEventConfirmationModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

export const CloseEventConfirmationModal = (props: ICloseEventConfirmationModalProps) => {
  const selectedEvent = useAppSelector((store) => store).events.selectedEvent
  const registrations = selectedEvent?.EventRegisteredUsers as EventRegisteredUsersModel[]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalRevenue = useMemo(() => calculateRevenue(registrations ?? []), [registrations])
  function calculateRevenue(registrations: EventRegisteredUsersModel[]) {
    let revenue = registrations?.reduce((totalRevenue: number, currRegistration) => {
      totalRevenue += calculateRevenueOfSingleRegistration(
        currRegistration,
        selectedEvent.basicEventDetails.registrationPrice ?? 0
      )
      return totalRevenue
    }, 0)

    return revenue
  }
  // Hooks and vars
  const router = useIonRouter()
  const history = useIonRouter()
  const dispatch = useDispatch()
  const toastMethods = useToasterHelper()
  const profileDetails = useAppSelector((state) => state.user.profileDetails)

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<'primary' | 'secondary'>('primary')

  const { headingClassName, eventId, eventAlreadyClosed } = props?.dataToPassOn

  // Functions
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Send Email
   */
  const sendEmailtoTeam = async () => {
    try {
      const res: any = await httpService({
        url: 'sendEmails',
        method: 'POST',
        data: {
          mail_type: 'cancel_event',
          mail_data: {
            owner_name: getUserFullName(profileDetails),
            event_id: eventId,
          },
        },
      })

      if (res?.status) {
        return true
      }
    } catch (err) {
      return false
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeEvent = async () => {
    try {
      setLoading(true)

      if (!eventId)
        return toastMethods.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      const eventSnap = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)

      const publishSnap = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (!eventSnap.exists() || !publishSnap.exists())
        return toastMethods.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      const event = EventModel.fromFirestoreDoc(eventSnap).cloneDeep()
      const publishedEvent = EventReviewPublishModel.fromFirestoreDoc(publishSnap).cloneDeep()

      event.status = eventAlreadyClosed
        ? COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
        : COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST

      publishedEvent.status = eventAlreadyClosed
        ? (COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT as any)
        : (COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST as any)

      await FirestoreService.updateItem(COLLECTIONS.EVENTS.NAME, eventId, {
        status: event.status,
      })

      await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
        status: publishedEvent.status,
      })

      if (!eventAlreadyClosed) {
        await sendEmailtoTeam()
      }

      router.push(
        `${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST}`
      )
      history.push(
        `${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST}`
      )

      dispatch(
        setSelectedEventKey({
          key: 'EventDetails',
          value: getConvertedData(event),
        })
      )

      setStep('secondary')
    } catch (error) {
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = async (cancel: 'closeClicked' | 'cancelClicked' | 'closeEventBtnClicked') => {
    if (cancel === 'closeEventBtnClicked') {
      props?.handleModal(true, MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION)
      await closeEvent()
      return
    }

    if (cancel === 'cancelClicked') {
      props?.handleModal(false, MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION)
      props?.handleModal(true, MODAL_CONSTS.MANAGE_EVENT_MODAL)
      return
    }

    props?.handleModal(false, MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION)
  }

  const handleTransferFunds = () => {
    closeModal('closeClicked')
    router.push(CONST.ROUTES.ACCOUNT_SETTINGS.URL, 'none', 'push', {
      as: 'Finance',
    })
    history.push(CONST.ROUTES.ACCOUNT_SETTINGS.URL)
  }

  return (
    <ModalComponent
      size="sm"
      show={true}
      fullScreen={true}
      onHide={() => closeModal('closeClicked')}
      headingClassName={headingClassName ?? ''}
      modaltopclassname={clsx(
        'md:max-h-[450px] h-[500px] pb-0',
        step === 'secondary' && '!min-h-fit'
      )}
      type={MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION}
    >
      <div className={'flex items-center flex-col max-h-[56vh] overflow-auto'}>
        {step === 'primary' ? (
          <>
            {eventAlreadyClosed ? (
              <div className="w-full relative flex justify-center items-center mb-3">
                <img
                  src="/assets/modals/closeEventIcon.svg"
                  alt="close event"
                  className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
                />
              </div>
            ) : (
              <div className="w-full relative flex justify-center items-center mb-3">
                <img
                  src="/assets/modals/closeEventIcon-1.svg"
                  alt="close event"
                  className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
                />
                <div className="flex flex-col absolute items-center">
                  <span className="text-SeabiscuitMainThemeColor text-[33px]">${totalRevenue}</span>
                  <span className="text-SeabiscuitMainThemeColor text-[13px]">Closing Balance</span>
                </div>
              </div>
            )}
            <span className="font-extrabold text-xl text-SeabiscuitDark200ThemeColor mb-2">
              {eventAlreadyClosed ? 'Open event?' : 'Close and save event?'}
            </span>

            <div className="text-center text-sm xl:text-[13px] 2xl:text-base text-SeabiscuitDark200ThemeColor ">
              {eventAlreadyClosed ? (
                <>
                  Opening the event will enable you to edit the event as if you never closed it.{' '}
                  <br />
                  <br />
                  This includes changing results, processing refunds, creating new reports, etc.{' '}
                  <br />
                  <br />
                  This event will move to ‘Current’ events in your dashboard. <br />
                  <br />
                </>
              ) : (
                <>
                  Click ‘Close & Save’ to officially end this event. <br />
                  <br />
                  The closing balance will be saved to your profile. You can transfer these funds to
                  your bank account at any time in your settings.
                  <br />
                  <br />
                  After closing this event, participants will still be able to make payments, sign
                  paperwork etc. <br /> <br />
                  You however will not be able to update event details such as edit results and
                  process refunds. <br /> <br />
                  You can reopen this event at any time in your event dashboard under ‘Past events’.{' '}
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className="w-[90%] bottom-5 absolute">
              <button
                type="button"
                onClick={() => closeModal('closeEventBtnClicked')}
                className="w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-0  capitalize"
              >
                {loading ? (
                  <AutorenewRounded className="animate-spin" />
                ) : eventAlreadyClosed ? (
                  'Open Event'
                ) : (
                  'Close & Save Event'
                )}
              </button>
              <button
                type="button"
                onClick={() => closeModal('cancelClicked')}
                className="w-full h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor capitalize"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full relative flex justify-center items-center mb-3">
              {eventAlreadyClosed ? (
                <img
                  src="/assets/modals/closeEventIcon.svg"
                  alt="close event"
                  className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
                />
              ) : (
                <div className="w-full relative flex justify-center items-center mb-3">
                  <img
                    src="/assets/modals/closeEventIcon.svg"
                    alt="close event"
                    className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
                  />
                </div>
              )}
            </div>
            <span className="font-extrabold text-xl text-SeabiscuitDark200ThemeColor mb-10 text-center">
              Event successfully saved
              <br />
              and {eventAlreadyClosed ? 'opened' : 'closed'}
            </span>

            {!eventAlreadyClosed && (
              <div className="w-[90%] bottom-5 absolute">
                <button
                  type="button"
                  onClick={() => handleTransferFunds()}
                  className="w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-0  capitalize"
                >
                  {loading ? <AutorenewRounded className="animate-spin" /> : 'Transfer funds'}
                </button>
                <button
                  type="button"
                  onClick={() => closeModal('cancelClicked')}
                  className="w-full h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor capitalize"
                >
                  Close
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </ModalComponent>
  )
}
