import { useParams } from 'react-router'
import { cloneDeep } from 'lodash'
import { v4 } from 'uuid'

import { useAppSelector } from '../../../../../store/hooks'
import {
  selectPublishedEventInDb,
  selectRegistration,
} from '../../../../../store/registration/registrationSlice'

import {
  IRegisterTab,
  IRegisterTabItem,
} from '../../../../../models/event-registered-users/event-registered-users.interface'
import { ITeamMember } from '../../../../../models/users/user.interface'
import { IManageInfo } from '../../../event-registration-tabs/EventRegistrationTabs'
import { getConvertedData } from '../../../../../models/interface.helper'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import ITypes, {
  ISaveRegisterTabDataFnArgs,
} from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'

import helpers from '../../../../../commonHelpers/helpers'
import { CustomError } from '../../../../../helpers/helpers'

import { MESSAGES_CONST } from '../../../../../const/messages-const'

type IOnRegistrationMemberChangeFnArgs = {
  currentRow: IRegisterTab
  member: ITeamMember | null
  /** @info If remove is true then `idOfMemberToRemove` will have id in it that needs to be removed */
  isRemove: boolean
  removedIndex?: number
  /** @info Id of the member this could be to delete to add as per `remove` arg */
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  isChildren?: boolean
}

const FILE_NAME = 'useRegisterTab.tsx'

interface useRegisterTabProps {
  saveRegisterTabData: (args: ISaveRegisterTabDataFnArgs) => Promise<ISaveRegisterTabDataFnArgs>
}
const useRegisterTab = ({ saveRegisterTabData }: useRegisterTabProps) => {
  const { eventId, userId } = useParams<ITypes['IParams']>()

  const publishedEventInDb = useAppSelector(selectPublishedEventInDb)
  const registration = useAppSelector(selectRegistration)

  const onRegistrationMemberChange = async ({
    currentRow,
    member,
    isRemove,
    removedIndex,
    isManage,
    manageInfo,
    setManageInfo,
    isChildren,
  }: IOnRegistrationMemberChangeFnArgs) => {
    if (!publishedEventInDb) return null

    const currentMember: IRegisterTab = cloneDeep(currentRow)
    const { EventDetails, EventFees } = publishedEventInDb

    try {
      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        EventDetails,
        member,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          fileName: FILE_NAME,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          moduleName: 'onRegistrationMemberChange',
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        })
      }

      if (!isRemove) {
        currentMember.registrationsByDay.push(
          getConvertedData({
            ...new RegistrationByDayModel({
              userId,
              eventDate: EventDetails.competitionStartDate,
              horseId: null,
              refundId: null,
              horseName: null,
              recipientId: null,
              backNumber: 0,
              score: {
                rank: null,
                totalPoints: null,
                sheet: null,
                judges: [],
                details: null,
                file: '',
              },
              order: currentMember.order ?? 0,
              refundDocId: null,
              amountRefunded: 0,
              isScratched: false,
              refundStatus: null,
              amountScratched: 0,
              isPaidByOwner: true,
              paymentStatus: 'pending',
              horseProfilePicture: null,
              eventId,
              userHorseMappingDocId: null,
              recipientProfilePicture: null,
              riderId: member?.memberId ?? null,
              riderDob: member?.memberDob ?? null,
              riderRole: member?.memberRole ?? null,
              riderName: member?.memberName ?? null,
              riderEmail: member?.memberEmail ?? null,
              registrationDocId: registration?.id ?? null,
              eventName: EventDetails?.competitionName ?? null,
              riderProfilePicture: member?.memberProfilePicture,
              registrationPrice: currentRow.price ?? 0,
              qualifyFee: currentRow?.qualifyFee ?? null,
              qualifyFeeName: currentRow?.qualifyFeeName || null,
              breakTime: EventFees.rideTimeRequirement?.minutes ?? null,
              registrationByDayName: currentRow.registrationByDayName,
              uuid: currentMember.uuid,
              uniqId: `${v4()}-${Date.now()}`,
            }).toObject(),
          })
        )
      }

      if (isManage) {
        const registerTabData = cloneDeep(manageInfo.register)
        let rowIndex = -1

        if (isChildren) {
          let byDayIndex = -1

          registerTabData.forEach((parent, rowIdx) => {
            parent?.children?.forEach((child, byDayIdx) => {
              if (child.uuid === currentMember.uuid) {
                rowIndex = rowIdx
                byDayIndex = byDayIdx
              }
            })
          })

          if (rowIndex !== -1 && byDayIndex !== -1) {
            const child = (registerTabData[rowIndex].children as IRegisterTabItem[])[byDayIndex]
            if (isRemove && removedIndex !== undefined) {
              child.registrationsByDay = [
                ...child.registrationsByDay.slice(0, removedIndex),
                ...child.registrationsByDay.slice(removedIndex + 1),
              ]
            } else {
              child.registrationsByDay = currentMember.registrationsByDay
            }
          }
        } else {
          rowIndex = registerTabData.findIndex((parent) => parent.uuid === currentMember.uuid)

          const row = registerTabData[rowIndex]

          if (isRemove && removedIndex !== undefined) {
            row.registrationsByDay = [
              ...currentMember.registrationsByDay.slice(0, removedIndex),
              ...currentMember.registrationsByDay.slice(removedIndex + 1),
            ]
          } else {
            row.registrationsByDay = currentMember.registrationsByDay
          }
        }

        setManageInfo({ ...manageInfo, register: registerTabData })
      } else {
        await saveRegisterTabData({
          ...(isRemove &&
            removedIndex !== undefined && {
              registrationsByDayToDelete: [cloneDeep(currentRow.registrationsByDay[removedIndex])],
            }),
          ...(!isRemove && {
            registrationsByDayToAdd: cloneDeep(currentMember.registrationsByDay.slice(-1)),
          }),
        })
      }
    } catch (error: any) {
      helpers.logger({
        message: `${error?.message} in ${FILE_NAME}`,
      })
    }
  }

  return { onRegistrationMemberChange }
}

export default useRegisterTab
