import { useState } from 'react'
//wrapper
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

import { ClassesList } from '../../../../fakeData/classesList'
import './competitorEventRegisterClassesTab.css'
import { ArrowForwardIos } from '@mui/icons-material'
import _ from 'lodash'

type Props = {
  title?: string
  description?: string
}

interface ISubClass {
  subClassName: string
  classTag: number
  fees: string
  code: string
}

interface ISubCategories {
  code: number
  date: string
  subClasses: ISubClass[]
}

interface ICategories {
  categoryName: string
  code: number
  subCategories: ISubCategories[]
}

interface ICategory {
  key?: string
  category: ICategories[]
}

type dataGot = {
  category: ICategory
  categoryItem: ICategories
  subCategories: ISubCategories
  subCategoryItem: ISubClass
}

const CompetitorEventRegisterClassesTab = (props: Props) => {
  const [selectedClasses, setSelectedClasses] = useState<ICategory[]>([])

  const handleSelectClasses = (checked: boolean, allData: any) => {
    const { data, indexValue } = allData
    const { category, categoryItem, subCategories, subCategoryItem } = data as dataGot
    const { subCategoryIndex, subCategoryItemIndex } = indexValue

    const addNewKey = () => {
      let dataToSet, selected_classes

      dataToSet = {
        ...categoryItem,
        subCategories: [
          {
            ...categoryItem.subCategories[subCategoryIndex],
            subClasses: [
              categoryItem.subCategories[subCategoryIndex]?.subClasses[subCategoryItemIndex],
            ],
          },
        ],
      }

      selected_classes = [
        {
          ...category,
          category: [dataToSet],
        },
      ]

      setSelectedClasses([...selectedClasses, ...selected_classes])
    }

    const appendDataToAKey = () => {
      // Creating copy of the curr selected class list
      const selectedClassArrCopy = [...selectedClasses]

      // Gets main class like equition, hunter etc's index
      let category_index_s,
        sub_category_index_s,
        // sub_class_index_s,
        sub_cat_item_index_s,
        sub_most_class_index_s,
        arr_backup

      // Category eg: equitation, hunter jumper
      selectedClassArrCopy.forEach((curr, index) => {
        if (curr?.key === category.key) category_index_s = index
      })

      // Category items like items of equitation etc
      if (_.isNumber(category_index_s)) {
        arr_backup = selectedClassArrCopy[category_index_s]
        selectedClassArrCopy[category_index_s].category.forEach((curr, index) => {
          if (curr?.code === categoryItem.code) sub_category_index_s = index
        })

        if (_.isNumber(sub_category_index_s)) {
          arr_backup = selectedClassArrCopy[category_index_s].category
          // arr_backup.forEach((curr, index) => {
          //     if (curr?.code === categoryItem.code)
          //         sub_class_index_s = index
          // })

          if (_.isNumber(sub_category_index_s)) {
            arr_backup =
              selectedClassArrCopy[category_index_s].category[sub_category_index_s].subCategories

            let addCategory = !arr_backup.filter((curr, index) => {
              if (curr.code === subCategories.code) sub_cat_item_index_s = index
              return curr.code === subCategories.code
            }).length

            if (addCategory) {
              arr_backup = [...arr_backup, { ...subCategories, subClasses: [subCategoryItem] }]
              selectedClassArrCopy[category_index_s].category[sub_category_index_s].subCategories =
                [...arr_backup]
              setSelectedClasses([...selectedClassArrCopy])
              return
            }

            //  ISubCategories
            if (_.isNumber(sub_cat_item_index_s)) {
              let arr_backup = selectedClassArrCopy[category_index_s].category[sub_category_index_s]
                .subCategories[sub_cat_item_index_s] as ISubCategories

              let addClass = !arr_backup.subClasses.filter((curr, index) => {
                if (curr.code === subCategoryItem.code) sub_most_class_index_s = index
                return curr.code === subCategoryItem.code
              }).length

              // Remove class
              if (_.isNumber(sub_most_class_index_s)) {
                arr_backup.subClasses.splice(sub_most_class_index_s as number, 1)
              }
              // Add class
              else if (addClass) {
                arr_backup.subClasses = [...arr_backup.subClasses, subCategoryItem]
              }

              if (arr_backup?.subClasses?.length === 0) {
                selectedClassArrCopy[category_index_s].category[
                  sub_category_index_s
                ].subCategories.splice(sub_cat_item_index_s, 1)

                if (
                  selectedClassArrCopy[category_index_s].category[sub_category_index_s]
                    .subCategories.length === 0
                ) {
                  selectedClassArrCopy[category_index_s].category.splice(sub_category_index_s, 1)

                  if (selectedClassArrCopy[category_index_s].category.length === 0) {
                    selectedClassArrCopy.splice(category_index_s, 1)
                  }
                }
              }

              setSelectedClasses([...selectedClassArrCopy])
            }
          }
        } else {
          selectedClassArrCopy[category_index_s].category = [
            ...selectedClassArrCopy[category_index_s].category,
            {
              ...categoryItem,
              subCategories: [
                {
                  ...subCategories,
                  subClasses: [subCategoryItem],
                },
              ],
            },
          ]
          setSelectedClasses([...selectedClassArrCopy])
        }
      }
    }

    if (!selectedClasses.length) addNewKey()
    else {
      const overwrite = selectedClasses.filter((curr) => {
        return curr.key === category.key
      }).length

      if (overwrite === 0) return addNewKey()
      appendDataToAKey()
    }
  }

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div className="col">
        <div className="tabs">
          {ClassesList.map((category: any, categoryIndex: number) => {
            return (
              <>
                <div key={categoryIndex}>
                  <h3 className="text-SeabiscuitDark200ThemeColor my-4 font-semibold capitalize">
                    {category.key}
                  </h3>
                  {category.category.map((categoryItem: any, categoryItemIndex: number) => {
                    return (
                      <>
                        <div key={100 + categoryItemIndex} className="tab mt-2">
                          <input
                            type="checkbox"
                            className="MainCheckbox"
                            id={`${categoryIndex}${categoryItemIndex}`}
                          />
                          <label
                            className="tab-label border border-[#122B4633] rounded-md mb-2"
                            htmlFor={`${categoryIndex}${categoryItemIndex}`}
                          >
                            <div className="accordionLable">
                              <span className="font-normal text-sm mr-4">{categoryItem.code}</span>
                              <span className="font-normal text-sm mr-4">{categoryItem.name}</span>
                            </div>
                          </label>

                          <div className="tab-content">
                            {categoryItem.subCategories.map(
                              (subCategories: any, subCategoryIndex: number) => {
                                return (
                                  <>
                                    <div
                                      key={subCategoryIndex + 100}
                                      className="flex justify-between items-center mb-2"
                                    >
                                      <div className="subCategoryLabel flex items-center">
                                        <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold mr-2">
                                          {subCategories.code}
                                        </p>
                                        <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold">
                                          {subCategories.name}
                                        </p>
                                      </div>
                                      <div className="subCategoryDetail flex category-center">
                                        {subCategories.date && (
                                          <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center mr-4">
                                            <img
                                              className="mr-1"
                                              src="/assets/og_icons/Tear-Off Calendar-1.svg"
                                              alt="calender"
                                            />
                                            {subCategories.date}
                                          </p>
                                        )}

                                        {subCategories.messurement && (
                                          <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center mr-4">
                                            <img
                                              className="mr-1"
                                              src="/assets/og_icons/Ruler Vertical-2.svg"
                                              alt="calender"
                                            />
                                            {subCategories.messurement}
                                          </p>
                                        )}

                                        {subCategories.judge && (
                                          <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center mr-4">
                                            <img
                                              className="mr-1"
                                              src="/assets/og_icons/Law-1.svg"
                                              alt="calender"
                                            />
                                            {subCategories.judge}
                                          </p>
                                        )}

                                        {subCategories.totalClasses && (
                                          <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center mr-4">
                                            <img
                                              className="mr-1"
                                              src="/assets/img/accordions/ClipboardList.png"
                                              alt="calender"
                                            />
                                            {subCategories.totalClasses} category
                                          </p>
                                        )}

                                        {subCategories.minMaxFees && (
                                          <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center mr-4">
                                            <img
                                              className="mr-1"
                                              src="/assets/og_icons/Us Dollar Circled-1.svg"
                                              alt="calender"
                                            />
                                            {subCategories.minMaxFees}
                                          </p>
                                        )}

                                        <p className="text-SeabiscuitDark200ThemeColor text-sm font-semibold flex items-center underline mr-2 cursor-pointer">
                                          More{' '}
                                          <ArrowForwardIos className="text-SeabiscuitDark200ThemeColor arrowBtn" />
                                        </p>
                                      </div>
                                    </div>
                                    {subCategories.subClasses.map(
                                      (subCategoryItem: any, subCategoryItemIndex: number) => {
                                        return (
                                          <>
                                            <label
                                              key={`${categoryIndex}${categoryItemIndex}${subCategoryIndex}${subCategoryItemIndex}`}
                                              htmlFor={`${categoryIndex}${categoryItemIndex}${subCategoryIndex}${subCategoryItemIndex}`}
                                              onClick={(e: any) =>
                                                e.target.classList.toggle(
                                                  'bg-SeabiscuitGrayThemeColor'
                                                )
                                              }
                                              className="flex border border-[#122B4633] justify-between p-2 m-2 rounded-md text-SeabiscuitDark200ThemeColor text-xs"
                                            >
                                              <div className="flex items-center">
                                                <p className="text-SeabiscuitDark200ThemeColor xl:text-xs xxl:text-sm mr-2">
                                                  {subCategoryItem.subClassName}
                                                </p>
                                              </div>
                                              <div className="flex items-center">
                                                <p className="text-SeabiscuitDark200ThemeColor xl:text-xs xxl:text-sm mr-2 w-28">
                                                  {subCategoryItem.classTag}
                                                </p>

                                                <p className="text-SeabiscuitDark200ThemeColor xl:text-xs xxl:text-sm mr-2 w-10">
                                                  ${subCategoryItem.fees}
                                                </p>

                                                <span className="w-10 text-center">
                                                  <input
                                                    id={`${categoryIndex}${categoryItemIndex}${subCategoryIndex}${subCategoryItemIndex}`}
                                                    onClick={(e: any) =>
                                                      handleSelectClasses(e.target.checked, {
                                                        data: {
                                                          category,
                                                          categoryItem,
                                                          subCategories,
                                                          subCategoryItem,
                                                        },
                                                        indexValue: {
                                                          categoryIndex,
                                                          categoryItemIndex,
                                                          subCategoryIndex,
                                                          subCategoryItemIndex,
                                                        },
                                                      })
                                                    }
                                                    className="focus:ring-0 focus:ring-offset-0 rounded-full checked:bg-SeabiscuitMainThemeColor checked:focus:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor checked:hover:bg-SeabiscuitMainThemeColor cursor-pointer"
                                                    type="checkbox"
                                                  />
                                                </span>
                                              </div>
                                            </label>
                                          </>
                                        )
                                      }
                                    )}
                                  </>
                                )
                              }
                            )}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterClassesTab
