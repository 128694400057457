import { useContext, useEffect, useRef } from 'react'

// Third party
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

// Constants
import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'
import FIREBASE_CONST from '../../const/firebase-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { EVENT_DOC_TYPES_CONST } from '../../pages/shows/detail/ShowsDetailPageEventDetailFormSubSection'
import { Clear } from '@mui/icons-material'

// Custom hooks
import useQueue from '../../hooks/useQueue'
import useToasterHelper from '../../helpers/ToasterHelper'
import { HandleModalContext, IHandleModal } from '../../layout/mainlayout/MainLayout'

// Redux
import { useAppDispatch } from '../../store/hooks'
import { setFilesToUpload } from '../../store/files/filesSlice'
import { PROFILE_IMAGE_TYPES_CONST } from '../user/views/profile/CompetitorProfileRootPageProfileTab'
import helpers from '../../commonHelpers/helpers'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const EVENT_VIDEO_STORAGE_PATH = CONST.DATA.STORAGE.EVENTS.EVENT_VIDEO.PREFIX
const EVENT_IMAGES_STORAGE_PATH = CONST.DATA.STORAGE.EVENTS.EVENT_IMAGES.PREFIX
const ENDORSEMENT_LETTER_STORAGE_PATH = CONST.DATA.STORAGE.USERS.ENDORSEMENT_URL.PREFIX

const STORAGE_PATHS = {
  [EVENT_DOC_TYPES_CONST.COVER_IMAGE]: EVENT_IMAGES_STORAGE_PATH,
  [EVENT_DOC_TYPES_CONST.UPLOAD_VIDEO]: EVENT_VIDEO_STORAGE_PATH,
  [EVENT_DOC_TYPES_CONST.EVENT_IMAGES]: EVENT_IMAGES_STORAGE_PATH,
  [EVENT_DOC_TYPES_CONST.COURSE_MAP_IMAGES]: EVENT_IMAGES_STORAGE_PATH,
  [PROFILE_IMAGE_TYPES_CONST.FOREIGN_ENDORESEMENT_LETTER]: ENDORSEMENT_LETTER_STORAGE_PATH,
}
const ALLOWED_EXTS = {
  IMAGE: ['image/jpeg', 'image/png', 'image/jpg'],
  VIDEO: ['video/mp4', 'video/3gpp', 'video/quicktime'],
  PDF: ['application/pdf'],
}

const titles = {
  [EVENT_DOC_TYPES_CONST.COVER_IMAGE]: 'banner',
  [EVENT_DOC_TYPES_CONST.EVENT_IMAGES]: 'images',
  [EVENT_DOC_TYPES_CONST.UPLOAD_VIDEO]: 'videos',
  [EVENT_DOC_TYPES_CONST.COURSE_MAP_IMAGES]: 'course map',
  [PROFILE_IMAGE_TYPES_CONST.FOREIGN_ENDORESEMENT_LETTER]: 'endoresement letters',
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Types
type Props = {
  show?: boolean
  handleModal?: IHandleModal['handleModal']
  dataToPassOn: {
    type: string
    id: string | null
    dontShowMessage: boolean
    setter?: (key: string, imageUrls: string[]) => void
  }
}

const UploadEventImagesModal = (props: Props) => {
  // Hooks and vars
  const handleModalContext = useContext(HandleModalContext)
  const dataToPassOn = props.dataToPassOn
  const setter = dataToPassOn.setter

  let allowedFileExtensions: any = {
    [EVENT_DOC_TYPES_CONST.COVER_IMAGE]: ALLOWED_EXTS.IMAGE,
    [EVENT_DOC_TYPES_CONST.EVENT_IMAGES]: ALLOWED_EXTS.IMAGE,
    [EVENT_DOC_TYPES_CONST.UPLOAD_VIDEO]: ALLOWED_EXTS.VIDEO,
    [EVENT_DOC_TYPES_CONST.COURSE_MAP_IMAGES]: ALLOWED_EXTS.IMAGE,
    [PROFILE_IMAGE_TYPES_CONST.FOREIGN_ENDORESEMENT_LETTER]: ALLOWED_EXTS.IMAGE,
  }

  allowedFileExtensions = allowedFileExtensions[dataToPassOn?.type]

  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const queue = useQueue({ allowedFileExtensions, keep: true })
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    if (!queue.loading && queue.files.length)
      setter?.(
        dataToPassOn.type,
        queue.files.map((currFile) => currFile.downloadUrl)
      )
  }, [dataToPassOn.type, queue.files, queue.loading, setter])

  // Functions

  /** Uploads file to firestore storage */
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataToPassOn.id && dataToPassOn.type) {
      queue.enqueueMultiplefiles(e.target.files, STORAGE_PATHS[dataToPassOn.type])
    } else {
      toastFunctions.error({ message: 'Invalid data was sent while upload' })
    }
  }

  /** Removes a uploaded image */
  const removeFile = async (currFileIndex: number) => {
    queue.removeQueueElement(currFileIndex).catch((error) => {
      if (error.code === FIREBASE_CONST.FILE_NOT_FOUND) {
        helpers.logger({
          isError: true,
          message: error,
        })
        toastFunctions.error({
          message:
            MESSAGES_CONST.UNABLE_TO_REMOVE_FILE + ', ' + MESSAGES_CONST.FILE_ALREADY_REMOVED,
        })
      }
    })
  }

  // Closes the modal
  const closeModal = (saved: boolean) => {
    if (saved && dataToPassOn?.dontShowMessage !== true) {
      toastFunctions.success({
        message: `${capitalizeFirstLetter(titles?.[dataToPassOn?.type])} saved successfully`,
      })
    }

    inputRef.current.value = ''

    dispatch(
      setFilesToUpload({
        type: dataToPassOn.type,
        id: handleModalContext?.dataToPassOn?.eventId,
        downloadUrls: queue.files.map((currFile) => currFile.downloadUrl),
      })
    )

    props.handleModal?.(false, MODAL_CONSTS.UPLOAD_EVENT_IMAGES)
  }

  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative w-full lg:w-1/2 xl:w-1/2 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 transition-all">
            {!queue.loading ? (
              <span
                onClick={() => closeModal(false)}
                className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
              >
                {/* <img
                                    src="/assets/og_icons/Close.svg"
                                    className="w-6"
                                    alt="close icon"
                                /> */}
                <Clear
                  fontSize={'small'}
                  style={{
                    color: 'grey',
                    fontWeight: '400',
                  }}
                />
              </span>
            ) : null}

            <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
              <h5
                className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                id="exampleModalScrollableLabel"
              >
                Add {titles?.[dataToPassOn?.type]}
              </h5>
            </div>

            <div
              id="drag_drop_area"
              className="bg-SeabiscuitMainThemeColor/5 p-4 relative rounded-md border border-dashed border-SeabiscuitMainThemeColor text-center"
            >
              <img src="/assets/img/redCloud.png" className="m-auto w-24" alt="cloudIcon" />
              <p className="text-SeabiscuitMainThemeColor">
                Drag & Drop{' '}
                <span className="capitalize pr-[3px]">{titles?.[dataToPassOn?.type]}</span>
                Here
              </p>
              <p className="text-SeabiscuitMainThemeColor">OR</p>
              <div className="text-SeabiscuitWhiteThemeColor bg-SeabiscuitMainThemeColor w-fit p-2 rounded-md relative m-auto cursor-pointer mt-2">
                {queue.files.length ? 'Browse More Files' : 'Browse Files'}
              </div>
              <input
                ref={inputRef}
                disabled={queue.loading}
                type="file"
                title={queue.loading ? 'Processing Files' : ''}
                {...(dataToPassOn?.type !== EVENT_DOC_TYPES_CONST.COVER_IMAGE && {
                  multiple: true,
                })}
                accept={allowedFileExtensions?.join(', ')}
                className="absolute w-full h-full opacity-0 cursor-pointer left-0 top-0"
                onChange={handleFileUpload}
              />
            </div>
            {queue.files.length ? (
              <div className="mt-4">
                <p className="text-SeabiscuitDark200ThemeColor text-[16px]">Uploaded Files</p>
                {queue.files.map((currFile, currFileIndex) => {
                  return (
                    <div
                      key={`${JSON.stringify(currFile)}${currFileIndex}`}
                      className={`flex justify-between rounded-md border border-[#D3DAEE] mt-2 items-center ${currFile?.progress < 100 ? 'animate-pulse' : ''}`}
                    >
                      <div className="w-[50px] h-[50px] rounded-md m-2 border border-[#D3DAEE] border-solid overflow-hidden shadow-md">
                        <img
                          className="w-full h-full object-cover"
                          src={
                            titles?.[dataToPassOn?.type] === 'videos'
                              ? '/assets/placeholders/video.svg'
                              : currFile.fileType !== 'pdf' &&
                                  currFile?.base64Src !== null &&
                                  currFile?.base64Src !== ''
                                ? (currFile?.base64Src as string)
                                : '/assets/img/redCloud.png'
                          }
                          alt="fileImage"
                        />
                      </div>
                      <div className="flex flex-col w-[calc(100%-120px)]">
                        <div className="text-left flex justify-between mb-2 mr-3">
                          <p className="text-SeabiscuitDark200ThemeColor text-[16px] w-[80%] truncate">
                            {currFile?.file?.name}
                          </p>
                          <p className="text-gray-400 text-[16px] transition-all">
                            {currFile?.progress ?? '0'}%
                          </p>
                        </div>
                        <div className="text-left">
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                              className={`${currFile?.progress === 100 ? 'bg-[#00B6AA]' : 'bg-SeabiscuitMainThemeColor'} h-2.5 rounded-full transition-all`}
                              style={{
                                width: `${currFile?.progress ?? 0}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/1 ml-4">
                        <CancelOutlinedIcon
                          className={`${currFile?.progress === 100 ? 'text-[#00B6AA]' : 'text-SeabiscuitMainThemeColor'} mr-2 cursor-pointer ${queue.loading ? 'pointer-events-none' : ''}`}
                          onClick={() => removeFile(currFileIndex)}
                        />
                      </div>
                    </div>
                  )
                })}

                {!queue.loading && (
                  <div className="flex justify-end mt-3 " onClick={() => closeModal(true)}>
                    <button className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor border-solid saveBtn">
                      Save
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadEventImagesModal
