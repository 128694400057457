import { useIonRouter } from '@ionic/react'
import { useContext } from 'react'
import { useParams } from 'react-router'

// Third party
import { Skeleton, Tooltip } from '@mui/material'
import clsx from 'clsx'

// Component
import InfiniteScrollDataTable from '../../../../../../components/common/tables/InfiniteScrollDataTable'
import TooltipIcon from '../../../../../../helpers/TooltipIcon'
import NoDataAvailable from '../NoDataAvailable'

// Styles
import { customStyles } from '../../../../../allEvents/root/AllEventsTableStyles'
import { tableStyles } from '../tableStyles'

// Types
import { IExhibitorDataTypes } from './data/exhibitor.data.types'

// Firestore

// Helpers
import FirestoreService from '../../../../../../services/firestoreService'

// Hooks
import useToasterHelper from '../../../../../../helpers/ToasterHelper'

// Models
import { EventRegisteredUsersModel } from '../../../../../../models/event-registered-users/event-registered-users.model'

// Constants
import { ROUTES_CONST } from '../../../../../../const/routes-const'
import { MANAGE_CLINIC_EXHIBITOR_CONSTS } from './data/exhibitor.data.const'

// Third party
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'

import helpers from '../../../../../../commonHelpers/helpers'
import { IRegistrationExtended } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import {
  organizerReducers,
  organizerSliceAcs,
} from '../../../../../../store/organizer/organizerSlice'
import { Link, useHistory } from 'react-router-dom'

import './Exhibitor.css'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
import { CONST } from '../../../../../../const/const'
const UNKNOWN = 'Unknown'
const MCEC = MANAGE_CLINIC_EXHIBITOR_CONSTS
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const ExhitiborAllList = (props: IExhibitorDataTypes['IExhitiborAllListProps']) => {
  const handleModalContext = useContext(HandleModalContext)

  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const toastMethods = useToasterHelper()
  const handleModal = useContext(HandleModalContext)?.handleModal
  const exhibitors = useAppSelector(organizerReducers.exhibitors)

  const { isFullScreen = false } = props
  const { eventId } = useParams<{ eventId?: any }>()

  const columns = [
    {
      label: 'PROFILE',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{MCEC.COLS.PROFILE}</span>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="flex items-center">
          <span
            className="w-full h-full rounded-full block shrink-0 cursor-pointer my-2"
            onClick={() => getExhibitorData(row.registrationDocId || '', row.userId || '')}
          >
            <img
              alt="icons"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${helpers.generateName(row.riderName ?? UNKNOWN)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
              src={row.riderProfilePicture ?? ''}
              className="object-cover w-[45px] h-[45px] rounded-full"
            />
          </span>
        </div>
      ),
      width: '20%',
    },
    {
      label: 'NAME',
      name: (
        <span className="px-2 text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCEC.COLS.NAME}</span>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="flex items-center">
          <span
            className="text-base capitalize text-SeabiscuitDark200ThemeColor font-normal cursor-pointer"
            onClick={() => getExhibitorData(row?.registrationDocId || '', row?.userId || '')}
          >
            {row?.riderName ?? UNKNOWN}
          </span>
        </div>
      ),
      width: '20%',
    },
    {
      label: 'PAPERWORK',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAPERWORK}</span>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row.mailLog?.filter((mailLog) => mailLog.status === 'Signed').length > 0 ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCEC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '20%',
    },
    {
      label: 'PAYMENT',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAYMENT}</span>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row?.paymentStatus === PAYMENT_STATUSES.PAID ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img alt="icon" src={MCEC.IMAGE_URLS.NOT_OK} />
          )}
        </div>
      ),
      width: '20%',
    },
    {
      label: 'TEAM',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.TEAM}</span>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row.teamstatus ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img alt="icon" src={MCEC.IMAGE_URLS.NOT_OK} />
          )}
        </div>
      ),
      width: '20%',
    },
  ]

  const columnsFullScreen = [
    {
      label: 'PROFILE',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.EXHIBITOR}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Riders registered for this event</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <>
          <div
            className="flex items-center gap-4 cursor-pointer w-[300px]"
            onClick={() => {
              getExhibitorData(row?.registrationDocId ?? '', row?.userId ?? '')
            }}
          >
            <span className="w-14 h-14 p-1 rounded-full block shrink-0">
              <img
                alt="icons"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${helpers.generateName(row.riderName ?? UNKNOWN)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
                src={row?.riderProfilePicture ?? ''}
                className="w-[45px] h-[45px] object-cover rounded-full"
              />
            </span>
            <span className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize max-w-[220px] ellipsis">
              {row.riderName ?? UNKNOWN}
            </span>
          </div>
        </>
      ),
      width: '25%',
    },
    {
      label: 'HORSE',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.HORSE}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Horse registered for this rider</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <>
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center">
              {row.horses?.slice(0, Math.min(row.horses.length, 3)).map((currHorse, index) => {
                let imgSrc = currHorse.horseProfilePicture ?? ''
                if (imgSrc === '') imgSrc = IMAGE_CONSTS.PLACEHOLDERS.USER
                return (
                  <img
                    alt={'UI'}
                    onError={(e) => ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)}
                    src={imgSrc}
                    key={`horsePic${currHorse.horseId}${index}`}
                    className={clsx(
                      'md:w-[45px] md:h-[45px] bg-SeabiscuitWhiteThemeColor object-cover rounded-full border-2 border-solid border-[#eaeaea]',
                      !!index && 'ml-[-20px]'
                    )}
                  />
                )
              })}
            </div>
            <div className="flex items-center justify-center flex-col">
              {row.horses && row.horses.length > 0
                ? row.horses.slice(0, Math.min(row.horses.length, 3))?.map((currHorse, index) => {
                    return (
                      <div
                        className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize max-w-[120px] ellipsis"
                        key={`horseName${currHorse.horseName}${index}`}
                      >
                        <Link
                          target="_blank"
                          className="block ellipsis"
                          title={currHorse.horseName ?? 'N/A'}
                          to={`/horse-public-profile/${currHorse.horseId}`}
                        >
                          {`${currHorse.horseName ?? 'N/A'}${row.horses?.[index + 1] ? ',' : ''}`}
                        </Link>
                      </div>
                    )
                  })
                : 'N/A'}
            </div>
          </div>
        </>
      ),
      width: '35%',
    },
    {
      label: 'PAPERWORK',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAPERWORK}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Has this exhibitor completed their event paperwork?</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row.mailLog?.filter((mailLog) => mailLog.status === 'Signed').length > 0 ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCEC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '10%',
    },
    {
      label: 'PAYMENT',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAYMENT}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Has this exhibitor paid their registration fees?</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row?.paymentStatus === PAYMENT_STATUSES.PAID ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img alt="icon" src={MCEC.IMAGE_URLS.NOT_OK} />
          )}
        </div>
      ),
      width: '10%',
    },
    {
      label: 'TEAM',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.TEAM}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">
                Are this exhibitors team members eligible for this event?
              </h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => (
        <div className="justify-center w-full flex">
          {row.teamstatus ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img alt="icon" src={MCEC.IMAGE_URLS.NOT_OK} />
          )}
        </div>
      ),
      width: '10%',
    },
    {
      label: 'CONTACT',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.CONTACT}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Click to direct message this exhibitor</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRegistrationExtended) => {
        return (
          <div
            className="w-full justify-center flex cursor-pointer"
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: row.riderEmail,
                user_name: row.riderName,
                profilePicture: row.riderProfilePicture,
              })
            }}
          >
            <p className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor">
              Message
            </p>
          </div>
        )
      },
      width: '10%',
    },
  ]

  async function getExhibitorData(registrationId: string, userId: string) {
    try {
      const docSnapshot = await FirestoreService.getItem(
        MCEC.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        registrationId
      )

      if (docSnapshot.exists()) {
        let document = EventRegisteredUsersModel.fromFirestoreDoc(docSnapshot).toObject()
        if (document?.userId === userId) {
          router.push(
            `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${eventId}/${registrationId}`
          )
          history.push(
            `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${eventId}/${registrationId}`
          )
        } else {
          toastMethods.info({
            message: `This team member is register under ${document?.userName}.`,
            autoClose: 0,
          })
        }
      }
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  const fetchMore = () => {
    dispatch(
      organizerSliceAcs.setExhibitorsAc({
        eventId: props.eventId,
        cursorId: exhibitors.tabs[props.activeTab.name].data.at(-1)?.id ?? null,
        tabName: props.activeTab.name,
      })
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openManageEventModal = () => {
    if (handleModal)
      handleModal(true, MODAL_CONSTS.MANAGE_EVENT_MODAL, {
        eventId,
        headingClassName: '!text-SeabiscuitDark200ThemeColor',
      })
  }

  return (
    <>
      {exhibitors.tabs[props.activeTab.name].status === 'fulfilled' &&
      exhibitors.tabs[props.activeTab.name].data.length === 0 ? (
        <NoDataAvailable
          url=""
          text={!!props.searchValue ? 'No data found.' : 'No one has registered yet'}
          height={isFullScreen ? null : 240}
          linkText="Edit registrations >"
          imgSrc="assets/placeholders/noUserFound.svg"
          onClickOfLink={openManageEventModal}
          mainClasses="!mx-0"
        />
      ) : (
        <InfiniteScrollDataTable
          hasMore={exhibitors.tabs[props.activeTab.name].hasMore}
          data={exhibitors.tabs[props.activeTab.name].data}
          columns={isFullScreen ? columnsFullScreen : columns}
          fixedHeader={true}
          fetchMore={fetchMore}
          progressPending={
            exhibitors.tabs[props.activeTab.name].status !== 'fulfilled' &&
            !exhibitors.tabs[props.activeTab.name].data.length
          }
          customStyles={isFullScreen ? customStyles : tableStyles}
          className={clsx(
            '!rounded-none exhibitorListTable exhibitorListTable',
            exhibitors.tabs[props.activeTab.name].status === 'loading' && 'pointer-events-none',
            isFullScreen
              ? '!h-[58vh]'
              : exhibitors.tabs[props.activeTab.name].data.length ||
                  exhibitors.tabs[props.activeTab.name].status === 'loading'
                ? '!h-[300px]'
                : ''
          )}
          noDataComponent={
            <NoDataAvailable
              text={!!props.searchValue ? 'No data found.' : 'No one has registered yet'}
              url=""
              height={isFullScreen ? null : 175}
              linkText="Edit registrations >"
              imgSrc="assets/placeholders/noUserFound.svg"
              onClickOfLink={openManageEventModal}
            />
          }
          progressComponent={
            <div className="flex flex-col flex-wrap items-left justify-start mt-2">
              {isFullScreen ? (
                <>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                </>
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default ExhitiborAllList
