import React, { FC } from 'react'

import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayReturn,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import { Tooltip } from '@mui/material'
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form'

import Accordion from '../../../accordion/common/Accordion'
import TogglesElement from '../../../elements/toggles/toggles/TogglesElement'
import Input from '../../../common/inputs/Input'
import TooltipIcon from '../../../../helpers/TooltipIcon'

import { MODAL_CONSTS } from '../../../../const/modal-const'

import { IEventFees } from '../../../../models/event-fees/event-fees.interface'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import MessageHelperComp from '../../../../helpers/MessageHelper'

interface ClassSettingsProps {
  control: Control<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  trigger: UseFormTrigger<IEventFees>
  watch: UseFormWatch<IEventFees>
  register: UseFormRegister<IEventFees>
  handleModal: (showHide: boolean, typeOfModal: string, data: { removeItem: () => void }) => void
  qualifyingClasses: UseFieldArrayReturn<IEventFees, 'qualifyingClasses.items', 'id'>
  errors?: FieldErrors<IEventFees>
}
export const ClassSettings: FC<ClassSettingsProps> = ({
  control,
  setValue,
  trigger,
  watch,
  errors,
  register,
  handleModal,
  qualifyingClasses,
}) => {
  const toasterFunctions = useToasterHelper()
  return (
    <Accordion header="Class Settings">
      <div className="py-4 px-1">
        <div className="relative flex flex-wrap items-center gap-2 mb-8">
          <div className="flex gap-1 items-center w-full sm:w-auto sm:mr-10">
            <h4>Show registration limit</h4>
          </div>
          <Controller
            name={`registrationLimit.isEnable`}
            control={control}
            render={({ field: { value } }) => {
              return (
                <TogglesElement
                  checkedMessage=""
                  uncheckedMessage=""
                  buttonClassName="h-[27px] w-[50px] rounded-full"
                  on={value}
                  onToggle={() => {
                    setValue(`registrationLimit.isEnable`, !watch(`registrationLimit.isEnable`))
                    trigger('registrationLimit.isEnable').then()
                  }}
                />
              )
            }}
          />
          {watch('registrationLimit.isEnable') && (
            <>
              <label className="ml-auto flex items-center cursor-pointer">
                <Input
                  type="number"
                  className="!text-base !placeholder:text-base border-none py-0 px-1 text-right"
                  register={register}
                  placeholder="0"
                  name="registrationLimit.spots"
                  value={watch('registrationLimit.spots')}
                />
                <span className="text-SeabiscuitDark200ThemeColor/50">spots</span>
              </label>
              {watch('registrationLimit.spots') && (
                <button
                  className="cursor-pointer absolute top-1/2 -translate-y-1/2 translate-x-full -right-6 flex items-center w-6"
                  onClick={() => {
                    handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                      removeItem: function () {
                        setValue(`registrationLimit.spots`, null)
                      },
                    })
                  }}
                >
                  <img src="/assets/og_icons/Cancel.svg" alt="Cancel" />
                </button>
              )}
            </>
          )}
        </div>
        <div className="relative flex flex-wrap items-center gap-2 mb-8">
          <div className="flex gap-1 items-center  w-full sm:w-auto sm:mr-4">
            <h4>Ride time requirement</h4>
            <Tooltip
              title={
                <h6 className="tooltip_title">
                  Set required time between rides to support scheduling.
                </h6>
              }
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color="#122B46" className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <Controller
            name={`rideTimeRequirement.isEnable`}
            control={control}
            render={({ field: { value } }) => {
              return (
                <TogglesElement
                  checkedMessage=""
                  uncheckedMessage=""
                  buttonClassName="h-[27px] w-[50px] rounded-full"
                  on={value}
                  onToggle={() => {
                    setValue(`rideTimeRequirement.isEnable`, !watch(`rideTimeRequirement.isEnable`))
                    trigger('rideTimeRequirement.isEnable').then()
                  }}
                />
              )
            }}
          />
          {watch('rideTimeRequirement.isEnable') && (
            <>
              <label className="ml-auto flex items-center cursor-pointer">
                <Input
                  type="number"
                  className="!text-base !placeholder:text-base border-none py-0 px-1 text-right"
                  register={register}
                  placeholder="0"
                  name="rideTimeRequirement.minutes"
                  value={watch('rideTimeRequirement.minutes')}
                />
                <span className="text-SeabiscuitDark200ThemeColor/50">minutes</span>
              </label>
              {watch('rideTimeRequirement.minutes') && (
                <button
                  className="cursor-pointer absolute top-1/2 -translate-y-1/2 translate-x-full -right-6 flex items-center w-6"
                  onClick={() => {
                    handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                      removeItem: function () {
                        setValue(`rideTimeRequirement.minutes`, null)
                      },
                    })
                  }}
                >
                  <img src="/assets/og_icons/Cancel.svg" alt="Cancel" />
                </button>
              )}
            </>
          )}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <div className="flex gap-1 items-center w-full sm:w-auto sm:mr-12">
            <h4>Bonus class fees</h4>
            <Tooltip
              title={
                <h6 className="tooltip_title">
                  Create fees and assign them to a class. Rider can elect to pay them when
                  registering. Eg. Qualifying or non-member fees
                </h6>
              }
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color="#122B46" className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <Controller
            name={`qualifyingClasses.isEnable`}
            control={control}
            render={({ field: { value } }) => {
              return (
                <TogglesElement
                  checkedMessage=""
                  uncheckedMessage=""
                  buttonClassName="h-[27px] w-[50px] rounded-full"
                  on={value}
                  onToggle={() => {
                    setValue(`qualifyingClasses.isEnable`, !watch(`qualifyingClasses.isEnable`))
                    trigger(`qualifyingClasses.isEnable`).then()
                  }}
                />
              )
            }}
          />
          {watch(`qualifyingClasses.isEnable`) && (
            <div className="ml-auto text-right flex flex-col gap-5 md:gap-0 items-end">
              {qualifyingClasses.fields.map((_, index) => (
                <div key={index} className="relative">
                  <div className="flex items-center">
                    <Controller
                      control={control}
                      name={`qualifyingClasses.items.${index}.name`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            className="!text-base !placeholder:text-base border-none p-0 text-right"
                            placeholder="Name"
                            value={value}
                            onChange={onChange}
                          />
                        )
                      }}
                    />
                    {!!errors?.qualifyingClasses?.items?.[index]?.name?.message ? (
                      <MessageHelperComp
                        className="absolute z-0 top-full md:top-1/2 md:right-full translate-0 md:-translate-y-1/2 whitespace-nowrap mr-2"
                        isError={true}
                        message={errors?.qualifyingClasses?.items?.[index]?.name?.message || ''}
                      />
                    ) : null}
                    <span className="text-SeabiscuitDark200ThemeColor/50 mx-1">•</span>
                    <span className="text-SeabiscuitDark200ThemeColor/50">$</span>
                    <Controller
                      control={control}
                      name={`qualifyingClasses.items.${index}.price`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            type="number"
                            containerClassName="!w-auto"
                            className="!text-base !placeholder:text-base border-none p-0 text-left"
                            placeholder="Price"
                            value={value}
                            onChange={onChange}
                            defaultWidth="36px"
                            responsive
                          />
                        )
                      }}
                    />
                  </div>
                  <button
                    className="cursor-pointer absolute top-1/2 -translate-y-1/2 translate-x-full -right-6 flex items-center w-6"
                    onClick={() => {
                      handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                        removeItem: function () {
                          qualifyingClasses.remove(index)
                        },
                      })
                    }}
                  >
                    <img src="/assets/og_icons/Cancel.svg" alt="Cancel" />
                  </button>
                </div>
              ))}
              <button
                className="text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
                type="button"
                onClick={() => {
                  qualifyingClasses.append({
                    name: '',
                    price: null,
                  })
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </div>
    </Accordion>
  )
}
