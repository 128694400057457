import React, { FC } from 'react'
import { Tooltip } from '@mui/material'

import { IEventReviewPublish } from '../../../../../models/event-review-publish/event-review-publish.interface'

export const HeaderHorseItems: FC<{ EventFees: IEventReviewPublish['EventFees'] | null }> = ({
  EventFees,
}) => {
  return (
    <div className="mb-2 ml-auto gap-6 justify-end flex items-center">
      {EventFees?.rideTimeRequirement?.isEnable && (
        <Tooltip
          title={
            <h4 className="text-[12px]">
              Do you want to waive the required break between ride times?
            </h4>
          }
          placement="top"
          arrow
        >
          <p className="text-[14px] text-SeabiscuitDark200ThemeColor/50 cursor-pointer underline hover:no-underline">
            Waive break?
          </p>
        </Tooltip>
      )}
      {EventFees?.qualifyingClasses?.isEnable && (
        <div className="underline">
          <Tooltip
            title={
              <h4 className="text-[12px] max-w-[180px]">Do you want to pay the extra class fee?</h4>
            }
            placement="top-end"
            arrow
          >
            <p className="text-[14px] text-SeabiscuitDark200ThemeColor/50 cursor-pointer underline hover:no-underline">
              Extra?
            </p>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
