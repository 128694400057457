import React, { useEffect, useRef, useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MainModal from '../../modals/common/MainModal'

import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import customImageComponent from '../../common/CustomImageComponent'
import ViewsLoader from '../../loader/ViewsLoader'
import { IconPlus } from '../../icons/IconPlus'
import { getUserFullName } from '../../../helpers/helpers'

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    competitor: IUserInterface
    users: IUserInterface[]
    userTeamMembers: IUserInterface[]
    authorizeUserHorses: IUserHorseMappingInterface[]
  }
}

interface IUserExtendedWithSelected extends IUserInterface {
  selected?: boolean
}

const RegistrationTeamTabModal = ({ dataToPassOn, handleModal }: Props) => {
  const dispatch = useAppDispatch()

  const { userTeamMembers, authorizeUserHorses, users, competitor } = dataToPassOn

  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<IUserExtendedWithSelected[]>([])

  const teamMembers = useAppSelector(selectTeamTabData)
  const myHorses = useAppSelector(selectHorses)

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  const handleUsers = (strToSearch: string) => {
    const selected = listToShow?.filter((item) => item.selected)

    if (strToSearch === '') {
      const defaultList = userTeamMembers?.map((user) => {
        const isSelected = selected.find((item) => item.id === user.id)
        if (isSelected) {
          return { ...user, selected: true }
        } else {
          return user
        }
      })
      return setListToShow([...defaultList])
    }

    const matched = users.filter((user: IUserInterface) => {
      const isExist = userTeamMembers?.find((member) => member.id === user.id)

      return (
        !isExist &&
        (user.userName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
          user.userFirstName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
          user.userLastName?.toLowerCase()?.includes(strToSearch?.toLowerCase()))
      )
    })
    setListToShow([...selected, ...matched])
  }

  async function addTeamMembersToViewOnly() {
    const teamData: IRegistrationTeamInterface[] = []
    let selectedHorseData: IUserHorseMappingInterface[] = []

    try {
      listToShow?.forEach((user) => {
        if (user.selected) {
          teamData.push(getConvertedData(getSelectedUserAsTeamMember(user, competitor)))
        }
      })

      teamData.forEach((data) => {
        const horse = authorizeUserHorses.filter(
          (horseData) => horseData.horseOwnerId === data.memberId
        )
        if (horse) {
          selectedHorseData = [...horse]
        }
      })

      dispatch(setTeamTabData([...teamData]))

      if (selectedHorseData.length) {
        dispatch(setHorses([...myHorses, ...selectedHorseData]))
      }
    } catch (error) {
      console.log(error, 'error')
    } finally {
      handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
    }
  }

  const handleChange = (team_member: IUserExtendedWithSelected) => {
    if (listToShow) {
      const sameTypeUsersArrCopy = listToShow.map((currUser) => {
        if (currUser.id === team_member?.id) {
          return { ...currUser, selected: !currUser.selected }
        } else {
          return currUser
        }
      })
      setListToShow([...sameTypeUsersArrCopy])
    }
  }

  useEffect(() => {
    const list: IUserExtendedWithSelected[] = []
    userTeamMembers?.forEach((user) => {
      const isSelected = teamMembers.find((member) => member.memberId === user.id)

      if (isSelected) {
        list.push({ ...user, selected: true })
      } else {
        list.push(user)
      }
    })
    setListToShow(list)
  }, [teamMembers, userTeamMembers])

  return (
    <>
      <MainModal
        title="Add team member"
        show={true}
        type="REGISTER_TEAM"
        size="md"
        onTransitionEnd={focusInput}
        titleClassName="!font-normal"
        buttons={[
          {
            label: 'SAVE',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: addTeamMembersToViewOnly,
            className: 'outline-none',
            textClass: 'text-white',
            disabled: !listToShow?.length,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => handleModal(false, MODAL_CONSTS.REGISTER_TEAM),
          },
        ]}
      >
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={(e) => handleUsers(e.target.value)}
            placeholder="Search..."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          {/*<Search className="absolute text-SeabiscuitMainThemeColor right-4" />*/}
          <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
            <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
            <button
              onClick={() => {
                handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
                handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
              }}
              className={
                'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-SeabiscuitMainThemeColor'
              }
            >
              <IconPlus /> New Person
            </button>
          </div>
        </div>
        {listToShow?.length ? (
          <>
            <div className="uselListWarp mt-4 flex flex-col">
              {listToShow?.length ? (
                listToShow?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleChange(item)}
                      className="form-check mb-3 cursor-pointer"
                    >
                      <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                        <div className="min-w-fit flex items-center">
                          {customImageComponent(
                            item.userProfilePicture,
                            getUserFullName(item),
                            'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                          )}
                          <div className="hourseDetails w-10/12">
                            <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                              <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                                {getUserFullName(item)}
                              </p>
                              <p className="text-xs">{item?.userEmail}</p>
                            </div>
                          </div>
                        </div>

                        {item.selected ? (
                          <img
                            src={CHECKED_ICON}
                            className="relative right-[0px] top-[0px]"
                            width={20}
                            height={20}
                            alt="checked"
                          />
                        ) : null}

                        <input
                          className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}
                          type="checkbox"
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor"></div>
              )}
            </div>
          </>
        ) : (
          <>
            {!!searchBoxRef?.current?.value?.length && !listToShow ? (
              <>No data found</>
            ) : (
              <>
                {!!searchBoxRef?.current?.value?.length && !listToShow ? (
                  <ViewsLoader size="md" color="red" className="mt-1 pt-1" />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </MainModal>
    </>
  )
}

export default RegistrationTeamTabModal
