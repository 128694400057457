import { ApexOptions } from 'apexcharts'

const themePrimaryColor = '#F70763'
const chatLabelsColor = '#C4C4C4'

export const SalesChartOptions: ApexOptions = {
  plotOptions: {
    area: {
      fillTo: 'origin',
    },
  },
  chart: {
    id: 'basic-bar',
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  colors: [themePrimaryColor],
  xaxis: {
    type: 'category',
    labels: {
      formatter: function (value) {
        return `${[parseInt(value)]}/${new Date().getFullYear().toString().substring(2)}`
      },
      style: {
        colors: [
          themePrimaryColor,
          ...(function () {
            let colorsArr = []
            for (let i = 1; i < 12; i++) {
              colorsArr.push(chatLabelsColor)
            }
            return colorsArr
          })(),
        ],
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `${value.toFixed(2)}k`.replace(/(0+k)|(\.0+k)/, 'K')
      },
      style: {
        colors: [chatLabelsColor],
      },
    },
  },
  stroke: {
    width: 2,
  },
}
