import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Third party
import { where } from 'firebase/firestore'

// Component
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { IconBack } from '../../../components/icons/IconBack'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { IconMail } from '../../../components/icons/IconMail'

import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { UserModel } from '../../../models/users/user.model'
import { EventReviewPublishModel } from '../../../models/event-review-publish/event-review-publish.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { getConvertedData } from '../../../models/interface.helper'

import FirestoreService from '../../../services/firestoreService'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import useToasterHelper from '../../../helpers/ToasterHelper'
import helpers from '../../../commonHelpers/helpers'

// Constants
import { CONST } from '../../../const/const'
import { IMAGE_CONSTS } from '../../../const/image-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { ROUTES_CONST } from '../../../const/routes-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { useDispatch } from 'react-redux'
import { riderActions } from '../../../store/rider/riderSlice'
import { selectRiderTeamMembersUpdates } from '../../../store/paperworks/paperworkSlice'
import { getUserFullName } from '../../../helpers/helpers'

// Types
type IProps = {
  backUrl: string
  isRefund?: boolean
  loading?: boolean
  activeTab?: string
  eventData?: IEventReviewPublish | null
  setEventData?: React.Dispatch<React.SetStateAction<IEventReviewPublish | null>>
  isCompetitor?: boolean
  showScratchedView: boolean
  exhibitor?: IUserInterface | null
  recipient: IRecipientInterface | null
  handleScratchView: (visible: boolean) => void
  exhibitorAllData: TEventRegisteredUsers | null
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IInfoWithStatusProps = {
  icon?: string
  label?: string
  value?: any
  className?: string
  isRiders?: boolean
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES
const InfoWithStatus = (props: IInfoWithStatusProps) => {
  // Hooks and vars
  const { label, value, className } = props

  return (
    <>
      <div className={className}>
        {/* <img src={icon} alt="icon" className="w-6 h-6 mr-3" /> */}
        <span className="mr-1 whitespace-nowrap text-SeabiscuitDark200ThemeColor">{`${label}`}</span>
        {typeof value === 'boolean' ? (
          value ? (
            <img src={`/assets/cp_icons/Ok-3.svg`} alt="icon" className="w-6 h-6 mr-3" />
          ) : (
            <img src={`/assets/cp_icons/Cancel-2.svg`} alt="icon" className="w-6 h-6 mr-3" />
          )
        ) : (
          <span
            className={`mr-3 text-SeabiscuitDark200ThemeColor w-full md:w-[initial] ${!props.isRiders && 'md:max-w-[230px] '} text-[#8795a2] text-wrap`}
          >
            {value}
          </span>
        )}
      </div>
    </>
  )
}

const ExhibitorProfileHeadSection = ({
  exhibitor,
  exhibitorAllData,
  showScratchedView,
  setEventData,
  eventData,
  recipient,
  isCompetitor,
  isRefund,
  backUrl,
  loading,
}: IProps) => {
  const toastFunction = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)

  const currentEntries = useAppSelector((state) => state.organizer.currentEntries)

  const [showProfilePicture, setShowProfilePicture] = useState(false)
  const [picToShow, setPicToShow] = useState<string>(IMAGE_CONSTS.PLACEHOLDERS.USER)
  const storeRider = useAppSelector((state) => state.rider)
  const dispatch = useAppDispatch()
  const riderTeamMembersUpdates = useAppSelector(selectRiderTeamMembersUpdates)

  useEffect(() => {
    if (isCompetitor === true && !!exhibitorAllData?.owner)
      setEventOwnerImage(exhibitorAllData?.owner)
    else if (!!exhibitor?.userProfilePicture) setPicToShow(exhibitor.userProfilePicture)
  }, [isCompetitor, exhibitorAllData?.owner, exhibitor?.userProfilePicture])

  const handleGetpublishEvent = async (eventId: string) => {
    const publishedEventSnapShot = await FirestoreService.getItem(
      COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )
    let publishedEvent = EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()
    if (setEventData) setEventData(publishedEvent)

    return publishedEvent
  }

  const getRiders = async () => {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []

    if (exhibitorAllData?.eventId && exhibitorAllData?.userId) {
      const riderTeamMembersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData.eventId)]
      )

      riderTeamMembersSnaps.docs.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      let documentsSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData.eventId),
        // where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.COMPITETOR_ID.KEY, '==', exhibitorAllData.userId),
      ])

      let documents = documentsSnaps.docs.map((currDoc) =>
        getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
      )

      const userTeam = riderTeamMembers.filter((r) => r.userId === exhibitor?.id)

      let docs: typeof documents = []
      userTeam.forEach((member) => {
        const innerDocs = documents.filter((doc) => doc.signatoryId === member.teamMemberId)
        if (innerDocs[0]) {
          docs = [...docs, ...innerDocs]
        }
      })

      const isSigned = !docs.some((d) => d.status !== 'Signed')
      dispatch(riderActions.setDocuments(docs))
      dispatch(riderActions.setIsAppPaperworkSign(isSigned))
    }
  }

  useEffect(() => {
    getRiders().then()
  }, [exhibitorAllData?.eventId, riderTeamMembersUpdates])

  useEffect(() => {
    if (exhibitorAllData && exhibitorAllData.eventId) {
      handleGetpublishEvent(exhibitorAllData.eventId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitorAllData])

  async function setEventOwnerImage(ownerId: string) {
    if (!ownerId) return

    const userSnap = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, ownerId)

    if (!userSnap.exists()) return

    const user = UserModel.fromFirestoreDoc(userSnap).toObject()

    if (!!user.userProfilePicture) setPicToShow(user.userProfilePicture)
  }

  let phoneNumber1 = exhibitor?.userPhoneNumber
  let phoneNumber = phoneNumber1?.toString()
  let formattedPhoneNumber = phoneNumber
  if (formattedPhoneNumber?.length === 10) {
    formattedPhoneNumber =
      phoneNumber?.substring(0, 3) +
      '-' +
      phoneNumber?.substring(3, 6) +
      '-' +
      phoneNumber?.substring(6, 10)
  } else {
    formattedPhoneNumber = exhibitor?.userPhoneNumber
  }

  if (loading)
    return (
      <div className="h-fit my-10 flex justify-center items-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )

  const onViewReceiptClick = () => {
    if (!recipient) {
      return toastFunction.info({ message: MESSAGES_CONST.NO_RECIPIENT })
    }
    handleModalContext?.handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, {
      bill: recipient,
    })
  }

  const ridersName = currentEntries.map((rider) => rider[0].riderName)

  const classes: string[] = []

  currentEntries.forEach((arr) =>
    arr.forEach((itm) => {
      if (itm.registrationByDayName && !classes.includes(itm.registrationByDayName)) {
        classes.push(itm.registrationByDayName)
      }
    })
  )

  return (
    <>
      <div>
        <Link
          to={backUrl}
          className="flex items-center md:hidden mb-4 hover:opacity-70 transition-all"
        >
          <IconBack />
          <span className={'ml-2'}>Go Back</span>
        </Link>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center">
          <Link
            to={backUrl}
            className="hidden md:block mr-[10px] w-5 hover:opacity-70 transition-all"
          >
            <IconBack />
          </Link>
          <span className="w-16 h-16 bg-SeabiscuitGrayThemeColor rounded-full overflow-hidden border-2 cursor-pointer">
            <img
              alt="icons"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${helpers.generateName(exhibitor ? getUserFullName(exhibitor) : 'N/A')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
              src={exhibitor?.userProfilePicture}
              className="object-cover w-full h-full"
            />
          </span>

          <Link to={`${ROUTES_CONST.EVENT_DETAILS.URL}/${exhibitorAllData?.eventId}`}>
            <span className="text-SeabiscuitDark200ThemeColor ml-2 lg:ml-4 font-bold lg:text-xl capitalize">
              {exhibitor ? getUserFullName(exhibitor) : 'N/A'}
            </span>
          </Link>
          {eventData && eventData.status === 'cancel' ? (
            <>
              <span className=" capitalize rounded-full py-1 px-4 mr-4 inline-block whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis mt-1 min-w-fit md:min-w-[unset] text-nr md:text-base cursor-pointer bg-[#FC2804] text-white">
                Cancelled{' '}
              </span>
            </>
          ) : null}
        </div>

        {/* Took this out of the condition because it was making the message icon not show up on paperwork */}
        {/* SCRATCHABLE.includes(props.activeTab ?? "") */}

        <div className={'flex gap-5'}>
          {!isCompetitor && !showScratchedView && !isRefund ? (
            <div className="flex items-center">
              <button
                type="button"
                className="border flex items-center rounded-[8px] justify-center border-solid border-[#D3DAEE] w-[45px] h-[45px] hover:opacity-50"
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                    emailId: exhibitor?.userEmail,
                    user_name: exhibitor ? getUserFullName(exhibitor) : 'N/A',
                    profilePicture: exhibitor?.userProfilePicture,
                  })
                }}
              >
                <IconMail />
              </button>
            </div>
          ) : null}

          <button
            className={
              'border flex items-center rounded-[8px] justify-center border-solid border-[#D3DAEE] w-[45px] h-[45px] hover:opacity-50'
            }
            onClick={onViewReceiptClick}
          >
            <img src="/assets/cp_icons/Bill.svg" alt="paidStatus" />
          </button>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex flex-col md:flex-row  md:items-center justify-between lg:mb-3 flex-wrap md:mb-2">
          <div className="flex">
            {/*<div>*/}
            {/*  <InfoWithStatus*/}
            {/*    className="flex items-center max-w-[300px] w-full min-w-fit mb-2 pb-2 md:mb-0"*/}
            {/*    label="Registered"*/}
            {/*    value={moment(exhibitorAllData?.registrationDate).format('MMM Do, YYYY')}*/}
            {/*    icon="/assets/cp_icons/Calendar-1.svg"*/}
            {/*  />*/}

            {/*  <InfoWithStatus*/}
            {/*    className="flex items-center max-w-[300px] w-full min-w-fit mb-2 md:mb-0"*/}
            {/*    label="Paid"*/}
            {/*    value={moment(exhibitorAllData?.modified).format('MMM Do, YYYY')}*/}
            {/*    icon="/assets/cp_icons/MagneticCard-1.svg"*/}
            {/*  />*/}
            {/*</div>*/}

            <div className="">
              <InfoWithStatus
                className="flex items-start max-w-[300px] w-full min-w-fit mb-2 pb-2 md:mb-0"
                label="Phone •"
                value={`${exhibitor?.userPhoneNumber ? formattedPhoneNumber : 'N/A'}`}
                icon="/assets/cp_icons/Phone.svg"
              />
              <div className={'mt-[-15px] mb-[10px] lg:hidden text-[#8795a2] text-[15px]'}>
                {classes.length} classes
              </div>
              <InfoWithStatus
                className="flex items-start max-w-[300px] w-full min-w-fit mb-2 md:mb-0"
                label="Email •"
                value={`${exhibitor?.userEmail ? exhibitor?.userEmail : 'N/A'}`}
                icon="/assets/cp_icons/Mail-1.svg"
              />
              <div className={'mt-[-8px] lg:hidden text-[#8795a2] text-[15px]'}>
                {ridersName.length} Riders {ridersName[0] ? '•' : ''} {ridersName.join(', ')}
              </div>
            </div>

            <div className="hidden lg:block">
              <InfoWithStatus
                className="flex items-start max-w-[300px] w-full min-w-fit mb-2 pb-2 md:mb-0"
                label="|"
                value={`${classes.length} classes`}
                icon="/assets/cp_icons/Phone.svg"
              />
              <InfoWithStatus
                className="flex items-start max-w-[40vw] w-full min-w-fit mb-2 md:mb-0"
                label={`| ${ridersName.length} Riders •`}
                value={`${ridersName.join(', ')}`}
                isRiders
                icon="/assets/cp_icons/Mail-1.svg"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center mt-8 lg:mb-3 gap-2 flex-wrap mb-2">
            <InfoWithStatus
              className="flex items-center"
              label="Payment"
              value={
                exhibitorAllData?.paymentStatus === PAYMENT_STATUSES.PAID ||
                exhibitorAllData?.paymentStatus === PAYMENT_STATUSES.CASH
              }
              icon="/assets/cp_icons/MoneyBag-1.svg"
            />

            <InfoWithStatus
              className="flex items-center"
              label="Paperwork"
              value={storeRider.isAppPaperworkSign}
              icon="/assets/cp_icons/RegularDocument-1.svg"
            />
            <InfoWithStatus
              className="flex items-center"
              label="Team"
              value={exhibitorAllData?.teamstatus}
              icon="/assets/cp_icons/UserGroups-1.svg"
            />
          </div>
        </div>
      </div>

      <LightboxCommonComponent
        onClose={() => setShowProfilePicture(false)}
        visible={showProfilePicture}
        slides={[
          {
            src: picToShow,
          },
        ]}
      />
    </>
  )
}

export default ExhibitorProfileHeadSection
