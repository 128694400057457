import moment from 'moment'
import { PDFDocument, StandardFonts, cmyk } from 'pdf-lib'
// @ts-ignore
import fontkit from '@pdf-lib/fontkit'
import FirebaseStorageService from '../../../services/storageService'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { IDocument } from '../../../fakeData/fakeDocumentList'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { capitalize, getUserFullName } from '../../../helpers/helpers'
import { CONST } from '../../../const/const'
import FirestoreService from '../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { sendEmailForSign } from '../sendEmail/sendEmailForSign'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  registrationByDay: IRegistrationByDayInterface
  isMature: boolean
  eventDraftId: string
  isStaff?: boolean
  isSigned?: boolean
  stringArray?: string[]
  index?: number
}
export const USEFEntryAgreementPDFTemplate = async (
  {
    userData,
    EventDetails,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    registrationByDay,
    isMature,
    isStaff,
    eventDraftId,
    isSigned,
    stringArray,
    index,
  }: IPaperworkTemplateData,
  customDocId?: string
) => {
  const url = '/assets/documents/4.pdf'
  await fetch(url).then((res) => res.arrayBuffer())

  let shortLocation =
    EventDetails?.competitionAddressLine1 && EventDetails?.competitionAddressLine1.length < 30
      ? EventDetails.competitionAddressLine1
      : `${EventDetails.competitionCity}, ${EventDetails.competitionState}, ${EventDetails.competitionCountry}`

  const d = new Date()
  let time = d.getTime()

  const pdfDoc = await PDFDocument.create()
  const firstPage = pdfDoc.addPage([550, 760])
  const secondPage = pdfDoc.addPage([550, 780])
  const thirdPage = pdfDoc.addPage([550, 760])

  pdfDoc.registerFontkit(fontkit)

  // Fonts
  const timeRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
  const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
  const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
    res.arrayBuffer()
  )
  const PoppinsFont = await pdfDoc.embedFont(fontPoppingsBytes)
  const jpgUrl = '/assets/Pegasus_app_logo.png'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  const jpgImage = await pdfDoc.embedPng(jpgImageBytes)

  firstPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })

  firstPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  firstPage.drawText(`USEF Entry Agreement, ${isMature ? '18+' : 'Minor'}`, {
    x: 110,
    y: 520,
    font: boldFont,
    size: 20,
  })

  // Event Name
  firstPage.drawText(`Event :`, { x: 230, y: 470, font: boldFont, size: 16 })
  firstPage.drawText(`${EventDetails.competitionName}`, {
    x: 290,
    y: 470,
    font: timeRomanFont,
    size: 16,
  })

  //Organizer Name
  firstPage.drawText(`Host :`, { x: 238, y: 450, font: boldFont, size: 16 })
  firstPage.drawText(
    `${organizerDetails ? getUserFullName(organizerDetails) : 'No organizer on file'}`,
    { x: 290, y: 450, font: timeRomanFont, size: 16 }
  )

  // Event Location
  firstPage.drawText(`Event location :`, { x: 170, y: 430, font: boldFont, size: 16 })
  firstPage.drawText(`${shortLocation}`, { x: 290, y: 430, font: timeRomanFont, size: 16 })

  // Event Date
  firstPage.drawText(`Event date :`, { x: 195, y: 390, font: boldFont, size: 16 })
  firstPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 290,
    y: 390,
    font: timeRomanFont,
    size: 16,
  })

  // Horse Name
  firstPage.drawText(`Horse :`, { x: 229, y: 370, font: boldFont, size: 16 })
  firstPage.drawText(
    `${isStaff ? 'No horse on file' : registrationByDay.horseName ? registrationByDay.horseName : 'No horse on file'}`,
    { x: 290, y: 370, font: timeRomanFont, size: 16 }
  )

  // Rider Name
  firstPage.drawText(`Rider :`, { x: 229, y: 350, font: boldFont, size: 16 })
  firstPage.drawText(`${isStaff ? 'No rider on file' : ridersTeamMember.riderName}`, {
    x: 290,
    y: 350,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer Role ////////
  firstPage.drawText(`Signer role at event :`, { x: 140, y: 290, font: boldFont, size: 16 })
  firstPage.drawText(`${capitalize(stringArray?.slice(0, 3).toString())}`, {
    x: 290,
    y: 290,
    font: timeRomanFont,
    size: 16,
  })
  firstPage.drawText(`${capitalize(stringArray?.slice(3, 6).toString())}`, {
    x: 290,
    y: 270,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer name ////////
  firstPage.drawText(`Signer name :`, { x: 185, y: 250, font: boldFont, size: 16 })
  firstPage.drawText(`${ridersTeamMember.teamMemberName}`, {
    x: 290,
    y: 250,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer Phone number ////////
  firstPage.drawText(`Signer's phone number :`, { x: 118, y: 230, font: boldFont, size: 16 })
  firstPage.drawText(
    `${ridersTeamMember.teamMemberPhoneNumber && ridersTeamMember.teamMemberPhoneNumber !== '' ? ridersTeamMember.teamMemberPhoneNumber : 'No number on file'}`,
    { x: 290, y: 230, font: timeRomanFont, size: 16 }
  )

  ///////// secondPage //////////
  secondPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  secondPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  secondPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  secondPage.drawText('FEDERATION AND ASSOCIATION ', { x: 30, y: 900, size: 18 })
  secondPage.drawText(`ENTRY AGREEMENT`, { x: 30, y: 980, size: 18 })
  // secondPage.drawText(`USEF Entry Agreement, ${isMature ? '18+' : 'Minor'}`, {
  //   x: 110,
  //   y: 520,
  //   font: boldFont,
  //   size: 20,
  // })

  secondPage.drawText(`${EventDetails.competitionName}`, { x: 30, y: 600, size: 16 })
  secondPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 30,
    y: 580,
    size: 16,
  })
  secondPage.drawText(`${EventDetails.competitionAddressLine1}`, { x: 30, y: 560, size: 16 })

  secondPage.drawText(`By entering a Federation-licensed Competition and signing this entry`, {
    x: 30,
    y: 520,
    size: 12,
  })
  secondPage.drawText(`blank as the Owner, Lessee, Trainer, Manager, Agent, Coach, Driver,`, {
    x: 30,
    y: 500,
    size: 12,
  })
  secondPage.drawText(
    `Rider, Handler, Vaulter or Longeur and on behalf of myself and my principals,`,
    { x: 30, y: 480, size: 12 }
  )
  secondPage.drawText(`representatives, employees and agents, I agree that I am subject to the`, {
    x: 30,
    y: 460,
    size: 12,
  })
  secondPage.drawText(`Bylaws and Rules of The United States Equestrian Federation, Inc. (the`, {
    x: 30,
    y: 440,
    size: 12,
  })
  secondPage.drawText(
    `“Federation”) and the local rules of ${EventDetails.competitionName} (Competition).`,
    { x: 30, y: 420, size: 12 }
  )

  secondPage.drawText(`I agree to be bound by the Bylaws and Rules of the Federation`, {
    x: 30,
    y: 380,
    size: 12,
  })
  secondPage.drawText(
    `and of the competition. I will accept as final the decision of the Hearing Committee`,
    { x: 30, y: 360, size: 12 }
  )
  secondPage.drawText(
    `on any question arising under the Rules, and agree to release and hold harmless the`,
    { x: 30, y: 340, size: 12 }
  )
  secondPage.drawText(
    `competition, the Federation, their officials, directors and employees for any`,
    { x: 30, y: 320, size: 12 }
  )
  secondPage.drawText(
    `action taken under the Rules. I represent that I am eligible to enter and/or`,
    { x: 30, y: 300, size: 12 }
  )
  secondPage.drawText(
    `participate under the Rules, and every horse I am entering is eligible as entered.`,
    { x: 30, y: 280, size: 12 }
  )
  secondPage.drawText(
    `I also agree that as a condition of and in consideration of acceptance of entry,`,
    { x: 30, y: 260, size: 12 }
  )
  secondPage.drawText(`the Federation and/or the Competition may use or assign photographs,`, {
    x: 30,
    y: 240,
    size: 12,
  })
  secondPage.drawText(
    `videos, audios, cable - casts, broadcasts, internet, film, new media or other`,
    { x: 30, y: 220, size: 12 }
  )
  secondPage.drawText(`likenesses of me and my horse taken during the course of the competition`, {
    x: 30,
    y: 200,
    size: 12,
  })
  secondPage.drawText(
    `for the promotion, coverage or benefit of the competition, sport, or the Federation.`,
    { x: 30, y: 180, size: 12 }
  )

  secondPage.drawText(`Those likenesses shall not be used to advertise a product and they may`, {
    x: 30,
    y: 160,
    size: 12,
  })
  secondPage.drawText(
    `not be used in such a way as to jeopardize amateur status. I hereby expressly and`,
    { x: 30, y: 140, size: 12 }
  )
  secondPage.drawText(
    `irrevocably waive and release any rights in connection with such use, including`,
    { x: 30, y: 120, size: 12 }
  )
  secondPage.drawText(
    `any claim to compensation, invasion of privacy, right of publicity, or to .`,
    { x: 30, y: 100, size: 12 }
  )
  secondPage.drawText(
    `misappropriation. The construction and application of Federation rules are governed`,
    { x: 30, y: 80, size: 12 }
  )

  ///////// thirdPage //////////
  thirdPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  thirdPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  thirdPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  thirdPage.drawText(
    `by the laws of the State of New York, and any action instituted against the `,
    { x: 30, y: 680, size: 12 }
  )
  thirdPage.drawText(`Federation must be filed in New York State. See GR908.4.`, {
    x: 30,
    y: 660,
    size: 12,
  })

  thirdPage.drawText(`BY SIGNING BELOW, I AGREE that I have read, understand, and agree to`, {
    x: 30,
    y: 640,
    size: 12,
  })
  thirdPage.drawText(
    `be bound by all applicable Federation Bylaws, rules, and policies including`,
    { x: 30, y: 620, size: 12 }
  )
  thirdPage.drawText(`the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies`, {
    x: 30,
    y: 600,
    size: 12,
  })
  thirdPage.drawText(`(MAAPP) as published at www.usef.org, as amended from time to time, as `, {
    x: 30,
    y: 580,
    size: 12,
  })
  thirdPage.drawText(`well as all terms and provisions of this Prize List. If I am signing and`, {
    x: 30,
    y: 560,
    size: 12,
  })
  thirdPage.drawText(`submitting this Agreement electronically, I acknowledge that my electronic`, {
    x: 30,
    y: 540,
    size: 12,
  })
  thirdPage.drawText(`signature shall have the same validity, force and effect as if I affixed.`, {
    x: 30,
    y: 520,
    size: 12,
  })
  thirdPage.drawText(`my signature by my own hand.`, { x: 30, y: 500, size: 12 })

  thirdPage.drawText(`Signers name: ${ridersTeamMember.teamMemberName}`, {
    x: 30,
    y: 200,
    size: 12,
  })
  thirdPage.drawText(`Date: ${moment(new Date()).format('MM-DD-YYYY')}`, {
    x: 30,
    y: 180,
    size: 12,
  })
  thirdPage.drawText(`Signature: Mind roots`, { x: 30, y: 160, size: 12 })

  const pdfBytes = await pdfDoc.save()

  // Step 1: Convert Uint8Array to Blob
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // Step 2: Convert Blob to File
  var file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/documents/${ridersTeamMember.teamMemberRole}${time}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
  )

  const USER_DOCUMENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS
  let retunedData: any = {}

  try {
    const eventsSnapshot = await FirestoreService.filterItems(USER_DOCUMENT.NAME, [
      where(USER_DOCUMENT.FIELDS.SIGNATORY_ID.KEY, '==', ridersTeamMember.teamMemberId),
      where(USER_DOCUMENT.FIELDS.EVENT_ID.KEY, '==', eventId),
      where(USER_DOCUMENT.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
      where(USER_DOCUMENT.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
      where(USER_DOCUMENT.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY, '==', ridersTeamMember.teamMemberRole),
      where(USER_DOCUMENT.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY, '==', registrationByDay?.id),
    ])

    let obj = {
      documentNameAsPerPdf: `USEF Entry Agreement, ${isMature ? '18+' : 'Minor'}`,
      documentName: paperwork.key,
      documentOriginalName: paperwork.document_name,
      documentUrl: (fileUrl as string) ?? '',
      eventId,
      eventLogo: EventDetails.eventLogo,
      eventName: EventDetails.competitionName,
      status: isSigned ? 'Signed' : 'Not Signed',
      u: false,
      riderId: ridersTeamMember.riderId,
      riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
      signatoryId: ridersTeamMember.teamMemberId,
      signatoryName: ridersTeamMember.teamMemberName,
      signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
      activityUser: getUserFullName(userData),
      documentOwner: ridersTeamMember.teamMemberId,
      competitorId: userData.id,
      signatoryDefaultRole: ridersTeamMember.teamMemberRole,
      signatoryEmail: ridersTeamMember.teamMemberEmail,
      reminder: false,
      registrationDocId: ridersTeamMember.registrationDocId,
      riderTeamMemberDocId: ridersTeamMember.id,
      registrationByDayDocId: registrationByDay?.id,
      registrationByDayUuid: registrationByDay?.uuid,
    }

    if (eventsSnapshot.size) {
      let docId = ''
      eventsSnapshot.forEach((doc) => {
        docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
      })

      await FirestoreService.updateItem(USER_DOCUMENT.NAME, customDocId || docId, obj)

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        customDocId || docId,
        {
          id: customDocId || docId,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF Entry Agreement, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
      retunedData = {
        docId: customDocId || docId,
      }
    } else {
      const document = await FirestoreService.createItem(USER_DOCUMENT.NAME, obj)
      retunedData = {
        docId: document.id,
      }
      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        document.id,
        {
          id: document.id,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF Entry Agreement, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
    }
  } catch (err: any) {
    return err
  }

  if (fileUrl && fileUrl !== '' && eventId) {
    await sendEmailForSign({
      ridersTeamMember,
      paperwork,
      paperworkUrl: fileUrl as string,
      eventId,
      isMature,
      isStaff,
      eventDraftId,
      senderData: {
        senderName: getUserFullName(userData),
        senderEmail: userData.userEmail || '',
      },
    })
  }

  return retunedData
}
