import React from 'react'
import { UnseenBadge } from '@knocklabs/react'
import { NotificationIconButtonProps } from '@knocklabs/react'

export const IconNotification = React.forwardRef<HTMLButtonElement, NotificationIconButtonProps>(
  ({ onClick, badgeCountType }, ref) => {
    return (
      <button className="relative" onClick={onClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#8c95ac"
            className="group-hover:fill-SeabiscuitMainThemeColor group-hover:opacity-1"
            d="M11.6386 2.00878C7.59682 2.19915 4.49988 5.67168 4.49988 9.69042V13.3242L3.17469 15.9902C3.17236 15.9951 3.17008 16 3.16785 16.0049C2.63472 17.1386 3.49774 18.5 4.75086 18.5H8.99988C8.99988 20.1479 10.352 21.5 11.9999 21.5C13.6478 21.5 14.9999 20.1479 14.9999 18.5H19.2479C20.5011 18.5 21.3652 17.1389 20.8319 16.0049C20.8297 16 20.8274 15.9951 20.8251 15.9902L19.4999 13.3242V9.49999C19.4999 5.24689 15.9315 1.80667 11.6386 2.00878ZM11.7089 3.50682C15.1689 3.34393 17.9999 6.08109 17.9999 9.49999V13.5C17.9998 13.6159 18.0265 13.7302 18.078 13.834L19.4745 16.6445C19.5643 16.837 19.4604 17 19.2479 17H4.75086C4.5384 17 4.43534 16.8373 4.52527 16.6445V16.6435L5.92176 13.834C5.97325 13.7302 5.99999 13.6159 5.99988 13.5V9.69042C5.99988 6.44015 8.48961 3.65845 11.7089 3.50682ZM10.4999 18.5H13.4999C13.4999 19.3371 12.837 20 11.9999 20C11.1628 20 10.4999 19.3371 10.4999 18.5Z"
          />
        </svg>
        <UnseenBadge badgeCountType={badgeCountType} />
      </button>
    )
  }
)
