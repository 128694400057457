import { IonItem } from '@ionic/react'
import { CONST } from '../../const/const'

type IProps = {
  eventTab: any
  setEventTab: any
  sortStyle: string
  setSortStyle: (value: string) => void
  handleActiveTab: (tabIndex: number) => void
}

const PaperWorkTabs = ({
  setEventTab,
  eventTab,
  sortStyle,
  setSortStyle,
  handleActiveTab,
}: IProps) => {
  // Hooks and vars
  const eventFormIndex = (function () {
    return 0
  })()

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-8 text-center py-2.5 relative min-w-fit text-nr transition ease-in duration-200 focus:outline-none rounded-lg text-center`

    if (eventTab === value || sequence < event_index) {
      style = `${style} `

      if (eventTab === value) {
        return `${style} bg-SeabiscuitDullMainThemeColor text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitWhiteThemeColor`
  }

  return (
    <div className="flex items-center justify-between mb-4 w-full">
      <IonItem lines="none" className="ulfat remove-child-pl">
        <div className="Innrclass w-full">
          <div className="flex overflow-x-auto gap-x-2 tabScroll w-full">
            {/**************************************************************/}
            {/* ALL */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.PAPERWORK.TABS.ALL.SEQUENCE <= eventFormIndex) {
                  setEventTab('All')
                  handleActiveTab(0)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.PAPERWORK.TABS.ALL.VALUE,
                          CONST.UI.EVENTS.PAPERWORK.TABS.ALL.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.PAPERWORK.TABS.ALL.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  All
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* SIGNED */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.PAPERWORK.TABS.SIGNED.SEQUENCE <= eventFormIndex) {
                  setEventTab('Signed')
                  handleActiveTab(1)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.PAPERWORK.TABS.SIGNED.VALUE,
                          CONST.UI.EVENTS.PAPERWORK.TABS.SIGNED.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.PAPERWORK.TABS.SIGNED.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Signed
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* UNSIGNED */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.PAPERWORK.TABS.UNSIGNED.SEQUENCE <= eventFormIndex) {
                  setEventTab('Unsigned')
                  handleActiveTab(2)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.PAPERWORK.TABS.UNSIGNED.VALUE,
                          CONST.UI.EVENTS.PAPERWORK.TABS.UNSIGNED.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.PAPERWORK.TABS.UNSIGNED.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Unsigned
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* RECEIVED */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.PAPERWORK.TABS.RECEIVED.SEQUENCE <= eventFormIndex) {
                  setEventTab('Received')
                  handleActiveTab(3)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.EVENTS.PAPERWORK.TABS.RECEIVED.VALUE,
                          CONST.UI.EVENTS.PAPERWORK.TABS.RECEIVED.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.PAPERWORK.TABS.RECEIVED.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Received
                </div>
              </div>
            </button>
          </div>
        </div>
      </IonItem>

      <select
        className="form-control border rounded-lg border-white text-SeabiscuitDark200ThemeColor mr-1 pr-7 pt-2 text-[14px] !ring-0 border-none"
        onChange={(e) => setSortStyle(e.currentTarget.value)}
        value={sortStyle}
      >
        <option value="" disabled>
          Sort
        </option>
        <option value="Newest">Newest</option>
        <option value="Oldest">Oldest</option>
      </select>
    </div>
  )
}

export default PaperWorkTabs
