import React, { useEffect, useRef, useState } from 'react'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Search } from '@mui/icons-material'

import DrawerComponent from './Components/DrawerComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import { IconPlus } from '../../../icons/IconPlus'

import { getConvertedData } from '../../../../models/interface.helper'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'

import FirestoreService from '../../../../services/firestoreService'

import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

import verifiedIcon from './assets/verified-icon.svg'
import customImageComponent from '../../../common/CustomImageComponent'
import { getUserFullName } from '../../../../helpers/helpers'

export const STAFF_TYPES = [
  { id: 1, title: 'Show manager' },
  { id: 2, title: 'Secretary' },
  { id: 3, title: 'Technical Delegate' },
  { id: 4, title: 'Course Designers' },
  { id: 5, title: 'Judges' },
  { id: 6, title: 'Scorers' },
  { id: 7, title: 'Stewards' },
  { id: 8, title: 'Ingates' },
  { id: 9, title: 'Announcers' },
  { id: 10, title: 'Farriers' },
  { id: 11, title: 'Veterinarians' },
  { id: 12, title: 'Volunteers' },
  { id: 13, title: 'Other' },
]

const ButtonContainer: React.FC<{ children: any; className?: string }> = ({
  children,
  className,
}) => <div className={`w-[80%] flex flex-col gap-3 p-4 mx-auto ${className}`}>{children}</div>

const SelectBox: React.FC<{
  member: IUserInterfaceNew
  selected: boolean
  onClick: (caption: IUserInterfaceNew) => void
}> = ({ member, selected, onClick }) => (
  <div
    className={`p-4 cursor-pointer mb-2 rounded-2xl flex items-center gap-4 text-[14px] border-[#D3DAEE]`}
    onClick={() => onClick(member)}
  >
    <div className="w-[40px] h-[40px] min-w-[40px] rounded-full mr-2 overflow-hidden bg-gray-100">
      {customImageComponent(member.userProfilePicture, getUserFullName(member))}
    </div>
    <div className="flex-1">
      <div className="capitalize">{getUserFullName(member)}</div>
      <div className="opacity-50">{member.userEmail}</div>
    </div>
    <div>{selected && <img src={verifiedIcon} alt="verified" />}</div>
  </div>
)

const USERS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS

interface IUserInterfaceNew extends IUserInterface {
  selected?: boolean
}

const StaffModal: React.FC<{
  show: boolean
  onHide?: () => void
  onChange?: (location: any) => void
  dataToPassOn: {
    row?: IUserInterfaceNew[]
    id: number
    onSaveStaff: (value: { selected: IUserInterface[] }) => Promise<void>
    addNewPersonFeature?: boolean
    onSaveJudge?: (user: IUserInterface) => Promise<void>
  }
  title?: string
  handleModal?: any
}> = ({ show, dataToPassOn, handleModal }) => {
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { row } = dataToPassOn
  const [startSearching, setStartSearching] = useState<string>('')
  const [allUsers, setAllUsers] = useState<IUserInterfaceNew[]>([])
  const [userTeamData, setUserTeamData] = useState<IUserInterfaceNew[]>([])

  async function getAllUsers() {
    setLoading(true)

    let users: IUserInterface[] = []

    try {
      const usersSnaps = await FirestoreService.filterItems(USERS_COLLECTION.NAME)
      usersSnaps.forEach((curr) => {
        const user = getConvertedData(UserModel.fromFirestoreDoc(curr).toObject())
        const isExist = row?.find((member) => member.id === user.id)
        if (!isExist) users.push(user)
      })

      setAllUsers(users)
    } catch (error) {
      console.log('error', 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllUsers().then()
  }, [])

  useEffect(() => {
    const selectedRow = row?.map((item) => ({ ...item, selected: true })) ?? []
    setUserTeamData(selectedRow)
  }, [row])

  const title =
    dataToPassOn.id < 14 && dataToPassOn.id > 0
      ? STAFF_TYPES[dataToPassOn.id - 1].title
      : STAFF_TYPES.at(-1)?.title

  useEffect(() => {
    inputRef.current?.focus?.()
  }, [])

  useEffect(() => {}, [])

  const handleUsers = (strToSearch: string) => {
    setStartSearching(strToSearch)
    const selected = userTeamData?.filter((item) => item.selected)

    if (strToSearch === '') return setUserTeamData(selected)

    const filteredUserList = allUsers?.filter((curr) => {
      return (
        curr.userFirstName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.userLastName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.userEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
      )
    })

    setUserTeamData([...selected, ...filteredUserList])
  }

  const changeHandle = (value: any) => {
    const updated = userTeamData.map((filter) => {
      if (filter.id === value.id) {
        return { ...filter, selected: !filter.selected }
      } else {
        return { ...filter }
      }
    })
    setUserTeamData(updated)
  }

  const saveHandle = async () => {
    setButtonLoading(true)
    const selected = userTeamData.filter((member) => member.selected)
    await dataToPassOn.onSaveStaff({ selected })
    setButtonLoading(false)
  }

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <ViewsLoader size="xl" color="red" />
      </div>
    )
  }

  return (
    <DrawerComponent
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)}
      title={`Select members to add to ${title}`}
      maxWidth={640}
    >
      <div className="pr-2 max-h-[calc(100vh_-_250px)] overflow-auto">
        <div className="inputSearch py-2 flex items-center sticky top-0 bg-white">
          <input
            type="text"
            placeholder="Search..."
            ref={inputRef}
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
            onChange={(e) => handleUsers(e.target.value)}
          />
          {dataToPassOn.addNewPersonFeature ? (
            <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
              <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
              <button
                onClick={() => {
                  handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)
                  handleModal(true, MODAL_CONSTS.ADD_NEW_USER, {
                    onSaveAddUser: dataToPassOn.onSaveJudge,
                  })
                }}
                className={
                  'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-SeabiscuitMainThemeColor'
                }
              >
                <IconPlus /> New Person
              </button>
            </div>
          ) : (
            <Search className="absolute text-SeabiscuitMainThemeColor right-4" />
          )}
        </div>
        <div className="mt-6 h-[300px] overflow-y-auto">
          {userTeamData?.map((member, index) => {
            return (
              <React.Fragment key={index}>
                <SelectBox
                  member={member}
                  onClick={changeHandle}
                  selected={member?.selected ?? false}
                />
              </React.Fragment>
            )
          })}

          {startSearching !== '' && userTeamData.length < 0 && (
            <p className="p-4 text-center opacity-50">Not Found</p>
          )}
        </div>
      </div>
      <ButtonContainer className="mt-2">
        <button
          onClick={saveHandle}
          disabled={buttonLoading}
          className={`rounded-lg border border-SeabiscuitMainThemeColor border-solid text-white p-3 w-full bg-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColorDark`}
        >
          {buttonLoading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'SAVE'}
        </button>
      </ButtonContainer>
    </DrawerComponent>
  )
}

export default StaffModal
