import { useChat } from './DashboardChat'
import { useChatContext } from 'stream-chat-react'

export const FilterButton = ({
  text,
  focus = false,
  onClick,
}: {
  text: string
  focus?: boolean
  onClick: () => void
}) => {
  const { setActiveChannel } = useChatContext()
  const { setShowProfile } = useChat()
  return (
    <button
      onClick={() => {
        setActiveChannel(undefined)
        setShowProfile(false)
        onClick()
      }}
      className={`rounded-md p-2 w-24 ${focus ? 'bg-SeabiscuitMainThemeColor/5  text-SeabiscuitMainThemeColor' : ''}`}
    >
      {text}
    </button>
  )
}

export const AllButton = ({ focused, onClick }: { focused?: boolean; onClick?: () => void }) => {
  const { setChatFilters } = useChat()
  return (
    <FilterButton
      text={'All'}
      onClick={() => {
        setChatFilters({ has_unread: undefined })
        onClick?.()
      }}
      focus={focused}
    />
  )
}

export const UnreadButton = ({ focused, onClick }: { focused?: boolean; onClick?: () => void }) => {
  const { setChatFilters } = useChat()

  return (
    <FilterButton
      text={'Unread'}
      onClick={() => {
        setChatFilters((f) => ({ ...f, has_unread: -1 }))
        onClick?.()
      }}
      focus={focused}
    />
  )
}

export const ExhibitorsButton = ({
  focused,
  onClick,
}: {
  focused?: boolean
  onClick?: () => void
}) => {
  const { setChatFilters } = useChat()

  return (
    <FilterButton
      text={'Exhibitors'}
      onClick={() => {
        setChatFilters((f) => ({ ...f, vertical: { $eq: 'exhibitors' } }))
        onClick?.()
      }}
      focus={focused}
    />
  )
}

export const StaffButton = ({ focused, onClick }: { focused?: boolean; onClick?: () => void }) => {
  const { setChatFilters } = useChat()

  return (
    <FilterButton
      text={'Staff'}
      onClick={() => {
        setChatFilters((f) => ({ ...f, vertical: { $eq: 'staff' } }))
        onClick?.()
      }}
      focus={focused}
    />
  )
}
