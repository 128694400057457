import React, { FC, useState } from 'react'

import Clock from 'web/public/assets/cp_icons/Clock-1.svg'

import { getIconColor } from '../../../../../helpers/getIconColor'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { sortByOrder } from '../../../../../pages/organizer/manage/clinic/ManageClinicNOtherOperationsTab/ManageClinicNOtherOperationsTab'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { convertTime } from '../../../../../helpers/time'
import { IBreak } from '../../../../../models/events/event.interface'
import { IconClock } from '../../../../icons/IconClock'
import { useEventTrainers } from '../../../../../hooks/useEventTrainers'
import { RiderTeamMemberModel } from '../../../../../models/rider-team-member/riderTeamMember.model'

// Types
type ExhibitorOrderOfGoAccordionProps = {
  data: IRegistrationByDayInterface[]
  fullTime: string
  increment: string
  publishOrder: boolean
  horses: IHorseData[]
  eventId: string
}

interface DetailRowProps {
  fullTime: string
  increment: string
  index: number
  color: string
  row: IRegistrationByDayInterface | IBreak
  publishOrder: boolean
  horse?: IHorseData
  trainer?: RiderTeamMemberModel
}
const DetailRow: FC<DetailRowProps> = ({
  row,
  fullTime,
  increment,
  publishOrder,
  index,
  color,
  trainer,
}) => {
  const [errorImg, setErrorImg] = useState(false)
  const newIncrement = increment && increment.length === 8 ? increment : '00:00:00'

  if ((row as IBreak).isBreakDrag) {
    if (!publishOrder) {
      return <></>
    }
    function convertTimeStringToReadableFormat(timeString: string): string {
      const [hoursStr, minutesStr, secondsStr] = timeString.split(':')

      const hours = parseInt(hoursStr, 10)
      const minutes = parseInt(minutesStr, 10)
      const seconds = parseInt(secondsStr, 10)

      let result = ''

      if (hours > 0) {
        result += `${hours}h `
      }

      if (minutes > 0) {
        result += `${minutes}min `
      }

      if (seconds > 0) {
        result += `${seconds}sec`
      }

      return result.trim()
    }
    return (
      <>
        <div className={'flex w-full my-4 justify-between gap-4'}>
          <div className={'flex-1'}>
            <div className={'flex items-center gap-3'}>
              <span
                className={
                  'w-[45px] h-[45px] rounded-full flex items-center justify-center bg-[#F6F7FB]'
                }
              >
                <IconClock />
              </span>

              <span className={'text-SeabiscuitDark200ThemeColor font-bold text-base'}>
                Break / Drag
              </span>
            </div>
          </div>
          <div>
            <div className="md:ml-auto flex gap-3 justify-center items-center">
              <div>
                <div className="font-semibold text-right">Break</div>
                <div className="opacity-50 flex items-center gap-1 text-[14px]">
                  <img src={Clock} className="w-4" alt="" />
                  {convertTimeStringToReadableFormat((row as IBreak).time) || '0min'}
                </div>
              </div>
              <div className="p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center bg-[#F6F7FB] text-black">
                {(row as IBreak).order}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }
  const registration = row as IRegistrationByDayInterface

  const arrayName = registration.riderName?.split(' ') || []

  return (
    <>
      <div className="flex flex-wrap items-center justify-between w-full my-4 gap-4 md:gap-2">
        <div className="w-full md:w-1/3 flex items-center">
          {registration.riderProfilePicture && !errorImg ? (
            <img
              onError={() => setErrorImg(true)}
              src={registration.riderProfilePicture}
              alt="avatar"
              className="w-[45px] h-[45px] rounded-full mt-1"
            />
          ) : (
            <div
              style={{ background: color }}
              className={`w-[45px] h-[45px] rounded-full mt-1 flex items-center justify-center text-white`}
            >
              {arrayName[0][0]}
              {arrayName.length > 1 ? arrayName[arrayName.length - 1][0] : ''}
            </div>
          )}
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {registration.riderName}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {registration.backNumber ? `Back number • ${registration.backNumber}` : null}
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/3 flex items-center">
          <img
            src={
              registration?.horseProfilePicture
                ? registration.horseProfilePicture
                : IMAGE_CONSTS.PLACEHOLDERS.USER
            }
            alt="avatar"
            className="w-[45px] h-[45px] rounded-full mt-1"
          />
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {registration.horseName ?? 'No Horse'}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {trainer ? `Trainer • ${trainer.teamMemberName}` : null}
            </span>
          </div>
        </div>
        {publishOrder ? (
          registration.orderOfGoScratched ? (
            <div className="md:ml-auto">
              <div className="md:text-right text-SeabiscuitDark200ThemeColor">No Place</div>
              <div className="md:text-right text-[14px] text-SeabiscuitDark200ThemeColor/50">
                Scratched
              </div>
            </div>
          ) : (
            <div className="md:ml-auto flex gap-3 justify-center items-center">
              <div>
                <div className="font-semibold text-right">Place</div>
                <div className="opacity-50 flex items-center gap-1 text-[14px]">
                  <img src={Clock} className="w-4" alt="" />
                  {convertTime(fullTime, newIncrement, index)}
                </div>
              </div>
              <div className="p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center bg-[#F6F7FB] text-black">
                {registration.order}
              </div>
            </div>
          )
        ) : (
          <div className="md:ml-auto">
            <div className="md:text-right text-SeabiscuitDark200ThemeColor">Order of Go</div>
            <div className="md:text-right text-[14px] text-SeabiscuitDark200ThemeColor/50">
              Not yet published
            </div>
          </div>
        )}
      </div>
      <hr></hr>
    </>
  )
}

const ExhibitorOrderOfGoAccordion: FC<ExhibitorOrderOfGoAccordionProps> = ({
  data,
  fullTime,
  increment,
  publishOrder,
  horses,
  eventId,
}) => {
  const { eventTrainers } = useEventTrainers(eventId)
  return (
    <>
      {data?.length > 0 &&
        sortByOrder(data).map(
          (currentItem: IRegistrationByDayInterface | IBreak, index: number) => {
            const regByDay = currentItem as IRegistrationByDayInterface

            const trainer = eventTrainers?.find(
              (eventTrainer) => eventTrainer.registrationByDayUniqId === regByDay.uniqId
            )

            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                row={currentItem}
                trainer={trainer}
                fullTime={fullTime}
                increment={increment}
                publishOrder={publishOrder}
                horse={
                  (currentItem as IBreak).isBreakDrag
                    ? undefined
                    : horses.find(
                        (horse) => horse.id === (currentItem as IRegistrationByDayInterface).horseId
                      )
                }
                index={index}
                color={getIconColor(data?.length, index)}
              />
            )
          }
        )}
    </>
  )
}

export default ExhibitorOrderOfGoAccordion
