import React from 'react'

interface IProps {
  color?: string
}

export const IconQuestion: React.FC<IProps> = ({ color = '#F70763' }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill={color}
          d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 1.5c4.703 0 8.5 3.797 8.5 8.5 0 4.703-3.797 8.5-8.5 8.5A8.489 8.489 0 011.5 10c0-4.703 3.797-8.5 8.5-8.5zm0 3c-1.648 0-3 1.352-3 3v.25a.75.75 0 101.5 0V7.5c0-.837.663-1.5 1.5-1.5s1.5.663 1.5 1.5c0 1.17-.304 1.317-.838 1.736-.267.21-.616.452-.915.859-.299.407-.497.974-.497 1.655a.75.75 0 101.5 0c0-.427.083-.6.206-.768.123-.167.336-.334.632-.566C12.179 9.951 13 9.08 13 7.5c0-1.648-1.352-3-3-3zm0 9.5a1 1 0 100 2 1 1 0 000-2z"
        ></path>
      </svg>
    </>
  )
}
