import React from 'react'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent, selectPublishedEvent } from '../../../../store/events/eventsSlice'
import SwitchElementUpdated from '../paperwork/SwitchElement'
import { EventQuestionInput } from '../../../../pages/shows/detail/components/EventQuestionInput'
import { IconPlusCircle } from '../../../icons/IconPlusCircle'
import { IQuestion } from '../../../../models/events/event.interface'

interface IProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  nextEventTab?: string
  eventTab?: string
}

export const EventQuestionsFormComponent: React.FC<IProps> = (props) => {
  const event = useAppSelector(selectedEvent)
  const publishedEvent = useAppSelector(selectPublishedEvent)
  const [questions, setQuestions] = React.useState<IQuestion[]>([
    {
      id: `${new Date().getTime()}`,
      value: '',
      isRequired: false,
    },
  ])
  const [isCustomQuestion, setIsCustomQuestion] = React.useState(false)

  const onChangeRequiredHandler = (isRequired: boolean, index: number) => {
    const newQuestions = [...questions]

    newQuestions[index] = {
      ...newQuestions[index],
      isRequired,
    }

    setQuestions(newQuestions)
  }
  const onChangeHandler = (value: string, index: number) => {
    const newQuestions = [...questions]

    newQuestions[index] = {
      ...newQuestions[index],
      value,
    }

    setQuestions(newQuestions)
  }

  React.useEffect(() => {
    if (event && event.Event && Array.isArray(event.Event.questions) && event.Event.questions[0]) {
      setQuestions(event.Event.questions)
      setIsCustomQuestion(true)
    }
  }, [event])

  const removeHandler = (index: number) => {
    const newQuestions = [...questions]
    newQuestions.splice(index, 1)
    setQuestions(newQuestions)
  }

  const renderQuestion = React.useMemo(() => {
    return questions.map((question, index) => (
      <EventQuestionInput
        isRequired={question.isRequired}
        onChangeRequired={(value) => onChangeRequiredHandler(value, index)}
        removeHandler={() => removeHandler(index)}
        onChange={(value) => onChangeHandler(value, index)}
        value={question.value}
        key={question.value + question.id + index}
      />
    ))
  }, [questions])

  return (
    <EventFormContainerComponent>
      <div className="mr-4 mb-4 w-full mx-auto">
        <EventFormHeaderComponent
          title="Custom Questions"
          description="Add questions for exhibitors to answer during registration, including uploading files."
        >
          <EventFormFooterCommonComponent
            publishedEvent={publishedEvent}
            publishButton={false}
            loading={false}
            onSaveAndExit={() => {
              return null
            }}
            onNext={(e: any, publishEvent) => {
              return publishEvent({
                dataToSave: {
                  v: event?.Event?.v || '',
                  id: event?.Event?.id || '',
                  owner: event?.Event?.owner || '',
                  questions: isCustomQuestion ? questions : [],
                },
                tabName: 'EventQuestions',
                validFormHandler: props.onValid,
              })
            }}
          />
        </EventFormHeaderComponent>

        <div className={'flex flex-wrap item-center justify-between gap-3 mt-7'}>
          <h2 className={'m-0 p-0 text-[#122B46] font-bold text-[20px] h-auto flex items-center '}>
            Do you want to add custom questions for this event?
          </h2>
          <SwitchElementUpdated
            onSwitch={() => setIsCustomQuestion(!isCustomQuestion)}
            on={isCustomQuestion}
          />
        </div>

        {isCustomQuestion && (
          <div className={'mt-7 flex flex-col gap-8'}>
            {renderQuestion}

            <button
              onClick={() => {
                setQuestions([
                  ...questions,
                  { id: `${new Date().getTime()}`, value: '', isRequired: false },
                ])
              }}
              className={
                'flex gap-3 items-center group text-[#122B4680] text-[14px] hover:text-SeabiscuitMainThemeColor'
              }
            >
              <IconPlusCircle />
              <span>New question</span>
            </button>
          </div>
        )}
      </div>
    </EventFormContainerComponent>
  )
}
