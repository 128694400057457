import { useEffect, useState } from 'react'

// Third party
import { AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { where } from 'firebase/firestore'
import { useIonRouter } from '@ionic/react'
import { Clear } from '@mui/icons-material'
import { cloneDeep } from 'lodash'

import ViewsLoader from '../../loader/ViewsLoader'
import { SelectPaymentMethod } from '../../payment/SelectPaymentMethod/SelectPaymentMethod'

// Redux
import { selectedEvent as selectedEventGetter } from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectpaymentStatus,
  selectProfileData,
  setPaymentStatus,
} from '../../../store/user/userSlice'
import {
  selectPayTabSummaryAccordion,
  selectRecipientsInDb,
  selectRegistertrationPaying,
  selectRegistration,
  selectRegistrationFeesR,
  selectRegistrationTicketsR,
  setPaying,
  setRecipientUser,
  setRecipientsInDb,
  setRegistrationAc,
  setRegistrationsByDay,
  setRegistrationsFeesAc,
  setRegistrationsTicketsAc,
  selectFilteredPaperworkDocuments,
  selectRidersTeamMembersR,
  selectRegisterTabData,
} from '../../../store/registration/registrationSlice'
import {
  allDataReset,
  selectSpectatorTicketsR,
  setPaymentDetails,
} from '../../../store/tickets/ticketslice'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'

import FirestoreService from '../../../services/firestoreService'

import { IUserCards, IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'

// Services
import helpers from '../../../commonHelpers/helpers'

import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../helpers/helpers'
import { eligibleToSign } from '../../../helpers/sign'

import { ICompetitorEventRegisterTypes as ICerhTypes } from '../../../pages/competitor/competitorEventRegister/competitorEventRegisterHelper'
import { httpService } from '../../../services/httpService'

import {
  IRegisterTab,
  TEventRegisteredUsers,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RecipientModel } from '../../../models/recipients/recipients'
import { IManageInfo } from '../../../pages/competitor/event-registration-tabs/EventRegistrationTabs'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'

type Props = {
  show: boolean
  handleModal: any
  dataToPassOn: {
    saveAllTabs: ICerhTypes['ISaveAllTabsFn']
    type?: any
    eventId: string
    assignedTickets: any
    registeredUser: IUserInterface | null
    manageInfo?: IManageInfo
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const FILE_NAME = 'RegisterForCompetitionPayByCardModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const RegisterForCompetitionPayByCardModal = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const router = useIonRouter()
  const history = useHistory()
  const toasterFunctions = useToasterHelper()
  const registration = useAppSelector(selectRegistration)
  const selectedEvent = useAppSelector(selectedEventGetter)
  const paying = useAppSelector(selectRegistertrationPaying)
  const recipientsInDb = useAppSelector(selectRecipientsInDb)
  const registrationsFees = useAppSelector(selectRegistrationFeesR)
  const registrationsTickets = useAppSelector(selectRegistrationTicketsR)
  const summaryAccordionData = useAppSelector(selectPayTabSummaryAccordion)
  const spectator_ticket = useAppSelector(selectSpectatorTicketsR)
  const paymentStatus = useAppSelector(selectpaymentStatus)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const registeredUser = useAppSelector((state) => state.registeredUser.data)
  const ridersTeamMembers = useAppSelector(selectRidersTeamMembersR)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const userProfile = useAppSelector(selectProfileData)
  const { eventMainData, payment_detail } = spectator_ticket
  const isTicketPayment = payment_detail?.type === 'spectator'

  const [amountToPay, setAmountToPay] = useState(0)
  const [stripeRes, setStripeRes] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)
  const [isPayByCash, setIsPayByCash] = useState(false)
  const [currentRegistrationsByDay, setCurrentRegistrationsByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)
  const [organiserProfile, setOrganiserProfile] = useState<IUserInterfaceExtended | null>(null)

  const getReRegistrationsByDay = (registerTabData: IRegisterTab[]) => {
    const registrationsByDay_: IRegistrationByDayInterface[] = []
    registerTabData.forEach((register) => {
      register.registrationsByDay.forEach((registrationByDay) => {
        registrationsByDay_.push(registrationByDay)
      })

      register.children?.forEach((child) => {
        child.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDay_.push(registrationByDay)
        })
      })
    })
    setCurrentRegistrationsByDay(registrationsByDay_)
  }

  useEffect(() => {
    if (registration?.id && window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)) {
      getReRegistrationsByDay(props.dataToPassOn.manageInfo?.register ?? [])
    } else {
      getReRegistrationsByDay(registerTabData)
    }
  }, [props.dataToPassOn.manageInfo?.register, registerTabData])

  useEffect(() => {
    let amountToPay_

    if (isTicketPayment) {
      amountToPay_ = payment_detail?.price
    } else {
      amountToPay_ = summaryAccordionData.remainingTotalPrice
    }

    setAmountToPay(amountToPay_)
  }, [
    summaryAccordionData.remainingTotalPrice,
    payment_detail,
    isTicketPayment,
    summaryAccordionData.totalPrice,
  ])

  const getOwnerProfile = async () => {
    const ownerSnapshots = await FirestoreService.getItem(
      COLLECTIONS.USERS.NAME,
      selectedEvent?.basicEventDetails?.owner ?? ''
    )

    const owner = UserModel.fromFirestoreDoc(ownerSnapshots).toObject()

    setOrganiserProfile(owner)
  }

  useEffect(() => {
    if (selectedEvent.basicEventDetails.owner) getOwnerProfile().then()
  }, [selectedEvent.basicEventDetails.owner])

  // Functions
  const saveAllTabs = async (args: ICerhTypes['ISaveAllTabsFnArgs']) => {
    if (props?.dataToPassOn && typeof props?.dataToPassOn?.saveAllTabs === 'function') {
      await props?.dataToPassOn?.saveAllTabs(args)
    }
  }

  const closeModal = () => {
    if (isTicketPayment && paymentStatus) {
      dispatch(allDataReset())
    }
    dispatch(setPaymentStatus(false))
    props.handleModal(false, 'competitonPayByCard')
  }

  const onViewReceiptClick = async () => {
    if (isTicketPayment) {
      let anchorElement = document.createElement('a')
      anchorElement.target = '_blank'
      anchorElement.href = stripeRes?.receipt_url ?? ''
      document.body.appendChild(anchorElement)
      anchorElement.click()
    } else {
      const recipientSnapshots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          registration?.id
        ),
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
          '==',
          registration?.userId
        ),
      ])

      const recipient = RecipientModel.fromFirestoreDoc(recipientSnapshots.docs[0]).toObject()

      props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD)
      props.handleModal(true, MODAL_CONSTS.BILL_BRIEFE, {
        bill: recipient,
      })
    }
  }

  const updateData = async (args: {
    cardNumber: IRecipientInterface['cardNo']
    eventId: TEventRegisteredUsers['eventId']
    isRegistered: TEventRegisteredUsers['isrecipient']
    paymentStatus: TEventRegisteredUsers['paymentStatus']
  }) => {
    let recipientSelected = false
    let isRegistered = args?.isRegistered ?? false
    let userData = cloneDeep(summaryAccordionData.userData)
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let registrationsTickets_: IRegistrationTicketInterface[]
    let registrationsFees_: IRegistrationFeesInterface[]

    if (currentRegistrationsByDay)
      registrationsByDay_ = currentRegistrationsByDay.map((currRegistrationByDay) => {
        recipientSelected = !!currRegistrationByDay?.recipientId
        return {
          ...currRegistrationByDay,
          recipientId: !recipientSelected ? registeredUser?.id : currRegistrationByDay?.recipientId,
          isPaidByOwner: !recipientSelected,
          paymentStatus: recipientSelected ? 'pending' : 'paid',
          update: true,
        }
      })

    registrationsTickets_ = registrationsTickets.map((currRegistrationTicket) => {
      recipientSelected = !!currRegistrationTicket?.recipientId
      return {
        ...currRegistrationTicket,
        recipientId: !recipientSelected
          ? (registeredUser?.id ?? null)
          : currRegistrationTicket?.recipientId,
        isPaidByOwner: !recipientSelected,
        paymentStatus: recipientSelected ? 'pending' : 'paid',
        update: true,
      }
    })

    registrationsFees_ = registrationsFees.map((currRegistrationFees) => {
      recipientSelected = !!currRegistrationFees?.recipientId
      return {
        ...currRegistrationFees,
        recipientId: !recipientSelected
          ? (registeredUser?.id ?? null)
          : currRegistrationFees?.recipientId,
        isPaidByOwner: !recipientSelected,
        paymentStatus: recipientSelected ? 'pending' : 'paid',
        update: true,
      }
    })

    userData = {
      ...userData,
      update: true,
      cardNo: args.cardNumber ?? null,
      paymentStatus: args.paymentStatus === 'paid' ? 'paid' : userData.paymentStatus,
    }

    let recipientsInDb_ = cloneDeep(recipientsInDb).map((currRecipientInDb) => {
      if (currRecipientInDb.recipientId === userData.recipientId) {
        currRecipientInDb = {
          ...currRecipientInDb,
          ...userData,
        }
      }

      return currRecipientInDb
    })

    dispatch(setRecipientUser(userData))
    dispatch(setRecipientsInDb(recipientsInDb_))
    dispatch(setRegistrationsFeesAc(registrationsFees_))
    dispatch(setRegistrationsByDay(registrationsByDay_))
    dispatch(setRegistrationsTicketsAc(registrationsTickets_))

    if (!registration)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'updateData',
        devMessage: `${registration} is [${registration}]`,
      })
    else {
      const registrationData = {
        ...registration,
        isRegistered,
        paymentStatus: args.paymentStatus,
      }
      dispatch(setRegistrationAc(registrationData))
    }
  }

  const payByCard = async (eventId: string) => {
    const pmId = selectedCard?.pmId
    const cardNumber = selectedCard?.cardNumber

    const data = {
      amount: amountToPay,
      userId: registeredUser?.id,
      pmId,
      eventId: selectedEvent.basicEventDetails.id,
      registrationId: registration?.id,
      ...(!isTicketPayment && {
        recipientDocId: userProfile.id,
      }),
      paymentType: isTicketPayment ? 'spectator_tickets' : 'event_register',
      description: !registeredUser?.id ? 'Guest User buy tickets' : 'Event Registration',
      userEmail: userProfile.userEmail,
      userName: getUserFullName(userProfile),
      userPhone: userProfile.userPhoneNumber,
      userStripeAccountId: organiserProfile?.userStripeAccountId,
    }

    let createChargeRes: {
      paymentIntent?: {
        recipient: IRecipientInterface
        id: string
        amount: number
        receipt_url: string
        transactionDocId: string
      }
    } | null

    createChargeRes = await httpService({
      url: 'create_payment_intents',
      method: 'POST',
      data,
    })
    if (createChargeRes?.paymentIntent?.id) setStripeRes(createChargeRes.paymentIntent)

    dispatch(setPaymentStatus(true))
    if (isTicketPayment) {
      dispatch(
        setPaymentDetails({
          payment_detail: {
            ...payment_detail,
            setLoading,
          },
          payment_status: {
            status: true,
            show_payment_done: false,
            data: createChargeRes?.paymentIntent ?? '',
          },
        })
      )
    } else {
      await updateData({
        isRegistered: true,
        eventId: eventId,
        paymentStatus: 'paid',
        cardNumber: cardNumber ?? null,
      })
    }

    if (registration?.id && window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)) {
      await saveAllTabs({
        isManage: true,
        cardNo: cardNumber || undefined,
        isPaid: true,
        invoiceId: createChargeRes?.paymentIntent?.id ?? '',
        invoiceUrl: createChargeRes?.paymentIntent?.receipt_url ?? '',
      })
    } else {
      await saveAllTabs({
        isPaid: true,
        cardNo: cardNumber || undefined,
        invoiceId: createChargeRes?.paymentIntent?.id ?? '',
        invoiceUrl: createChargeRes?.paymentIntent?.receipt_url ?? '',
      })
    }
  }

  const onPayByCash = async () => {
    if (registration?.id && window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)) {
      await saveAllTabs({ isManage: true, isPayByCash: true })
    } else {
      await saveAllTabs({ isPayByCash: true })
    }
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      registration?.id ?? '',
      {
        isRegistered: true,
        paymentStatus: PAYMENT_STATUSES.PENDING,
      }
    )
    dispatch(setPaymentStatus(true))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Pays the grand total amount of the grand total
   */
  const onPayAmount = async () => {
    const eventOwnerId = selectedEvent.basicEventDetails.owner
    let eventId: string | null = selectedEvent.basicEventDetails.id ?? null

    const { notSignedOwner, isMinorGuardian } = eligibleToSign({
      paperworkDocuments: filteredPaperworksDocuments,
      userId: registration?.userId ?? '',
      ridersTeamMembers,
      isManage: window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL),
      manageInfo: props.dataToPassOn.manageInfo,
    })

    if (!selectedEvent.basicEventDetails.id) {
      return toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } else if (notSignedOwner) {
      return toasterFunctions.error({
        message: MESSAGES_CONST.REGISTRATION_EVENT_NOT_SIGNED,
      })
    } else if (isMinorGuardian) {
      return toasterFunctions.error({
        message: MESSAGES_CONST.REGISTRATION_EVENT_GUARDIAN_IS_MINOR,
      })
    }

    if (!isTicketPayment) {
      if (
        !currentRegistrationsByDay?.length &&
        !window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)
      )
        return toasterFunctions.error({
          message: MESSAGES_CONST.PLEASE_ADD_TEAM_MEMBERS,
        })
    }

    if (!selectedCard?.pmId && !isPayByCash)
      return toasterFunctions.error({
        message: MESSAGES_CONST.PLEASE_SELECT_CARD,
      })

    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      eventId,
      eventOwnerId,
      ...(!isPayByCash && { pmId: selectedCard?.pmId }),
      ...(!isTicketPayment && {
        userId: registeredUser?.id,
        registrationId: registration?.id,
      }),
    })

    if (emptyVarName)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'onPayAmount',
        devMessage: `${emptyVarName} is [${emptyVarValue}]`,
      })

    dispatch(setPaying(true))
    setLoading(true)

    if (isTicketPayment) eventId = eventMainData?.id ?? null

    try {
      if (isPayByCash) {
        await onPayByCash()
      } else {
        await payByCard(eventId ?? '')
      }
    } catch (error: any) {
      toasterFunctions.error({
        message:
          error.response?.data?.message ?? error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.log(error, 'error')
      dispatch(setPaying(false))
      setLoading(false)
    } finally {
      dispatch(setPaying(false))
      setLoading(false)
      let link
      if (registration) {
        link = `${CONST.ROUTES.COMPETITOR_REGISTERED_EVENT.URL}/${registration?.userId}/${registration?.eventId}/${registration?.id}`
      } else {
        link = `${CONST.ROUTES.EVENT_DETAILS.URL}/${eventId}`
      }

      if (!isTicketPayment) {
        router.push(link)
        history.push(link)
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes payment tab and opens add new card tab
   */
  const openAddNewCardModal = () => {
    props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD, {
      type: 'ticketPurchase',
    })
    props.handleModal(true, MODAL_CONSTS.CARD, {
      saveAllTabs,
      add_card: !isTicketPayment,
      re_open_modal: true,
      type: 'ticketPurchase',
      modal_name: MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD,
      manageInfo: props.dataToPassOn.manageInfo,
      organizerId: selectedEvent.basicEventDetails.owner,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes pay using card modal
   * @info Closes pay using card modal
   */
  const closePayByCardModal = () => {
    if (isTicketPayment) dispatch(allDataReset())
    dispatch(setPaymentStatus(false))
    props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 max-h-[90vh]">
          <span
            onClick={() => {
              if (loading || paying) return

              closeModal()
            }}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          {!paymentStatus && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Pay
                </h5>
              </div>
              <div className="min-h-1/2 overflow-y-auto">
                <div className="mt-3 flex w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      You will be charged:
                    </p>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Dollarcoin.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        $
                        {isTicketPayment
                          ? payment_detail?.price?.toFixed(2)
                          : amountToPay?.toFixed(2)}
                      </p>
                    </div>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Error.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        Payment required to complete registration
                      </p>
                    </div>
                  </div>
                </div>
                <SelectPaymentMethod
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  selectedEvent={selectedEvent}
                  setIsPayByCash={setIsPayByCash}
                  isPayByCash={isPayByCash}
                  openAddNewCardModal={openAddNewCardModal}
                  userProfile={userProfile}
                  organiserProfile={organiserProfile}
                />
              </div>

              <button
                disabled={paying || loading}
                type="submit"
                className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor focus:ring-SeabiscuitMainThemeColor"
                onClick={onPayAmount}
              >
                {paying || loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : isPayByCash ? (
                  'Submit registration'
                ) : (
                  'Pay'
                )}
              </button>

              <button
                disabled={paying || loading}
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:bg-[#0b15261a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                Cancel
              </button>
            </>
          )}

          {paymentStatus && (
            <>
              <div className="mx-auto w-full">
                {!paying ? (
                  <>
                    <img className="mx-auto" src={'assets/img/light/Ok.svg'} alt="icon" />
                    <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                      Success!
                    </h6>
                  </>
                ) : (
                  <>
                    <ViewsLoader color="red" size="md" />
                    <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                      Processing! please wait
                    </h6>
                  </>
                )}

                {!isTicketPayment ? (
                  <>
                    <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                      You registered for this event.
                    </p>
                    {isPayByCash && (
                      <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                        You will be required to pay your bill when you arrive at the show.
                      </p>
                    )}
                    <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                      Each of your team members were sent paperwork to sign .
                    </p>
                  </>
                ) : null}

                <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3  ">
                  Amount paid: ${(stripeRes?.amount ?? 0) / 100}
                </p>
              </div>
              <button
                disabled={!paymentStatus || paying}
                className={clsx(
                  'w-full h-12 mx-auto py-2 px-4 mt-5 border-2 border-solid border-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor hover:text-white bg-[white]  flex items-center justify-center'
                )}
                onClick={onViewReceiptClick}
              >
                VIEW RECEIPT
              </button>
              <button
                disabled={!paymentStatus || paying}
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor "
              >
                CLOSE
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RegisterForCompetitionPayByCardModal
