import React from 'react'
import { Link } from 'react-router-dom'

// Third party
import { motion } from 'framer-motion'
import { IonList } from '@ionic/react'
import { KeyboardBackspace } from '@mui/icons-material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import clsx from 'clsx'
import ViewsLoader from '../../loader/ViewsLoader'

// Types
type IWithoutCustomHeader = {
  headLink?: string
  loading?: boolean
  isError?: boolean
  title?: string
  className?: string
  children: ReactJSXElement
  noBg?: boolean
  wrapperStyles?: Record<any, any>
  removeShadow?: boolean
  removePadding?: boolean
  customHeader?: ReactJSXElement
  headerStyles?: React.CSSProperties
  hideTitle?: boolean
  onGoBackButtonClick?: (args?: any) => any
}

type IWithCustomHeader = {
  headLink?: string
  title?: string
  loading?: boolean
  children: ReactJSXElement
  noBg?: boolean
  className?: string
  isError?: boolean
  wrapperStyles?: Record<any, any>
  removeShadow?: boolean
  removePadding?: boolean
  customHeader: ReactJSXElement
  headerStyles?: React.CSSProperties
  hideTitle?: boolean
  onGoBackButtonClick?: (args?: any) => any
}

type IProps = IWithoutCustomHeader | IWithCustomHeader

/**
 * @info Wraps the component
 * @returns jsx component
 */
const WrapperContainer = (props: IProps) => {
  let containerClasses = clsx('w-full md:px-[25px] 2xl:px-[45px] px-4')

  const ionListStyles = {
    marginBottom: '77px',
    minHeight: '85vh',
    padding: props?.removePadding ? '0' : '20px',
    borderRadius: '20px',
    marginTop: !props?.title ? '0' : '',
    display: props.loading || props.isError ? 'flex' : 'block',
    alignItems: props.loading || props.isError ? 'center' : 'normal',
    background: props?.noBg === true ? 'transparent' : 'white',
    boxShadow: props?.removeShadow
      ? 'none'
      : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '--ion-background-color': props?.noBg === true ? 'transparent' : 'white',
    '--background': props?.noBg === true ? 'transparent' : 'white',
    ...(props.className && { className: props.className }),
    ...(props?.title === 'Bills' && { height: 'calc(98vh - 131px)' }),
  }

  let headerStyles = props?.headerStyles ?? {}

  return (
    <motion.div
      className={containerClasses}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.5,
      }}
    >
      {props?.customHeader ? (
        props.customHeader
      ) : props?.title ? (
        <div
          className={clsx(
            `text-base md:text-[24px] py-3 text-SeabiscuitDark200ThemeColor font-bold wrapper_container`,
            props?.title === 'My Events' && 'mt-14'
          )}
          style={headerStyles}
        >
          {!props?.hideTitle ? (
            props.headLink ? (
              <Link
                to={props.headLink}
                onClick={() => props?.onGoBackButtonClick?.()}
                className="transition-all hover:opacity-70"
              >
                <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
                {props?.title}
              </Link>
            ) : (
              (props?.title ?? '')
            )
          ) : null}
        </div>
      ) : null}
      <IonList style={ionListStyles}>
        {props.loading ? (
          <ViewsLoader className="flex items-center w-full justify-center" size="lg" color="red" />
        ) : (
          props.children
        )}
      </IonList>
    </motion.div>
  )
}

export default WrapperContainer
