// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonListHeader, IonTitle } from '@ionic/react'
import { IconChevron } from '../../icons/IconChevron'
import { useState } from 'react'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface HomeSectionHeaderComponentProps {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * The title that will be shown at the header of the item.
   */
  title: string
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * The route to which the "See All" button will head to.
   *
   * When testing this is not necessary
   */
  seeAllRouteUrl?: string
  events?: string
  filterByStatus: (status: string) => void
}

export enum EMenu {
  upcoming,
  past,
  cancelled,
}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const HomeSectionHeaderComponent: React.FC<HomeSectionHeaderComponentProps> = (props) => {
  // past
  // draft
  // cancel
  // current
  const [menu] = useState([
    {
      title: 'Upcoming',
      value: 'current',
    },
    {
      title: 'Past',
      value: 'past',
    },
    {
      title: 'Cancelled',
      value: 'cancel',
    },
  ])
  const [activeMenu, setActiveMenu] = useState(EMenu.upcoming)
  const [isSubMenuShown, setIsSubMenuShown] = useState(false)
  const ref = useOutsideClick(() => setIsSubMenuShown(false))

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonListHeader className="mx-0 z-10 w-full relative overflow-visible box-border px-5">
      <div className="flex flex-wrap justify-between items-center w-full mx-auto gap-2 pb-4">
        <IonTitle className="p-0 font-bold flex-1 text-[15px] text-SeabiscuitDark200ThemeColor">
          {menu[activeMenu].title}
        </IonTitle>
        <div ref={ref} className={'relative z-10'}>
          <div
            onClick={() => setIsSubMenuShown(true)}
            className={
              'text-SeabiscuitDark200ThemeColor justify-between flex items-center gap-3 cursor-pointer'
            }
          >
            <span className={isSubMenuShown ? 'rotate-180' : ''}>
              <IconChevron />
            </span>{' '}
            <span>{menu[activeMenu].title}</span>
          </div>

          {isSubMenuShown && (
            <div
              className={
                'absolute top-[100%] rounded border border-[#D3DAEE] p-2 overflow-hidden mr-[-10px] right-0 flex flex-col bg-white min-w-[200px]'
              }
            >
              {menu.map((itm, i) => {
                if (activeMenu === i) {
                  return <></>
                }
                return (
                  <button
                    className={'text-right py-1 px-2 hover:bg-[#F6F7FB] rounded'}
                    onClick={() => {
                      setActiveMenu(i)
                      props.filterByStatus(menu[i].value.toLowerCase())
                      setIsSubMenuShown(false)
                    }}
                    key={itm.value + i}
                  >
                    {itm.title}
                  </button>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </IonListHeader>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
HomeSectionHeaderComponent.defaultProps = {
  title: 'Default Title',
  seeAllRouteUrl: '',
}

export default HomeSectionHeaderComponent
