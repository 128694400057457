import { cloneDeep, sumBy } from 'lodash'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import Button from '../../../../components/common/buttons/Button'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { IRegistrationFeesInterface } from '../../../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import {
  refundAmountThunk,
  resetExhibitorRefundFields,
  selectExhibitorRefundErrors,
  selectExhibitorRefundTotals,
  selectExhibitorTotals,
  selectRefundAmountR,
} from '../../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import helpers from '../../../../commonHelpers/helpers'
import { CustomError, toFixed } from '../../../../helpers/helpers'
import Accordion from '../../../../components/accordion/common/Accordion'
import { useRef, useState } from 'react'

// Types
type IProps = {
  userDocId: string
  registrationId: string
  registrationData: IRegistrationByDayInterface[]
  registrationfeesData: IRegistrationFeesInterface[]
  registrationticketData: IRegistrationTicketInterface[]
  setRegistrationData: React.Dispatch<React.SetStateAction<IRegistrationByDayInterface[]>>
  setRegistrationfeesData: React.Dispatch<React.SetStateAction<IRegistrationFeesInterface[]>>
  setRegistrationTicketData: React.Dispatch<React.SetStateAction<IRegistrationTicketInterface[]>>
}

type IRefundApiArrayObject = {
  docId: string
  amountToRefund: number
}

type IRefundAmountApiData = {
  comment: string
  userDocId: string
  registrationDocId: string
  paymentInfo: { recipientId: string; chargeId: string }[]
  scratchedFeesItems: IRefundApiArrayObject[]
  scratchedTicketItems: IRefundApiArrayObject[]
  scratchedRegistrationByDayItems: IRefundApiArrayObject[]
  reason: 'duplicate' | 'fraudulent' | 'requested_by_customer'
}

const MINIMUM_AMOUNT_TO_REFUND = 1

const RefundPageFooter = ({
  userDocId,
  registrationId,
  registrationData,
  registrationfeesData,
  registrationticketData,
  setRegistrationData,
  setRegistrationTicketData,
  setRegistrationfeesData,
}: IProps) => {
  // Hooks and vars
  const toastFunction = useToasterHelper()
  const totals = useAppSelector(selectExhibitorTotals)
  const refundAmount = useAppSelector(selectRefundAmountR)
  const refundTotals = useAppSelector(selectExhibitorRefundTotals)
  const refundErrors = useAppSelector(selectExhibitorRefundErrors)

  const textAreaText = useRef<string>('')

  const dispatch = useAppDispatch()

  const total = totals.eventsPriceTotal + totals.feesPriceTotal + totals.ticketsPriceTotal

  const refundTotal =
    sumBy(refundTotals.eventsRefundPriceTotal, 'amountRefunded') +
    sumBy(refundTotals.feesRefundPriceTotal, 'amountRefunded') +
    sumBy(refundTotals.ticketsRefundPriceTotal, 'amountRefunded')

  const onRefundButtonClickFn = () => {
    let error = Object.values(refundErrors).find((currValue) => {
      return !!currValue
    })

    if (total < MINIMUM_AMOUNT_TO_REFUND) {
      error = `The refund amount must be more than $${MINIMUM_AMOUNT_TO_REFUND}`
    } else if (total < refundTotal) {
      error = `The max amount that can be refunded is $${total}`
    }

    if (error)
      return toastFunction.error({
        message: error,
      })

    const paymentInfo: { recipientId: string; chargeId: string; invoiceUrl: string }[] = []

    refundTotals.eventsRefundPriceTotal?.forEach((eventsRefundPriceTotal) => {
      if (
        eventsRefundPriceTotal.invoiceId &&
        eventsRefundPriceTotal.recipientId &&
        eventsRefundPriceTotal.invoiceUrl
      )
        paymentInfo.push({
          chargeId: eventsRefundPriceTotal.invoiceId,
          invoiceUrl: eventsRefundPriceTotal.invoiceUrl,
          recipientId: eventsRefundPriceTotal.recipientId,
        })
    })

    refundTotals.ticketsRefundPriceTotal?.forEach((ticketsRefundPriceTotal) => {
      if (
        ticketsRefundPriceTotal.invoiceId &&
        ticketsRefundPriceTotal.recipientId &&
        ticketsRefundPriceTotal.invoiceUrl
      )
        paymentInfo.push({
          chargeId: ticketsRefundPriceTotal.invoiceId,
          invoiceUrl: ticketsRefundPriceTotal.invoiceUrl,
          recipientId: ticketsRefundPriceTotal.recipientId,
        })
    })

    const refundAmountApiData: IRefundAmountApiData = {
      scratchedFeesItems: [],
      scratchedTicketItems: [],
      scratchedRegistrationByDayItems: [],
      userDocId: userDocId,
      comment: textAreaText.current,
      reason: 'requested_by_customer',
      paymentInfo,
      registrationDocId: registrationId,
    }

    const registrationfeesData_ = cloneDeep(registrationfeesData).map((currRegistrationFees) => {
      if (!currRegistrationFees.refundAmountAdded && currRegistrationFees.isScratched) {
        currRegistrationFees.refundAmountAdded = true
        refundAmountApiData.scratchedFeesItems.push({
          docId: currRegistrationFees.id!,
          amountToRefund: currRegistrationFees.amountRefunded,
        })
      }
      return currRegistrationFees
    })

    const registrationData_ = cloneDeep(registrationData).map((currRegistration) => {
      if (!currRegistration.refundAmountAdded && currRegistration.isScratched) {
        currRegistration.refundAmountAdded = true
        refundAmountApiData.scratchedRegistrationByDayItems.push({
          docId: currRegistration.id!,
          amountToRefund: currRegistration.amountRefunded,
        })
      }
      return currRegistration
    })

    const registrationticketData_ = cloneDeep(registrationticketData).map(
      (currRegistrationTicket) => {
        if (!currRegistrationTicket.refundAmountAdded && currRegistrationTicket.isScratched) {
          currRegistrationTicket.refundAmountAdded = true
          refundAmountApiData.scratchedTicketItems.push({
            docId: currRegistrationTicket.id!,
            amountToRefund: currRegistrationTicket.amountRefunded,
          })
        }
        return currRegistrationTicket
      }
    )

    dispatch(refundAmountThunk(refundAmountApiData))
      .then((data) => {
        let payload: any = data.payload
        if (data.meta.requestStatus === 'rejected') {
          toastFunction.error({
            message: payload?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })
        } else {
          setRegistrationData(registrationData_)
          setRegistrationTicketData(registrationticketData_)
          setRegistrationfeesData(registrationfeesData_)

          dispatch(resetExhibitorRefundFields())

          toastFunction.success({
            message: `Amount refunded successfully`,
          })
        }
      })
      .catch((err) => {
        toastFunction.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

        helpers.logger({
          message: CustomError.somethingWentWrong({
            fileName: 'RefundPageFooter',
            moduleName: 'onRefundButtonClickFn',
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: err?.message,
          }),
        })
      })
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Accordion
        icon={<AddCircleOutline className="add_icon text-[red]" />}
        initialState={true}
        className="mb-2"
        header={
          <div
            className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
          >
            Comments
          </div>
        }
        headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
      >
        <textarea
          onChange={(e) => (textAreaText.current = e.target.value)}
          rows={6}
          placeholder="Add note to customer...(optional)"
          className="w-full border border-solid !ring-0 !border-[#D3DAEE] rounded-lg font-normal text-[14px] text-SeabiscuitDark200ThemeColor"
        />
      </Accordion>

      <Button
        fullWidth={true}
        className="mb-2 shadow-none"
        label={`Refund $${toFixed(refundTotal)}`}
        loading={refundAmount.status === 'loading'}
        onClick={onRefundButtonClickFn}
      />
      <Button
        disabled={refundAmount.status === 'loading'}
        label="Cancel"
        background="muted"
        className="shadow-none"
        fullWidth={true}
      />
    </>
  )
}

export default RefundPageFooter
