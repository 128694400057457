import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LightboxCommonComponent from '../../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ROUTES_CONST } from '../../../../const/routes-const'
import { COUNTRIES_LIST } from '../../../../fakeData/countriesList'
import { getUserFullName } from '../../../../helpers/helpers'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import ITypes from './StaffProfileHeader.types'
import InfoWithStatus from './components/InfoWithStatus'
import { Tooltip } from '@mui/material'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffProfileHeader = (props: ITypes['IStaffProfileProps']) => {
  // Hooks and vars
  const [phoneCode, setPhoneCode] = useState<string | null>(null)
  const [showProfilePicture, setShowProfilePicture] = useState(false)
  const [picToShow, setPicToShow] = useState<string>(IMAGE_CONSTS.PLACEHOLDERS.USER)

  const [hover, setHover] = useState(false)
  const user = props?.staff
  const userName = user ? getUserFullName(user) : 'Unknown'

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let phoneCode_ = props.staff?.userPhoneCode ?? null

    if (!!props.staff?.userProfilePicture) setPicToShow(props.staff?.userProfilePicture)

    if (!phoneCode_) return

    phoneCode_ =
      COUNTRIES_LIST.find((currCountryDetails) => {
        return currCountryDetails.value === phoneCode_
      })?.phoneCode ?? null

    setPhoneCode(phoneCode_)
  }, [props.staff?.userProfilePicture, props.staff?.userPhoneCode])

  const handleModalContext = useContext(HandleModalContext)
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="w-16 h-16 bg-SeabiscuitGrayThemeColor rounded-full overflow-hidden border-2 cursor-pointer">
            {user?.userProfilePicture ? (
              <img
                className="object-cover w-full h-full"
                src={picToShow}
                alt="profilePicture"
                onClick={() => setShowProfilePicture(true)}
              />
            ) : (
              <img className="object-cover w-full h-full" src={picToShow} alt="profilePicture" />
            )}
          </span>

          <Link to={`${ROUTES_CONST.USER_PROFILE.URL}/${user?.id}`}>
            <span className="text-SeabiscuitDark200ThemeColor ml-4 font-bold text-xl capitalize">
              {userName}
            </span>
          </Link>
        </div>

        <div className="flex items-center">
          <Tooltip
            title={<h1 className="tooltip_title">{`Send Message`}</h1>}
            placement="top"
            arrow
          >
            <button
              type="button"
              className=" text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] cursor-pointer hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColor/5 text-sm rounded-full p-2"
              onClick={() => {
                handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                  emailId: props.staff?.userEmail,
                  user_name: props.staff ? getUserFullName(props.staff) : 'N/A',
                  profilePicture: props.staff?.userProfilePicture,
                })
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <img
                src={
                  hover ? `/assets/og_icons/Messaging-2.svg` : `/assets/og_icons/Messaging-1.svg`
                }
                alt="icon"
                className="w-6 h-6 "
              />
            </button>
          </Tooltip>

          {/* <button
                        type="button"
                        onClick={props.onScratchButtonClick}
                        className=" text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] cursor-pointer hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColor/5 text-sm py-2 px-4 rounded-lg w-24 mr-2"
                        disabled={props?.isStaffScratched}
                    >
                        {props?.isStaffScratched ? "Scratched" : "Scratch"}
                    </button> */}
        </div>
      </div>

      <div className="mt-5">
        <div className="flex items-center justify-between lg:mb-3 flex-wrap md:mb-2">
          <div className="">
            <div>
              <InfoWithStatus
                className="flex items-center"
                label="Role"
                value={props?.staffRole ? props?.staffRole : 'N/A'}
                icon="/assets/og_icons/Name Tag-1.svg"
              />
            </div>

            <div className="flex gap-8 mt-2">
              <InfoWithStatus
                className="flex items-center max-w-[250px] w-full min-w-fit mb-2 md:mb-0"
                label="Phone"
                value={user?.userPhoneNumber ? `${phoneCode} ${user?.userPhoneNumber}` : 'N/A'}
                icon="/assets/cp_icons/Phone.svg"
              />
              <InfoWithStatus
                className="flex items-center max-w-[250px] w-full min-w-fit mb-2 md:mb-0"
                label="Email"
                value={user?.userEmail ? user?.userEmail : 'N/A'}
                icon="/assets/cp_icons/Mail-1.svg"
              />
            </div>
          </div>

          <div className="flex items-center mt-8 lg:mb-3 gap-2 flex-wrap mb-2">
            <InfoWithStatus
              className="flex items-center"
              label="Paperwork"
              value={props.areAllPaperworksSigned}
              icon="/assets/og_icons/Regular Document-1.svg"
            />
            <InfoWithStatus
              className="flex items-center"
              label="Eligibility"
              value={props.isElligible}
              icon="/assets/og_icons/Regular Document-1.svg"
            />
          </div>
        </div>
      </div>

      <LightboxCommonComponent
        onClose={() => setShowProfilePicture(false)}
        visible={showProfilePicture}
        slides={[
          {
            src: picToShow,
          },
        ]}
      />
    </>
  )
}

export default StaffProfileHeader
