import { useEffect, useRef, useState } from 'react'

// Componenents
import { Clear } from '@mui/icons-material'
import ViewsLoader from '../../../components/loader/ViewsLoader'

// Third party
import { DocumentData, QueryDocumentSnapshot, collection, query, where } from 'firebase/firestore'

// Constants
import { CONST } from '../../../const/const'

// Firebase
import FirebaseApp from '../../../services/firebaseApp'

// Redux
import { useIonRouter } from '@ionic/react'
import { useCollection } from 'react-firebase-hooks/firestore'
import helpers from '../../../commonHelpers/helpers'
import { IMAGE_CONSTS } from '../../../const/image-const'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { IEventWaitlistInterface } from '../../../models/event-waitlist/eventWaitlist.interface'
import { EventWailistModel } from '../../../models/event-waitlist/eventWaitlist.model'
import { IHorseData } from '../../../models/horse/horse.interface'
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { selectHolder, setHorsesHolder, setUsersHolder } from '../../../store/holders/holdersSlice'
import { holdersSliceInitialState } from '../../../store/holders/holdersSlice.data'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'
import { getUserFullName } from '../../../helpers/helpers'
import { useHistory } from 'react-router-dom'

type Props = {
  show: boolean
  handleModal: (show: boolean, modal_name: string, data?: any) => void
  dataToPassOn: {
    id: { tab: number; tabTitle: string; subTitle: string }
    eventClicked: { eventId: undefined | string }
  }
}

type IRegisteredUser = {
  horses: ITeamMember['horses']
  memberId: ITeamMember['memberId']
  memberName: ITeamMember['memberName']
  memberProfilePicture: ITeamMember['memberProfilePicture']
}

type IRegisteredUserUi = {
  horses: string
  memberId: ITeamMember['memberId']
  memberName: ITeamMember['memberName']
  memberProfilePicture: ITeamMember['memberProfilePicture']
}

// Constants
const NO_USER_REGISTERED = 'None of the users have registered yet'
const NO_USER_IN_WAITLIST = 'None of the user are there in the waitlist'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const EVENT_REGISTERED_USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS

const tabData = [
  { id: 1, name: 'Registered' },
  { id: 2, name: 'Wait List' },
]

const RegisteredUsersModal = (props: Props) => {
  // Hooks and vars

  const dispatch = useAppDispatch()
  const router = useIonRouter()
  const history = useHistory()

  const holder = useAppSelector(selectHolder)
  const [loading, setLoading] = useState(false)
  const tabSelected = (id: number) => setSelectedTab(id)
  const eventId = props?.dataToPassOn?.eventClicked?.eventId ?? ''

  const userId = useAppSelector(selectUserId)
  const [waitlistEvents, setWaitlistEvents] = useState<IEventWaitlistInterface[]>([])
  const waitlistRef = useRef(false)

  const [selectedTab, setSelectedTab] = useState(1)
  const [registerdUsersUi, setRegisteredUsersUi] = useState<IRegisteredUserUi[]>([])

  const holderRef = useRef(holdersSliceInitialState)
  const PROFILE_ROUTE = CONST.ROUTES.USER_PROFILE.URL

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!waitlistEvents?.length && !waitlistRef.current) {
      getwaitListUser()
      waitlistRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Gets the docs from registered users table, where event id matches the clicked event
   */
  const [registeredIn, firestore_loading] = useCollection(
    query(
      collection(FirebaseApp.firestore, EVENT_REGISTERED_USERS.NAME),
      where(EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId)
    ),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  )

  // Sets the active tab
  useEffect(() => {
    const bckSelectedTab = props?.dataToPassOn?.id?.tab !== 3 ? props?.dataToPassOn?.id?.tab : 1
    setSelectedTab(bckSelectedTab)
  }, [props?.dataToPassOn?.id?.tab])

  // Gets the registered users from db for the clicked event
  useEffect(() => {
    if (registeredIn) {
      setLoading(true)
      const registeredUsers = registeredIn.docs.reduce((acc: IRegisteredUser[], currDoc) => {
        if (!Array.isArray(acc)) acc = []
        let regEventModel = EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
        regEventModel.selectionDetails.selectedEvents?.forEach((currEvent) => {
          currEvent.members?.forEach((currRider) => {
            acc.push({
              horses: currRider?.horses,
              memberId: currRider.memberId,
              memberName: currRider?.memberName ?? '',
              memberProfilePicture: currRider.memberProfilePicture ?? '',
            })
          })
        })
        return acc
      }, [] as IRegisteredUser[])

      updateUiItems(registeredUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredIn])

  const getwaitListUser = async () => {
    setLoading(true)
    try {
      const waitListedData: IEventWaitlistInterface[] = []

      const result = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST.NAME,
        [where('eventId', '==', eventId)]
      )

      if (result.size) {
        result?.forEach((doc) =>
          waitListedData.push(getConvertedData(EventWailistModel.fromFirestoreDoc(doc).toObject()))
        )
      }

      //   const myWaitlistEvent = await FirestoreService.filterItems(
      //     CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_DETAILS.NAME,
      //     [
      //       where(documentId(), "in", myEventIdArray)
      //     ]
      //   )

      //   if (myWaitlistEvent.size) {
      //     myWaitlistEvent?.forEach((doc) => {
      //       const dataTable = EventDetailsModel.fromFirestoreDoc(doc).toObject()
      //       waitListedData.push(getConvertedData({
      //         eventId: dataTable.id,
      //         eventName: dataTable.competitionName,
      //         eventLogo: dataTable.competitionEventImage[0] ?? "",
      //         eventCountry: dataTable.competitionCity,
      //         eventCategory: dataTable.category,
      //         eventState: dataTable.competitionState,
      //         eventCity: dataTable.competitionCity,
      //         discipline: dataTable.tags,
      //         registrationDate: dataTable.competitionRegistrationOpenDate,
      //         registeredUsers: dataTable.waitlistCount ? dataTable.waitlistCount : 0
      //       }))
      //     })
      //   }

      setWaitlistEvents(waitListedData)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  async function updateUiItems(registeredUsers: IRegisteredUser[]) {
    const UNKNOWN = 'Unknown'

    let horses = ''
    let horseNames: string[] = []
    let horse: IHorseData | null = null
    let user: IUserInterface | null = null
    let idsOfUsersInRegistration: string[] = []
    let idsOfHorsesInRegistration: string[] = []
    let registerdUsersUi_: IRegisteredUserUi[] = []
    let userSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let horseSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let alreadyFetchedUsers = holderRef.current.usersHolder.data
    let alreadyFetchedHorses = holderRef.current.horsesHolder.data

    const fetchedHorses: IHorseData[] = []
    const fetchedUsers: IUserInterface[] = []

    registeredUsers.forEach((currItem) => {
      if (currItem.memberId) idsOfUsersInRegistration.push(currItem.memberId)
      currItem.horses?.forEach((currHorse) => {
        idsOfHorsesInRegistration.push(currHorse.id)
      })
      if (currItem.memberId) idsOfUsersInRegistration.push(currItem.memberId)
    })

    // Removing duplicates
    idsOfUsersInRegistration = [...new Set(idsOfUsersInRegistration)]
    idsOfHorsesInRegistration = [...new Set(idsOfHorsesInRegistration)]

    // Removing undefined
    idsOfUsersInRegistration = idsOfUsersInRegistration.filter((c) => !!c)
    idsOfHorsesInRegistration = idsOfHorsesInRegistration.filter((c) => !!c)

    // Removing already fetched
    idsOfUsersInRegistration = idsOfUsersInRegistration.filter(
      (cId) => !alreadyFetchedUsers.find((cu) => cu.id === cId)
    )
    idsOfHorsesInRegistration = idsOfHorsesInRegistration.filter(
      (cId) => !alreadyFetchedHorses.find((ch) => ch.id === cId)
    )

    // Fetching the remaining docs
    userSnaps = await FirestoreService.getItemsUsingIds(
      COLLECTIONS.USERS.NAME,
      idsOfUsersInRegistration
    )

    horseSnaps = await FirestoreService.getItemsUsingIds(
      COLLECTIONS.HORSES.NAME,
      idsOfHorsesInRegistration
    )

    userSnaps.forEach((currDoc) => {
      fetchedUsers.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    horseSnaps.forEach((currHorse) => {
      fetchedHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currHorse).toObject()))
    })

    alreadyFetchedUsers = [...alreadyFetchedUsers, ...fetchedUsers]

    alreadyFetchedHorses = [...alreadyFetchedHorses, ...fetchedHorses]

    if (fetchedUsers.length) dispatch(setUsersHolder(alreadyFetchedUsers))

    if (fetchedHorses.length) dispatch(setHorsesHolder(alreadyFetchedHorses))

    registerdUsersUi_ = registeredUsers.map((currItem) => {
      horses = ''
      currItem.horses?.forEach((currHorse) => {
        horseNames = []
        horse = alreadyFetchedHorses.find((currHorse_) => currHorse_.id === currHorse.id) ?? null
        if (horse) horseNames.push(horse?.horseName ?? UNKNOWN)
      })

      horses = horseNames.join(', ')

      user = alreadyFetchedUsers.find((currUser_) => currUser_.id === currItem.memberId) ?? null

      return {
        horses,
        memberId: currItem.memberId,
        memberName: user ? getUserFullName(user) : UNKNOWN,
        memberProfilePicture: user?.userProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER,
      }
    })

    setLoading(false)
    setRegisteredUsersUi(registerdUsersUi_)
  }

  const hendleUserProfile = (id: string | null) => {
    if (!id) return
    router.push(`${PROFILE_ROUTE}/${id}`)
    history.push(`${PROFILE_ROUTE}/${id}`)
    props.handleModal(false, 'totalRegistersEvents')
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current py-5 px-6">
          <span
            onClick={() => props.handleModal(false, 'totalRegistersEvents')}
            className="absolute text right-2 top-2 text-SeabiscuitMainThemeColor cursor-pointer"
          >
            <Clear fontSize="small" className="text-SeabiscuitDark200ThemeColor" />
          </span>

          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl font-bold leading-normal text-SeabiscuitDark200ThemeColor mb-3"
              id="exampleModalScrollableLabel"
            >
              Registrations
            </h5>
          </div>
          <div className="min-h-1/2">
            <ul className="flex justify-start">
              {tabData.map((tab, index) => {
                return (
                  <li
                    key={`${JSON.stringify(tab)}${index}`}
                    className={`${selectedTab === tab.id && 'text-SeabiscuitMainThemeColor border-b-[1px] border-SeabiscuitMainThemeColor'} cursor-pointer text-SeabiscuitDark200ThemeColor px-1 pb-1 text-nr mr-3`}
                    onClick={() => tabSelected(tab.id)}
                  >
                    {tab.name}
                  </li>
                )
              })}
            </ul>
            <div
              className={`py-4 h-[50vh] overflow-y-auto pr-6 ${firestore_loading || loading ? 'flex items-center justify-center' : ''}`}
            >
              {!firestore_loading || loading ? (
                <>
                  {selectedTab === 1 ? (
                    registerdUsersUi.length ? (
                      registerdUsersUi.map((user, userIndex) => {
                        return (
                          <div
                            className="form-check mb-3"
                            key={`${JSON.stringify(user)}${userIndex}`}
                          >
                            <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                              <div className="min-w-fit flex items-center">
                                <span
                                  className="block w-10 h-10 mr-2 shrink-0"
                                  onClick={() => {
                                    hendleUserProfile(user?.memberId)
                                  }}
                                >
                                  <img
                                    src={
                                      user?.memberProfilePicture &&
                                      user?.memberProfilePicture !== ''
                                        ? user?.memberProfilePicture
                                        : '/assets/og_icons/User-1.svg'
                                    }
                                    className="object-cover rounded-full w-full h-full"
                                    alt="user"
                                  />
                                </span>
                                <div className="hourseDetails w-10/12">
                                  <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                                    <p className="text-SeabiscuitDark200ThemeColor text-sm capitalize">
                                      {user?.memberName ?? ''}
                                    </p>
                                    <div className="flex items-center justify-start">
                                      <img src="/assets/img/Horse.svg" alt="horse" />
                                      <p className="text-sm ml-2 capitalize">
                                        {user?.horses ?? 'unknown'}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className="text-SeabiscuitDark200ThemeColor text-sm">
                        {NO_USER_REGISTERED}
                      </div>
                    )
                  ) : null}

                  {selectedTab === 2 ? (
                    waitlistEvents?.[0]?.waitlist?.length ? (
                      waitlistEvents?.[0]?.waitlist?.map((user: any, index: Number) => {
                        return (
                          <div className="form-check mb-3" key={`${JSON.stringify(user)}${index}`}>
                            <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                              <div className="min-w-fit flex items-center">
                                <div
                                  className="block w-10 h-10 mr-2 shrink-0 cursor-pointer"
                                  onClick={() => {
                                    hendleUserProfile(user?.memberId)
                                  }}
                                >
                                  <img
                                    src={
                                      user?.memberProfilePicture &&
                                      user?.memberProfilePicture !== ''
                                        ? user?.memberProfilePicture
                                        : '/assets/og_icons/User-1.svg'
                                    }
                                    className="object-cover rounded-full w-full h-full"
                                    alt="user"
                                  />
                                </div>
                                <div className="hourseDetails w-10/12">
                                  <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                                    <p className="text-SeabiscuitDark200ThemeColor text-sm capitalize">
                                      {user?.memberName ?? ''}
                                    </p>
                                    {/* <div className="flex items-center justify-start ">
                                                                <img src="/assets/img/Horse.svg" alt="horse" />
                                                                <p className="text-xs ml-2 capitalize">{user?. ?? "unknown"}</p>
                                                            </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className="text-SeabiscuitDark200ThemeColor text-sm">
                        {NO_USER_IN_WAITLIST}
                      </div>
                    )
                  ) : null}
                </>
              ) : (
                <ViewsLoader size="md" bgColor="#122B46" color="#F70763" />
              )}
            </div>
          </div>
          {/* <button
                        onClick={() => props.handleModal(false, "totalRegistersEvents")}
                        type="button"
                        className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor">
                        CANCEL
                    </button> */}
        </div>
      </div>
    </div>
  )
}

export default RegisteredUsersModal
