import { IonApp, setupIonicReact } from '@ionic/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Theme variables */
import AppLayout from './layout/AppLayout'

/* Custom Global Imports */
import './theme/variables.css'
import './theme/styles.css'

/* Other Imports */
import { useLoadScript } from '@react-google-maps/api'
import { FC, ReactText, useCallback, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MobileScreen from './Mobile/MobileScreen'
import ViewsLoader from './components/loader/ViewsLoader'
import FirebaseApp from './services/firebaseApp'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { useIonRouter } from '@ionic/react'
import { loadSystemStoredData, selectDarkMode } from './store/system/systemSlice'
import { loadUserStoredData, selectIsLoggedIn, setIsLoggedIn } from './store/user/userSlice'

import { IntercomProvider } from 'react-use-intercom'
import { useNetworkState } from '@react-hookz/web'
import { toast } from 'react-toastify'
import { useRef } from 'react'

// Add this import
import OfflineScreen from './offline-screen'
import { CONST } from './const/const'
import { setAllUsers } from './store/users/usersSlice'
import { getConvertedData } from './models/interface.helper'
import { UserModel } from './models/users/user.model'
import FirestoreService from './services/firestoreService'
import { IUserInterface } from './models/users/user.interface'

setupIonicReact()

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const App: FC = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const [loading_, setLoading_] = useState(true)
  const [user, loading] = useAuthState(FirebaseApp.auth)
  const isDarkModeEnabled = useAppSelector(selectDarkMode)

  useLoadScript({
    googleMapsApiKey: 'AIzaSyCHa26D4cUJbzCVgDuJPeQfqOJKb-8ifZQ',
    libraries: ['places'],
  })

  useEffect(() => {
    ;(async () => {
      await dispatch(loadSystemStoredData())
      await dispatch(loadUserStoredData())
    })()
  }, [loading_])

  const getAllUsers = async () => {
    try {
      const allUsers: IUserInterface[] = []

      const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

      usersSnaps.forEach((currSnap) => {
        allUsers.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      })

      dispatch(setAllUsers(allUsers))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getAllUsers().then()
  }, [])

  useEffect(() => {
    !loading && dispatch(setIsLoggedIn(!!user))
  }, [user, loading])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @danger Dont remove this
   */
  useEffect(() => {
    if (typeof isLoggedIn === 'boolean') {
      setTimeout(() => {
        setLoading_(false)
      }, 1000)
    }
  }, [isLoggedIn])

  const INTERCOM_APP_ID = 'fdff82yb'
  const router = useIonRouter()

  const showMobileScreen = false

  // close stripe browser Tab
  if (router.routeInfo.search === '?stripe-connected') window.close()

  const networkState = useNetworkState()
  const prevOnlineRef = useRef(true)
  const toastIdRef = useRef<ReactText | null>(null)

  const isOnline = networkState.online ?? navigator.onLine

  useEffect(() => {
    if (!isOnline && prevOnlineRef.current) {
      toastIdRef.current = toast.error('You are offline. All changes will not be saved.', {
        position: 'bottom-left',
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (isOnline && !prevOnlineRef.current) {
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, {
          render: 'You are back online.',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        })
        toastIdRef.current = null
      }
    }

    prevOnlineRef.current = isOnline

    return () => {
      if (toastIdRef.current) toast.dismiss(toastIdRef.current)
    }
  }, [isOnline])

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <IonApp className={`${isDarkModeEnabled ? 'dark-theme' : ''}`}>
        {!isOnline ? (
          <OfflineScreen />
        ) : loading_ || isLoggedIn === null ? (
          <ViewsLoader
            isPageLoader={true}
            className="flex items-center w-full justify-center h-screen"
            size="xl"
            color="red"
          />
        ) : showMobileScreen ? (
          <MobileScreen />
        ) : (
          <AppLayout />
        )}
        <ToastContainer autoClose={3000} pauseOnHover theme="dark" />
      </IonApp>
    </IntercomProvider>
  )
}

export default App
