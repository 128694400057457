import React, { useEffect, useState } from 'react'
import { useChat } from '../components/DashboardChat'
import { Modal } from './Modal'
import { useQuery } from '@tanstack/react-query'
import FirestoreService from '../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { CONST } from '../../../const/const'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import { getConvertedData } from '../../../models/interface.helper'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { UserCard } from './MembersModal'
import { getUserFullName } from '../../../helpers/helpers'
import { CustomError } from '../../../helpers/helpers'
import { EventStaffModel } from '../../../models/event-staff/event-staff.model'
import { useChatContext } from 'stream-chat-react'
import { useAppSelector } from '../../../store/hooks'
import { selectProfileData } from '../../../store/user/userSlice'
import { ChannelData } from 'stream-chat'
import { v4 as uuidv4 } from 'uuid'

type IUsers = IUserInterface & { role: string; vertical: string }
type Vertical = 'all' | 'exhibitors' | 'staff'

const options: { id: string; vertical: Vertical; sub_vertical: string }[] = [
  { id: 'all', vertical: 'all', sub_vertical: '' },
  { id: 'staff', vertical: 'staff', sub_vertical: '' },
  { id: 'judges', vertical: 'staff', sub_vertical: 'Judges' },
  { id: 'show manager', vertical: 'staff', sub_vertical: 'Show manager' },
  { id: 'exhibitors', vertical: 'exhibitors', sub_vertical: '' },
  { id: 'riders', vertical: 'exhibitors', sub_vertical: 'Rider' },
  { id: 'trainers', vertical: 'exhibitors', sub_vertical: 'Trainer' },
  { id: 'owners', vertical: 'exhibitors', sub_vertical: 'Owner' },
  { id: 'coaches', vertical: 'exhibitors', sub_vertical: 'Coach' },
  { id: 'grooms', vertical: 'exhibitors', sub_vertical: 'Groom' },
  { id: 'guardians', vertical: 'exhibitors', sub_vertical: 'Guardian' },
]

export const useEventRidersUsers = ({
  eventId,
  search,
  select,
  enabled = true,
}: {
  eventId: string
  search: string
  select?: (data: IUsers[]) => IUsers[]
  enabled?: boolean
}) => {
  return useQuery<IUsers[]>({
    queryKey: ['event', eventId, 'team', search],
    queryFn: async () => {
      const filters = [
        where(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY,
          '==',
          eventId
        ),
      ]
      if (search) {
        filters.push(
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.RIDER_NAME_N_GRAM.KEY,
            'array-contains',
            search
          )
        )
      }

      // Get rider team members from firestore
      const ridersSnapshots = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        filters
      )
      let riderTeamMembers: IRiderTeamMemberInterface[] = []
      ridersSnapshots.docs.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      // Get unique user ids
      const userIdsSet = new Set<string>()
      riderTeamMembers.forEach((rider) => {
        if (rider.userId) {
          userIdsSet.add(rider.userId)
        }
      })
      const userIdsList: string[] = Array.from(userIdsSet)

      // Get users from firestore
      let usersSnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        userIdsList
      )
      const users: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      // Merge users with their roles
      const users_with_roles: IUsers[] = []
      const uniqueUsersMap = new Map<string, IUsers>()

      riderTeamMembers.forEach((member) => {
        const user = users.find((u) => u.id === member.userId)
        if (user) {
          const key = `${user.id}-${member.teamMemberRole}`
          if (!uniqueUsersMap.has(key) && member.teamMemberRole) {
            const userWithRole = { ...user, role: member.teamMemberRole, vertical: 'exhibitors' }
            uniqueUsersMap.set(key, userWithRole)
            users_with_roles.push(userWithRole)
          }
        }
      })

      return users_with_roles
    },
    select: select,
    initialData: [],
    enabled: !!eventId && enabled,
  })
}

export const useEventStaffUsers = ({
  eventId,
  search,
  enabled = true,
  select,
}: {
  eventId: string
  search: string
  enabled?: boolean
  select?: (data: IUsers[]) => IUsers[]
}) => {
  return useQuery<IUsers[]>({
    queryKey: ['event', eventId, 'staff', search],
    queryFn: async () => {
      const staffSnap = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_STAFF.NAME,
        eventId
      )

      if (!staffSnap.exists)
        throw CustomError.somethingWentWrong({
          lineNumber: 84,
          fileName: 'useEventStaffUsers',
          message: 'no event staff found',
          devMessage: `Could not find event staff for event ${eventId}`,
        })

      const staffs = EventStaffModel.fromFirestoreDoc(staffSnap).toObject()

      const userIdsList: string[] = []
      const userIdsWithRole: { userId: string; role: string }[] = []

      staffs.eventStaff?.forEach((user_staff) => {
        user_staff.value.forEach((currentId) => {
          if (typeof currentId === 'string') {
            userIdsList.push(currentId)
            userIdsWithRole.push({ userId: currentId, role: user_staff.title })
          }
        })
      })
      let usersSnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        userIdsList
      )
      const users: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      const users_with_roles: IUsers[] = []
      users.forEach((user) => {
        const member = userIdsWithRole.find((m) => m.userId === user.id)

        if (member) {
          users_with_roles.push({ ...user, role: member.role, vertical: 'staff' })
        }
      })

      return users_with_roles
    },
    select,
    initialData: [],
    enabled: !!eventId && enabled,
  })
}

const useEventUserStats = (eventId: string) => {
  const { data: team_members } = useEventRidersUsers({
    eventId: eventId ?? '',
    search: '',
  })
  const { data: staff_members } = useEventStaffUsers({
    eventId: eventId ?? '',
    search: '',
  })
  const [stats, setStats] = useState({
    exhibitors: 0,
    riders: 0,
    trainers: 0,
    owners: 0,
    coaches: 0,
    grooms: 0,
    guardians: 0,
    staff: 0,
    judges: 0,
    show_manager: 0,
    all: 0,
  })

  useEffect(() => {
    // Exhibitors
    const exhibitors = team_members.length
    const riders = team_members.filter((m) => m.role === 'Rider').length
    const trainers = team_members.filter((m) => m.role === 'Trainer').length
    const owners = team_members.filter((m) => m.role === 'Owner').length
    const coaches = team_members.filter((m) => m.role === 'Coach').length
    const grooms = team_members.filter((m) => m.role === 'Groom').length
    const guardians = team_members.filter((m) => m.role === 'Guardian').length
    //Staff
    const staff = staff_members.length
    const judges = staff_members.filter((s) => s.role === 'Judges').length
    const show_manager = staff_members.filter((s) => s.role === 'Show manager').length
    // All
    const all = exhibitors + staff

    setStats({
      exhibitors,
      riders,
      trainers,
      owners,
      coaches,
      grooms,
      guardians,
      staff,
      judges,
      show_manager,
      all,
    })
  }, [eventId, team_members, staff_members])

  return { stats }
}

interface Props {
  open: boolean
  onClose: () => void | Promise<void>
  onSubmit: (users: IUsers[], channelData?: ChannelData) => void
  title?: string
  eventId: string
}

export default function MembersEventModal({ open, onClose, onSubmit, title, eventId }: Props) {
  const [selectedUsers, setSelectedUsers] = useState<IUsers[]>([])
  const [selectedOption, setSelectedOption] = useState('all')
  const [filter, setFilter] = useState<{
    vertical: Vertical
    sub_vertical: string
  }>({ vertical: 'all', sub_vertical: '' })
  const [searchTerm, setSearchTerm] = useState('')
  const { data: team_members } = useEventRidersUsers({
    eventId: eventId ?? '',
    search: searchTerm,
    enabled: filter.vertical === 'all' || filter.vertical === 'exhibitors',
    select: filter.sub_vertical
      ? (data: IUsers[]) => data.filter((m) => m.role === filter.sub_vertical)
      : undefined,
  })
  const { data: staff_members } = useEventStaffUsers({
    eventId: eventId ?? '',
    search: searchTerm,
    enabled: filter.vertical === 'all' || filter.vertical === 'staff',
    select: filter.sub_vertical
      ? (data: IUsers[]) => data.filter((m) => m.role === filter.sub_vertical)
      : undefined,
  })
  const { stats } = useEventUserStats(eventId ?? '')

  const users =
    filter.vertical === 'all'
      ? [...team_members, ...staff_members]
      : filter.vertical === 'staff'
        ? staff_members
        : team_members

  const toggleUserSelection = (user: IUsers) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.some((u) => u.id === user.id)
        ? prevSelectedUsers.filter((u) => u.id !== user.id)
        : [...prevSelectedUsers, user]
    )
  }

  const selecAllUsers = (users: IUsers[]) => {
    setSelectedUsers(users)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="lg:w-[640px] lg:h-[640px] bg-white transition duration-500 rounded-xl p-5 text-black flex flex-col justify-between">
        <div className="bg-white p-6 rounded-lg w-full space-y-4 h-full flex flex-col">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">New message</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-black">
              ✕
            </button>
          </div>

          <div className="flex space-x-2 rounded-md">
            <input
              type="text"
              placeholder="Search people"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow border p-2 rounded"
            />
            <select
              id="category"
              className="w-40 border p-2 rounded"
              value={selectedOption}
              onChange={(e) => {
                const value = e.target.value
                setSelectedOption(value)
                const selectedObj = options.find((option) => option.id === value)
                if (selectedObj) {
                  setFilter({
                    vertical: selectedObj.vertical,
                    sub_vertical: selectedObj.sub_vertical,
                  })
                }
              }}
            >
              <option value="all">All {stats?.all}</option>
              <option value="exhibitors">Exhibitors {stats?.exhibitors}</option>
              <option value="staff">Staff {stats?.staff}</option>
              <option disabled>──────────</option>
              <option value="judges">Judges {stats?.judges}</option>
              <option value="show manager">Show Manager {stats?.show_manager}</option>
              <option disabled>──────────</option>
              <option value="riders">Riders {stats?.riders}</option>
              <option value="trainers">Trainers {stats?.trainers}</option>
              <option value="owners">Owners {stats?.owners}</option>
              <option value="coaches">Coaches {stats?.coaches}</option>
              <option value="grooms">Grooms {stats?.grooms}</option>
              <option value="guardians">Guardians {stats?.guardians}</option>
            </select>
          </div>

          <div className="text-sm text-gray-500 flex flex-row justify-between">
            <div>
              {selectedUsers.length} selected
              {selectedUsers.length > 0 && (
                <button
                  onClick={() => setSelectedUsers([])}
                  className="ml-2 text-blue-500 underline"
                >
                  Clear all
                </button>
              )}
            </div>
            <button onClick={() => selecAllUsers(users)}>Select all</button>
          </div>

          <div className="w-full overflow-auto flex flex-1 grow flex-col">
            <div className="w-full relative">
              {users?.map((user) => (
                <button
                  className="w-full"
                  key={user.id + user.role}
                  onClick={() => toggleUserSelection(user)}
                >
                  <UserCard
                    className="h-full"
                    selected={selectedUsers.some((u) => u.id === user.id && u.role === user.role)}
                    userProfilePicture={user?.userProfilePicture}
                    name={getUserFullName(user)}
                    description={`${user.vertical[0].toUpperCase()}${user.vertical.slice(1)} • ${user.role}`}
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full space-y-2">
            <button
              className="bg-SeabiscuitMainThemeColor h-[40px] w-full rounded-md text-white"
              onClick={() => {
                if (!selectedUsers.length) return
                onSubmit(selectedUsers, { eventId })
                setSelectedUsers([])
                onClose()
              }}
            >
              NEW MESSAGE
            </button>
            <button className="bg-[#1F41731A] h-[40px] w-full rounded-md text-white">CANCEL</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}

export function NewChatEventModal() {
  const { newChatModal, setNewChatModal, userId, eventId } = useChat()
  const { client, setActiveChannel } = useChatContext()
  const profileData = useAppSelector(selectProfileData)

  return (
    <MembersEventModal
      open={newChatModal}
      onClose={() => setNewChatModal(false)}
      eventId={eventId ?? ''}
      onSubmit={async (users, channelData?: ChannelData) => {
        //newChat({ users: [otherUserId, userId], dispatch })
        const otherUserIds = users.map((user) => user.id)
        let channel

        try {
          const channelName =
            users.map((user) => getUserFullName(user)).join(', ') +
            `-${getUserFullName(profileData)}`

          if (users.length === 1) {
            const channelId = await getSHA256Hash(
              [...otherUserIds, userId, eventId, users[0].vertical, users[0].role].sort().join('')
            )
            const extraData = {
              ...channelData,
              vertical: users[0].vertical,
              user_role: users[0].role,
            }
            channel = client.channel('messaging', channelId, {
              name: channelName,
              ...extraData,
            })
          } else if (users.length > 1) {
            const channelId = uuidv4()
            channel = client.channel('messaging', channelId, {
              name: channelName,
              ...channelData,
            })
          }
          await channel?.create()
          await channel?.addMembers([...otherUserIds, userId])

          await channel?.watch()
          await channel?.show()
          setActiveChannel(channel)
        } catch (error) {
          // TO DO: improve log error
          console.error(`Error creating chat: ${error}`)
        }
      }}
    />
  )
}
