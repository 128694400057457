import { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router'

// Third party
import { Skeleton, Tooltip } from '@mui/material'
import clsx from 'clsx'

// Component
import InfiniteScrollDataTable from '../../../../../../../../components/common/tables/InfiniteScrollDataTable'
import TooltipIcon from '../../../../../../../../helpers/TooltipIcon'
import NoDataAvailable from '../../../NoDataAvailable'

// Styles
import { customStyles } from '../../../../../../../allEvents/root/AllEventsTableStyles'
import { tableStyles } from '../../../tableStyles'

// Types
import { IExhibitorDataTypes as ITypes } from '../../data/exhibitor.data.types'
import IUserStaffListingRootTypes from '../../hooks/useStaffListingRoot.types'

// Firestore

// Helpers

// Hooks

// Models

// Constants
import { MANAGE_CLINIC_EXHIBITOR_CONSTS } from '../../data/exhibitor.data.const'

import '../../../Exhibitors/Exhibitor.css'
// Third party
import { MODAL_CONSTS } from '../../../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../../../../../../services/firestoreService'
import { CONST } from '../../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../../const/messages-const'
import { UserModel } from '../../../../../../../../models/users/user.model'
import { AutorenewRounded } from '@mui/icons-material'
import useToasterHelper from '../../../../../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../../../../../helpers/helpers'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const MCEC = MANAGE_CLINIC_EXHIBITOR_CONSTS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorScratchedList = ({
  loading,
  staffs,
  onStaffProfileClick,
  ...props
}: ITypes['IExhitiborAllListProps']) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = useContext(HandleModalContext)?.handleModal

  const { isFullScreen = false } = props
  const { eventId } = useParams<{ eventId?: any }>()
  const rowIndexToLoadRef = useRef(-1)
  const setRowIndexToLoad = useState(-1)[1]

  const sendEmail = async (index: number, staffDocId: string | null) => {
    try {
      setRowIndexToLoad(index)
      rowIndexToLoadRef.current = index

      if (!staffDocId) {
        throw new Error('staffDocId is empty')
      }

      const userSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        staffDocId
      )

      if (!userSnapshot.exists()) {
        throw new Error(MESSAGES_CONST.USER_NOT_FOUND)
      }

      const user = UserModel.fromFirestoreDoc(userSnapshot)
      const emailId = user.userEmail
      const user_name = getUserFullName(user)
      const profilePicture = user.userProfilePicture

      handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
        emailId,
        user_name,
        profilePicture,
      })
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.error(error)
    } finally {
      setRowIndexToLoad(-1)
      rowIndexToLoadRef.current = -1
    }
  }

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const generateName = (fullName: string) => {
    return fullName
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase()
  }

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCEC.COLS.PROFILE}</span>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="flex items-center">
          <span
            className="w-full h-full rounded-full block shrink-0 cursor-pointer my-2"
            onClick={() => onStaffProfileClick(row)}
          >
            <img
              src={row.staffProfilePicture ?? ''}
              alt="icons"
              className="object-cover w-[45px] h-[45px] rounded-full"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${generateName(row.staffName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
            />
          </span>
        </div>
      ),
      width: '25%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCEC.COLS.NAME}</span>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="flex items-center mr-[5px]">
          <div
            className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize cursor-pointer"
            onClick={() => onStaffProfileClick(row)}
          >
            {row.staffName}
          </div>
        </div>
      ),
      width: '25%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">{MCEC.COLS.ROLE}</span>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="flex items-center">
          <span className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize">
            {row?.staffRole}
          </span>
        </div>
      ),
      width: '25%',
    },
    // {
    //     name: <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
    //         <span className="whitespace-nowrap">{MCEC.COLS.ELIGIBILITY}</span>
    //     </span>,
    //     cell: (row: IUserStaffListingRootTypes["IStaff"]) => <div className="justify-center w-full flex">
    //         {row?.isElligible ? <img src={IMAGE_URLS.OK} alt="ok" /> : <img src={IMAGE_URLS.NOT_OK} alt="not ok" />}
    //     </div>,
    //     width: "150px"
    // },
    {
      label: MCEC.COLS.PAPERWORK,
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAPERWORK}</span>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="justify-center w-full flex">
          {row?.areAllPaperworksSigned ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCEC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '25%',
    },
    // {
    //     name: <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
    //         <span className="whitespace-nowrap">{MCEC.COLS.CONTACT}</span>
    //     </span>,
    //     cell: (row: IUserStaffListingRootTypes["IStaff"]) =>
    //         <div className="w-full justify-center flex cursor-pointer" onClick={() => {
    //             // toastMethods.info({
    //             //     message: MESSAGES_CONST.WILL_AVAILABLE_SOON
    //             // })
    //             handleModalContext?.handleModal(true, MODAL_CONSTS.MESSAGES_VIA_MAIL, { data: "data is coming" })
    //         }}>
    //             {/* <Link to={LINKS.MESSAGE_USER + row?.userId}> */}
    //             <p className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor">Message</p>
    //             {/* </Link> */}
    //         </div>,
    //     width: "180px"
    // },
  ]

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const [columnsFullScreen] = useState<any[]>([
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.EXHIBITOR}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Horses the exhibitor registered for this event</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <>
          <div
            className="flex items-center gap-4 cursor-pointer w-[300px]"
            onClick={() => onStaffProfileClick(row)}
          >
            <span className="w-14 h-14 p-1 rounded-full block shrink-0">
              <img
                alt="icons"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${generateName(row.staffName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
                src={row?.staffProfilePicture ?? ''}
                className="object-cover w-full h-full rounded-full"
              />
            </span>
            <div className="text-base text-SeabiscuitDark200ThemeColor font-normal capitalize max-w-[130px] ellipsis">
              {row.staffName}
            </div>
          </div>
        </>
      ),
      width: '54%',
    },
    {
      label: MCEC.COLS.ROLE,
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.ROLE}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Has this exhibitor paid their registration fees?</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="justify-center w-full flex text-SeabiscuitDark200ThemeColor text-base font-normal">
          {row?.staffRole}
        </div>
      ),
      width: '12%',
    },
    {
      label: MCEC.COLS.SCRATCHED,
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.SCRATCHED}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Has this exhibitor completed their event paperwork?</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="justify-center w-full flex">
          {row?.isScratched ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCEC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '12%',
    },
    {
      label: MCEC.COLS.PAPERWORK,
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.PAPERWORK}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Has this exhibitor completed their event paperwork?</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff']) => (
        <div className="justify-center w-full flex">
          {row?.areAllPaperworksSigned ? (
            <img src={MCEC.IMAGE_URLS.OK} alt="icon" />
          ) : (
            <img src={MCEC.IMAGE_URLS.NOT_OK} alt="icon" />
          )}
        </div>
      ),
      width: '12%',
    },
    // {
    //     name: <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
    //         <span className="whitespace-nowrap">{MCEC.COLS.ELIGIBILITY}</span>
    //         <Tooltip
    //             title={<h1 className="tooltip_title">Is this exhibitor eligible to participate in this event?</h1>}
    //             placement="top"
    //             arrow
    //             className="shrink-0"
    //         >
    //             <button><TooltipIcon color='#122B46' />
    //             </button>
    //         </Tooltip>
    //     </span>,
    //     cell: (row: IUserStaffListingRootTypes["IStaff"]) => <>
    //         <div className="flex justify-center w-full">
    //             {row.isElligible ? <img src={MCEC.IMAGE_URLS.OK} alt="ok" /> : <img src={MCEC.IMAGE_URLS.NOT_OK} alt="not ok" />}
    //         </div>
    //     </>,
    //      width: "14%"
    // },
    {
      label: 'CONTACT',
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{MCEC.COLS.CONTACT}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Click to direct message this exhibitor</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IUserStaffListingRootTypes['IStaff'], rowIndex: number) => (
        <div
          className="w-full justify-center flex cursor-pointer"
          onClick={() => sendEmail(rowIndex, row.staffId)}
        >
          <p className="bg-[#F2F3F8] py-3 px-6 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor h-[44px] w-[108px] flex justify-center items-center">
            {rowIndexToLoadRef.current === rowIndex ? (
              <AutorenewRounded fontSize="small" className="animate-spin" />
            ) : (
              'Message'
            )}
          </p>
        </div>
      ),
      width: '12%',
    },
  ])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openManageEventModal = () => {
    if (handleModal)
      handleModal(true, MODAL_CONSTS.MANAGE_EVENT_MODAL, {
        eventId,
        headingClassName: '!text-SeabiscuitDark200ThemeColor',
      })
  }

  return (
    <>
      {!loading && !staffs.length && !!!props.searchValue && !isFullScreen ? (
        <NoDataAvailable
          text={!!props.searchValue ? 'No data found.' : 'No one has registered yet'}
          url=""
          height={isFullScreen ? null : 240}
          linkText="Edit registrations >"
          imgSrc="assets/placeholders/noUserFound.svg"
          mainClasses="!mx-0"
          onClickOfLink={openManageEventModal}
        />
      ) : (
        <InfiniteScrollDataTable
          hasMore={false}
          fixedHeader={true}
          id="StaffListTable"
          data={staffs}
          columns={isFullScreen ? columnsFullScreen : columns}
          progressPending={loading}
          disableInfiniteScroll={true}
          customStyles={isFullScreen ? customStyles : tableStyles}
          className={clsx(
            '!rounded-none exhibitorListTable',
            isFullScreen ? '!h-[58vh]' : staffs.length || loading ? '!h-[300px]' : ''
          )}
          noDataComponent={
            <NoDataAvailable
              text={!!props.searchValue ? 'No data found.' : 'No one has registered yet'}
              url=""
              height={isFullScreen ? null : 175}
              linkText="Edit Event >"
              imgSrc="assets/placeholders/noUserFound.svg"
              onClickOfLink={openManageEventModal}
              mainClasses="!w-[85%] !pl-[15%]"
            />
          }
          progressComponent={
            <div className="flex flex-col flex-wrap items-left justify-start mt-2">
              {isFullScreen ? (
                <>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={25}
                      className="mb-2 ml-5"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={25}
                      className="mb-2 ml-14"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-56"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-32"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="circular"
                      width={25}
                      height={25}
                      className="mb-2 ml-36"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={35}
                      className="mb-2 ml-28"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                  <div className="flex items-center items-left justify-start ml-[-40px]">
                    <Skeleton
                      variant="circular"
                      width={45}
                      height={45}
                      className="mb-4"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={20}
                      className="mb-4 ml-6"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={340}
                      height={20}
                      className="mb-4 ml-20"
                      style={{ backgroundColor: '#F1F3F8' }}
                    />
                  </div>
                </>
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default ExhibitorScratchedList
