/* eslint-disable react/jsx-no-target-blank */
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { capitalize } from '../../../../helpers/helpers'
import { IUserInterface } from '../../../../models/users/user.interface'
import OrganizerSideProfileMinimap from '../OrganizerSideProfileMinimap/OrganizerSideProfileMinimap'

const Heading = ({ heading, className }: { heading: string; className?: string }) => {
  return (
    <>
      <h2
        className={`text-normal text-left text-[18px] text-SeabiscuitDark200ThemeColor uppercase ${className}`}
      >
        {capitalize(heading, {
          capitalizeAll: false,
        })}
      </h2>
    </>
  )
}

const IconWithDetail = ({ icon, data }: { icon: string; data: any }) => {
  return (
    <div className="border border-[#D3DAEE] rounded-xl p-4 mt-2 flex items-center w-full">
      <img className="w-[26px] mr-4" src={icon} alt="icon" />
      <p className="text-SeabiscuitDark200ThemeColor">{data}</p>
    </div>
  )
}
// isMyProfile: boolean | undefined

type IOrganizerSideProfileUserInfoTabProps = {
  handleModal: any
  userData: IUserInterface | null
  isMyProfile?: boolean
}

interface NewlineTextProps {
  text: string
}

function NewlineText({ text }: NewlineTextProps) {
  return <span style={{ whiteSpace: 'pre-line' }}>{text}</span>
}

const OrganizerSideProfileUserInfoTab = (props: IOrganizerSideProfileUserInfoTabProps) => {
  const MAX = 300
  const { userData, handleModal } = props

  let showMore = (userData?.userSummary?.length ?? 0) > MAX

  return (
    <>
      <div className="text-left pr-2 overflow-y-auto">
        {/* <div className="personalDetail">

          <Heading
            heading="Personal Details"
          />

          {
            userData.userName ?
              <IconWithDetail
                icon="/assets/og_icons/User-3.svg"
                data={userData.userName}
              /> : null
          }

          {
            userData.userDOB ?
              <IconWithDetail
                icon="/assets/og_icons/Calendar-3.svg"
                data={`${calculateAge(userData.userDOB)} year old`}
              /> : null
          }

          {
            userData.userNationality ?
              <IconWithDetail
                icon="/assets/og_icons/Globe-3.svg"
                data={GetCountry(userData.userNationality)}
              /> : null
          }


        </div>

        <hr className="my-4"></hr>

        <div className="personalDetail">

          <Heading
            heading="COMPETITION DETAILS"
          />

          {
            userData.userDiscipline ?
              <IconWithDetail
                icon="/assets/og_icons/YearofHorse-3.svg"
                data={userData.userDiscipline}
              /> : null
          }

          {
            userData.userZone ?
              <IconWithDetail
                icon="/assets/og_icons/MindMap-3.svg"
                data={userData.userZone}
              /> : null
          }

        </div>

        <hr className="my-4"></hr> */}

        <div className="aboutSection">
          <Heading heading="About" className="mb-2" />
          <p className="text-SeabiscuitDark200ThemeColor text-[16px]">
            {showMore ? (
              <NewlineText
                text={capitalize(userData?.userSummary?.substring(0, MAX) + '...', {
                  capitalizeAll: false,
                })}
              />
            ) : (
              <NewlineText
                text={capitalize(userData?.userSummary ?? '', {
                  capitalizeAll: false,
                })}
              />
            )}
            {showMore && (
              <button
                className="text-[#b0b9d2] hover:text-SeabiscuitMainThemeColor"
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.SEE_MORE, {
                    title: 'About',
                    dataToShow: userData?.userSummary ? (
                      <NewlineText text={userData.userSummary} />
                    ) : (
                      "No 'About' information available."
                    ),
                    headingClassName: '!text-SeabiscuitDark200ThemeColor',
                    bodyClassName: 'min-h-[60vh] text-SeabiscuitDark200ThemeColor text-[14px] ',
                  })
                }}
              >
                {'More >'}
              </button>
            )}
          </p>
        </div>

        <hr className="my-4"></hr>

        {userData?.userWebsite && userData.userWebsite !== '' ? (
          <>
            <div className="aboutSection flex items-center justify-between">
              <Heading heading="WEBSITE" />

              <a
                href={userData.userWebsite}
                target="_blank"
                className="bg-SeabiscuitLightGrayThemeColor hover:bg-SeabiscuitMainThemeColor rounded-full p-2 website_container"
              >
                <img
                  src="/assets/og_icons/Globe-1.svg"
                  className="max-w-[25px] globe_1"
                  alt="globeIcon"
                />
                <img
                  src="/assets/og_icons/Globe-4.svg"
                  className="max-w-[25px] globe_4"
                  alt="globeIcon"
                />
              </a>
            </div>
            <hr className="my-4"></hr>
          </>
        ) : null}

        {(userData?.userFacebook && userData.userFacebook !== '') ||
        (userData?.userLinkedin && userData.userLinkedin !== '') ||
        (userData?.userInstagram && userData.userInstagram !== '') ||
        (userData?.userTwitter && userData.userTwitter !== '') ? (
          <>
            <div className="aboutSection flex flex-col md:flex-row gap-3 md:gap-0 md:items-center justify-between">
              <Heading heading="FOLLOW US" />

              <div className="flex items-center">
                {userData.userFacebook && userData.userFacebook !== '' ? (
                  <a
                    href={userData.userFacebook}
                    target="_blank"
                    className="bg-SeabiscuitLightGrayThemeColor rounded-full p-2 mr-3 group hover:bg-SeabiscuitMainThemeColor"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/og_icons/Facebook-1.svg"
                      className="max-w-[25px] group-hover:hidden"
                      alt="facebook"
                    />
                    <img
                      src="/assets/og_icons/Facebook-4.svg"
                      className="max-w-[25px] hidden group-hover:block"
                      alt="facebook"
                    />
                  </a>
                ) : null}

                {userData.userInstagram && userData.userInstagram !== '' ? (
                  <a
                    href={userData.userInstagram}
                    target="_blank"
                    className="bg-SeabiscuitLightGrayThemeColor rounded-full p-2 mr-3 group hover:bg-SeabiscuitMainThemeColor"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/og_icons/Instagram-1.svg"
                      className="max-w-[25px] group-hover:hidden"
                      alt="instagram"
                    />
                    <img
                      src="/assets/og_icons/Instagram-4.svg"
                      className="max-w-[25px] hidden group-hover:block"
                      alt="instagram"
                    />
                  </a>
                ) : null}

                {userData.userTwitter && userData.userTwitter !== '' ? (
                  <a
                    href={userData.userTwitter}
                    target="_blank"
                    className="bg-SeabiscuitLightGrayThemeColor rounded-full p-2 mr-3 group hover:bg-SeabiscuitMainThemeColor"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/og_icons/Twitter-1.svg"
                      className="max-w-[25px] group-hover:hidden"
                      alt="twitter"
                    />
                    <img
                      src="/assets/og_icons/Twitter-4.svg"
                      className="max-w-[25px] hidden group-hover:block"
                      alt="twitter"
                    />
                  </a>
                ) : null}

                {userData.userLinkedin && userData.userLinkedin !== '' ? (
                  <a
                    href={userData.userLinkedin}
                    target="_blank"
                    className="bg-SeabiscuitLightGrayThemeColor rounded-full p-2 group hover:bg-SeabiscuitMainThemeColor"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/og_icons/LinkedIn-1.svg"
                      className="max-w-[25px] group-hover:hidden"
                      alt="linkedin"
                    />
                    <img
                      src="/assets/og_icons/LinkedIn-4.svg"
                      className="max-w-[25px] hidden group-hover:block"
                      alt="linkedin"
                    />
                  </a>
                ) : null}
              </div>
            </div>
            <hr className="my-4"></hr>
          </>
        ) : null}

        {(userData?.userPocDetails?.pocFullName && userData.userPocDetails?.pocFullName !== '') ||
        (userData?.userPocDetails?.pocPhone && userData.userPocDetails?.pocPhone !== '') ||
        (userData?.userPocDetails?.pocEmail && userData.userPocDetails?.pocEmail !== '') ? (
          <div className="aboutSection">
            <Heading heading="POINT OF CONTACT" className="mb-4" />

            {userData.userPocDetails?.pocFullName ? (
              <IconWithDetail
                icon="/assets/og_icons/UserGroups-1.svg"
                data={userData.userPocDetails?.pocFullName}
              />
            ) : null}

            {userData.userPocDetails?.pocPhone ? (
              <IconWithDetail
                icon="/assets/cp_icons/Phone.svg"
                data={String(userData.userPocDetails?.pocPhone).replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '$1-$2-$3'
                )}
              />
            ) : null}

            {userData.userPocDetails?.pocEmail ? (
              <IconWithDetail
                icon="/assets/og_icons/Mail-1.svg"
                data={userData.userPocDetails?.pocEmail}
              />
            ) : null}

            {userData.userAddress ? (
              <IconWithDetail icon="/assets/og_icons/Location-1.svg" data={userData.userAddress} />
            ) : null}

            <hr className="my-4"></hr>
          </div>
        ) : null}

        {userData?.userLat &&
        userData.userLat !== '' &&
        userData.userLong &&
        userData.userLong !== '' ? (
          <>
            <Heading heading="Location" className="mb-4" />
            <OrganizerSideProfileMinimap
              lat={userData?.userLat}
              long={userData?.userLong}
              title={userData.userAddress ?? ''}
            />
          </>
        ) : null}
      </div>
    </>
  )
}

export default OrganizerSideProfileUserInfoTab
