import * as yup from 'yup'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IAssignedTicket } from './assigned-tickets.interface'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class AssignTicketsModal extends ModelBaseModel<IAssignedTicket> implements IModelBaseModel {
  // Vars
  public id: IAssignedTicket['id']
  public userId: IAssignedTicket['userId']
  public isPlatformUser: IAssignedTicket['isPlatformUser']
  public eventDocId: IAssignedTicket['eventDocId']
  public registrationDocId: IAssignedTicket['registrationDocId']
  public spectatorTicketDocId: IAssignedTicket['spectatorTicketDocId']
  public registrationTicketDocId: IAssignedTicket['registrationTicketDocId']
  public checkInDate: IAssignedTicket['checkInDate']
  public ticketBuyerDocId: IAssignedTicket['ticketBuyerDocId']
  public ticketBuyerName: IAssignedTicket['ticketBuyerName']
  public ticketBuyerEmailId: IAssignedTicket['ticketBuyerEmailId']
  public paymentStatus: IAssignedTicket['paymentStatus']
  public amountRefunded: IAssignedTicket['amountRefunded']
  public amountScratched: IAssignedTicket['amountScratched']
  public scratchedOn: IAssignedTicket['scratchedOn']
  public ticketItemId: IAssignedTicket['ticketItemId']
  public ticketHolderName: IAssignedTicket['ticketHolderName']
  public ticketHolderEmailId: IAssignedTicket['ticketHolderEmailId']
  public ticketTitle: IAssignedTicket['ticketTitle']
  public isScratched: IAssignedTicket['isScratched']
  public ticketStatus: IAssignedTicket['ticketStatus']
  public assignmentDate: IAssignedTicket['assignmentDate']
  public chargeId: IAssignedTicket['chargeId']
  public ticketType: IAssignedTicket['ticketType']
  public ticketPrice: IAssignedTicket['ticketPrice']
  public receipetUrl: IAssignedTicket['receipetUrl']
  public created: IAssignedTicket['created']
  public updated: IAssignedTicket['updated']
  public ticketTemplateUrl: IAssignedTicket['ticketTemplateUrl']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  // Returns draft

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj?: IAssignedTicket) {
    super()
    this.id = obj?.id ?? null
    this.userId = obj?.userId ?? null
    this.eventDocId = obj?.eventDocId ?? null
    this.isPlatformUser = obj?.isPlatformUser ?? false
    this.amountRefunded = obj?.amountRefunded ?? 0
    this.scratchedOn = obj?.scratchedOn ?? null
    this.amountScratched = obj?.amountScratched ?? 0
    this.registrationDocId = obj?.registrationDocId ?? null
    this.spectatorTicketDocId = obj?.spectatorTicketDocId ?? null
    this.registrationTicketDocId = obj?.registrationTicketDocId ?? null
    this.checkInDate = obj?.checkInDate ?? null
    this.chargeId = obj?.chargeId ?? null
    this.ticketBuyerName = obj?.ticketBuyerName ?? null
    this.ticketBuyerEmailId = obj?.ticketBuyerEmailId ?? null
    this.ticketBuyerDocId = obj?.ticketBuyerDocId ?? null
    this.ticketItemId = obj?.ticketItemId ?? null
    this.paymentStatus = obj?.paymentStatus ?? 'pending'
    this.ticketHolderName = obj?.ticketHolderName ?? null
    this.ticketHolderEmailId = obj?.ticketHolderEmailId ?? null
    this.ticketTitle = obj?.ticketTitle ?? null
    this.receipetUrl = obj?.receipetUrl ?? null
    this.isScratched = obj?.isScratched ?? false
    this.ticketStatus = obj?.ticketStatus ?? 'available'
    this.assignmentDate = obj?.assignmentDate ?? null
    this.ticketType = obj?.ticketType ?? 'spectator'
    this.ticketPrice = obj?.ticketPrice ?? 0
    this.ticketPrice = obj?.ticketPrice ?? 0
    this.ticketTemplateUrl = obj?.ticketTemplateUrl ?? null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.created!,
    })

    this.updated = this.utcTimestamp({
      key: 'updated',
      isTimestamp: true,
      value: obj?.updated!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new AssignTicketsModal({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object().shape({
      tickets: yup.array().of(
        yup.object().shape({
          assignerName: yup.string().required('This Field is required.'),
          assignerEmail: yup
            .string()
            .email('Please enter valid email')
            .required('This Field is required.'),
        })
      ),
    })
  }

  static spectatorValidationSchema() {
    return yup.object().shape({
      tickets: yup.array().of(
        yup.object().shape({
          assignerName: yup.string(),
          assignerEmail: yup.string().email('Please enter valid email'),
        })
      ),
    })
  }
}
