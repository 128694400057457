import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IUserInterface } from '../../models/users/user.interface'

const initialState: IUserInterface[] = []

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    setAllUsers: (_state, { payload }: { payload: IUserInterface[] }) => payload,
  },
})

export const { setAllUsers } = usersSlice.actions

export const selectAllUsers = (state: RootState) => state.users

export default usersSlice.reducer
